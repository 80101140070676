import React from 'react'
import useSWRImmutable from 'swr'
import {get, capitalize} from 'lodash-es'
import {
  chakra,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Skeleton,
} from '@chakra-ui/react'
import {useRails} from '../../../../../contexts/rails'
import {fetcher} from '../../../../../../api'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'
import RewardLevelBadge from '../../../../../components/SupplierRewardsModal/RewardLevelBadge'

import SupplierRewardTierRequirements from '../../../../../components/SupplierRewardTierRequirements'

const SupplierRewardsCard = () => {
  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )
  const {data, isLoading} = useSWRImmutable(
    `/api/suppliers/${organisation.currentSupplier.id}/supplier_rewards_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      revalidateOnFocus: false,
    },
  )

  if (isLoading) {
    return null
  }

  const currentLevelIndex = data.levels.findIndex(
    (level) => level.key === data.currentLevel,
  )
  const currentLevelData = data.levels.find(
    (level) => level.key === data.currentLevel,
  )
  const nextLevelData = data.levels[currentLevelIndex + 1]
  const previousLevel = data.levels[currentLevelIndex - 1]
  const pendingDowngrade = data.pendingDowngrade && previousLevel

  return (
    <Box
      display="flex"
      flexDirection="column"
      bg="neutral.white"
      p="md"
      my="md"
      border="1px solid"
      borderColor="neutral.glitter"
      borderRadius="lg"
      data-testid="supplier-rewards-card"
    >
      <Text fontSize="md" fontWeight="medium">
        Current status
      </Text>

      <Box
        display="flex"
        flexDirection="row"
        flexWrap={{base: 'wrap', md: 'nowrap'}}
        gap="md"
        mt="xs"
      >
        <Skeleton
          height="112px"
          width="112px"
          isLoaded={!isLoading}
          fontSize="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
        >
          {!isLoading && (
            <RewardLevelBadge
              key={currentLevelData.key}
              level={currentLevelData}
              current
              scale={0.7}
            />
          )}
        </Skeleton>
        {nextLevelData && !pendingDowngrade ? (
          <Box maxWidth={{lg: '42%'}} mb="xxs">
            <Text fontSize="base" fontWeight="500">
              How to upgrade to{' '}
              <chakra.span textTransform="uppercase">
                {nextLevelData.key}
              </chakra.span>{' '}
              status:
            </Text>
            <Box borderRadius="sm" background="neutral.100" p="sm">
              <SupplierRewardTierRequirements
                requirements={nextLevelData.requirements}
                tierKey={nextLevelData.key}
              />
            </Box>
          </Box>
        ) : (
          <Box maxWidth={{lg: '42%'}} mb="xxs">
            <Text fontSize="base" fontWeight="500">
              How to stay on{' '}
              <chakra.span textTransform="uppercase">
                {currentLevelData.key}
              </chakra.span>{' '}
              status:
            </Text>
            <Box borderRadius="sm" background="neutral.100" p="sm">
              <SupplierRewardTierRequirements
                requirements={currentLevelData.requirements}
                tierKey={currentLevelData.key}
              />
            </Box>
          </Box>
        )}
        {nextLevelData && !pendingDowngrade ? (
          <Box maxWidth={{lg: '42%'}}>
            <Text fontSize="base" fontWeight="500">
              Why upgrade?
            </Text>
            <Box borderRadius="sm" background="neutral.100" p="sm">
              <UnorderedList fontSize="sm" spacing="xxs">
                {!fixedPricingEnabled && (
                  <>
                    <ListItem>
                      <Text as="span" textStyle="body-small">
                        Lower your cash-in fee
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text as="span" textStyle="body-small">
                        More invoices will be available with a fee rate under{' '}
                        {data.maximumPrice} to be cashed-in
                      </Text>
                    </ListItem>
                  </>
                )}
                {fixedPricingEnabled && (
                  <ListItem>
                    <Text as="span" textStyle="body-small">
                      More invoices will be available to be cashed-in
                    </Text>
                  </ListItem>
                )}
              </UnorderedList>
            </Box>
          </Box>
        ) : (
          <Box maxWidth={{lg: '42%'}}>
            <Text fontSize="base" fontWeight="500">
              Why stay on {capitalize(currentLevelData.key)} status?
            </Text>
            <Box borderRadius="sm" background="neutral.100" p="sm">
              <UnorderedList fontSize="sm" spacing="xxs">
                <ListItem>Lowest fee possible</ListItem>
              </UnorderedList>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SupplierRewardsCard
