import React from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Text,
  SimpleGrid,
  useCheckbox,
  useCheckboxGroup,
} from '@chakra-ui/react'
import {createContext} from '@chakra-ui/react-utils'
import Checkbox from '../Checkbox'

const [CheckboxCardGroupProvider, useCheckboxCardGroupContext] = createContext({
  name: 'CheckboxCardGroupContext',
  strict: false,
})

export const CheckboxCard = ({children, onClick, ...props}) => {
  const group = useCheckboxCardGroupContext()

  const {getCheckboxProps: getProps} = useCheckboxGroup(group)
  const checkboxProps = getProps({...group, ...props})

  const {getInputProps, state} = useCheckbox(checkboxProps)

  const {style, type, ...input} = getInputProps()

  return (
    <Flex
      cursor="pointer"
      borderWidth="1px"
      borderRadius="md"
      borderColor="neutral.20"
      alignItems="center"
      _disabled={{cursor: 'not-allowed'}}
      _hover={{bg: 'neutral.dust'}}
      _focus={{boxShadow: 'outline'}}
    >
      <Checkbox
        {...input}
        onChange={group.onChange ?? input.onChange}
        inputProps={{onClick}}
        isChecked={state.isChecked}
        px={5}
        py={4}
        width="100%"
      >
        <Text fontSize="base" fontWeight="medium" ml={1}>
          {children}
        </Text>
      </Checkbox>
    </Flex>
  )
}

CheckboxCard.defaultProps = {
  onClick: undefined,
}

CheckboxCard.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}

export const CheckboxCardGroup = ({columns, children, ...props}) => (
  <CheckboxCardGroupProvider value={props}>
    <SimpleGrid gap="sm" columns={columns}>
      {children}
    </SimpleGrid>
  </CheckboxCardGroupProvider>
)

CheckboxCardGroup.defaultProps = {
  columns: 1,
  name: undefined,
  value: undefined,
  onChange: undefined,
}

CheckboxCardGroup.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}
