import React from 'react'
import PropTypes from 'prop-types'
import {Spinner, Tag, TagLabel, TagLeftIcon} from '@chakra-ui/react'
import {CheckCircleIcon, WarningIcon} from '@chakra-ui/icons'
import {useTranslation} from 'react-i18next'

const BillPaymentStatus = ({status}) => {
  const {t} = useTranslation('billPayments')

  const colorScheme = {
    successful: 'success',
    created: 'info',
    processing: 'info',
    failed: 'alert',
  }[status]

  const StatusIcon = {
    successful: CheckCircleIcon,
    created: Spinner,
    processing: Spinner,
    failed: WarningIcon,
  }[status]

  return (
    <Tag variant="status" colorScheme={colorScheme}>
      <TagLeftIcon
        as={StatusIcon}
        color={`mld.${colorScheme}.700`}
        label="status-icon"
        speed="1.0s"
      />
      <TagLabel>
        {t(`billPayments:billPayment.billPaymentStatus.${status}`)}
      </TagLabel>
    </Tag>
  )
}

BillPaymentStatus.propTypes = {
  status: PropTypes.oneOf(['successful', 'created', 'processing', 'failed'])
    .isRequired,
}

export default BillPaymentStatus
