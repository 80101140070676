import React from 'react'
import {useTranslation} from 'react-i18next'
import DrawerFieldItem from './DrawerFieldItem'
import DrawerSection from './DrawerSection'
import billPaymentPropType from '../../../utils/propTypes/billPayment'
import {formatAccountNumber} from '../../../utils/formatter'

const PayeeDetailsSection = ({billPayment}) => {
  const {t} = useTranslation('billPayments')

  return (
    <DrawerSection
      title={t('billPayments:billPayment.drawer.payeeSectionTitle')}
    >
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.name')}
        value={billPayment.payee.name}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.email')}
        value={billPayment.payee.email}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.branchCode')}
        value={billPayment.payee.branchCode}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.accountNumber')}
        value={formatAccountNumber(billPayment.payee.accountNumber)}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.businessNumber')}
        value={billPayment.payee.businessNumber}
      />
    </DrawerSection>
  )
}

PayeeDetailsSection.propTypes = {
  billPayment: billPaymentPropType.isRequired,
}

export default PayeeDetailsSection
