import React from 'react'
import PropTypes from 'prop-types'
import {flatten} from 'lodash-es'
import {
  Box,
  Text,
  MenuGroup,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {SortIcon} from '../../../../icons'
import DropdownSelect from '../../../../components/DropdownSelect'

const SortDropdown = ({onSort, value, options}) => {
  const onChange = (option) => {
    const id = option.value.split(' ')[0]
    const desc = option.value.split(' ')[1] === 'desc'
    onSort([{id, desc}])
  }

  const mappedOptions = options.map((option) => {
    if (option.options) {
      return option.options
    }
    return option
  })
  const flattenedOptions = flatten(mappedOptions)

  return (
    <>
      <Box
        width="fit-content"
        display={{base: 'none', md: 'flex'}}
        flexDirection="row"
      >
        <Text my="auto" textStyle="body-small" mr="sm">
          Sort by:
        </Text>
        <DropdownSelect
          name="sortBy"
          variant="sort"
          value={flattenedOptions.find((option) => option.value === value)}
          options={options}
          isClearable={false}
          isSearchable={false}
          onChange={onChange}
        />
      </Box>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<SortIcon boxSize={4} color="primary.deeppurple" />}
          display={{base: 'inline-flex', md: 'none'}}
          textTransform="uppercase"
          p="xs"
          backgroundColor="neutral.white"
          border="1px solid"
          borderColor="neutral.300"
          color="primary.deeppurple"
          fontSize="sm"
        >
          Sort
        </MenuButton>
        <MenuList zIndex="dropdown">
          {options.map((option) => {
            if (option.options) {
              return (
                <MenuGroup title={option.label}>
                  {option.options.map((subOption) => (
                    <MenuItem
                      _focus={{}}
                      backgroundColor={
                        value === subOption.value ? 'grey.100' : ''
                      }
                      onClick={() => {
                        onChange(subOption)
                      }}
                    >
                      {subOption.label}
                    </MenuItem>
                  ))}
                </MenuGroup>
              )
            }
            return (
              <MenuItem
                _focus={{}}
                backgroundColor={value === option.value ? 'grey.100' : ''}
                onClick={() => {
                  onChange(option)
                }}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}

SortDropdown.propTypes = {
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default SortDropdown
