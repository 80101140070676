import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionButton,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
  Text,
  Flex,
  Link,
  ListItem,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import {AddIcon, MinusIcon} from '@chakra-ui/icons'

import ConnectionError from './ConnectionErrorMessage'

const AccordionIcon = ({isExpanded}) =>
  isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />

AccordionIcon.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
}

const AccordionItem = ({title, children}) => (
  <ChakraAccordionItem
    borderTop="0px"
    _last={{borderBottom: '0px'}}
    paddingRight="xs"
  >
    {({isExpanded}) => (
      <>
        <AccordionButton borderBottom="1px" px="0">
          <Box flex="1" textAlign="left">
            <Text textStyle="body-copy-medium">{title}</Text>
          </Box>
          <AccordionIcon isExpanded={isExpanded} />
        </AccordionButton>
        <AccordionPanel px="0" pb={4}>
          {children}
        </AccordionPanel>
      </>
    )}
  </ChakraAccordionItem>
)

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const LearnMoreView = ({
  isLoading,
  startConnecting,
  tokenLoaded,
  onboardingFlow,
}) => {
  return (
    <Box>
      <Box>
        {!onboardingFlow && (
          <Text textStyle="body-intro-medium" pb="sm">
            Connect your bank account to Marmalade securely
          </Text>
        )}
        <Text pb="md" textStyle="body-small">
          Connecting your bank account helps us verify business transactions,
          which will help us better support your business and enable smooth
          payment processes.
        </Text>
      </Box>

      <Accordion defaultIndex={[0]} allowToggle paddingRight="xxs">
        <AccordionItem title="How does bank connection data and verification work?">
          <Text textStyle="body-small">
            We partnered with{' '}
            <Link
              href="https://www.yodlee.com"
              rel="nofollow"
              target="_blank"
              variant="primary"
            >
              Yodlee
            </Link>
            , a world leader in banking technology, to link your bank accounts
            securely. Marmalade does not store your login credentials, and all
            sensitive financial data is encrypted and protected by Yodlee.
            Yodlee is compliant with{' '}
            <Link
              href="https://treasury.gov.au/policy-topics/economy/consumer-data-right"
              rel="nofollow"
              target="_blank"
              variant="primary"
            >
              Australia&apos;s Consumer Data Right
            </Link>
            . You can learn more about Yodlee&apos;s security practices{' '}
            <Link
              href="https://www.yodlee.com/au/company/secure-data"
              rel="nofollow"
              target="_blank"
              variant="primary"
            >
              here
            </Link>
            .
          </Text>
        </AccordionItem>
        <AccordionItem title="How does Marmalade access and use my business transactions?">
          <Text textStyle="body-small">
            At Marmalade, we take your security seriously. We have partnered
            with Yodlee, a global leader in the secure hosting and encryption of
            financial data that uses bank-grade security and data encryption
            services. Yodlee is used by many major intentional banks and trusted
            by over 70 million users worldwide.
          </Text>

          <Text pt="sm" textStyle="body-small">
            It is important to note:
          </Text>
          <UnorderedList pl="xxs">
            <ListItem textStyle="body-small">
              Marmalade cannot make any changes to your financial accounts
            </ListItem>
            <ListItem textStyle="body-small">
              Marmalade cannot approve payments or fund transfers
            </ListItem>
            <ListItem textStyle="body-small">
              Marmalade does not store your login credentials
            </ListItem>
            <ListItem textStyle="body-small">
              We can only use your data to verify bank ownership and to
              understand how Marmalade can support your business.
            </ListItem>
          </UnorderedList>
        </AccordionItem>
        <AccordionItem title="What happens if I disconnect my bank account from Marmalade?">
          <Text textStyle="body-small">
            If you disconnect the bank connection, we will stop collecting data
            from your bank accounts, and upon written request, we will delete
            the data we collected from the accounts, unless the retention of
            this information is mandated by law.
          </Text>

          <Text textStyle="body-small">
            Once disconnected, we will be limited in our ability to support you
            with features like Cash-in.
          </Text>

          <Text textStyle="body-small">
            If you wish to disconnect your bank accounts from Marmalade, please
            contact us at{' '}
            <Link href="mailto:support@withmarmalade.com" variant="primary">
              support@withmarmalade.com
            </Link>
            .
          </Text>
        </AccordionItem>
        <AccordionItem title="Which bank account should I connect with Marmalade?">
          <Text textStyle="body-small">
            If the account you nominated to receive payments into is not your
            primary business transaction account, we&apos;ll need you to also
            connect your primary business transaction account.
          </Text>
        </AccordionItem>
      </Accordion>

      {!onboardingFlow && (
        <Flex mb="sm" justifyContent="center">
          {isLoading && (
            <Button variant="primary" isLoading minW="70%">
              Connect bank account now
            </Button>
          )}

          {!isLoading && tokenLoaded && (
            <Button variant="primary" onClick={startConnecting} minW="70%">
              Connect bank account now
            </Button>
          )}
        </Flex>
      )}

      {!isLoading && !tokenLoaded && <ConnectionError />}
    </Box>
  )
}

LearnMoreView.defaultProps = {
  onboardingFlow: false,
}

LearnMoreView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
  startConnecting: PropTypes.func.isRequired,
  onboardingFlow: PropTypes.bool,
}

export default LearnMoreView
