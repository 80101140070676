export default {
  defaultProps: {
    variant: 'solid',
    borderRadius: 'md',
  },
  baseStyle: {
    icon: {
      '&[data-status="warning"]': {
        color: 'mld.warning.700',
        svg: {
          height: '20px',
          width: '20px',
        },
      },
      '&[data-status="error"]': {
        color: 'mld.alert.700',
        svg: {
          backgroundColor: 'mld.neutral.50',
          borderRadius: 'full',
          height: '20px',
          width: '20px',
        },
      },
      '&[data-status="success"]': {
        color: 'mld.success.700',
        svg: {
          backgroundColor: 'mld.neutral.50',
          borderRadius: 'full',
          height: '20px',
          width: '20px',
        },
      },
      '&[data-status="info"]': {
        color: 'mld.neutral.900',
        svg: {
          height: '20px',
          width: '20px',
        },
      },
    },
    title: {
      lineHeight: 3,
    },
    description: {
      lineHeight: 3,
    },
    container: {
      '&[data-status="warning"]': {
        background: 'mld.warning.100',
        color: 'mld.neutral.900',
      },
      '&[data-status="error"]': {
        background: 'mld.alert.200',
        color: 'mld.neutral.900',
      },
      '&[data-status="success"]': {
        background: 'mld.success.100',
        color: 'mld.neutral.900',
      },
      '&[data-status="info"]': {
        background: 'mld.neutral.200',
        color: 'mld.neutral.900',
      },
    },
  },
}
