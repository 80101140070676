import React, {useEffect} from 'react'
import {Box, Text, Flex, Link, Button} from '@chakra-ui/react'

import {CheckIcon, InfoIcon, WarningIcon} from '@chakra-ui/icons'
import {createSetupVerificationInvoice} from '../../../../api'
import StepTitle from './StepTitle'
import {useRails} from '../../../contexts/rails'

export const stepName = 'updateInvoiceTemplate'

const calculateStatus = ({marmaladeInvoiceSetupStatus, submittedRequest}) => {
  if (marmaladeInvoiceSetupStatus === 'verified') {
    return 'completed'
  }
  if (submittedRequest || marmaladeInvoiceSetupStatus === 'pending') {
    return 'reviewing'
  }
  if (marmaladeInvoiceSetupStatus === 'declined') {
    // We are setting the status as incomplete to allow the user to re-submit once the issue is resolved.
    return 'incomplete'
  }

  return 'incomplete'
}

/* eslint-disable react/prop-types */
const DeclinedNotification = ({accountingProviderName}) => {
  const howToUpdateUrl =
    accountingProviderName === 'Xero'
      ? 'https://marmalade.ladesk.com/263421-Create-a-Marmalade-Branding-Theme'
      : 'https://marmalade.ladesk.com/494873-Quickbooks---Update-Invoice-Form-Style'

  return (
    <Flex alignItems="start">
      <WarningIcon boxSize="13px" color="red.600" mt="xxs" mr="xxs" />
      <Box>
        <Text textStyle="body-small">
          Invoice template not updated, try again.
        </Text>
        <Text textStyle="body-small">
          Still unsure?{' '}
          <Link
            href={howToUpdateUrl}
            isExternal
            target="_blank"
            color="secondary.purple"
          >
            Go to help article
          </Link>
        </Text>
      </Box>
    </Flex>
  )
}

const calculateNotification = ({
  status,
  isCurrentStep,
  accountingProvider,
  marmaladeInvoiceSetupStatus,
}) => {
  if (status === 'reviewing') {
    return (
      <Flex alignItems="start">
        <InfoIcon boxSize="13px" color="secondary.blue" mt="xxs" mr="xxs" />
        <Text textStyle="body-small">
          We are verifying the update. This may take a few hours.
        </Text>
      </Flex>
    )
  }

  if (marmaladeInvoiceSetupStatus === 'declined') {
    return (
      <DeclinedNotification accountingProviderName={accountingProvider.name} />
    )
  }

  if (status === 'incomplete' && isCurrentStep) {
    return (
      <Flex alignItems="start">
        <Text textStyle="body-small">
          Please check your email & follow the instruction
        </Text>
      </Flex>
    )
  }

  return null
}
/* eslint-enable react/prop-types */

export default (stepperData) => {
  const {authenticityToken, accountingProvider} = useRails()

  const [submittedRequest, setSubmittedRequest] = React.useState(false)

  const onClick = async () => {
    await createSetupVerificationInvoice({
      supplierId: stepperData.supplierId,
      authenticityToken,
    })
    setSubmittedRequest(true)
  }
  const isCurrentStep = stepperData.currentStep === stepName
  const status = calculateStatus({...stepperData, submittedRequest})

  useEffect(() => {
    // Reset the submitting override state when the status is changed
    setSubmittedRequest(false)
  }, [stepperData.marmaladeInvoiceSetupStatus])

  return {
    name: stepName,
    visible: true,
    status,
    icon: (
      <Text color="inherit" textStyle="body-copy">
        {stepperData.showingBankConnectionStep ? 4 : 3}
      </Text>
    ),
    title: (
      <StepTitle
        title="Update invoice template"
        tooltipParagraphs={[
          'Marmalade auto reconciles your payments by setting you up with a virtual bank account. You’ll receive the virtual bank account details and the instructions email about how to add it to your invoice template in accounting platform.',
        ]}
      />
    ),
    notification: calculateNotification({
      status,
      isCurrentStep,
      accountingProvider,
      marmaladeInvoiceSetupStatus: stepperData.marmaladeInvoiceSetupStatus,
    }),
    action: (
      <Box>
        <Button
          isDisabled={!isCurrentStep}
          variant="primary"
          size="sm"
          borderRadius="md"
          leftIcon={<CheckIcon boxSize="10px" />}
          onClick={onClick}
        >
          I&apos;ve done this
        </Button>
      </Box>
    ),
  }
}
