import React from 'react'

import PropTypes from 'prop-types'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  Box,
  Button,
} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {ThemeProvider, Input, LogoHorizontal, Alert} from '../../components'

const UpdatePasswordForm = ({
  authenticityToken,
  resetToken,
  resourceError,
  resourceName,
  updatePasswordPath,
}) => {
  const {values, dirty, touched, errors, handleChange, handleBlur} = useFormik({
    initialValues: {
      [resourceName]: {
        password: '',
        password_confirmation: '',
      },
    },
    validationSchema: Yup.object().shape({
      [resourceName]: Yup.object().shape({
        password: Yup.string()
          .min(8, 'Password must be 8 characters minimum')
          .required('Required'),
        password_confirmation: Yup.string().required('Required'),
      }),
    }),
  })

  return (
    <form method="POST" acceptCharset="UTF-8" action={updatePasswordPath}>
      <Text textStyle="body-copy" mb="sm">
        Change password
      </Text>

      <input type="hidden" name="_method" value="put" />
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input
        type="hidden"
        name={`${resourceName}[reset_password_token]`}
        value={resetToken}
      />

      {!!Object.keys(resourceError).length && (
        <Alert
          status="error"
          mb={8}
          message={
            <Box display="flex" flexDirection="column">
              {Object.keys(resourceError).map((key) => (
                <Text textStyle="body-small" key={key}>
                  {`${(key.slice(0, 1).toUpperCase() + key.slice(1)).replace(
                    /_/g,
                    ' ',
                  )} ${resourceError[key].map((e) => e.toLowerCase())}`}
                </Text>
              ))}
            </Box>
          }
        />
      )}

      <FormControl mb={4}>
        <FormLabel htmlFor="password" hidden>
          Password
        </FormLabel>
        <Input
          type="password"
          name={`${resourceName}[password]`}
          id={`${resourceName}_password`}
          placeholder="Password"
          aria-describedby="password-helper-text"
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          autoComplete="new-password"
          errorMessage={
            (touched[resourceName] &&
              touched[resourceName].password &&
              errors[resourceName] &&
              errors[resourceName].password) ||
            ''
          }
          value={values[resourceName].password}
        />
        <FormHelperText hidden id="password-helper-text">
          Enter your new password.
        </FormHelperText>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="password_conformation" hidden>
          Confirm new password
        </FormLabel>
        <Input
          type="password"
          name={`${resourceName}[password_confirmation]`}
          id={`${resourceName}_password_confirmation`}
          placeholder="Confirm new password"
          aria-describedby="password-confirmation-helper-text"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
          errorMessage={
            (touched[resourceName] &&
              touched[resourceName].password_confirmation &&
              errors[resourceName] &&
              errors[resourceName].password_confirmation) ||
            ''
          }
          value={values[resourceName].password_confirmation}
        />
        <FormHelperText hidden id="password-confirmation-helper-text">
          Enter your new password again for confirmation.
        </FormHelperText>
      </FormControl>

      <Button
        variant="secondary"
        type="submit"
        w="100%"
        isDisabled={!dirty || Object.keys(errors).length}
      >
        Change my password
      </Button>
    </form>
  )
}

UpdatePasswordForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  resetToken: PropTypes.string.isRequired,
  resourceError: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  resourceName: PropTypes.string.isRequired,
  updatePasswordPath: PropTypes.string.isRequired,
}

UpdatePasswordForm.defaultProps = {
  resourceError: {},
}

const UpdatePasswordScreen = ({
  authenticityToken,
  resourceError,
  resetToken,
  resourceName,
  updatePasswordPath,
  signInPath,
  unlockAccountInstructionPath,
}) => {
  return (
    <ThemeProvider>
      <Box minH="100vh">
        <Box
          maxWidth="500px"
          position="absolute"
          top="40%"
          left="0"
          right="0"
          transform="translateY(-40%)"
          px="sm"
          margin="0 auto"
        >
          <LogoHorizontal w="323px" h="38px" mb="md" />
          <UpdatePasswordForm
            authenticityToken={authenticityToken}
            resourceError={resourceError}
            resetToken={resetToken}
            resourceName={resourceName}
            updatePasswordPath={updatePasswordPath}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={4}
          >
            <Text as="a" href={signInPath} color="secondary.blue">
              Sign in
            </Text>
            <Text
              as="a"
              href={unlockAccountInstructionPath}
              color="secondary.blue"
            >
              Sign up
            </Text>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

UpdatePasswordScreen.propTypes = {
  resourceError: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  authenticityToken: PropTypes.string.isRequired,
  resetToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  updatePasswordPath: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  unlockAccountInstructionPath: PropTypes.string.isRequired,
}

export default UpdatePasswordScreen
