import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'
import {QuestionOutlineIcon} from '@chakra-ui/icons'

const IconTooltip = ({children, title, icon, iconColor, iconSize, onOpen}) => {
  const clonedIcon = React.cloneElement(icon, {
    color: iconColor,
    boxSize: iconSize,
    ml: 'xxs',
  })

  return (
    <Popover trigger="hover" placement="bottom" onOpen={onOpen}>
      <PopoverTrigger>
        {icon ? (
          clonedIcon
        ) : (
          <QuestionOutlineIcon color={iconColor} boxSize={iconSize} ml="xxs" />
        )}
      </PopoverTrigger>
      <PopoverContent
        bg="neutral.white"
        zIndex={1}
        width="300px"
        boxShadow="lg"
      >
        {!!title.length && (
          <PopoverHeader fontSize="sm" fontWeight="medium" border="0">
            {title}
          </PopoverHeader>
        )}
        <PopoverArrow />
        <PopoverBody fontSize="xs">{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

IconTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  onOpen: PropTypes.func,
}

IconTooltip.defaultProps = {
  title: '',
  iconColor: 'primary.actionblue',
  icon: <QuestionOutlineIcon />,
  iconSize: '0.85em',
  onOpen: () => {},
}

export default IconTooltip
