import {Box, Text, Link, Table, Tbody, Tr, Th, Td} from '@chakra-ui/react'
import {DownloadIcon} from '@chakra-ui/icons'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React from 'react'

import {Card} from '../../components'
import {toCurrency} from '../../../utils/formatter'

const InvoiceLine = ({label, value, note, operator}) => (
  <Tr>
    <Th>
      <Text>{label}:</Text>
    </Th>
    <Td>
      <Text>{operator}</Text>
    </Td>
    <Td>
      <Text>
        <strong>{value}</strong> {note && `(${note})`}
      </Text>
    </Td>
  </Tr>
)

InvoiceLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  note: PropTypes.string,
  operator: PropTypes.string,
}

InvoiceLine.defaultProps = {
  note: null,
  operator: null,
}

function dueDate(dueInDays) {
  if (dueInDays >= 0) {
    return `in ${dueInDays} ${pluralize('day', dueInDays)}`
  }

  const dueDaysAgo = Math.abs(dueInDays)

  return `${dueDaysAgo} ${pluralize('day', dueDaysAgo)} ago`
}

const InvoiceSummary = ({invoice}) => (
  <Box py="lg" borderBottom="1px solid" borderColor="neutral.glitter">
    <Card dark px="md">
      <Table variant="receipt">
        <Tbody>
          <InvoiceLine label="Invoice No" value={invoice.number} />
          <InvoiceLine label="Supplier" value={invoice.supplierName} />
          <InvoiceLine
            label="Amount"
            value={toCurrency(invoice.amount.cents)}
          />

          {!invoice.isVoided &&
            invoice.dueAmount.cents !== invoice.amount.cents && (
              <>
                {invoice.appliedCreditNoteAmount.cents > 0 && (
                  <InvoiceLine
                    label="Credit Notes"
                    operator="-"
                    value={toCurrency(invoice.appliedCreditNoteAmount.cents)}
                  />
                )}

                <InvoiceLine
                  label="Payments"
                  operator="-"
                  value={toCurrency(invoice.invoicePaymentsAmount.cents)}
                />

                <InvoiceLine
                  label="Due Amount"
                  value={toCurrency(invoice.dueAmount.cents)}
                />
              </>
            )}

          <InvoiceLine
            label="Due"
            value={invoice.dueDate}
            note={dueDate(invoice.dueInDays)}
          />

          {invoice.isPaid && <InvoiceLine label="Status" value="Paid" />}
          {invoice.isVoided && <InvoiceLine label="Status" value="Voided" />}
        </Tbody>
      </Table>

      {invoice.pdfAvailable && (
        <Box mt="sm">
          <Link
            color="secondary.blue"
            rel="nofollow"
            href={`/pay/pdf/${invoice.xeroInvoiceId}`}
          >
            <DownloadIcon mr="sm" />
            Invoice PDF
          </Link>
        </Box>
      )}
    </Card>
  </Box>
)

InvoiceSummary.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xeroInvoiceId: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    isPaid: PropTypes.bool.isRequired,
    isVoided: PropTypes.bool.isRequired,
    amount: PropTypes.shape({
      cents: PropTypes.number,
      currency_iso: PropTypes.string,
    }).isRequired,
    dueAmount: PropTypes.shape({
      cents: PropTypes.number,
      currency_iso: PropTypes.string,
    }).isRequired,
    appliedCreditNoteAmount: PropTypes.shape({
      cents: PropTypes.number,
      currency_iso: PropTypes.string,
    }).isRequired,
    invoicePaymentsAmount: PropTypes.shape({
      cents: PropTypes.number,
      currency_iso: PropTypes.string,
    }).isRequired,
    dueDate: PropTypes.string.isRequired,
    dueInDays: PropTypes.number.isRequired,
    pdfAvailable: PropTypes.bool.isRequired,
  }).isRequired,
}

export default InvoiceSummary
