import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Box, DarkMode} from '@chakra-ui/react'

import backgroundImage from '../../../../assets/images/pillars_background.svg'

import {LogoHorizontalSecondary, ThemeProvider} from '../../components'

const SignInLayout = ({children}) => {
  i18next.loadNamespaces('signInPage')

  return (
    <ThemeProvider>
      <DarkMode>
        <Box
          minH="100vh"
          backgroundColor="black"
          backgroundRepeat="repeat-x"
          backgroundSize="auto 100%"
          sx={{
            'background-image': `url("${backgroundImage}")`,
          }}
        >
          <Box
            maxWidth="500px"
            position="absolute"
            top="40%"
            left="0"
            right="0"
            transform="translateY(-40%)"
            px="sm"
            margin="0 auto"
          >
            <LogoHorizontalSecondary
              maxWidth="346px"
              w="100%"
              maxHeight="40px"
              mb="md"
              mx="auto"
            />
            {children}
          </Box>
        </Box>
      </DarkMode>
    </ThemeProvider>
  )
}

SignInLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SignInLayout
