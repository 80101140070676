import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, ButtonGroup, Text} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {createCustomerAccount} from '../../../api'
import {Alert, Input} from '../../components'

const createAccountFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Password must be 8 characters minimum')
    .required('Required'),
})

const PaymentSuccess = ({
  supplierName,
  customerDetails,
  authenticityToken,
  isLoggedIn,
}) => {
  const [requestErrorMessage, setRequestErrorMessage] = React.useState('')

  const formik = useFormik({
    initialValues: {
      email: (customerDetails && customerDetails.email) || '',
      password: '',
    },
    validationSchema: createAccountFormSchema,
    onSubmit: async (values) => {
      const {email, password} = values

      const result = await createCustomerAccount({
        authenticityToken,
        email,
        password,
      }).catch(() => ({
        success: false,
        message: 'Error in creating account',
      }))

      if (result.success) {
        // Redirect to customer invoice list page on success
        window.location.href = '/customer-invoices'
      } else {
        // When request is not successful without message,
        // it would mean user has a login with the same email
        setRequestErrorMessage(
          result.message || 'Account has already been created',
        )
      }
    },
  })

  return (
    <Box mt="lg">
      <Text fontSize="lg" mb="sm">
        Thank you!
      </Text>
      {isLoggedIn && (
        <Text fontSize="18px">
          Return to{' '}
          <Text
            as="a"
            href="/customer-invoices"
            fontSize="inherit"
            textDecoration="underline"
          >
            dashboard
          </Text>{' '}
          to see your paid invoices.
        </Text>
      )}
      {!isLoggedIn && customerDetails.hasLoginAccount && (
        <Text fontSize="18px">
          <Text
            as="a"
            href="/customer_logins/sign_in"
            fontSize="inherit"
            textDecoration="underline"
          >
            Sign in
          </Text>{' '}
          to see your paid invoices.
        </Text>
      )}
      {!isLoggedIn && !customerDetails.hasLoginAccount && (
        <>
          <Text fontSize="18px">
            Create an account for easy future payments to {supplierName}
          </Text>
          <Box as="form" mt="md" onSubmit={formik.handleSubmit}>
            {requestErrorMessage && (
              <Alert status="error" message={requestErrorMessage} mb="sm" />
            )}
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isDisabled={Boolean(customerDetails.email)}
              isInvalid={Boolean(formik.touched.email && formik.errors.email)}
              errorMessage={formik.touched.email && formik.errors.email}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(
                formik.touched.password && formik.errors.password,
              )}
              errorMessage={formik.touched.password && formik.errors.password}
            />
            <ButtonGroup mt="md">
              <Button
                variant="primary"
                isDisabled={!formik.isValid}
                type="submit"
              >
                Create account
              </Button>
              <Button as="a" variant="secondary" href="/" px="md">
                I&apos;ll skip for now
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}
    </Box>
  )
}

PaymentSuccess.defaultProps = {
  isLoggedIn: false,
}

PaymentSuccess.propTypes = {
  supplierName: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  customerDetails: PropTypes.shape({
    hasLoginAccount: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  isLoggedIn: PropTypes.bool,
}

export default PaymentSuccess
