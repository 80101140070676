import React from 'react'
import useSWRImmutable from 'swr'
import {capitalize} from 'lodash-es'
import {Box, Flex, SkeletonText, Text} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'
import {useRails} from '../../contexts/rails'
import {useSupplierRewardModal} from '../../contexts/supplierRewardsModal'
import {fetcher} from '../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'

const SupplierRewardsModalButton = () => {
  const {organisation} = useRails()
  const {data, isLoading} = useSWRImmutable(
    `/api/suppliers/${organisation.currentSupplier.id}/supplier_rewards_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      revalidateOnFocus: false,
    },
  )

  const {onOpen} = useSupplierRewardModal()

  return (
    <Box width="100%" px="xs">
      <Flex
        as="button"
        onClick={onOpen}
        cursor="pointer"
        direction="row"
        justifyContent="space-between"
        width="100%"
        borderRadius="base"
        p="xs"
        color="mld.sideNav.btnColor"
        _hover={{
          backgroundColor: 'mld.sideNav.btnHoverBg',
          color: 'mld.sideNav.btnHoverColor',
        }}
      >
        <Flex>
          <Box
            width="20px"
            height="20px"
            borderRadius="base"
            mr="xs"
            my="auto"
            background={data && `mld.rewards.${data.currentLevel}`}
          />
          <SkeletonText
            my="auto"
            isLoaded={!isLoading && data}
            py="0"
            noOfLines={1}
          >
            <Text textStyle="body-small">
              {data && capitalize(data.currentLevel)} Status
            </Text>
          </SkeletonText>
        </Flex>
        <ChevronRightIcon h="24px" w="24px" />
      </Flex>
    </Box>
  )
}

export default SupplierRewardsModalButton
