import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text} from '@chakra-ui/react'
import patternBackground from '../../../../assets/images/pattern_background.svg'

export const EmptyListContainer = ({children, px}) => (
  <Box
    display="flex"
    height={['200px', '400px', '500px', '700px']}
    my="sm"
    px={px}
  >
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="md"
      flex={1}
      backgroundImage={patternBackground}
      backgroundSize="cover"
    >
      {children}
    </Box>
  </Box>
)

EmptyListContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  px: PropTypes.string,
}

EmptyListContainer.defaultProps = {
  px: 'md',
}

export const EmptyListHeaderTag = ({text}) => (
  <Text fontWeight="500" fontSize={['md', '32px']}>
    {text}
  </Text>
)

EmptyListHeaderTag.propTypes = {
  text: PropTypes.string.isRequired,
}

export const EmptyListHeader = ({resourceName}) => (
  <EmptyListHeaderTag text={`No ${resourceName} found`} />
)

EmptyListHeader.propTypes = {
  resourceName: PropTypes.string.isRequired,
}

export const EmptyList = ({resourceName}) => (
  <EmptyListContainer>
    <EmptyListHeader resourceName={resourceName} />
    <Text textStyle="body-copy">You currently have no {resourceName}</Text>
  </EmptyListContainer>
)

EmptyList.defaultProps = {
  resourceName: 'Elements',
}

EmptyList.propTypes = {
  resourceName: PropTypes.string,
}

export const FilterEmptyList = ({resourceName}) => (
  <EmptyListContainer>
    <EmptyListHeader resourceName={resourceName} />
    <Text textStyle="body-copy">
      There are no {resourceName} that match these filters.
    </Text>
    <Text textStyle="body-copy">Please refine your search filters.</Text>
  </EmptyListContainer>
)

FilterEmptyList.defaultProps = {
  resourceName: 'Elements',
}

FilterEmptyList.propTypes = {
  resourceName: PropTypes.string,
}
