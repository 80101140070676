import React from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, SimpleGrid, useRadio, useRadioGroup} from '@chakra-ui/react'
import {createContext} from '@chakra-ui/react-utils'

const [RadioCardGroupProvider, useRadioCardGroupContext] = createContext({
  name: 'RadioCardGroupContext',
  strict: false,
})

export const RadioCard = ({
  children,
  onClick,
  compact,
  align,
  height,
  borderRadius,
  checkedBorderColor,
  checkedBorderWidth,
  checkedBackgroundColor,
  focusShadow,
  ...props
}) => {
  const group = useRadioCardGroupContext()
  const {getRadioProps} = useRadioGroup(group)

  const radioProps = getRadioProps(props)
  const {getInputProps, getCheckboxProps, htmlProps, getLabelProps} =
    useRadio(radioProps)

  const input = getInputProps()

  return (
    <Box as="label" {...htmlProps}>
      <input
        {...input}
        onClick={onClick}
        onChange={group.onChange ?? input.onChange}
      />
      <Flex
        {...getCheckboxProps()}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={borderRadius}
        borderColor="neutral.20"
        // minHeight="80px"
        minHeight={compact ? '0' : '80px'}
        align={align}
        height={height}
        direction="column"
        justify="center"
        px="md"
        py="sm"
        _disabled={{cursor: 'not-allowed'}}
        _hover={{bg: 'neutral.dust'}}
        _focus={{boxShadow: focusShadow}}
        _checked={{
          bg: checkedBackgroundColor,
          borderColor: checkedBorderColor,
          borderWidth: checkedBorderWidth,
          color: 'primary.actionblue',
          fontWeight: 'medium',
        }}
      >
        <span {...getLabelProps()}>{children}</span>
      </Flex>
    </Box>
  )
}

RadioCard.defaultProps = {
  onClick: undefined,
  align: 'center',
  height: 'auto',
  borderRadius: 'md',
  compact: false,
  checkedBorderColor: 'background.purple',
  checkedBorderWidth: '1px',
  checkedBackgroundColor: 'background.purple',
  focusShadow: 'outline',
}

RadioCard.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  align: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  checkedBorderColor: PropTypes.string,
  checkedBorderWidth: PropTypes.string,
  checkedBackgroundColor: PropTypes.string,
  focusShadow: PropTypes.string,
  compact: PropTypes.bool,
}

export const RadioCardGroup = ({columns, children, ...props}) => {
  const {getRootProps} = useRadioGroup(props)

  const groupProps = getRootProps()

  return (
    <RadioCardGroupProvider value={props}>
      <SimpleGrid gap="sm" columns={columns} {...groupProps}>
        {children}
      </SimpleGrid>
    </RadioCardGroupProvider>
  )
}

RadioCardGroup.defaultProps = {
  columns: 1,
  name: undefined,
  value: undefined,
  onChange: undefined,
}

RadioCardGroup.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}
