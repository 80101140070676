import React from 'react'
import PropTypes from 'prop-types'
import {CloseIcon} from '@chakra-ui/icons'
import {
  Box,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {GroupByIcon} from '../../../../icons'

const GroupOptions = ({onGroup, value, options}) => {
  return (
    <>
      <Box
        width="fit-content"
        flexDirection="row"
        display={{base: 'none', md: 'flex'}}
      >
        <Text my="auto" textStyle="body-small" mr="xxs">
          Group by:
        </Text>
        {options.map(({label: optionLabel, value: optionValue}) => (
          <Box
            data-testid={`groupBy${optionValue}`}
            p="xs"
            border="1px solid"
            borderColor="grey.300"
            borderRadius="base"
            my="auto"
            mx="xs"
            cursor="pointer"
            backgroundColor={
              value === optionValue ? 'primary.actionblue' : 'neutral.white'
            }
            color={value === optionValue ? 'neutral.white' : 'black'}
            fontSize="sm"
            fontWeight={value === optionValue ? 'medium' : 'normal'}
            _hover={{
              backgroundColor:
                value === optionValue ? 'primary.actionhover' : 'neutral.smoke',
            }}
            onClick={() => {
              if (value === optionValue) {
                onGroup(null)
              } else {
                onGroup(optionValue)
              }
            }}
          >
            {optionLabel}
            {value === optionValue && <CloseIcon ml="xs" boxSize="2" />}
          </Box>
        ))}
      </Box>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<GroupByIcon boxSize={4} color="primary.deeppurple" />}
          display={{base: 'inline-flex', md: 'none'}}
          textTransform="uppercase"
          p="xs"
          backgroundColor="neutral.white"
          border="1px solid"
          borderColor="neutral.300"
          color="primary.deeppurple"
          fontSize="sm"
        >
          Group By
        </MenuButton>
        <MenuList zIndex="dropdown">
          {options.map((option) => (
            <MenuItem
              _focus={{}}
              backgroundColor={value === option.value ? 'grey.100' : ''}
              onClick={() => {
                if (value === option.value) {
                  onGroup(null)
                } else {
                  onGroup(option.value)
                }
              }}
            >
              {option.label}
              {value === option.value && <CloseIcon ml="xs" boxSize="2" />}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}

GroupOptions.propTypes = {
  onGroup: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default GroupOptions
