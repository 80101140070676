import PropTypes from 'prop-types'
import DineroPropShape from './dinero'
import paymentMethodPropType from './paymentMethod'

const billPaymentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  payee: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    branchCode: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    businessNumber: PropTypes.string.isRequired,
  }).isRequired,
  netPaymentAmount: DineroPropShape.isRequired,
  grossPaymentAmount: DineroPropShape.isRequired,
  paymentReference: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  paymentMethod: paymentMethodPropType.isRequired,
})

export default billPaymentPropType
