import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {createBillPayment} from '../../../../api/billPayments'
import {useRails} from '../../../contexts/rails'

export default function useFormSubmit(supplierId) {
  const {t} = useTranslation('billPayments')

  const {authenticityToken} = useRails()

  const [errorMessage, setErrorMessage] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async (values) => {
    if (submitted) return false

    setSubmitted(true)
    setErrorMessage(null)

    return createBillPayment({
      supplierId,
      authenticityToken,
      params: {
        billPayment: {
          ...values,
          amount: {
            cents: values.amount,
            currency: 'AUD',
          },
        },
      },
    })
      .then(() => {
        window.location.href = '/bill_payments'
      })
      .catch(() => {
        setSubmitted(false)
        setErrorMessage(t('billPayments:billPayment.form.submitError'))
      })
  }

  return {
    submitted,
    errorMessage,
    onSubmit,
  }
}
