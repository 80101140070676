import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  Flex,
  Icon,
  Image,
  HStack,
  Link,
  Button,
} from '@chakra-ui/react'
import {ExternalLinkIcon} from '@chakra-ui/icons'

import ConnectionError from './ConnectionErrorMessage'
import yodleeHeaderImage from '../../../../../assets/images/yodlee-connection-header.svg'
import {ShieldIcon, PersonIcon, SparkleGraphIcon} from '../../../icons'

const InitialView = ({
  isLoading,
  tokenLoaded,
  startConnecting,
  showLearnMore,
}) => {
  return (
    <>
      <Image
        alt="Connect your bank account via Yodlee"
        margin="auto"
        src={yodleeHeaderImage}
      />

      <Box px="lg">
        <Text textStyle="body-intro-medium" textAlign="center" pb="md">
          Marmalade uses Yodlee to link your bank account
        </Text>

        <HStack pb="md">
          <Icon as={ShieldIcon} boxSize="6" />
          <Text pl="sm">
            The connection between Marmalade and your bank accounts is secured
            by Yodlee. <br />
            <Link
              color="primary.actionblue"
              href="https://www.yodlee.com/company/data-promise"
              rel="nofollow"
              target="_blank"
            >
              What&apos;s Yodlee? <ExternalLinkIcon />
            </Link>
          </Text>
        </HStack>

        <HStack pb="md">
          <Icon as={PersonIcon} boxSize="6" />
          <Text pl="sm">
            Your personal info is not sold to anyone. We can&apos;t move money
            from your bank accounts either.
          </Text>
        </HStack>

        <HStack pb="md">
          <Icon as={SparkleGraphIcon} boxSize="6" />
          <Text pl="sm">
            We use your transaction data to better understand your business
            needs.
          </Text>
        </HStack>

        <Flex pt="sm" justifyContent="center">
          {isLoading && (
            <Button variant="primary" width="80%" isLoading>
              Loading bank connection details
            </Button>
          )}

          {!isLoading && tokenLoaded && (
            <Button variant="primary" width="80%" onClick={startConnecting}>
              Continue
            </Button>
          )}

          {!isLoading && !tokenLoaded && <ConnectionError />}
        </Flex>

        <Flex pt="sm" mb="sm" justifyContent="center">
          <Button variant="secondary" width="80%" onClick={showLearnMore}>
            Learn more about bank connection
          </Button>
        </Flex>
      </Box>
    </>
  )
}

InitialView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
  startConnecting: PropTypes.func.isRequired,
  showLearnMore: PropTypes.func.isRequired,
}

export default InitialView
