import {Badge, Box, Stack, Text, Button, Flex} from '@chakra-ui/react'
import {map, get, snakeCase} from 'lodash-es'
import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import mixpanel from 'mixpanel-browser'
import dayjs from 'dayjs'
import SupplierLayout from '../../layouts/SupplierLayout'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import {EmptyList, FilterEmptyList, Pagination} from '../../components'
import DataTable from '../../components/DataTable'
import {useRails} from '../../contexts/rails'
import {fetcher} from '../../../api'
import {camelizeResult} from '../../../api/serializer'
import StatementInformationBanner from './StatementInformationBanner'
import CustomerFilter, {preFilterQuery} from './CustomerFilter'
import {AltCheckIcon, DotIcon} from '../../icons'
import Feedback from '../../components/Feedback'

const loadCustomerPage = (customerId) => {
  window.location.href = `/customers/${customerId}`
}

const customerRowClicked = (customer) => {
  mixpanel.track(`Customers Page: Customer Row clicked for customer`)
  loadCustomerPage(customer.id)
}

const viewDetailsClicked = (customerId, event) => {
  event.stopPropagation()
  mixpanel.track(`Customers Page: View Details clicked for customer`)
  loadCustomerPage(customerId)
}

const statementSentValue = (rowData) =>
  rowData.latestStatementSentAt
    ? dayjs(rowData.latestStatementSentAt).format('DD/MM/YYYY')
    : '-'

const overduePattern = /overdue/i
const dueSoonPattern = /due soon/i
const notDueYetPattern = /not due yet/i

const statusDotColor = (status) => {
  if (overduePattern.test(status)) {
    return 'mld.alert.700'
  }
  if (dueSoonPattern.test(status)) {
    return 'mld.warning.700'
  }
  if (notDueYetPattern.test(status)) {
    return 'mld.success.700'
  }
  return 'mld.neutral.500'
}

const statusTextColor = (status) => {
  if (overduePattern.test(status)) {
    return 'mld.alert.700'
  }
  return null
}

const columns = [
  {header: 'Name', accessorKey: 'contactName'},
  {
    header: 'No. of unpaid invoices',
    accessorKey: 'outstandingInvoiceCount',
    cell: ({getValue}) => {
      return <Text>{getValue()}</Text>
    },
  },
  {
    header: 'Invoice status',
    accessorKey: 'outstandingInvoiceStatus',
    cell: ({getValue, row}) => {
      const value = getValue()
      return (
        <Flex align="center" gap="2">
          <Badge variant="invoiceStatus">
            {row.original.outstandingInvoiceStatusCount}
          </Badge>
          <Text color={statusTextColor(value)}>{value}</Text>
          <DotIcon boxSize="15px" color={statusDotColor(value)} />
        </Flex>
      )
    },
  },
  {
    header: 'Last statement sent',
    accessorKey: 'latestStatementSentAt',
    accessorFn: statementSentValue,
    cell: ({getValue}) => {
      return <Text>{getValue()}</Text>
    },
  },
  {
    header: 'Statement opened',
    accessorKey: 'latestStatementOpenedAt',
    cell: ({getValue}) => {
      const value = getValue()
      return (
        <Text>
          {value ? dayjs(getValue()).format('DD/MM/YYYY') : '-'}
          {value && <AltCheckIcon ml="xs" />}
        </Text>
      )
    },
  },
  {
    header: '',
    accessorKey: 'id',
    cell: ({getValue}) => (
      <Button
        variant="secondary"
        onClick={(e) => viewDetailsClicked(getValue(), e)}
        height="8"
        data-testid="view_details_btn"
      >
        View Details
      </Button>
    ),
    enableSorting: false,
  },
]

const SupplierCustomersScreen = ({
  previousCustomerStatementAt,
  nextCustomerStatementAt,
}) => {
  const {
    params: preFilterParams,
    activeFilters: preActiveFilters,
    filterOption: preFilterOption,
  } = preFilterQuery(window.location.search)

  const [filterParams, setFilterParams] = React.useState(preFilterParams || '')
  const [filterOption, setFilterOption] = React.useState(preFilterOption || {})
  const [activeFilters, setActiveFilters] = React.useState(
    preActiveFilters || 0,
  )

  const [sortBy, setSortBy] = React.useState(null)
  const onSort = ([sort]) => {
    if (!sort) {
      setSortBy(null)
    } else {
      setSortBy(`${snakeCase(sort.id)} ${sort.desc ? 'desc' : 'asc'}`)
    }
  }

  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(20)
  React.useEffect(() => setPage(1), [filterParams, sortBy])

  const {organisation} = useRails()

  const constructUrl = () => {
    let url = `/api/suppliers/${organisation.currentSupplier.id}/customers?page=${page}&per_page=${perPage}`

    if (filterParams) url = `${url}&${filterParams}`
    if (sortBy) url = `${url}&q[s]=${sortBy}`

    return url
  }
  const {data, isLoading} = useSWR(constructUrl(), fetcher, {
    use: [camelizeResult],
  })

  const totalCount = get(data, 'metadata.totalCount', 0)
  const totalPages = get(data, 'metadata.totalPages', 0)

  const customers = map(get(data, 'customers'), (customer) => ({
    id: customer.id,
    contactName: customer.contactName,
    outstandingInvoiceCount: customer.outstandingInvoiceCount,
    outstandingInvoiceStatus: customer.outstandingInvoiceStatus,
    outstandingInvoiceStatusCount: customer.outstandingInvoiceStatusCount,
    latestStatementSentAt: customer.latestStatement?.sentAt,
    latestStatementOpenedAt: customer.latestStatement?.openedAt,
  }))

  const noFilters = activeFilters === 0

  const noData = customers.length === 0

  const emptyList = !isLoading && noData && noFilters

  const filterEmptyList = !isLoading && noData && !noFilters

  return (
    <SupplierLayout state="Customers">
      <Box flexGrow="1" overflow="scroll" whiteSpace="nowrap">
        <Stack
          direction="row"
          justifyContent="space-between"
          px="md"
          pt="sm"
          bg="neutral.white"
        >
          <Text fontSize="24px" fontWeight="medium">
            Customers
          </Text>

          <CustomerFilter
            activeFilters={activeFilters}
            filterOption={filterOption}
            setFilterParams={setFilterParams}
            setFilterOption={setFilterOption}
            setActiveFilters={setActiveFilters}
          />
        </Stack>

        <Feedback px="md" />

        {!emptyList && (
          <Box my="sm" px="md">
            <StatementInformationBanner
              previousAt={previousCustomerStatementAt}
              nextAt={nextCustomerStatementAt}
            />
          </Box>
        )}

        <Box display="flex" flexDirection="column" height="100vh">
          <DataTable
            data={customers}
            columns={columns}
            enableSorting
            manualSorting
            sortByValue={[{id: 'contactName', desc: false}]}
            isLoading={isLoading}
            onClickRow={customerRowClicked}
            onSort={onSort}
          />

          {emptyList && <EmptyList resourceName="customers" />}
          {filterEmptyList && <FilterEmptyList resourceName="customers" />}
        </Box>
      </Box>

      {totalCount > perPage && (
        <Box px="md" mb="md" left="0">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            totalCount={totalCount}
            setPerPage={setPerPage}
            perPage={perPage}
            onPageChange={setPage}
          />
        </Box>
      )}
    </SupplierLayout>
  )
}

SupplierCustomersScreen.propTypes = {
  previousCustomerStatementAt: PropTypes.string,
  nextCustomerStatementAt: PropTypes.string,
}

SupplierCustomersScreen.defaultProps = {
  previousCustomerStatementAt: null,
  nextCustomerStatementAt: null,
}

export default withProviders(withSWR(SupplierCustomersScreen))
