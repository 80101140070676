// eslint-disable-next-line import/no-extraneous-dependencies
import {tableAnatomy} from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const receipt = definePartsStyle(() => {
  return {
    td: {
      verticalAlign: 'top',
      padding: 0,
    },
    tr: {
      th: {
        verticalAlign: 'top',
        padding: 0,
        whiteSpace: 'nowrap',
        paddingRight: '1em',
        fontSize: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
      },
    },
  }
})

const baseStyle = {
  thead: {
    bg: 'white',
    position: 'sticky',
    zIndex: 'sticky',
    insetBlockStart: 0,
    borderColor: 'chakra-border-color',
    borderBottom: 'none !important',
    boxShadow: 'inset 0 -1px 0 var(--chakra-colors-chakra-border-color)',
    p: '1px 0',
  },
  th: {
    textTransform: 'none',
    letterSpacing: 'normal',
    textAlign: 'start',
    fontFamily: 'body',
    fontSize: 'sm',
    lineHeight: 3,
    fontWeight: 'medium',
    color: 'neutral.60',
  },
}

const sizes = {
  md: {
    th: {
      px: '6',
      py: '4',
      fontSize: 'sm',
      lineHeight: '3',
    },
    td: {
      px: '6',
      py: '2',
      fontSize: '16px',
    },
  },
  lg: {
    th: {
      fontSize: 'sm',
      lineHeight: '3',
    },
    td: {
      py: '7',
      fontSize: '16px',
    },
  },
}

export default defineMultiStyleConfig({sizes, baseStyle, variants: {receipt}})
