import React from 'react'
import {first, last} from 'lodash-es'

function range(start, end) {
  const length = end - start + 1
  return Array.from({length}, (_, index) => index + start)
}

const MAX_PAGES_TO_SHOW = 7

// eslint-disable-next-line consistent-return
function calculatePageNumbers(totalPages, currentPage) {
  if (MAX_PAGES_TO_SHOW >= totalPages) {
    return range(1, totalPages)
  }

  const leftSibling = Math.max(currentPage - 1, 1)
  const rightSibling = Math.min(currentPage + 1, totalPages)

  const shouldShowLeftMenu = leftSibling > 3
  const shouldShowRightMenu = rightSibling < totalPages - 2

  const firstPage = 1
  const lastPage = totalPages

  if (!shouldShowLeftMenu && shouldShowRightMenu) {
    const leftItemCount = MAX_PAGES_TO_SHOW - 2
    const leftRange = range(firstPage, leftItemCount)
    const rightMenuRange = range(last(leftRange) + 1, lastPage - 1)

    return [...leftRange, rightMenuRange, lastPage]
  }

  if (shouldShowLeftMenu && !shouldShowRightMenu) {
    const rightItemCount = MAX_PAGES_TO_SHOW - 2
    const rightRange = range(totalPages - rightItemCount + 1, totalPages)
    const leftMenuRange = range(firstPage + 1, first(rightRange) - 1)

    return [firstPage, leftMenuRange, ...rightRange]
  }

  if (shouldShowLeftMenu && shouldShowRightMenu) {
    const middleRange = range(leftSibling, rightSibling)
    const leftMenuRange = range(2, leftSibling - 1)
    const rightMenuRange = range(rightSibling + 1, lastPage - 1)

    return [firstPage, leftMenuRange, ...middleRange, rightMenuRange, lastPage]
  }
}

export default function usePagination({totalPages, currentPage}) {
  const pageNumbers = React.useMemo(() => {
    return calculatePageNumbers(totalPages, currentPage)
  }, [totalPages, currentPage])

  return pageNumbers
}
