import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CloseButton,
  CheckboxGroup,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react'
import Checkbox from '../../../components/Checkbox'
import CustomerFilter from '../../../components/CustomerFilter'

const initialFilterOptionState = {
  customer: undefined,
  includeNoOutstanding: false,
  invoiceStatus: [],
  statementStatus: undefined,
}

const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED'
const INCLUDE_NO_OUTSTANDING_UPDATED = 'INCLUDE_NO_OUTSTANDING_UPDATED'
const INVOICE_STATUS_UPDATED = 'INVOICE_STATUS_UPDATED'
const STATEMENT_STATUS_UPDATED = 'STATEMENT_STATUS_UPDATED'
const RESET = 'RESET'

const filterOptionReducer = (state, action) => {
  const {payload, type} = action
  switch (type) {
    case CUSTOMER_SELECTED:
      return {...state, customer: payload.customer}
    case INCLUDE_NO_OUTSTANDING_UPDATED:
      return {...state, includeNoOutstanding: payload.includeNoOutstanding}
    case INVOICE_STATUS_UPDATED:
      return {...state, invoiceStatus: payload.invoiceStatus}
    case STATEMENT_STATUS_UPDATED:
      return {...state, statementStatus: payload.statementStatus}
    case RESET:
      return initialFilterOptionState
    default:
      return state
  }
}

const initStateFn = (state) => ({...initialFilterOptionState, ...state})

const CustomerFilterOptions = ({
  filterOption,
  onClickApply,
  onClickReset,
  isOpen,
  onClose,
}) => {
  const [state, dispatch] = React.useReducer(
    filterOptionReducer,
    filterOption,
    initStateFn,
  )

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent data-testid="section-filters">
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="xs"
          >
            <Text fontSize="md">Filter customers</Text>

            <CloseButton onClick={onClose} tabIndex="-1" />
          </Box>
        </DrawerHeader>

        <DrawerBody overflowY="scroll">
          <Box mb="sm">
            <CustomerFilter
              value={state.customer}
              onChange={(value) =>
                dispatch({type: CUSTOMER_SELECTED, payload: {customer: value}})
              }
            />
          </Box>
          <Stack mb="md" data-testid="filter-exclude-no-outstanding">
            <Checkbox
              defaultChecked={state.includeNoOutstanding}
              onChange={(e) =>
                dispatch({
                  type: INCLUDE_NO_OUTSTANDING_UPDATED,
                  payload: {includeNoOutstanding: e.target.checked},
                })
              }
            >
              <Text>Include customers with no outstanding amounts</Text>
            </Checkbox>
          </Stack>

          <>
            <Text mb="sm" color="neutral.80" fontWeight="medium">
              Invoice status
            </Text>

            <CheckboxGroup
              name="invoice_status"
              defaultValue={state.invoiceStatus}
              onChange={(value) =>
                dispatch({
                  type: INVOICE_STATUS_UPDATED,
                  payload: {invoiceStatus: value},
                })
              }
            >
              <Stack mb="md" data-testid="filter-invoice-status">
                <Checkbox value="0">
                  <Text>Overdue</Text>
                </Checkbox>
                <Checkbox value="1">
                  <Text>Due soon</Text>
                </Checkbox>
                <Checkbox value="2">
                  <Text>Not due yet</Text>
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </>

          <Text mb="sm" color="neutral.80" fontWeight="medium">
            Statement status
          </Text>

          <RadioGroup
            name="statement_status"
            defaultValue={state.statementStatus}
            onChange={(value) =>
              dispatch({
                type: STATEMENT_STATUS_UPDATED,
                payload: {statementStatus: value},
              })
            }
          >
            <Stack mb="md" data-testid="filter-payment-status">
              <Radio value="opened">
                <Text>Opened</Text>
              </Radio>
              <Radio value="sent_not_opened">
                <Text>Sent, not opened</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </DrawerBody>

        <DrawerFooter
          justifyContent="flex-start"
          boxShadow="0px 9px 32px rgba(23, 27, 30, 0.15)"
        >
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                onClickApply(state)
              }}
              px="lg"
            >
              Apply
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch({type: RESET})

                // Trigger update
                onClickReset(initialFilterOptionState)
              }}
              px="lg"
            >
              Clear all filters
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

CustomerFilterOptions.defaultProps = {
  onClickApply: () => {},
  onClickReset: () => {},
  filterOption: {},
}

CustomerFilterOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickApply: PropTypes.func,
  onClickReset: PropTypes.func,
  filterOption: PropTypes.shape({
    customer: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
    includeNoOutstanding: PropTypes.bool,
    statementOpened: PropTypes.string,
  }),
}

export default CustomerFilterOptions
