import React from 'react'
import {Box} from '@chakra-ui/react'
import propTypes from 'prop-types'

import Step from './Step'
import ProgressBar from './ProgressBar'

const StepProgress = ({steps = [], currentState = ''}) => {
  const currentStateIndex =
    steps.findIndex((step) => step.state === currentState) || -1

  return (
    <Box display="flex" alignItems={['flexStart', 'center']}>
      {steps.reduce((currentList, step, index, originalList) => {
        const updatedList = currentList.concat(
          <Step
            {...step}
            isCurrentState={step.state === currentState}
            isBeforeCurrentState={index < currentStateIndex}
            key={`${step.state}-${index + 1}`}
          />,
        )

        // Add progress bar only if it is not the end of the list
        if (index !== originalList.length - 1) {
          return updatedList.concat(
            <ProgressBar
              isProgressed={index < currentStateIndex}
              key={`progressbar-${index + 1}`}
            />,
          )
        }

        return updatedList
      }, [])}
    </Box>
  )
}

StepProgress.propTypes = {
  steps: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      state: propTypes.string.isRequired,
    }),
  ).isRequired,
  currentState: propTypes.string.isRequired,
}

export default StepProgress
