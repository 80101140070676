import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {
  Box,
  CloseButton,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  Flex,
  OrderedList,
  ListItem,
  Divider,
  UnorderedList,
  Tooltip,
  Button,
} from '@chakra-ui/react'
import {toast} from 'react-toastify'
import {Trans, useTranslation} from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import Checkbox from '../Checkbox'
import {useRails} from '../../contexts/rails'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import InvoiceDetails from '../InvoiceDetails'
import DropdownSelect from '../DropdownSelect'
import DineroPropShape from '../../../utils/propTypes/dinero'
import {defaultHeaders, fetcher} from '../../../api'
import ToastMessage from '../Toast'
import {Alert} from '..'

const CreditNoteLabel = ({creditNoteNumber, amount}) => {
  return (
    <Flex justify="space-between">
      <Text textStyle="body-copy">{creditNoteNumber}</Text>
      <Text textStyle="body-copy" color="grey.700">
        {amount.toFormat()}
      </Text>
    </Flex>
  )
}

CreditNoteLabel.propTypes = {
  creditNoteNumber: PropTypes.string.isRequired,
  amount: PropTypes.shape(DineroPropShape).isRequired,
}

const ErrorAlert = ({
  creditNoteNumber,
  errorMessage,
  accountingProviderName,
  maxPercentage,
}) => {
  const {t} = useTranslation('invoicesPage')
  return (
    <Alert
      status="error"
      title={
        <Trans
          t={t}
          i18nKey="invoicesPage:allocateCreditNotePanel.errorTitle"
          values={{
            creditNoteNumber,
          }}
          components={{
            span: <Text as="span" fontWeight="medium" />,
          }}
        />
      }
      message={t(
        [
          `invoicesPage:allocateCreditNotePanel.errorMessages.${errorMessage}`,
          'invoicesPage:allocateCreditNotePanel.errorMessages.fallback',
        ],
        {
          accountingProviderName,
          maxPercentage,
        },
      )}
    />
  )
}

ErrorAlert.propTypes = {
  creditNoteNumber: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  accountingProviderName: PropTypes.string.isRequired,
  maxPercentage: PropTypes.number.isRequired,
}

const OpenCreditNotePanelButton = ({isDisabled, openDrawer}) => {
  const {t} = useTranslation('invoicesPage')

  return (
    <Box>
      <Button variant="secondary" isDisabled={isDisabled} onClick={openDrawer}>
        {t('invoicesPage:allocateCreditNotePanel.panelTriggerLabel')}
      </Button>
    </Box>
  )
}

OpenCreditNotePanelButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  openDrawer: PropTypes.func.isRequired,
}

const AllocateCreditNotePanel = ({
  invoiceId,
  onClose,
  triggerButton,
  delayFetch,
}) => {
  const {t} = useTranslation('invoicesPage')
  const {organisation, authenticityToken} = useRails()
  const [selectedOption, setSelectedOption] = useState()
  const [selectedCreditNote, setSelectedCreditNote] = useState()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const {isOpen, onClose: close, onOpen: openPanel} = useDisclosure()

  const {data: invoiceData, isLoading: invoiceLoading} = useSWR(
    delayFetch || isOpen
      ? `/api/suppliers/${organisation.currentSupplier.id}/invoices/${invoiceId}`
      : null,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {
    data: creditNotesData,
    isLoading: creditNotesLoading,
    mutate: refetchCreditNotes,
  } = useSWR(
    delayFetch || isOpen
      ? `/api/suppliers/${organisation.currentSupplier.id}/credit_notes`
      : null,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const isLoading = invoiceLoading || creditNotesLoading

  const {invoice = []} = invoiceData || {}
  const {creditNotes = [], metadata = {}} = creditNotesData || {}

  const {maximumAllocationPercentage = 0.3} = metadata

  const validCreditNotes = creditNotes.filter(
    (creditNote) =>
      creditNote.customer.id === invoice.customerId &&
      creditNote.amount.lessThanOrEqual(invoice.dueAmount),
  )

  const exceedsMaximumAllocationPercentage = (creditNote) => {
    const totalPercentage = invoice.total.multiply(maximumAllocationPercentage)

    return creditNote.amount.greaterThan(totalPercentage)
  }

  const canBeAllocatedCreditNote =
    invoice.accelerated &&
    invoice.paymentStatus === 'Unpaid' &&
    invoice.creditNoteApplications.length === 0 &&
    validCreditNotes.length

  const isDisabled = isLoading || !canBeAllocatedCreditNote

  const selectCreditNote = (newSelectedOption) => {
    setErrorMessage()
    setSelectedOption(newSelectedOption)

    const newSelection = validCreditNotes.find(
      ({id}) => id === newSelectedOption?.value,
    )
    setSelectedCreditNote({...(newSelection || {})})

    if (exceedsMaximumAllocationPercentage(newSelection)) {
      setErrorMessage('exceedsMaximumValue')
    }
  }

  const closeDrawer = () => {
    onClose()
    close()
  }

  const openDrawer = () => {
    mixpanel.track(
      `Action/Allocate Credit Note: Clicked for Invoice ${invoiceId}`,
    )
    openPanel()
  }

  const toggleAcceptTerms = ({target}) => {
    setTermsAccepted(target.checked)
  }

  const allocateCreditNote = () => {
    setSubmitting(true)

    fetcher(
      `/api/suppliers/${organisation.currentSupplier.id}/invoices/${invoiceId}/credit_notes/${selectedCreditNote.id}`,
      {
        method: 'PUT',
        headers: defaultHeaders({authenticityToken}),
      },
    )
      .then(() => {
        toast.dark(
          <ToastMessage>
            {t('invoicesPage:allocateCreditNotePanel.successToastMessage')}
          </ToastMessage>,
        )
        closeDrawer()
      })
      .catch((e) => {
        setErrorMessage(e.message)
        // Refetch credit notes in case a selected credit note is now no longer valid.
        refetchCreditNotes()
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const tooltipContext = useMemo(() => {
    if (!isDisabled) {
      return ''
    }
    if (invoice.paymentStatus === 'Paid') {
      return 'paid'
    }
    if (!invoice.accelerated) {
      return 'notCashedIn'
    }
    if (invoice.creditNoteApplications.length > 0) {
      return 'invoiceHasCreditNotes'
    }
    if (creditNotes.length === 0) {
      return 'noCreditNotes'
    }
    if (validCreditNotes.length === 0) {
      return 'noValidCreditNotes'
    }
    return ''
  }, [creditNotes.length, isDisabled, invoice, validCreditNotes])

  const accountingProviderName =
    organisation.currentSupplier.accountingProvider.name

  return (
    <Box>
      <Tooltip
        isDisabled={!isDisabled || isLoading}
        label={t('invoicesPage:allocateCreditNotePanel.disabledTooltip', {
          context: tooltipContext,
        })}
        zIndex="tooltip"
        boxShadow="lg"
        placement="left"
        hasArrow
      >
        {triggerButton({isDisabled, openDrawer})}
      </Tooltip>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={closeDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid"
              borderColor="neutral.glitter"
              py="xs"
            >
              <Text textStyle="headline4">
                {t('invoicesPage:allocateCreditNotePanel.title', {
                  invoiceNumber: invoice.number,
                })}
              </Text>

              <CloseButton onClick={closeDrawer} />
            </Box>
          </DrawerHeader>

          <DrawerBody overflowY="scroll">
            <Alert
              status="info"
              title={t(
                'invoicesPage:allocateCreditNotePanel.explanation.title',
              )}
              message={
                <OrderedList textStyle="body-small">
                  <ListItem>
                    {t(
                      'invoicesPage:allocateCreditNotePanel.explanation.firstListItem',
                      {accountingProviderName},
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'invoicesPage:allocateCreditNotePanel.explanation.secondListItem',
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'invoicesPage:allocateCreditNotePanel.explanation.thirdListItem',
                    )}
                  </ListItem>
                  <ListItem>
                    {t(
                      'invoicesPage:allocateCreditNotePanel.explanation.fourthListItem',
                      {maxPercentage: maximumAllocationPercentage * 100},
                    )}
                  </ListItem>
                </OrderedList>
              }
              mb="md"
            />

            <InvoiceDetails invoiceId={invoiceId} />

            <Divider mb="md" />

            <Flex direction="column" gap="md">
              <Text textStyle="body-copy" fontWeight="medium">
                {t('invoicesPage:allocateCreditNotePanel.formSubtitle')}
              </Text>

              <Flex direction="column" gap="xs">
                <DropdownSelect
                  width="100%"
                  name={t(
                    'invoicesPage:allocateCreditNotePanel.creditNoteDropdownName',
                  )}
                  placeholder={t(
                    'invoicesPage:allocateCreditNotePanel.creditNoteDropdownPlaceholder',
                  )}
                  options={validCreditNotes.map((note) => ({
                    value: note.id,
                    label: <CreditNoteLabel {...note} />,
                  }))}
                  value={selectedOption}
                  isClearable={false}
                  isSearchable={false}
                  onChange={selectCreditNote}
                />
                <Text textStyle="body-small" color="grey.700">
                  {t('invoicesPage:allocateCreditNotePanel.formCaption')}
                </Text>
              </Flex>
              <Flex>
                <Checkbox
                  isDisabled={!selectedOption}
                  alignItems="flex-start"
                  isChecked={termsAccepted}
                  onChange={toggleAcceptTerms}
                >
                  <Text as="div" textStyle="body-small" color="grey.700">
                    <Trans
                      t={t}
                      i18nKey="invoicesPage:allocateCreditNotePanel.termsLabel"
                      components={{
                        list: <UnorderedList />,
                        listItem: <ListItem />,
                        span: (
                          <Text
                            as="span"
                            textStyle="body-small"
                            fontWeight="medium"
                          />
                        ),
                      }}
                    />
                  </Text>
                </Checkbox>
              </Flex>
              {errorMessage && (
                <ErrorAlert
                  creditNoteNumber={selectedCreditNote.creditNoteNumber}
                  errorMessage={errorMessage}
                  maxPercentage={maximumAllocationPercentage * 100}
                  accountingProviderName={accountingProviderName}
                />
              )}
              <Flex width="100%" gap="sm">
                <Button variant="secondary" width="100%" onClick={closeDrawer}>
                  {t('invoicesPage:allocateCreditNotePanel.dismissButton')}
                </Button>

                <Button
                  variant="primary"
                  colorScheme="primary"
                  width="100%"
                  isLoading={submitting}
                  loadingText={t(
                    'invoicesPage:allocateCreditNotePanel.submitButtonSubmitting',
                  )}
                  isDisabled={errorMessage || !selectedOption || !termsAccepted}
                  onClick={allocateCreditNote}
                >
                  {t('invoicesPage:allocateCreditNotePanel.submitButton')}
                </Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

AllocateCreditNotePanel.defaultProps = {
  onClose: () => {},
  triggerButton: OpenCreditNotePanelButton,
  delayFetch: true,
}

AllocateCreditNotePanel.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  triggerButton: PropTypes.func,
  delayFetch: PropTypes.bool,
}

export default AllocateCreditNotePanel
