import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Text} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import isValidAbn from 'is-valid-abn'

import {Input} from '../../components'
import {useAuthProvider} from '../../contexts/authentication'
import {updateSupplierCustomerContactDetails} from '../../../api'

const customerDetailFormSchema = Yup.object().shape({
  businessName: Yup.string().required('Required'),
  abn: Yup.string().required('Required'),
  email: Yup.string().email('Email is invalid').required('Required'),
  phone: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
})

const InvoiceCustomerDetailForm = ({
  customer,
  invoiceId,
  onClickSubmit,
  onClickCancel,
}) => {
  const [invoiceCustomerDetailError, setInvoiceCustomerDetailError] =
    React.useState('')

  const {authenticityToken, updateAuthenticityToken} = useAuthProvider()

  const formik = useFormik({
    initialValues: {
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      email: customer?.email || '',
      phone: customer?.phone || '',
      businessName: customer?.businessName || '',
      abn: customer?.abn || '',
    },
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const {authenticity_token: token} =
          await updateSupplierCustomerContactDetails({
            authenticityToken,
            invoiceId,
            id: customer.id,
            ...values,
          })
        updateAuthenticityToken(token)
        onClickSubmit()
      } catch (e) {
        setInvoiceCustomerDetailError(e.message)
      }
    },
    validate: (values) => {
      const errors = {}

      const phone = parsePhoneNumberFromString(values.phone, 'AU')

      if ((values.phone && !phone) || (phone && !phone.isValid())) {
        errors.phone = 'Invalid AU phone'
      }

      if (values.abn && !isValidAbn(values.abn)) {
        errors.abn = 'Invalid ABN'
      }

      return errors
    },
    validationSchema: customerDetailFormSchema,
  })

  const validInitialAbn = isValidAbn(formik.initialValues.abn)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="businessName"
        placeholder="Enter Business Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.businessName}
        errorMessage={formik.errors.businessName}
        isInvalid={formik.touched.businessName && formik.errors.businessName}
        isDisabled={formik.values.businessName && !formik.errors.businessName}
        mb="sm"
      />
      <Input
        name="abn"
        placeholder="Enter ABN"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.abn}
        errorMessage={formik.errors.abn}
        isInvalid={formik.touched.abn && formik.errors.abn}
        isDisabled={validInitialAbn}
        mb="sm"
      />
      <Input
        name="email"
        placeholder="Enter Email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        errorMessage={formik.errors.email}
        isInvalid={formik.touched.email && formik.errors.email}
        isDisabled={formik.initialValues.email && !formik.errors.email}
        mb="sm"
      />
      <Input
        name="phone"
        placeholder="Enter Phone"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.phone}
        errorMessage={formik.errors.phone}
        isInvalid={formik.touched.phone && formik.errors.phone}
        isDisabled={formik.initialValues.phone && !formik.errors.phone}
        mb="sm"
      />
      <Input
        name="firstName"
        placeholder="Enter First Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        errorMessage={formik.errors.firstName}
        isInvalid={formik.touched.firstName && formik.errors.firstName}
        isDisabled={formik.initialValues.firstName && !formik.errors.firstName}
        mb="sm"
      />
      <Input
        name="lastName"
        placeholder="Enter Last Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
        errorMessage={formik.errors.lastName}
        isInvalid={formik.touched.lastName && formik.errors.lastName}
        isDisabled={formik.initialValues.lastName && !formik.errors.lastName}
        mb="sm"
      />
      {invoiceCustomerDetailError && (
        <Box>
          <Text fontSize="sm" color="primary.errorred">
            Fail to update customer detail: {invoiceCustomerDetailError}
          </Text>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt="sm"
        mb="md"
        gap="xs"
      >
        <Button
          variant="secondary"
          onClick={() => {
            setInvoiceCustomerDetailError('')
            onClickCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          colorScheme="primary"
          type="submit"
          isDisabled={!formik.isValid}
          isLoading={formik.isSubmitting}
        >
          Submit
        </Button>
      </Box>
    </form>
  )
}

InvoiceCustomerDetailForm.defaultProps = {
  onClickSubmit: () => {},
  onClickCancel: () => {},
  invoiceId: null,
}

InvoiceCustomerDetailForm.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    abn: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    businessName: PropTypes.string,
  }).isRequired,
  invoiceId: PropTypes.string,
  onClickSubmit: PropTypes.func,
  onClickCancel: PropTypes.func,
}

export default InvoiceCustomerDetailForm
