import PropTypes from 'prop-types'
import React from 'react'
import {Flex} from '@chakra-ui/react'

const InputFlexWrap = ({children}) => (
  <Flex
    align="left"
    justifyContent="start"
    py="sm"
    gap="xs"
    alignItems="center"
  >
    {children}
  </Flex>
)

InputFlexWrap.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InputFlexWrap
