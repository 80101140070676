import React from 'react'
import PropTypes from 'prop-types'
import {first, last, noop} from 'lodash-es'
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons'
import {
  Box,
  Text,
  Stack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'

import Select from 'react-select'
import PaginationButton from './PaginationButton'
import usePagination from './usePagination'

const Pagination = ({
  onPageChange,
  totalCount,
  totalPages,
  currentPage,
  perPage,
  setPerPage,
}) => {
  const pageNumbers = usePagination({
    currentPage,
    totalPages,
  })

  const prevPage = () => onPageChange(currentPage - 1)
  const nextPage = () => onPageChange(currentPage + 1)

  React.useEffect(() => {
    if (window.scrollTo) window.scrollTo(0, 0)
  }, [currentPage])

  const currentStart = perPage * (currentPage - 1) + 1
  const currentEnd = Math.min(perPage * currentPage, totalCount)

  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      justifyContent={setPerPage ? 'space-around' : 'flex-end'}
      py="sm"
      px="lg"
      borderRadius="lg"
      minHeight="64px"
      width="100%"
    >
      {setPerPage && (
        <Flex alignItems="center" flex="1">
          <Text as="span" mr="sm">
            Items per page:
          </Text>
          <Select
            name="per-page"
            options={[
              {label: '10', value: '10'},
              {label: '20', value: '20'},
              {label: '40', value: '40'},
              {label: '60', value: '60'},
              {label: '80', value: '80'},
              {label: '100', value: '100'},
            ]}
            menuPlacement="auto"
            value={{label: perPage, value: perPage}}
            onChange={(option) => {
              setPerPage(option?.value)
            }}
            isClearable={false}
            styles={{
              control: (styles) => {
                return {
                  ...styles,
                  width: '100px',
                }
              },
            }}
          />
        </Flex>
      )}

      {totalPages > 1 && (
        <Stack
          direction="row"
          spacing="4px"
          width={{base: '100%', lg: '66.66%', xl: '50%'}}
          justifyContent={{base: 'center', lg: 'start', xl: 'center'}}
        >
          <Box pr="sm">
            <PaginationButton
              aria-label="Previous page"
              isDisabled={currentPage === 1}
              onClick={prevPage}
            >
              <ChevronLeftIcon size="24px" />
            </PaginationButton>
          </Box>

          {pageNumbers.map((item) => {
            if (Array.isArray(item)) {
              return (
                <Box key={first(item)}>
                  <Menu>
                    <PaginationButton
                      as={MenuButton}
                      aria-label={`Pages ${first(item)}-${last(item)}`}
                    >
                      &hellip;
                    </PaginationButton>

                    <MenuList
                      minWidth="100px"
                      maxHeight="200px"
                      overflowY="scroll"
                      placement="top"
                    >
                      {item.map((option) => (
                        <MenuItem
                          key={option}
                          aria-label={`Page ${option}`}
                          onClick={() => onPageChange(option)}
                          display="block"
                          textAlign="center"
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              )
            }

            return (
              <PaginationButton
                key={item}
                aria-label={`Page ${item}`}
                current={item === currentPage}
                data-testid={item === currentPage ? 'current-page' : undefined}
                onClick={() => onPageChange(item)}
              >
                {item}
              </PaginationButton>
            )
          })}

          <Box pl="sm">
            <PaginationButton
              aria-label="Next page"
              isDisabled={currentPage === totalPages}
              onClick={nextPage}
            >
              <ChevronRightIcon size="24px" />
            </PaginationButton>
          </Box>
        </Stack>
      )}

      {totalCount > 0 && (
        <Box
          color="neutral.60"
          width={{base: '100%', lg: '33.33%', xl: '25%'}}
          textAlign={{base: 'center', lg: 'right'}}
          fontSize="base"
        >
          Showing results: {currentStart}-{currentEnd} of {totalCount}
        </Box>
      )}
    </Flex>
  )
}

Pagination.defaultProps = {
  onPageChange: noop,
  setPerPage: null,
}

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  setPerPage: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
}

export default Pagination
