import {camelizeKeys, decamelizeKeys} from 'humps'
import {fetcher, postHeaders} from './fetcher'

export const createBillPayment = async ({
  supplierId,
  authenticityToken,
  params,
}) => {
  const body = decamelizeKeys(params, {deep: true})
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/suppliers/${supplierId}/bill_payments`, opts).then(
    (data) => {
      return camelizeKeys(data, {deep: true})
    },
  )
}

export const createPaymentMethod = async ({
  supplierId,
  authenticityToken,
  acceptTerms: acceptedTerms,
  type,
  params,
}) => {
  // The api will pass the type key to define the key that the params will be under ie:
  // {type: 'debit_agreement', debit_agreement: { ... }}
  const body = decamelizeKeys(
    {
      acceptedTerms,
      type,
      [type]: params,
    },
    {deep: true},
  )
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/suppliers/${supplierId}/payment_methods`, opts).then(
    (data) => {
      return camelizeKeys(data, {deep: true})
    },
  )
}
