import React from 'react'
import {Flex, Text, Container} from '@chakra-ui/react'

import {LogoHorizontal, ThemeProvider} from '../../components'

const PayerStatementResentScreen = () => {
  return (
    <ThemeProvider>
      <Container
        maxWidth={{base: 'full', lg: '80ch'}}
        py={{base: 'sm', md: 'lg'}}
      >
        <Flex
          justifyContent="space-between"
          direction={{base: 'column', md: 'row'}}
          alignItems={{base: 'start', md: 'end'}}
          gap="sm"
        >
          <Text textStyle="headline4" order={{base: '2', md: '1'}}>
            Statement Resent
          </Text>

          <Text
            color="grey.500"
            fontWeight="400"
            fontSize="12px"
            display="flex"
            flexDirection="row"
            order={{base: '1', md: '2'}}
          >
            Powered by
            <LogoHorizontal width={85} height={11} mt="3px" ml="xs" />
          </Text>
        </Flex>
        <Text textStyle="body-copy" pt="md">
          Please check your email and follow the link to view your online
          statement.
        </Text>
      </Container>
    </ThemeProvider>
  )
}

export default PayerStatementResentScreen
