import React from 'react'
import useSWR from 'swr'
import {Skeleton, Box, Text} from '@chakra-ui/react'

import {fetcher} from '../../../../api'
import BankAccountDetailsDrawer from './BankAccountDetailsDrawer'

const CustomerPaymentSettings = () => {
  const {data: response, error} = useSWR('/api/suppliers/settings', fetcher, {
    refreshInterval: 0,
  })

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  if (error && error.message !== 'Unauthorized') {
    return (
      <Box width="100%">
        <Text fontSize="sm" mb="sm" color="primary.errorred">
          Fail to retrieve supplier&apos;s customer payment settings.
        </Text>
      </Box>
    )
  }

  if (!response && !error) {
    return (
      <Box width="100%">
        {Array.from({length: 3}).map((_, i) => (
          <Skeleton key={`skeleton-${i + 1}`} height="20px" my="sm" />
        ))}
      </Box>
    )
  }

  const {
    bsb,
    account_number: accountNumber,
    account_name: accountName,
    payid,
  } = response.data.receivable_bank_account || {}

  return (
    response && (
      <BankAccountDetailsDrawer
        accountName={accountName}
        accountNumber={accountNumber}
        bsb={bsb}
        payid={payid}
      />
    )
  )
}

export default CustomerPaymentSettings
