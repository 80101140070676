/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React, {useCallback, useEffect, useState} from 'react'
import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  CloseButton,
  HStack,
  VStack,
  Skeleton,
  useDisclosure,
  Box,
  Link,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import useSWR from 'swr'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import {fetcher} from '../../../api'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import EstimationsTable from './EstimationsTable'

export const SEEN_MODAL_KEY = 'mld-seenPricingEstimationModal'

const parseSeenCache = () => {
  const {value, expires} =
    JSON.parse(localStorage.getItem(SEEN_MODAL_KEY)) || {}
  const currentTime = new Date().getTime()

  // If the value is set and it has not expired yet return it
  if (value && currentTime < expires) {
    return value
  }

  // otherwise treat it like it hasnt been seen
  return false
}

const PricingEstimationsModal = ({supplierId}) => {
  const {t} = useTranslation()

  const {isOpen, onClose, onOpen} = useDisclosure()
  const {
    isOpen: popoverDisplayed,
    onClose: dismissPopover,
    onOpen: openPopover,
  } = useDisclosure()

  const [hasOpened, setHasOpened] = useState(parseSeenCache())
  const [popoverHasOpened, setPopoverHasOpened] = useState(false)

  useEffect(() => {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 1)

    localStorage.setItem(
      SEEN_MODAL_KEY,
      JSON.stringify({
        value: hasOpened,
        expires: expiryDate.getTime(),
      }),
    )
  }, [hasOpened])

  const openModal = useCallback(() => {
    onOpen()
    setHasOpened(true)
  }, [onOpen, setHasOpened])

  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()

  const {data: supplierTierData, isLoading: loadingSupplierTier} = useSWR(
    `/api/suppliers/${supplierId}/supplier_rewards_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      revalidateOnFocus: false,
    },
  )
  const {data: cashInCountData, isLoading: loadingCashInCount} = useSWR(
    `/api/suppliers/${supplierId}/cash_in_count`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const pricingEstimationModalEnabled =
    !loadingFeatures && features && features.pricingEstimationModal

  const currentTier = supplierTierData?.currentLevel
  const isBronzeTier = !loadingSupplierTier && currentTier === 'bronze'

  const isVisible = pricingEstimationModalEnabled && isBronzeTier
  const openOnLoad =
    isVisible && !loadingCashInCount && cashInCountData.cashInCount < 8

  const {data: estimationData, isLoading} = useSWR(
    isOpen &&
      `/api/suppliers/${supplierId}/pricing_estimations?limit=10&filter=eligible_only&sort_by=current_price`,
    fetcher,
    {use: [camelizeResult, dineroMoneyObjects]},
  )

  const {pricingEstimations = []} = estimationData || {}

  const withTranslatedReasons = pricingEstimations.map(
    ({mainDriverReason, ...estimation}) => ({
      ...estimation,
      mainDriverReason: t(
        `cashinPage:unavailable_reasons.${mainDriverReason.key}`,
        mainDriverReason.values,
      ),
    }),
  )

  useEffect(() => {
    if (!hasOpened && openOnLoad) {
      openModal()
    }
  }, [hasOpened, openOnLoad, openModal])

  const dismissModal = () => {
    onClose()
    if (openOnLoad && !popoverHasOpened) {
      openPopover()
      setPopoverHasOpened(true)
    }
  }

  if (!isVisible) {
    return <Box data-testid="pricing-estimation-not-displayed" />
  }

  const highestTier = supplierTierData.levels.at(-1).key

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={popoverDisplayed}
        onClose={dismissPopover}
        placement="bottom"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Link as="button" variant="primary" onClick={openModal}>
            {t('cashinPage:pricingEstimationsModal.modalTriggerLabel')}
          </Link>
        </PopoverTrigger>
        <PopoverContent
          backgroundColor="mld.neutral.900"
          color="mld.neutral.50"
          width="max-content"
        >
          <PopoverArrow backgroundColor="mld.neutral.900" />
          <PopoverBody
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text textStyle="body-small">
              {t('cashinPage:pricingEstimationsModal.popoverMessage')}
            </Text>
            <CloseButton onClick={dismissPopover} aria-label="Close popover" />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Modal
        isOpen={isOpen}
        size="xl"
        onClose={dismissModal}
        preserveScrollBarGap
        scrollBehavior="inside"
      >
        <ModalOverlay zIndex="modal" />
        <ModalContent
          borderRadius="lg"
          mx={{base: '0', md: 'sm'}}
          py="sm"
          maxWidth="1000px"
        >
          <ModalHeader fontSize="lg">
            <HStack justifyContent="space-between">
              <VStack alignItems="flex-start">
                <Text textStyle="headline2">
                  {t('cashinPage:pricingEstimationsModal.title')}
                </Text>
                <Text textStyle="body-copy">
                  {t('cashinPage:pricingEstimationsModal.description')}
                </Text>
              </VStack>
              <CloseButton alignSelf="flex-start" onClick={dismissModal} />
            </HStack>
          </ModalHeader>

          <ModalBody>
            {isLoading ? (
              <VStack data-testid="loading-pricing-estimates" height="400px">
                {[...Array(8).keys()].map((i) => (
                  <Skeleton key={`skeleton-${i}`} height="40px" width="100%" />
                ))}
              </VStack>
            ) : (
              <Box>
                <Divider
                  variant="dashed"
                  borderColor="mld.neutral.700"
                  size="1px"
                  pb="xs"
                />
                <EstimationsTable
                  pricingEstimations={withTranslatedReasons}
                  currentTierKey={currentTier}
                  highestTierKey={highestTier}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

PricingEstimationsModal.propTypes = {
  supplierId: PropTypes.string.isRequired,
}

export default PricingEstimationsModal
