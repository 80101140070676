import React from 'react'
import {Flex, Button, Text, Box, Link, IconButton} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import {CrossIcon} from '../../icons'

const ProductFeatureBar = ({text, href, id, onAcknowledge}) => {
  return (
    <Flex bg="mld.primary.200" data-testid={id} py={1}>
      <Box mx="auto">
        <Flex alignItems="center" flexWrap="wrap">
          <Text mx={4} my={2}>
            {text}
          </Text>
          <Link href={href} onClick={onAcknowledge} isExternal>
            <Button
              variant="tertiary"
              bg="mld.neutral.50"
              mx={4}
              my={2}
              aria-label="Read more"
            >
              Read More
            </Button>
          </Link>
        </Flex>
      </Box>
      <IconButton
        bg="inheret"
        _hover={{background: 'inheret'}}
        _active={{background: 'inheret'}}
        icon={<CrossIcon boxSize={6} />}
        onClick={onAcknowledge}
        my={2}
      />
    </Flex>
  )
}

ProductFeatureBar.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
}

export default ProductFeatureBar
