export default {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 'normal',
  },
  variants: {
    primary: (props) => {
      const {colorScheme: c} = props

      const colorScheme = [
        'primary',
        'warning',
        'success',
        'alert',
        'neutral',
      ].includes(c)
        ? c
        : 'neutral'

      const colorProps = {
        primary: {
          bg: 'mld.primary.200',
          hoverBg: 'mld.primary.400',
        },
        warning: {
          bg: 'mld.warning.100',
          hoverBg: 'mld.warning.50',
        },
        success: {
          bg: 'mld.success.100',
          hoverBg: 'mld.success.50',
        },
        alert: {
          bg: 'mld.alert.100',
          hoverBg: 'mld.alert.50',
        },
        neutral: {
          bg: 'mld.neutral.100',
          hoverBg: 'mld.neutral.50',
        },
      }[colorScheme]

      return {
        container: {
          bg: colorProps.bg,
          py: '4px',
          pr: '8px',
          pl: '6px',
          _hover: {
            bg: colorProps.hoverBg,
          },
        },
        label: {
          textStyle: 'tag',
        },
      }
    },
    status: (props) => {
      const {colorScheme: c} = props

      const colorScheme = [
        'alert',
        'warning',
        'success',
        'info',
        'neutral',
      ].includes(c)
        ? c
        : 'neutral'

      const colorProps = {
        alert: {
          bg: 'mld.alert.200',
        },
        warning: {
          bg: 'mld.warning.100',
        },
        success: {
          bg: 'mld.success.100',
        },
        info: {
          bg: 'mld.neutral.100',
        },
        neutral: {
          bg: 'mld.neutral.500',
        },
      }[colorScheme]

      return {
        container: {
          bg: colorProps.bg,
          py: '4px',
          pr: '8px',
          pl: '6px',
        },
        label: {
          textStyle: 'tag',
        },
      }
    },
  },
}
