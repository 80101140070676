import {get} from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import useSWR from 'swr'
import {
  Box,
  CloseButton,
  Text,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
} from '@chakra-ui/react'

import {DataTable} from '../../components'
import {
  toCurrency,
  toEpochMillisecond,
  toLocalDate,
} from '../../../utils/formatter'

const columns = [
  {
    header: 'Invoice number',
    accessorKey: 'number',
  },
  {
    header: 'Total',
    accessorKey: 'total.cents',
    cell: ({getValue}) => toCurrency(getValue()),
  },
  {
    header: 'Last reported',
    accessorKey: 'last_reported',
    cell: ({getValue}) => toLocalDate(toEpochMillisecond(getValue())),
  },
]

const DirectPaymentsInvoicesDraw = ({onClose, isOpen, name, id}) => {
  const {data, error} = useSWR(
    `/api/direct_payment_invoices/?customer_id=${id}`,
  )

  const loading = !data && !error

  const invoices = get(data, 'invoices')

  return (
    <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="neutral.glitter"
            pt="xs"
            pb="md"
          >
            <Text fontWeight="medium">{name}</Text>
            <CloseButton onClick={onClose} />
          </Box>
        </DrawerHeader>

        <DrawerBody>
          <Text fontWeight="medium">Invoices paid direct</Text>

          <Box mx={-6} whiteSpace="nowrap" data-testid="invoices">
            <DataTable
              columns={columns}
              data={invoices}
              isLoading={loading}
              enableSorting
            />

            {error && (
              <Box px="md" py="xs">
                Unable to fetch invoices, please try again later.
              </Box>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

DirectPaymentsInvoicesDraw.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default DirectPaymentsInvoicesDraw
