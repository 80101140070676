import {colorMode} from '../foundations/colors'

// https://chakra-ui.com/docs/components/radio/theming
export default {
  defaultProps: {colorScheme: 'indigo'},
  variants: {
    mld: (props) => {
      return {
        container: {
          alignItems: 'start',
        },
        control: {
          marginTop: 'xxs',
          borderColor: colorMode('mld.neutral.700', 'mld.neutral.50')(props),
          _checked: {
            color: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
            borderColor: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
            backgroundColor: 'transparent',
          },
        },
      }
    },
  },
}
