import React from 'react'
import {Box, Link} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import {CUSTOMER_REVIEW_URL} from '../../constants'

const Feedback = ({px}) => (
  <Box my={2} px={px}>
    <Link
      href={CUSTOMER_REVIEW_URL}
      color="primary.actionblue"
      fontSize="sm"
      rel="nofollow"
      target="_blank"
    >
      Give us feedback on this page
    </Link>
  </Box>
)

Feedback.propTypes = {
  px: PropTypes.string.isRequired,
}

export default Feedback
