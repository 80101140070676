import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import useFeatureCookie from '../../hooks/useFeatureCookie'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import {useRails} from '../../contexts/rails'
import OverdueInvoicesPopup from './OverdueInvoicesPopup'
import OverdueInvoicesModal from './OverdueInvoicesModal'

const OverdueInvoicesDetails = ({allowClose}) => {
  const {organisation} = useRails()
  const {
    data: {features},
  } = useFeatureFlags()
  const daysPastMostOverdueInvoiceDueDate = get(
    organisation,
    'currentSupplier.daysPastMostOverdueInvoiceDueDate',
    0,
  )
  const overdueInvoiceInfoEnabled = features && features.overdueInvoiceInfo
  const [overdueInvoicesBannerDisabled, disableOverdueInvoicesBanner] =
    useFeatureCookie('oib_disabled')
  const [overdueInvoicesModalDisabled, disableOverdueInvoicesModal] =
    useFeatureCookie('oim_disabled')

  return (
    <>
      {daysPastMostOverdueInvoiceDueDate >= 25 &&
        overdueInvoiceInfoEnabled &&
        !overdueInvoicesBannerDisabled && (
          <OverdueInvoicesPopup
            disableOverdueInvoicesBanner={disableOverdueInvoicesBanner}
            overdueDays={daysPastMostOverdueInvoiceDueDate}
          />
        )}

      {daysPastMostOverdueInvoiceDueDate >= 33 &&
        overdueInvoiceInfoEnabled &&
        !overdueInvoicesModalDisabled && (
          <OverdueInvoicesModal
            allowClose={allowClose}
            disableOverdueInvoicesModal={disableOverdueInvoicesModal}
          />
        )}
    </>
  )
}

OverdueInvoicesDetails.propTypes = {
  allowClose: PropTypes.bool.isRequired,
}

export default OverdueInvoicesDetails
