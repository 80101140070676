import React from 'react'
import PropTypes from 'prop-types'
import {Trans, useTranslation} from 'react-i18next'
import {Text} from '@chakra-ui/react'

import {Alert} from '../../components'
import useFeatureFlags from '../../hooks/useFeatureFlags'

const OverdueInvoicesPopup = ({disableOverdueInvoicesBanner, overdueDays}) => {
  const {t} = useTranslation()

  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()

  const creditNotesEnabled =
    !loadingFeatures && features && features.creditNotes

  const i18nKey = `overdueInvoicesPopup.${
    creditNotesEnabled ? 'message' : 'oldMessage'
  }`

  return (
    <Alert
      data-testid="overdue-invoices-popup"
      status="warning"
      isDismissable
      message={
        <Trans
          t={t}
          i18nKey={i18nKey}
          values={{context: overdueDays > 33 ? '33DaysOverdue' : ''}}
          components={{strong: <Text as="span" fontWeight="medium" />}}
        />
      }
      onClose={disableOverdueInvoicesBanner}
    />
  )
}

OverdueInvoicesPopup.propTypes = {
  disableOverdueInvoicesBanner: PropTypes.func.isRequired,
  overdueDays: PropTypes.number.isRequired,
}

export default OverdueInvoicesPopup
