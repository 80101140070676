import React from 'react'
import mixpanel from 'mixpanel-browser'
import {Box, Text, Flex, Link, useDisclosure, Button} from '@chakra-ui/react'
import {InfoIcon, WarningIcon} from '@chakra-ui/icons'
import AddSupplierBankAccountIntegrationModal from '../../AddSupplierBankAccountIntegrationModal'
import StepTitle from './StepTitle'

export const stepName = 'connectBankAccount'

const recordStartedConnectingMixpanelEvent = () => {
  mixpanel.track('Bank Data Form Started')
}

const recordTooltipMixpanelEvent = () => {
  mixpanel.track('Tooltip: Connect bank provider')
}

const calculateStatus = ({bankingProviderApprovalStatus}) => {
  if (bankingProviderApprovalStatus === 'approved') {
    return 'completed'
  }
  if (bankingProviderApprovalStatus === 'pending') {
    return 'reviewing'
  }
  if (bankingProviderApprovalStatus === 'denied') {
    return 'declined'
  }

  return 'incomplete'
}

const PendingNotification = () => (
  <Flex alignItems="start">
    <InfoIcon boxSize="13px" color="secondary.blue" mt="xxs" mr="xxs" />
    <Text textStyle="body-small">
      We are currently reviewing the details you submitted
    </Text>
  </Flex>
)

const DeclinedNotification = () => (
  <Flex alignItems="start">
    <WarningIcon boxSize="13px" color="red.600" mt="xxs" mr="xxs" />
    <Box>
      <Text textStyle="body-small">
        There was an issue connecting your account.
      </Text>
      <Text textStyle="body-small">
        Please contact{' '}
        <Link
          href="mailto:support@withmarmalade.com"
          isExternal
          color="secondary.purple"
        >
          Customer Support
        </Link>
      </Text>
    </Box>
  </Flex>
)
const calculateNotification = ({bankingProviderApprovalStatus}) => {
  if (bankingProviderApprovalStatus === 'pending') {
    return PendingNotification()
  }
  if (bankingProviderApprovalStatus === 'denied') {
    return DeclinedNotification()
  }
  return null
}

export default (stepperData) => {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const [openOnLearnMore, setOpenOnLearnMore] = React.useState(false)

  const openBankConnectionModal = ({learnMore = false} = {}) => {
    recordStartedConnectingMixpanelEvent()
    setOpenOnLearnMore(learnMore)
    onOpen()
  }

  return {
    name: stepName,
    visible: stepperData.showingBankConnectionStep,
    status: calculateStatus(stepperData),
    icon: (
      <Text color="inherit" textStyle="body-copy">
        1
      </Text>
    ),
    title: (
      <StepTitle
        title="Connect your bank account"
        onTooltipOpen={recordTooltipMixpanelEvent}
        tooltipParagraphs={[
          'Connecting your bank account helps us verify your business transactions. This will also help us support your business better and enable a smooth payment payout process.',
        ]}
      />
    ),
    notification: calculateNotification(stepperData),
    action: (
      <Box>
        <Button variant="primary" size="sm" onClick={openBankConnectionModal}>
          Connect
        </Button>
        <AddSupplierBankAccountIntegrationModal
          isOpen={isOpen}
          onClose={onClose}
          openOnLearnMore={openOnLearnMore}
        />
      </Box>
    ),
  }
}
