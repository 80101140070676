import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Image, Button, CloseButton} from '@chakra-ui/react'

import {WarningOutlineIcon} from '../../icons'
import {toCurrency} from '../../../utils/formatter'
import CustomerDetailsForm from '../CustomerDetailsForm'
import PigFlip from '../../../../assets/images/pig_flip.svg'

export const FailureScreen = ({
  selectedInvoice,
  customer,
  reissuanceProcessing,
  reissuanceError,
  reissuanceEnabled,
  handleClose,
  onSubmit,
}) => {
  return (
    <>
      <Box
        fontSize="lg"
        px="0"
        mx="sm"
        borderBottom="1px solid"
        borderColor="neutral.glitter"
      >
        <CloseButton onClick={handleClose} margin="0 0 0 auto" fontSize="sm" />
        <Text
          textStyle="headline3"
          fontWeight="medium"
          width="full"
          textAlign="center"
          py="sm"
        >
          Error
        </Text>
      </Box>

      <Box px="sm">
        <Image
          maxWidth="150px"
          margin="0 auto"
          my="sm"
          borderTopRadius="lg"
          src={PigFlip}
        />
        <Box
          display="flex"
          backgroundColor="red.50"
          p="sm"
          my="sm"
          borderRadius="lg"
        >
          <WarningOutlineIcon mr="xs" my="auto" color="red.500" fontSize="md" />
          <Text textStyle="body-copy" data-testid="reissuance-error-info">
            {reissuanceError}
          </Text>
        </Box>
        <Box
          border="1px solid"
          borderColor="neutral.glitter"
          p="md"
          my="sm"
          borderRadius="lg"
        >
          {customer && (
            <CustomerDetailsForm
              selectedInvoice={selectedInvoice}
              customer={customer}
              displayForm={false}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            pt="md"
            borderTop="1px solid"
            borderColor="neutral.glitter"
          >
            <Text width="25%" />
            <Text textStyle="body-small" color="grey.700">
              Invoice total
            </Text>
            <Text textStyle="headline3" text-align="right" fontWeight="medium">
              {toCurrency(selectedInvoice.due_amount.cents)}
            </Text>
          </Box>
        </Box>
        <Box margin="0 auto" width="fit-content" mb="md">
          <Button
            variant="primary"
            colorScheme="primary"
            loadingText="Reissuing..."
            isLoading={reissuanceProcessing}
            onClick={onSubmit}
            isDisabled={!reissuanceEnabled || reissuanceProcessing}
          >
            Try Again
          </Button>
        </Box>
      </Box>
    </>
  )
}

FailureScreen.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  customer: null,
}

FailureScreen.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_amount: PropTypes.shape({
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.string.isRequired,
    due_date: PropTypes.number.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    businessName: PropTypes.string,
    abn: PropTypes.string,
    email: PropTypes.string,
  }),
  reissuanceProcessing: PropTypes.bool.isRequired,
  reissuanceEnabled: PropTypes.bool.isRequired,
  reissuanceError: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default FailureScreen
