import React from 'react'
import PropTypes from 'prop-types'
import {Divider, useBreakpointValue} from '@chakra-ui/react'
import {CheckCircleIcon} from '@chakra-ui/icons'
import useTabContent from './useTabContent'
import InvoicesList from '../InvoicesList'
import EmptyState from '../InvoicesList/EmptyState'
import {toEpochMillisecond, toLocalDate} from '../../../../../utils/formatter'
import CashedInAmountCell from '../InvoicesList/CashedInAmountCell'

const EmptyContent = ({handleTabsChange}) => {
  return (
    <EmptyState
      tabName="cashedInTab"
      onClick={() => {
        handleTabsChange(0)
      }}
    />
  )
}

EmptyContent.propTypes = {
  handleTabsChange: PropTypes.func.isRequired,
}

const CashedInTab = ({reloadRequired, handleTabsChange}) => {
  const {commonColumns, ...listProps} = useTabContent({
    endpointAction: 'cashed_in_invoices',
    reloadRequired,
  })

  const payoutAmountColumn = {
    header: (
      <>
        <CheckCircleIcon color="secondary.green" mr="xxs" mb={0.5} /> Cashed-in
        amount
      </>
    ),
    accessorKey: 'setPayoutAmountCents',
    accessorFn: (rowData) => rowData.payoutAmount.toFormat(),
    cell: CashedInAmountCell,
    enableSorting: !listProps.groupBy,
    wrap: useBreakpointValue({base: true, md: false}),
    colSpan: useBreakpointValue({base: 6, md: 2}),
  }

  const cashedInDateColumn = {
    header: 'Cashed-in date',
    accessorKey: 'acceleratedAt',
    accessorFn: (rowData) =>
      toLocalDate(toEpochMillisecond(rowData.cashedInDate), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }),
    enableSorting: !listProps.groupBy || !listProps.groupBy === 'acceleratedAt',
    hidden: useBreakpointValue({base: true, md: false}),
    colSpan: 2,
  }

  const groupOptions = [
    ...listProps.groupOptions,
    {value: 'acceleratedAt', label: 'Cashed-in date'},
  ]
  const sortOptions = [
    ...listProps.sortOptions,
    {
      label: <Divider />,
      options: [
        {value: 'accelerated_at desc', label: 'Latest cashed-in date'},
        {value: 'accelerated_at asc', label: 'Oldest cashed-in date'},
      ],
    },
  ]
  // Set default sort option
  if (!listProps.sortBy) {
    listProps.onSort([
      {
        id: 'accelerated_at',
        desc: true,
      },
    ])
  }

  const columns = [
    commonColumns.customerColumn,
    commonColumns.invoiceColumn,
    cashedInDateColumn,
    commonColumns.invoiceAmountColumn,
    payoutAmountColumn,
    commonColumns.issueDateColumn,
    commonColumns.feeRateColumn,
  ]

  return (
    <InvoicesList
      columns={columns}
      emptyContent={<EmptyContent handleTabsChange={handleTabsChange} />}
      {...listProps}
      groupOptions={groupOptions}
      sortOptions={sortOptions}
      sortBy={listProps.sortBy}
    />
  )
}

CashedInTab.propTypes = {
  reloadRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  handleTabsChange: PropTypes.func.isRequired,
}

export default CashedInTab
