import React from 'react'
import {Box, Button, Flex, Text} from '@chakra-ui/react'
import {Screen, ThemeProvider, LogoHorizontal} from '../../components'

const SignUpContactUsScreen = () => {
  return (
    <ThemeProvider>
      <Screen>
        <Flex justifyContent="center" mt={4}>
          <LogoHorizontal width={250} height={100} />
        </Flex>
        <Box w={{sm: '100%', md: '70%', lg: '50%'}} margin="auto">
          <Box mt={4}>
            <Text
              textStyle="body-intro-medium"
              fontSize="lg"
              mb="sm"
              textAlign="center"
            >
              Contact us for more information
            </Text>
            <Text textAlign="center">
              Find out how we can support your business by contacting us.
            </Text>
            <Text textAlign="center">
              Drop us a line to schedule a call with our expert sales team.
            </Text>

            <Flex justifyContent="center" mt={12}>
              <Button
                as="a"
                variant="primary"
                colorScheme="primary"
                px="xl"
                href="mailto:support@withmarmalade.com"
              >
                Contact us now
              </Button>
            </Flex>
          </Box>
        </Box>
      </Screen>
    </ThemeProvider>
  )
}

export default SignUpContactUsScreen
