import React from 'react'
import {Box} from '@chakra-ui/react'

import {SupplierSignInOption, CustomerSignInOption} from '../../components'
import SignInLayout from '../../layouts/SignInLayout'

const SignInSelectScreen = () => {
  return (
    <SignInLayout>
      <Box pt="md">
        <SupplierSignInOption link />
        <CustomerSignInOption link />
      </Box>
    </SignInLayout>
  )
}

export default SignInSelectScreen
