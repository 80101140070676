import React from 'react'
import {Box, Button, CloseButton, Divider, Flex, Spacer} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import SupplierRewardsModalButton from '../SupplierRewardsModalButton'
import {LogoHorizontalSecondary} from '../Logo'
import DropdownSelect from '../DropdownSelect'
import {LockIcon} from '../../icons'
import NavigationItemList from './NavigationItemList'
import linkType from './linkType'

const SideNav = ({
  authenticityToken,
  generalLinks,
  managementLinks,
  dropdownSelectProps,
  displayRewardsModal,
  signOutAction,
  onClose,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      alignContent="stretch"
      bg="mld.sideNav.background"
      width="inherit"
      height="100%"
      position="fixed"
      top="0"
      left="0"
      zIndex="9"
    >
      <Flex my="md" flexGrow={0} width="100%" px="md">
        <LogoHorizontalSecondary width={168} height={27} />
        <Spacer />
        <CloseButton
          display={{base: 'block', lg: 'none'}}
          onClick={onClose}
          color="white"
        />
      </Flex>
      <Divider mb="sm" borderColor="mld.sideNav.dividerColor" />
      {dropdownSelectProps && (
        <>
          <Box px="xs" mb="sm" width="100%">
            <DropdownSelect
              name={dropdownSelectProps.name}
              placeholder={dropdownSelectProps.placeholder}
              options={dropdownSelectProps.options}
              value={dropdownSelectProps.value}
              isClearable={false}
              isSearchable={false}
              variant="sidenav"
              onChange={dropdownSelectProps.onChange}
            />
          </Box>
          <Divider mb="sm" borderColor="mld.sideNav.dividerColor" />
        </>
      )}
      <Box flexGrow={1} width="100%" px="xs">
        <NavigationItemList links={generalLinks} />
      </Box>
      {children != null && (
        <>
          {children}
          <Divider my="sm" borderColor="mld.sideNav.dividerColor" />
        </>
      )}

      {displayRewardsModal && (
        <>
          <SupplierRewardsModalButton />
          <Divider my="sm" borderColor="mld.sideNav.dividerColor" />
        </>
      )}

      <Box flexGrow={0} width="100%" px="xs" mb="sm">
        <NavigationItemList links={managementLinks} />
      </Box>
      <Divider mb="sm" borderColor="mld.sideNav.dividerColor" />
      <Box alignItems="center" flexGrow={0} width="100%" pb="sm" px="xs">
        <form method="POST" action={signOutAction}>
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <input type="hidden" name="_method" value="delete" />
          <Button
            fontFamily="body"
            fontSize="base"
            type="submit"
            fontWeight="normal"
            margin="0"
            color="mld.sideNav.btnColor"
            backgroundColor="transparent"
            width="100%"
            textAlign="left"
            justifyContent="flex-start"
            borderRadius="md"
            px="sm"
            _hover={{
              backgroundColor: 'mld.sideNav.navHoverBg',
              color: 'mld.sideNav.navHoverColor',
            }}
          >
            <LockIcon mr="xs" />
            Sign out
          </Button>
        </form>
      </Box>
    </Box>
  )
}

const dropdownOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

SideNav.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  displayRewardsModal: PropTypes.bool.isRequired,
  generalLinks: PropTypes.arrayOf(linkType),
  managementLinks: PropTypes.arrayOf(linkType),
  dropdownSelectProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(dropdownOptionShape).isRequired,
    value: dropdownOptionShape.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  signOutAction: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

SideNav.defaultProps = {
  generalLinks: [],
  managementLinks: [],
  dropdownSelectProps: undefined,
  onClose: () => {},
  children: null,
}

export default SideNav
