import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import {
  chakra,
  Box,
  Text,
  UnorderedList,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react'
import {useRails} from '../../contexts/rails'
import levelType from './levelType'
import SupplierRewardTierRequirements from '../SupplierRewardTierRequirements'

const UpgradeInstructions = ({selectedLevelData, maximumPrice}) => {
  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )

  return (
    <SimpleGrid columns={{lg: 2}} spacing="md" borderRadius="md" p="sm">
      <Box>
        <Text
          textStyle="body-copy"
          color="neutral.800"
          fontWeight="medium"
          mb="xxs"
        >
          How to upgrade to{' '}
          <chakra.span textTransform="uppercase">
            {selectedLevelData.key}
          </chakra.span>{' '}
          status:
        </Text>
        <Box backgroundColor="neutral.white" borderRadius="base" p="sm">
          <SupplierRewardTierRequirements
            requirements={selectedLevelData.requirements}
            tierKey={selectedLevelData.key}
          />
        </Box>
      </Box>
      <Box>
        <Text
          textStyle="body-copy"
          color="neutral.800"
          fontWeight="medium"
          mb="xxs"
        >
          Why upgrade?
        </Text>
        <Box backgroundColor="neutral.white" borderRadius="base" p="sm">
          <UnorderedList spacing="xxs">
            {!fixedPricingEnabled && (
              <>
                <ListItem>
                  <Text as="span" textStyle="body-small">
                    Lower your cash-in fee
                  </Text>
                </ListItem>
                <ListItem>
                  <Text as="span" textStyle="body-small">
                    More invoices will be available with a fee rate under{' '}
                    {maximumPrice} to be cashed-in
                  </Text>
                </ListItem>
              </>
            )}
            {fixedPricingEnabled && (
              <ListItem>
                <Text as="span" textStyle="body-small">
                  More invoices will be available to be cashed-in
                </Text>
              </ListItem>
            )}
          </UnorderedList>
        </Box>
      </Box>
    </SimpleGrid>
  )
}

UpgradeInstructions.propTypes = {
  selectedLevelData: levelType.isRequired,
  maximumPrice: PropTypes.string.isRequired,
}

export default UpgradeInstructions
