import React from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, Text} from '@chakra-ui/react'

const DescriptionList = ({term, desc}) => (
  <Flex justify="space-between">
    <Text fontSize="sm">{term}:</Text>
    <Text fontSize="sm" fontWeight="medium">
      {desc}
    </Text>
  </Flex>
)

DescriptionList.propTypes = {
  term: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

const BankTransferDetails = ({supplierCode, invoiceNumber, bankAccount}) => (
  <Box borderRadius="lg" border="1px solid" borderColor="primary.actionhover">
    <Box p="sm">
      <Text fontSize="sm">{bankAccount.accountName}</Text>

      <Box py="sm">
        <DescriptionList term="BSB" desc={bankAccount.bsb} />
        <DescriptionList
          term="Account Number"
          desc={bankAccount.accountNumber}
        />
        <DescriptionList
          term="Reference"
          desc={`${supplierCode} ${invoiceNumber}`}
        />
      </Box>
    </Box>

    {bankAccount.payid && (
      <Box backgroundColor="gray.100" p="sm" roundedBottom="lg">
        <Text fontSize="sm" py="sm">
          Make bank transfers in less than a minute. Head to the &apos;Pay
          Anyone&apos; section in your internet banking or mobile banking app.
        </Text>

        <DescriptionList term="Pay ID" desc={bankAccount.payid} />
        <DescriptionList
          term="Reference"
          desc={`${supplierCode} ${invoiceNumber}`}
        />
      </Box>
    )}
  </Box>
)

BankTransferDetails.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  supplierCode: PropTypes.string.isRequired,
  bankAccount: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
    payid: PropTypes.string,
  }).isRequired,
}

const InformPaymentSuccess = ({
  invoiceNumber,
  supplierName,
  supplierCode,
  bankAccount,
}) => (
  <Box>
    <Text as="h1" textStyle="headline2" py="lg">
      Thanks
    </Text>
    <Text>
      We have noted that you have paid <strong>{invoiceNumber}</strong> to{' '}
      <strong>{supplierName}</strong>.
    </Text>

    <Text>
      We will follow up with <strong>{supplierName}</strong> to ensure they have
      received the funds and rectify the issue.
    </Text>

    <Text py="sm">
      In future please ensure that you pay <strong>{supplierName}</strong> using
      their current bank details, or via the Marmalade portal.
    </Text>

    <Text pb="xs" fontWeight="medium">
      Bank Transfer
    </Text>

    <BankTransferDetails
      invoiceNumber={invoiceNumber}
      supplierCode={supplierCode}
      bankAccount={bankAccount}
    />
  </Box>
)

InformPaymentSuccess.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierCode: PropTypes.string.isRequired,
  bankAccount: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
  }).isRequired,
}

export default InformPaymentSuccess
