import React from 'react'
import PropTypes from 'prop-types'

import {toast} from 'react-toastify'

import {ToastMessage, ToastContainer} from '../Toast'

const RailsFlashMessages = ({flashMessages}) => {
  if (!window.seenFlashMessages) window.seenFlashMessages = []

  flashMessages.forEach((msg) => {
    if (!window.seenFlashMessages.includes(msg.id)) {
      toast.dark(<ToastMessage>{msg.text}</ToastMessage>, {
        containerId: 'rails-flash-messages',
      })

      window.seenFlashMessages.push(msg.id)
    }
  })

  return <ToastContainer containerId="rails-flash-messages" />
}

RailsFlashMessages.propTypes = {
  flashMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default RailsFlashMessages
