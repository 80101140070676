import {Form, Formik} from 'formik'
import {string, object} from 'yup'
import React from 'react'
import {Box, Text, FormControl, FormLabel, Button} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {updateSupplierCompliance} from '../../../../../api'
import {useRails} from '../../../../contexts/rails'
import industries from '../../../../../utils/businessIndustries'
import {Alert, DropdownSelect} from '../../../../components'
import {ArrowRightIcon} from '../../../../icons'
import AbnLookup from './AbnLookup'
import RelationshipToBusiness, {
  schema as relationshipSchema,
} from './RelationshipToBusiness'

const complianceFormSchema = object().shape({
  ...relationshipSchema,
  organisationName: string().ensure().required('Company name is required'),
  abn: string()
    .ensure()
    .required('ABN is required')
    .length(11, 'Enter a valid ABN'),
  businessIndustry: string().ensure().required('Business industry is required'),
})

const groupedBusinessIndustries = industries.map((group) => ({
  ...group,
  options: group.options.map((value) => ({value, label: value})),
}))

const ComplianceForm = () => {
  const {authenticityToken, organisation, compliance} = useRails()
  const [complianceError, setComplianceError] = React.useState('')
  const {t} = useTranslation('onboarding')

  const handleSubmit = async (values) => {
    try {
      await updateSupplierCompliance({
        ...values,
        supplierId: organisation.currentSupplier.id,
        authenticityToken,
      })
      window.location.href = '/dashboard'
    } catch (e) {
      if (e.message === 'Unprocessable Entity') {
        setComplianceError(
          'There was an issue creating your account. Please contact our sales team for more information.',
        )
      } else {
        setComplianceError(e.message)
      }
    }
  }

  return (
    <Formik
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={complianceFormSchema}
      initialValues={complianceFormSchema.cast(compliance)}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form>
          {complianceError && (
            <Alert status="error" message={complianceError} mb={8} />
          )}

          <AbnLookup />

          <FormControl>
            <FormLabel hidden htmlFor="businessIndustry">
              Industry
            </FormLabel>

            <Box mb={4}>
              <DropdownSelect
                options={groupedBusinessIndustries}
                value={
                  values.businessIndustry && {
                    label: values.businessIndustry,
                    value: values.businessIndustry,
                  }
                }
                name="businessIndustry"
                placeholder="Select industry"
                onChange={(option) => {
                  setFieldValue('businessIndustry', option?.value)
                }}
                onBlur={() => {
                  setFieldTouched('businessIndustry', true)
                }}
              />
              {touched.businessIndustry && errors.businessIndustry && (
                <Text mt={1} textStyle="body-detail" color="red.500">
                  {errors.businessIndustry}
                </Text>
              )}
            </Box>
          </FormControl>

          <RelationshipToBusiness />

          <Button
            variant="primary"
            colorScheme="primary"
            isLoading={isSubmitting}
            type="submit"
            data-testid="continue-button"
            size="lg"
            mt="sm"
          >
            {t('onboarding:Compliance.submitButton')}
            <ArrowRightIcon width="24px" height="24px" ml="xs" />
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Compliance = () => {
  const {accountingProvider} = useRails()
  const {t} = useTranslation('onboarding')

  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t('onboarding:Compliance.heading', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <Text textStyle="body-intro" my="md">
        {t('onboarding:Compliance.body', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <ComplianceForm />
    </>
  )
}

export default Compliance
