import {decamelizeKeys} from 'humps'
import {fetcher, postHeaders} from './fetcher'

export const createInvoiceIssue = ({type, invoiceId, authenticityToken}) => {
  const body = {
    invoice_issue: {
      issue_type: type,
    },
  }

  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/invoice_issues`, opts)
}

export const createPayerArrangment = ({
  invoiceId,
  authenticityToken,
  ...attributes
}) => {
  const body = {
    payer_arrangement: decamelizeKeys(attributes),
  }

  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/payer_arrangements`, opts)
}

export const reissueInvoice = (authenticityToken, invoiceId, body = {}) => {
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/reissue`, opts)
}
