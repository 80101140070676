import React from 'react'

import PropTypes from 'prop-types'
import {Box, Text, Link, SimpleGrid, Flex, Spacer} from '@chakra-ui/react'
import backgroundImage from '../../../../assets/images/pillars_background.svg'

import withProviders from '../../contexts/withProviders'
import {Alert, LogoHorizontal, LogoHorizontalSecondary} from '../../components'
import SignUpForm from './SignUpForm'
import {useRails} from '../../contexts/rails'

const SupplierSignUpScreen = ({signInPath}) => {
  const {
    railsErrors,
    resource: {name: resourceName},
  } = useRails()

  const parsedErrors =
    (railsErrors &&
      Object.keys(railsErrors)
        .map((k) => `${k.replace(/_/g, ' ')} ${railsErrors[k][0]}`)
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))) ||
    []

  return (
    <Box>
      <SimpleGrid
        templateColumns={{md: '45% 55%', base: 'repeat(1)'}}
        height="100vh"
      >
        <Box
          py="lg"
          px="lg"
          display={{base: 'none', md: 'flex'}}
          flexDirection="column"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          backgroundColor="black"
          backgroundRepeat="repeat-x"
          backgroundSize="auto 100%"
          sx={{
            'background-image': `url("${backgroundImage}")`,
          }}
        >
          <LogoHorizontalSecondary width={250} height={null} pb="md" />
          <Spacer />
          <Text textStyle="headline2" mb="lg" color="white" lineHeight="1">
            Always on the money with Marmalade.
          </Text>
          <Spacer />
          <Text
            textStyle="body-copy"
            fontWeight="medium"
            color="white"
            width="70%"
          >
            Still unsure? Call us on (03) 9917 8567
          </Text>
        </Box>
        <Flex
          p={{base: 'sm', md: 'lg'}}
          flexDir="column"
          justifyContent="space-between"
          alignItems="center"
          overflowY="auto"
        >
          <Spacer />
          <Box maxW="500px" w="100%">
            <Box
              w="100%"
              display={{base: 'flex', md: 'none'}}
              mt={resourceName === 'supplier_login' ? 0 : 10}
            >
              <LogoHorizontal width={250} height={null} py={12} />
            </Box>
            <Flex justifyContent="space-between" pb="sm">
              <Text textStyle="headline3" textTransform="uppercase">
                Create your login
              </Text>
              <Text my="auto" textStyle="body-small">
                Already have a login?{' '}
                <Link
                  href={signInPath}
                  textDecoration="underline"
                  variant="primary"
                >
                  Sign in
                </Link>
              </Text>
            </Flex>
            {parsedErrors.length > 0 && (
              <Alert
                status="error"
                mb={8}
                message={
                  <Box display="flex" flexDirection="column">
                    {Array.isArray(parsedErrors) &&
                      parsedErrors.map((error) => (
                        <Text textStyle="body-small" key={error}>
                          {error}
                        </Text>
                      ))}
                  </Box>
                }
              />
            )}
            <SignUpForm signInPath={signInPath} />
          </Box>
          <Spacer />
        </Flex>
      </SimpleGrid>
    </Box>
  )
}

SupplierSignUpScreen.propTypes = {
  signInPath: PropTypes.string.isRequired,
}

export default withProviders(SupplierSignUpScreen)
