import React, {useRef} from 'react'
import {string, object, bool} from 'yup'
import {Formik, Form} from 'formik'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  UnorderedList,
  IconButton,
} from '@chakra-ui/react'
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
import Checkbox from '../../components/Checkbox'
import {ArrowRightIcon} from '../../icons'
import {useRails} from '../../contexts/rails'
import {DropdownSelect, Input} from '../../components'
import PhoneNumberInput from '../../components/PhoneNumberInput'
import industries from '../../../utils/businessIndustries'
import AbnLookup from '../DashboardScreen/Supplier/Onboarding/AbnLookup'
import RelationshipToBusiness, {
  schema as relationshipSchema,
} from '../DashboardScreen/Supplier/Onboarding/RelationshipToBusiness'

const sharedSchemaShape = {
  email: string().email('Invalid email').required('Required'),
  password: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must be 8 characters minimum and must contain: at least 1 lower case, 1 upper case, 1 numeric',
    )
    .required('Required'),
  accept_terms: bool().oneOf([true], 'Accept Terms & Conditions is required'),
}

const SupplierSchema = object().shape({
  supplier_login: object().shape({
    ...sharedSchemaShape,
    ...relationshipSchema,
    first_name: string().required('Required'),
    last_name: string().required('Required'),
    phone_number: string()
      .min(9, 'Please enter a valid Australian phone number')
      .required('Required'),
    abn: string()
      .ensure()
      .required('ABN is required')
      .length(11, 'Enter a valid ABN'),
    businessIndustry: string()
      .default(null)
      .nullable()
      .required('Business industry is required'),
    organisationName: string().ensure().required('Company name is required'),
  }),
})

const CustomerSchema = object().shape({
  customer_login: object().shape(sharedSchemaShape),
})

const groupedBusinessIndustries = industries.map((group) => ({
  ...group,
  options: group.options.map((value) => ({value, label: value})),
}))

const getEmailFromLocation = () => {
  const searchParams = new URLSearchParams(window.location.search)

  return searchParams.get('email')
}

const SignUpForm = () => {
  const {
    authenticityToken,
    resource: {user, name: resourceName, signUpPath},
  } = useRails()
  const [showPassword, setShowPassword] = React.useState(false)
  const [industryPlaceholder, setIndustryPlaceholder] =
    React.useState('Select industry')
  const handlePasswordVisibility = () => setShowPassword(!showPassword)
  const customerEmail =
    resourceName === 'customer_login' && getEmailFromLocation()

  const formEl = useRef(null)
  const doHtmlFormPost = () => {
    formEl.current.submit()
  }

  const handleSubmit = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'formSubmission',
        formType: 'Sign up',
      })
    }
  }

  return (
    <Formik
      initialValues={{
        [resourceName]: {
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          email: user.email || customerEmail || '',
          password: '',
          phone_number: user.phone_number || '',
          abn: user.signup_abn || '',
          organisationName: user.signup_organisation_name || '',
          businessIndustry: user.signup_business_industry || null,
          signup_relationship_to_business:
            user.signup_relationship_to_business || null,
          accept_terms: false,
        },
      }}
      onSubmit={doHtmlFormPost}
      validateOnMount
      validationSchema={
        resourceName === 'supplier_login' ? SupplierSchema : CustomerSchema
      }
    >
      {({
        dirty,
        errors,
        handleChange,
        handleBlur,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
      }) => (
        <Form
          accept_charset="UTF-8"
          name="sign_up_form"
          action={signUpPath}
          method="POST"
          onSubmit={handleSubmit}
          ref={formEl}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <input type="hidden" name="commit" value="Sign+up" />

          {resourceName === 'supplier_login' && (
            <>
              <Text
                textStyle="body-detail"
                fontWeight="medium"
                textTransform="uppercase"
                mb="sm"
              >
                Your Information
              </Text>
              <Flex
                alignItems="flex-start"
                flexDirection="center"
                gap={{base: '', md: 'xs'}}
                wrap={{base: 'wrap', md: 'nowrap'}}
              >
                <FormControl>
                  <Input
                    type="text"
                    name={`${resourceName}[first_name]`}
                    id="first_name"
                    placeholder="First name"
                    aria-describedby="first-name-helper-text"
                    value={values[resourceName].first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors[resourceName] &&
                      errors[resourceName].first_name &&
                      touched[resourceName] &&
                      touched[resourceName].first_name
                    }
                    errorMessage={
                      touched[resourceName] &&
                      touched[resourceName].first_name &&
                      errors[resourceName] &&
                      errors[resourceName].first_name &&
                      errors[resourceName].first_name
                    }
                  />
                  <FormHelperText hidden id="first-name-helper-text">
                    Your first name
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="last_name" hidden>
                    Last name
                  </FormLabel>
                  <Input
                    type="text"
                    name={`${resourceName}[last_name]`}
                    id="last_name"
                    placeholder="Last name"
                    aria-describedby="last-name-helper-text"
                    value={values[resourceName].last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors[resourceName] &&
                      errors[resourceName].last_name &&
                      touched[resourceName] &&
                      touched[resourceName].last_name
                    }
                    errorMessage={
                      touched[resourceName] &&
                      touched[resourceName].last_name &&
                      errors[resourceName] &&
                      errors[resourceName].last_name &&
                      errors[resourceName].last_name
                    }
                  />
                  <FormHelperText hidden id="first-name-helper-text">
                    Your last name
                  </FormHelperText>
                </FormControl>
              </Flex>
              <RelationshipToBusiness formResourceContext={resourceName} />
            </>
          )}
          <Text
            textStyle="body-detail"
            fontWeight="medium"
            textTransform="uppercase"
            mb="sm"
          >
            Business Information
          </Text>
          <FormControl>
            <FormLabel htmlFor="email" hidden>
              Business email
            </FormLabel>
            <Input
              type="email"
              name={`${resourceName}[email]`}
              id="email"
              placeholder="Business email"
              aria-describedby="email-helper-text"
              value={values[resourceName].email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={
                errors[resourceName] &&
                errors[resourceName].email &&
                touched[resourceName] &&
                touched[resourceName].email
              }
              errorMessage={
                touched[resourceName] &&
                touched[resourceName].email &&
                errors[resourceName] &&
                errors[resourceName].email &&
                errors[resourceName].email
              }
            />
            <FormHelperText hidden id="email-helper-text">
              We&apos;ll never share your email.
            </FormHelperText>
          </FormControl>
          {resourceName === 'supplier_login' && (
            <>
              <FormControl>
                <FormLabel htmlFor="phone_number" hidden>
                  Phone number
                </FormLabel>
                <PhoneNumberInput
                  name={`${resourceName}[phone_number]`}
                  value={values[resourceName].phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors[resourceName] &&
                    errors[resourceName].phone_number &&
                    touched[resourceName] &&
                    touched[resourceName].phone_number
                  }
                  errorMessage={
                    touched[resourceName] &&
                    touched[resourceName].phone_number &&
                    errors[resourceName] &&
                    errors[resourceName].phone_number &&
                    errors[resourceName].phone_number
                  }
                />
              </FormControl>
              <AbnLookup formResourceContext={resourceName} />

              <FormControl>
                <FormLabel hidden htmlFor={`${resourceName}[businessIndustry]`}>
                  Industry
                </FormLabel>

                <Box mb={4}>
                  <DropdownSelect
                    options={groupedBusinessIndustries}
                    value={
                      values[resourceName]?.businessIndustry && {
                        label: values[resourceName]?.businessIndustry,
                        value: values[resourceName]?.businessIndustry,
                      }
                    }
                    name={`${resourceName}[businessIndustry]`}
                    variant="mld"
                    placeholder={industryPlaceholder}
                    onChange={(option) => {
                      setFieldValue(
                        `${resourceName}[businessIndustry]`,
                        option?.value,
                      )
                    }}
                    onFocus={() => {
                      setIndustryPlaceholder('Type an industry...')
                    }}
                    onBlur={() => {
                      setFieldTouched(`${resourceName}[businessIndustry]`, true)
                    }}
                  />
                  {touched[resourceName]?.businessIndustry &&
                    errors[resourceName]?.businessIndustry && (
                      <Text mt={1} textStyle="body-detail" color="red.500">
                        {errors[resourceName].businessIndustry}
                      </Text>
                    )}
                </Box>
              </FormControl>
            </>
          )}

          <Text
            textStyle="body-detail"
            fontWeight="medium"
            textTransform="uppercase"
            mb="sm"
          >
            Account Creation
          </Text>
          <FormControl>
            <FormLabel htmlFor="password" hidden>
              Password
            </FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name={`${resourceName}[password]`}
                id="password"
                placeholder="Password"
                aria-describedby="password-helper-text"
                value={values[resourceName].password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  errors[resourceName] &&
                  errors[resourceName].password &&
                  touched[resourceName] &&
                  touched[resourceName].password
                }
                errorMessage={
                  touched[resourceName] &&
                  touched[resourceName].password &&
                  errors[resourceName] &&
                  errors[resourceName].password &&
                  errors[resourceName].password
                }
              />
              <InputRightElement>
                <IconButton
                  size="sm"
                  mt={2}
                  mr={2}
                  onClick={handlePasswordVisibility}
                >
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </IconButton>
              </InputRightElement>
            </InputGroup>
            <FormHelperText hidden id="password-helper-text">
              Enter a secure password!
            </FormHelperText>
          </FormControl>
          {resourceName === 'supplier_login' && (
            <Accordion allowMultiple>
              <AccordionItem border="0px">
                <h2>
                  <AccordionButton bgColor="mld.neutral.200" borderRadius="md">
                    <Box flex="1" textAlign="left">
                      <Text textStyle="body-small" fontWeight="medium">
                        How we secure your data
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb="0px">
                  <Text textStyle="body-small" py="sm">
                    Marmalade takes data security seriously and adheres to
                    industry leading standards. We transfer your data using
                    256-bit TLS encryption technology and house it within an
                    Australian data centre configured with firewalls and network
                    intrusion detection technology.
                  </Text>

                  <Text textStyle="body-small">Please note, Marmalade:</Text>
                  <UnorderedList pl="xs">
                    <ListItem>
                      <Text textStyle="body-small">
                        Cannot make any changes to your financial accounts
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text textStyle="body-small">
                        Cannot approve payments or fund transfers, and
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text textStyle="body-small">
                        Will not store your login credentials.
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <FormControl>
            <Checkbox
              name={`${resourceName}[accept_terms]`}
              id="accept_terms"
              isChecked={values[resourceName].accept_terms}
              onChange={(event) => {
                setFieldValue(
                  `${resourceName}.accept_terms`,
                  event.target.checked,
                )
              }}
              py="md"
            >
              <Text textStyle="body-detail">
                I have read and agree to Marmalade&apos;s{' '}
                <Link
                  href="https://www.withmarmalade.com.au/terms-of-use"
                  variant="primary"
                  textStyle="body-detail"
                  target="_blank"
                >
                  Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link
                  href="https://www.withmarmalade.com.au/privacy-policy"
                  variant="primary"
                  textStyle="body-detail"
                  target="_blank"
                >
                  Privacy Policy.
                </Link>
              </Text>
            </Checkbox>
            {touched[resourceName] &&
              touched[resourceName].accept_terms &&
              errors[resourceName].accept_terms && (
                <Text mt={1} textStyle="body-detail" color="red.500">
                  {errors[resourceName].accept_terms}
                </Text>
              )}
          </FormControl>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            textAlign="right"
          >
            <Button
              isDisabled={!dirty || Object.keys(errors).length}
              variant="primary"
              colorScheme="primary"
              isLoading={isSubmitting}
              type="submit"
            >
              Continue
              <ArrowRightIcon width="24px" height="24px" ml="xs" />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
