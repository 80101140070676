import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button,
  CloseButton,
} from '@chakra-ui/react'
import CustomerDetailsForm from '../CustomerDetailsForm'
import {toCurrency} from '../../../utils/formatter'

export const InitialScreen = ({
  selectedInvoice,
  customer,
  reissuanceProcessing,
  reissuanceEnabled,
  handleClose,
  onSubmit,
  updateCustomer,
  abnRequired,
}) => {
  const {email, abn} = customer || {}
  const hasCustomerDetails = email && email.length && abn && abn.length
  const [updatedCustomer, setUpdatedCustomer] = React.useState(false)
  const onClickSubmit = () => {
    updateCustomer()
    setUpdatedCustomer(true)
  }

  return (
    <>
      <Box
        fontSize="lg"
        px="0"
        mx="sm"
        borderBottom="1px solid"
        borderColor="neutral.glitter"
      >
        <CloseButton onClick={handleClose} margin="0 0 0 auto" fontSize="sm" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="full"
          py="sm"
        >
          <Text textStyle="headline3" fontWeight="medium" mb="xs">
            Reissue invoice
          </Text>
          <Text textStyle="headline3" fontWeight="medium">
            {selectedInvoice.number}
          </Text>
        </Box>
      </Box>

      <Box px="sm">
        <Box
          display="flex"
          backgroundColor="neutral.smoke"
          p="sm"
          my="sm"
          borderRadius="lg"
        >
          <Text textStyle="body-copy" fontWeight="medium" mb="xs" mr="xs">
            <span role="img" aria-label="Moneybag emoji">
              &#128176;
            </span>
          </Text>
          <Box>
            <Text
              textStyle="body-copy"
              fontWeight="medium"
              mb="xs"
              data-testid="reissuance-info"
            >
              Let&apos;s reissue your invoice!
            </Text>
            <Text textStyle="body-small">
              Reissue the invoice to contain Marmalade payment details. The
              reissued invoice will be sent to your customer.
            </Text>
          </Box>
        </Box>
        <Box
          border="1px solid"
          borderColor="neutral.glitter"
          p="md"
          my="sm"
          borderRadius="lg"
        >
          {customer && (
            <CustomerDetailsForm
              selectedInvoice={selectedInvoice}
              onClickSubmit={onClickSubmit}
              customer={customer}
              displayForm={!hasCustomerDetails || updatedCustomer}
              abnRequired={abnRequired}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            pt="md"
            borderTop="1px solid"
            borderColor="neutral.glitter"
          >
            <Text width="25%" />
            <Text textStyle="body-small" color="grey.700">
              Invoice total
            </Text>
            <Text textStyle="headline3" text-align="right" fontWeight="medium">
              {toCurrency(selectedInvoice.due_amount.cents)}
            </Text>
          </Box>
        </Box>
        <Box mb="sm">
          <Text color="grey.700" textStyle="body-small">
            By re-issuing the invoice:
          </Text>
          <UnorderedList color="grey.700" fontSize="sm">
            <ListItem>
              I confirm that all goods and/or services being invoiced have been
              delivered.
            </ListItem>
            <ListItem>
              I confirm that the invoice is resent to my customer with Marmalade
              details.
            </ListItem>
          </UnorderedList>
        </Box>
        <Box margin="0 auto" width="fit-content" mb="md">
          <Button
            variant="primary"
            colorScheme="primary"
            loadingText="Reissuing..."
            isLoading={reissuanceProcessing}
            data-testid="reissuance-button"
            onClick={onSubmit}
            isDisabled={!reissuanceEnabled || reissuanceProcessing}
          >
            Reissue Invoice
          </Button>
        </Box>
      </Box>
    </>
  )
}

InitialScreen.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  customer: null,
}

InitialScreen.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_amount: PropTypes.shape({
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.string.isRequired,
    due_date: PropTypes.number.isRequired,
  }).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    businessName: PropTypes.string,
    abn: PropTypes.string,
    email: PropTypes.string,
  }),
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  reissuanceProcessing: PropTypes.bool.isRequired,
  reissuanceEnabled: PropTypes.bool.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  abnRequired: PropTypes.bool.isRequired,
}

export default InitialScreen
