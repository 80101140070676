import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {CookiesProvider} from 'react-cookie'
import PropTypes from 'prop-types'
import React from 'react'

import {HamburgerIcon} from '@chakra-ui/icons'
import {
  LogoHorizontalSecondary,
  SideNav,
  ToastContainer,
} from '../../components'

import linkType from '../../components/SideNav/linkType'

const smallScreenBreakpoint = 'lg'

const MobileNav = ({onOpen, ...rest}) => {
  return (
    <Flex
      ml={{base: 0, [smallScreenBreakpoint]: 60}}
      px={{base: 4, [smallScreenBreakpoint]: 24}}
      height="50px"
      alignItems="center"
      justifyContent="center"
      bg="mld.sideNav.background"
      {...rest}
    >
      <IconButton
        position="absolute"
        left="0px"
        ml="sm"
        colorScheme="mld.sideNav.background"
        onClick={onOpen}
        aria-label="Open navigation menu"
        icon={<HamburgerIcon />}
      />
      <LogoHorizontalSecondary width={150} height={20} />
    </Flex>
  )
}

MobileNav.propTypes = {
  onOpen: PropTypes.func.isRequired,
}

const SideNavLayout = ({
  authenticityToken,
  generalLinks,
  managementLinks,
  dropdownSelectProps,
  signOutAction,
  children,
  sideNavChildren,
  displayRewardsModal,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const mobileView = useBreakpointValue({
    base: true,
    [smallScreenBreakpoint]: false,
  })

  return (
    <CookiesProvider>
      <Box minH="100vh" backgroundColor="neutral.white">
        {!mobileView && (
          <Box width="240px">
            <SideNav
              authenticityToken={authenticityToken}
              generalLinks={generalLinks}
              managementLinks={managementLinks}
              dropdownSelectProps={dropdownSelectProps}
              displayRewardsModal={displayRewardsModal}
              signOutAction={signOutAction}
            >
              {sideNavChildren}
            </SideNav>
          </Box>
        )}
        {mobileView && (
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <SideNav
                authenticityToken={authenticityToken}
                generalLinks={generalLinks}
                managementLinks={managementLinks}
                dropdownSelectProps={dropdownSelectProps}
                displayRewardsModal={displayRewardsModal}
                onClose={onClose}
                signOutAction={signOutAction}
              >
                {sideNavChildren}
              </SideNav>
            </DrawerContent>
          </Drawer>
        )}
        <ToastContainer />
        <MobileNav
          display={{base: 'flex', [smallScreenBreakpoint]: 'none'}}
          onOpen={onOpen}
        />
        <Box ml={{base: 0, [smallScreenBreakpoint]: 60}}>{children}</Box>
      </Box>
    </CookiesProvider>
  )
}

const dropdownOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

SideNavLayout.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  displayRewardsModal: PropTypes.bool,
  generalLinks: PropTypes.arrayOf(linkType),
  managementLinks: PropTypes.arrayOf(linkType),
  dropdownSelectProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(dropdownOptionShape).isRequired,
    value: dropdownOptionShape.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  signOutAction: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  sideNavChildren: PropTypes.node,
}

SideNavLayout.defaultProps = {
  generalLinks: [],
  managementLinks: [],
  dropdownSelectProps: undefined,
  sideNavChildren: null,
  displayRewardsModal: false,
}

export default SideNavLayout
