import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'
import {CheckCircleIcon, InfoOutlineIcon, createIcon} from '@chakra-ui/icons'

const AlertWarningIcon = createIcon({
  displayName: 'AlertWarningIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fill="currentColor"
        d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
      />
      <rect x="11" y="6" width="2" height="7" fill="mld.neutral.900" />
      <rect x="11" y="15" width="2" height="2" fill="mld.neutral.900" />
    </>
  ),
})

const Alert = ({
  status,
  title,
  isDismissable,
  message,
  onClose: closeCallback,
  ...props
}) => {
  const {isOpen: isVisible, onClose: hideAlert} = useDisclosure({
    defaultIsOpen: true,
  })

  const onClose = () => {
    hideAlert()
    closeCallback()
  }

  if (!isVisible) {
    return null
  }

  const overridenIcon = {
    'cash-in': <CheckCircleIcon h="100%" w="100%" color="mld.primary.200" />,
    info: <InfoOutlineIcon />,
    warning: <AlertWarningIcon />,
  }[status]

  return (
    <ChakraAlert
      status={status === 'cash-in' ? 'info' : status}
      variant="solid"
      alignItems="center"
      borderRadius="md"
      border={status === 'info' ? '1px' : ''}
      borderColor="mld.neutral.800"
      textAlign="start"
      width="auto"
      {...props}
    >
      <Box alignSelf="start">
        {overridenIcon ? (
          <AlertIcon>{overridenIcon}</AlertIcon>
        ) : (
          <AlertIcon height="27px" />
        )}
      </Box>
      <Flex direction="column" width="100%" gap="xxs" justify="center">
        {title && (
          <AlertTitle textStyle="body-intro-medium" textWrap="wrap">
            {title}
          </AlertTitle>
        )}
        <AlertDescription textStyle="body-small">{message}</AlertDescription>
      </Flex>
      {isDismissable && (
        <CloseButton onClick={onClose} size="sm" alignSelf="start" />
      )}
    </ChakraAlert>
  )
}

Alert.propTypes = {
  status: PropTypes.oneOf(['error', 'success', 'warning', 'info', 'cash-in'])
    .isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isDismissable: PropTypes.bool,
  onClose: PropTypes.func,
}

Alert.defaultProps = {
  title: null,
  isDismissable: false,
  onClose: () => {},
}

export default Alert
