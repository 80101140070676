import {decamelizeKeys} from 'humps'

import {defaultHeaders, fetcher, getHeaders, postHeaders} from './fetcher'

export const createSupplier = ({externalOrganisationId, authenticityToken}) => {
  const body = decamelizeKeys({externalOrganisationId})
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers', opts)
}

export const updateSupplierCompliance = ({
  authenticityToken,
  supplierId,
  ...compliance
}) => {
  const body = decamelizeKeys(compliance)
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/suppliers/${supplierId}/compliance`, opts)
}

export const switchOrganisation = ({supplierId, authenticityToken}) => {
  const body = {supplier_id: supplierId}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/switch', opts)
}

export const requestHideVerificationStatus = ({authenticityToken}) => {
  const body = {}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/hide_verification_status', opts)
}

export const requestVerification = ({authenticityToken, supplierId}) => {
  const body = {}
  const opts = postHeaders({authenticityToken, body})
  return fetcher(`/api/suppliers/${supplierId}/verifications`, opts)
}

export const disconnectAccountingProvider = ({authenticityToken}) => {
  const body = {}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/disconnect', opts)
}

export const updateSupplierCustomerContactDetails = ({
  authenticityToken,
  ...customerDetail
}) => {
  const body = {
    invoice_id: customerDetail?.invoiceId,
    xero_contact_uuid: customerDetail?.id,
    first_name: customerDetail?.firstName,
    last_name: customerDetail?.lastName,
    email: customerDetail?.email,
    abn: customerDetail?.abn?.replace(/\D/g, ''),
    phone_number: customerDetail?.phone?.trim(),
    name: customerDetail?.businessName,
  }
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/update_customer_contact_details', opts)
}

export const setBankAccount = (params) => {
  const body = {AccountID: params.accountId}
  if (params.accountNumber) {
    body.accountNumber = params.accountNumber
  }
  const {authenticityToken, supplierId} = params
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/suppliers/${supplierId}/bank_accounts/choose`, opts)
}

export const setExpenseAccount = ({
  accountId,
  authenticityToken,
  supplierId,
}) => {
  const body = {AccountID: accountId}
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/suppliers/${supplierId}/expense_accounts/choose`, opts)
}

export const updateSupplierSettings = ({displayName, authenticityToken}) => {
  const body = {display_name: displayName}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/update_settings', opts)
}

export const createSetupVerificationInvoice = ({
  supplierId,
  authenticityToken,
}) => {
  const body = {supplier_id: supplierId}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/setup_verification_invoices', opts)
}

export const requestSupplierAgreement = ({supplierId, authenticityToken}) => {
  const body = {supplier_id: supplierId}
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/suppliers/supplier_agreements', opts)
}

export const lookupOrganisationName = (abn) => {
  return fetcher(
    `/api/suppliers/lookup_organisation_name?abn=${abn}`,
    getHeaders(),
  )
}

export const logBankIntegrationEvent = ({
  supplierId,
  authenticityToken,
  eventType,
  eventBody,
}) => {
  return fetcher(`/api/suppliers/${supplierId}/banking_providers/log_event`, {
    method: 'POST',
    headers: defaultHeaders({authenticityToken}),
    body: JSON.stringify({
      event_type: eventType,
      event_body: eventBody,
    }),
  })
}
