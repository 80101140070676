import PropTypes from 'prop-types'

const requirementsShape = PropTypes.shape({
  requirementKey: PropTypes.string.isRequired,
  issuanceRequirementPercentage: PropTypes.string,
  marmaladePaymentRequirementPercentage: PropTypes.string,
  unresolvedInvoiceIssueIds: PropTypes.arrayOf(PropTypes.string),
  onTrack: PropTypes.bool.isRequired,
})

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  feeRate: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(requirementsShape).isRequired,
  downgradable: PropTypes.bool,
})
