import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Box,
  Button,
  Flex,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  ButtonGroup,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react'
import {CopyIcon, ViewIcon, ViewOffIcon, WarningIcon} from '@chakra-ui/icons'
import useSWR from 'swr'
import mixpanel from 'mixpanel-browser'
import copy from 'copy-to-clipboard'
import pluralize from 'pluralize'
import {
  SmallInverseTickedIcon,
  SolidWarningIcon,
  CoinCashInIcon,
} from '../../icons'

import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import {fetcher} from '../../../api'
import {camelizeResult} from '../../../api/serializer'
import {DataTable, DropdownSelect} from '../../components'
import {
  toCurrency,
  toEpochMillisecond,
  toLocalDate,
} from '../../../utils/formatter'
import {tabsByDaysPastDue, tabsByDueStatus, tabsByMonthDue} from './utils'

const eventProperties = {
  page: 'Payer Individual View',
  table: 'Unpaid Invoices',
}

const DateCell = ({getValue}) => {
  const value = getValue()
  return <Text>{value ? toLocalDate(toEpochMillisecond(value)) : '-'}</Text>
}
DateCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

const CurrencyCell = ({getValue}) => {
  const value = getValue()
  return <Text>{value ? toCurrency(value) : '-'}</Text>
}
CurrencyCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

const CashInCell = ({getValue}) => {
  return (
    <Text>
      {getValue() ? (
        <Tag variant="primary" colorScheme="primary">
          <TagLeftIcon boxSize="12px" as={SmallInverseTickedIcon} mr="1" />
          <TagLabel>Cashed-in</TagLabel>
        </Tag>
      ) : (
        'Not cashed-in'
      )}
    </Text>
  )
}
CashInCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

const StatusCell = ({row}) => {
  const statusOverdue = row.original.overDue && 'Overdue'
  const statusDueSoon = row.original.overDueDays >= -3 && 'Due soon'

  if (statusDueSoon && !statusOverdue) {
    return (
      <Tag variant="status" colorScheme="warning">
        <TagLeftIcon
          boxSize="12px"
          mr="1"
          color="mld.warning.700"
          as={SolidWarningIcon}
        />
        <TagLabel>due soon</TagLabel>
      </Tag>
    )
  }

  if (statusOverdue) {
    return (
      <Tag variant="status" colorScheme="alert">
        <TagLeftIcon
          boxSize="12px"
          mr="1"
          color="mld.alert.700"
          as={WarningIcon}
        />
        <TagLabel>overdue</TagLabel>
        <Badge variant="nestedStatus" colorScheme="alert">
          {row.original.overDueDays}{' '}
          {pluralize('day', row.original.overDueDays)}
        </Badge>
      </Tag>
    )
  }

  return (
    <Tag variant="status" colorScheme="info">
      <TagLeftIcon boxSize="12px" mr="1" as={CoinCashInIcon} />
      <TagLabel>not yet due</TagLabel>
    </Tag>
  )
}
StatusCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      overDue: PropTypes.bool,
      overDueDays: PropTypes.number,
    }),
  }).isRequired,
}

const columns = [
  {
    header: 'Issue Date',
    accessorKey: 'issueDate',
    cell: DateCell,
  },
  {
    header: 'Invoice',
    accessorKey: 'number',
  },
  {
    header: 'Amount',
    accessorKey: 'total.cents',
    cell: CurrencyCell,
  },
  {
    header: 'Cash-in',
    accessorKey: 'accelerated',
    cell: CashInCell,
  },
  {
    header: 'Status',
    accessorKey: 'overDueDays',
    cell: StatusCell,
  },
  {
    header: 'Due date',
    accessorKey: 'dueDate',
    cell: DateCell,
    footer: <Text align="right">Total due:</Text>,
  },
  {
    header: 'Due amount',
    accessorKey: 'dueAmount.cents',
    cell: CurrencyCell,
    footer: ({table}) => {
      const {rows} = table.getFilteredRowModel()
      const total = rows.reduce(
        (acc, row) => acc + row.getValue('dueAmount_cents'),
        0,
      )
      return <Text>{toCurrency(total)}</Text>
    },
  },
]

const tabFilters = {
  byMonthDue: tabsByMonthDue,
  byDaysPastDue: tabsByDaysPastDue,
  byDueStatus: tabsByDueStatus,
}

const tabFiltersOptions = [
  {value: 'byMonthDue', label: '3 month breakdown'},
  {value: 'byDaysPastDue', label: 'Days past due'},
  {value: 'byDueStatus', label: 'Not yet due, due, overdue'},
]

export const UnpaidInvoices = ({customerId}) => {
  const [tabs, setTabs] = React.useState([])
  const [tabIndex, setTabIndex] = React.useState(0)
  const [tabFilter, setTabFilter] = React.useState(tabFiltersOptions[0])
  const [showCashIn, setShowCashIn] = React.useState(true)
  const copyTableRef = React.useRef()

  const {data, error, isLoading} = useSWR(
    `/api/invoices?customer_id=${customerId}&payment_status=unpaid&per_page=1000`,
    fetcher,
    {
      use: [camelizeResult],
    },
  )

  useEffect(() => {
    if (!isLoading) setTabs(tabFilters[tabFilter.value](data?.invoices))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, tabFilter])

  if (error) {
    return (
      <Text ml="md">Unable to fetch invoices, please try again later.</Text>
    )
  }

  const handleShowCashInToggle = () => {
    setShowCashIn(!showCashIn)
  }

  const handleCopyTable = () => {
    mixpanel.track(`Button clicked: Copy table`, {
      ...eventProperties,
      filter: tabFilter.label,
      tab: tabs[tabIndex].label,
    })
    copy(copyTableRef.current.innerHTML, {format: 'text/html'})
  }

  const handleChangeTabIndex = (index) => {
    mixpanel.track(`Table tab applied`, {
      ...eventProperties,
      filter: tabFilter.label,
      tab: tabs[index].label,
    })
    setTabIndex(index)
  }

  const handleChangeTabFilter = (filter) => {
    mixpanel.track(`Table filter applied`, {
      ...eventProperties,
      filter: filter.label,
    })
    setTabFilter(filter)
    setTabIndex(0)
  }

  return (
    <Box
      flexGrow="1"
      whiteSpace="nowrap"
      overflow="scroll"
      mt="md"
      px="md"
      py="sm"
      borderRadius="lg"
      backgroundColor="neutral.smoke"
    >
      <Flex align="center" justifyContent="space-between" mb="sm">
        <Text fontSize="md" fontWeight="medium" color="neutral.80">
          Unpaid Invoices
        </Text>
        <DropdownSelect
          options={tabFiltersOptions}
          value={tabFilter}
          onChange={handleChangeTabFilter}
          isClearable={false}
          name="tabFilter"
          placeholder="Select filter"
        />
      </Flex>
      <Tabs isLazy index={tabIndex} onChange={handleChangeTabIndex}>
        <Flex justify="space-between" flexWrap="wrap-reverse">
          <TabList mb="sm">
            {tabs.map(({label, invoices}, index) => (
              <Tab key={label}>
                <Text>
                  {label}
                  <Badge
                    ml="xs"
                    color={tabIndex === index && 'mld.neutral.900'}
                    bgColor={
                      tabIndex === index ? 'mld.neutral.500' : 'mld.neutral.50'
                    }
                  >
                    {invoices?.length}
                  </Badge>
                </Text>
              </Tab>
            ))}
          </TabList>
          <ButtonGroup float="right">
            <Button
              size="sm"
              variant="secondary"
              leftIcon={showCashIn ? <ViewOffIcon /> : <ViewIcon />}
              onClick={handleShowCashInToggle}
            >
              {showCashIn ? 'Hide' : 'Show'} Cash-in
            </Button>
            <Button
              size="sm"
              variant="secondary"
              leftIcon={<CopyIcon />}
              onClick={handleCopyTable}
            >
              Copy Table
            </Button>
          </ButtonGroup>
        </Flex>

        <TabPanels bg="white" ref={copyTableRef} overflow="scroll">
          {tabs.map(({label, invoices}) => (
            <TabPanel key={label} p="xs">
              <DataTable
                columns={[...columns].filter(
                  (column) =>
                    column.accessorKey !== 'accelerated' || showCashIn,
                )}
                data={invoices}
                isLoading={isLoading}
                enableSorting
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

UnpaidInvoices.propTypes = {
  customerId: PropTypes.string.isRequired,
}

export default withProviders(withSWR(UnpaidInvoices))
