import {stepName as connectBankAccount} from './steps/connectBankAccount'
import {stepName as idVerification} from './steps/idVerification'
import {stepName as signAgreement} from './steps/signAgreement'
import {stepName as updateInvoiceTemplate} from './steps/updateInvoiceTemplate'

export default ({
  bankingProviderApprovalStatus,
  approvedBankConnectionRequired,
  verificationStatus,
  manualKycDone,
  supplierAgreementAccepted,
  marmaladeInvoiceSetupStatus,
  canVerifyIdentity,
}) => {
  if (
    approvedBankConnectionRequired &&
    bankingProviderApprovalStatus === 'incomplete'
  ) {
    return connectBankAccount
  }

  if (
    verificationStatus !== 'verified' &&
    !manualKycDone &&
    canVerifyIdentity
  ) {
    return idVerification
  }

  if (!supplierAgreementAccepted) {
    return signAgreement
  }

  if (marmaladeInvoiceSetupStatus !== 'verified') {
    return updateInvoiceTemplate
  }

  return null
}
