import React from 'react'
import {Box, Text, Skeleton, Stack} from '@chakra-ui/react'

import {Help, BankAccountCard, PayidCard} from '../../components'
import useBankAccount from '../../hooks/useBankAccount'

const DirectPaymentsInstructions = () => {
  const {data, error, loading} = useBankAccount()

  return (
    <Box display="flex" flexDir="column" h="full">
      <Text fontWeight="medium" fontSize="lg" mb="sm">
        What do I need to do?
      </Text>

      <Text mb="md" lineHeight="3" fontSize="sm">
        We recommend reaching out to your customers and asking them to update
        their bank account details for future invoices. We have provided your
        correct bank details below. If you&apos;ve already informed them via
        email we recommend a quick phone call.
      </Text>

      {error && (
        <Text fontSize="sm" fontWeight="medium">
          Unable to fetch bank details, please try again later.
        </Text>
      )}

      {!error && (
        <Stack spacing="sm" mb="sm">
          <Skeleton isLoaded={!loading}>
            <BankAccountCard
              accountName={data.accountName || ' '}
              accountNumber={data.accountNumber || ' '}
              bsb={data.bsb || ' '}
            />
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            {data.payid && <PayidCard payid={data.payid} />}
          </Skeleton>
        </Stack>
      )}

      <Help heading="What is a direct payment?" mt="auto" lineHeight="3">
        <Text fontSize="sm" mb="sm">
          A direct payment is when an invoice is paid directly to your bank
          account instead of the new Marmalade bank account used on the invoice.
        </Text>

        <Text fontWeight="medium" fontSize="sm">
          If direct payments continue to occur, this may reduce your total
          Cash-in limit.
        </Text>
      </Help>
    </Box>
  )
}

export default DirectPaymentsInstructions
