import React from 'react'
import PropTypes from 'prop-types'
import {Button, Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon} from '@chakra-ui/icons'

const SmallScreenTabMenu = ({tabs, currentTabObject, changeTabs}) => {
  const {t} = useTranslation('settings')

  return (
    <Menu gutter="0">
      <MenuButton
        as={Button}
        p="md"
        mb="xs"
        backgroundColor="mld.neutral.50"
        variant="outline"
        width="100%"
        _active={{
          border: '1px solid',
          borderColor: 'mld.primary.200',
        }}
        rightIcon={<ChevronDownIcon />}
      >
        {t(`settings:SettingsScreen.tabs.${currentTabObject.tabName}`)}
      </MenuButton>
      <MenuList rootProps={{width: '100%', px: 'md'}}>
        {tabs.map(({tabName}, index) => (
          <MenuItem
            key={`${tabName}MenuItems`}
            display={tabName === currentTabObject.tabName ? 'none' : 'block'}
            minH="48px"
            color="inherit"
            py="sm"
            onClick={() => changeTabs(index)}
          >
            {t(`settings:SettingsScreen.tabs.${tabName}`)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const tabShape = PropTypes.shape({
  tabName: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
})

SmallScreenTabMenu.propTypes = {
  tabs: PropTypes.arrayOf(tabShape).isRequired,
  currentTabObject: tabShape.isRequired,
  changeTabs: PropTypes.func.isRequired,
}

export default SmallScreenTabMenu
