import React from 'react'
import pluralize from 'pluralize'
import {CheckIcon, CloseIcon, DownloadIcon, TimeIcon} from '@chakra-ui/icons'
import {Tooltip} from '@chakra-ui/react'
import {generateHistory} from '../../utils/paymentHistory'
import {toCurrency} from '../../../utils/formatter'

function matchSubLabel(payment) {
  if (payment.passedBack) {
    return ''
  }
  if (!payment.fullyMatched) {
    return 'Partial match'
  }
  if (payment.creditCardFee.cents) {
    return `Inc. credit card fee of ${toCurrency(payment.creditCardFee.cents)}`
  }
  return ''
}

function generateHistoryItem(item, payment) {
  if (['paid-out-fast', 'paid-out'].includes(item.step)) {
    return {
      ...item,
      label: 'Paid out to your account',
      secondaryLabel: '',
      showDivider: true,
      icon: <CheckIcon boxSize="4" />,
    }
  }

  if (item.step === 'passed-back') {
    return {
      ...item,
      label: 'Paid out to your account',
      secondaryLabel: 'Outstanding balance settled',
      showDivider: true,
      icon: <CheckIcon boxSize="4" />,
    }
  }

  if (item.step === 'passed-back-marmalade') {
    return {
      ...item,
      label: 'Paid out to Marmalade',
      secondaryLabel: 'Outstanding balance settled',
      showDivider: true,
      icon: <CheckIcon boxSize="4" />,
    }
  }

  if (item.step === 'unmatched') {
    return {
      ...item,
      label: 'Payment not matched to invoice(s)',
      showDivider: true,
      icon: <CloseIcon boxSize="4" />,
    }
  }

  if (item.step === 'matched-passed-back') {
    return {
      ...item,
      label: `Payment not matched to ${pluralize(
        'invoice',
        payment.matchedInvoiceCount,
      )}`,
      showDivider: true,
      subLabel: matchSubLabel(payment),
      icon: <CheckIcon boxSize="4" />,
    }
  }

  if (item.step === 'matched') {
    return {
      ...item,
      label: `Payment matched to ${pluralize(
        'invoice',
        payment.matchedInvoiceCount,
      )}`,
      showDivider: true,
      subLabel: matchSubLabel(payment),
      icon: payment.fullyMatched ? (
        <CheckIcon boxSize="4" />
      ) : (
        <TimeIcon boxSize="4" />
      ),
    }
  }

  if (item.step === 'payment-received') {
    return {
      ...item,
      label: 'Payment Received',
      showDivider: false,
      icon: <CheckIcon boxSize="4" />,
    }
  }

  if (item.step === 'payment-pending') {
    return {
      ...item,
      label: 'Payment pending',
      showDivider: false,
      icon: (
        <Tooltip
          label="Direct debit takes 3-4 business days to receive"
          zIndex="tooltip"
          placement="right"
        >
          <DownloadIcon boxSize="4" />
        </Tooltip>
      ),
    }
  }

  return null
}

export function generate(payment) {
  return generateHistory(payment).map((item) =>
    generateHistoryItem(item, payment),
  )
}
