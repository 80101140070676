import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

const AuthContext = React.createContext()

const useAuthenticityToken = (token = '') => {
  const [authenticityToken, updateAuthenticityToken] = React.useState(token)
  return {authenticityToken, updateAuthenticityToken}
}

const AuthProvider = ({children, authenticityToken: token}) => {
  const {authenticityToken, updateAuthenticityToken} =
    useAuthenticityToken(token)

  const value = useMemo(
    () => ({authenticityToken, updateAuthenticityToken}),
    [authenticityToken, updateAuthenticityToken],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const useAuthProvider = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuthProvider must be used within a AuthProvider')
  }

  const {authenticityToken, updateAuthenticityToken} = context

  return {authenticityToken, updateAuthenticityToken}
}

export {AuthProvider, useAuthProvider}
