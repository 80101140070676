import Dinero from 'dinero.js'
import {camelizeKeys} from 'humps'

export const camelizeResult = (useSWRNext) => {
  return (key, fetcher, config) => {
    const result = useSWRNext(key, fetcher, config)

    if (result.data) {
      return {...result, data: camelizeKeys(result.data, {deep: true})}
    }
    return result
  }
}

const isMoneyObject = (object) => {
  const objectKeys = Object.keys(object)

  return (
    objectKeys.length === 2 &&
    objectKeys.includes('cents') &&
    objectKeys.includes('currency')
  )
}

// Recursive function which checks all object values and their children for any
// money objects ie {cents, currency} and replaces them with Dinero objects
const replaceMoneyWithDineroObject = (object) => {
  if (isMoneyObject(object)) {
    // If we start out with a money object convert it to Dinero
    return Dinero({...object, amount: object.cents})
  }

  return Object.keys(object).reduce((obj, key) => {
    const value = object[key]

    if (typeof value !== 'object' || value === null || value === undefined) {
      // When the value is missing or not a object/array leave it unchanged.
      return {...obj, [key]: value}
    }

    if (Array.isArray(value)) {
      // If value is an array map through and recurse for all object elements
      return {
        ...obj,
        [key]: value.map((childValue) =>
          typeof childValue === 'object'
            ? replaceMoneyWithDineroObject(childValue)
            : childValue,
        ),
      }
    }

    // If we find a money object return a Dinero object
    if (isMoneyObject(value)) {
      return {...obj, [key]: Dinero({...value, amount: value.cents})}
    }

    // Otherwise dig deeper
    return {...obj, [key]: replaceMoneyWithDineroObject(value)}
  }, {})
}

// Converts any money objects into Dinero objects for formatting and subtraction etc
export const dineroMoneyObjects = (useSWRNext) => {
  return (key, fetcher, config) => {
    const result = useSWRNext(key, fetcher, config)

    if (result.data && typeof result.data === 'object') {
      return {...result, data: replaceMoneyWithDineroObject(result.data)}
    }
    return result
  }
}
