import React from 'react'
import {createIcon} from '@chakra-ui/react'

export const InverseSolidTickedIcon = createIcon({
  displayName: 'InverseSolidTickedIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'currentColor',
  },
  path: (
    <>
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
      <path d="M6.5 12.5L10 15.5L16.5 7" stroke="#000000" strokeWidth="2" />
    </>
  ),
})
