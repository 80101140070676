import React from 'react'
import useSWR from 'swr'
import {get} from 'lodash-es'
import {
  Badge,
  Icon,
  Text,
  Stack,
  Skeleton,
  Collapse,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react'
import {ChevronUpIcon, ChevronDownIcon} from '@chakra-ui/icons'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import HeaderSummary from './HeaderSummary'
import PricingEstimationsModal from '../../../../components/PricingEstimationsModal'

const Header = () => {
  const {organisation} = useRails()
  const {data, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/unpaid_invoices_summary`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )
  const {isOpen, onToggle} = useDisclosure()

  return (
    <>
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        px={{base: '0', md: 'md'}}
        py="sm"
        bg="neutral.white"
      >
        <Text fontSize="24px" fontWeight="medium">
          Cash-in
        </Text>

        <Spacer />
        <PricingEstimationsModal supplierId={organisation.currentSupplier.id} />
        <Skeleton
          onClick={onToggle}
          isLoaded={!isLoading}
          fontSize="lg"
          display="flex"
          alignItems="center"
        >
          <Text textStyle="body-copy" color="grey.700" mx="sm" my="auto">
            Total unpaid
          </Text>
          <Badge
            textStyle="body-copy"
            bg="grey.100"
            borderRadius="sm"
            my="auto"
          >
            {get(data, 'totalCount')}
          </Badge>
          <Text textStyle="body-copy-medium" fontWeight="700" mx="sm" my="auto">
            {get(data, 'totalAmount')?.toFormat('$0,0')}
          </Text>

          <Icon
            as={isOpen ? ChevronUpIcon : ChevronDownIcon}
            color="primary.actionblue"
            cursor="pointer"
            my="auto"
            h="24px"
            w="24px"
            data-testid="tooltip-icon"
          />
        </Skeleton>
      </Stack>

      <Collapse in={isOpen}>
        <HeaderSummary fetchData={isOpen} />
      </Collapse>
    </>
  )
}

export default Header
