import React from 'react'
import {Badge, Box, Flex, List, ListItem, Spacer} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import {PadlockIcon} from '../../icons'
import linkType from './linkType'

const NavigationItemList = ({links}) => (
  <List>
    {links.map(({disabled, active, label, icon, href, tag}, index) => (
      <ListItem
        key={label}
        as="a"
        href={disabled ? undefined : href}
        color={active ? 'mld.sideNav.color' : 'mld.neutral.500'}
        bg={active ? 'mld.sideNav.navActiveBg' : 'none'}
        py="xs"
        px="sm"
        mb={index !== links.length - 1 ? 'xs' : ''}
        borderRadius="md"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        _hover={
          disabled || {
            cursor: 'pointer',
            bg: 'mld.sideNav.navHoverBg',
            color: 'mld.sideNav.navHoverColor',
          }
        }
      >
        {icon}
        <Box as="span" ml="xs" width="100%">
          {label}
        </Box>
        {tag && (
          <Badge
            bg="mld.sideNav.color"
            borderRadius="md"
            color="mld.sideNav.background"
            margin="auto 0"
          >
            {tag}
          </Badge>
        )}
        {disabled && (
          <>
            <Spacer />
            <Flex
              borderRadius="full"
              w="24px"
              h="24px"
              align="center"
              justify="center"
            >
              <PadlockIcon color="mld.sideNav.color" />
            </Flex>
          </>
        )}
      </ListItem>
    ))}
  </List>
)

NavigationItemList.propTypes = {
  links: PropTypes.arrayOf(linkType).isRequired,
}

export default NavigationItemList
