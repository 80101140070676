import {Button, Stack, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'

import Layout from './Layout'

const SupplierRequestClosureScreen = ({supplierName}) => {
  return (
    <Layout>
      <Stack spacing="sm">
        <Text as="h1" textStyle="headline1" fontSize="xl">
          Request account closure
        </Text>

        <Text>
          If you no longer want to use Marmalade to get paid instantly for your
          invoices, you can request to have your <strong>{supplierName}</strong>{' '}
          account closed.
        </Text>
      </Stack>

      <form method="post">
        <Button variant="primary" colorScheme="danger" type="submit">
          Close my account
        </Button>
      </form>
    </Layout>
  )
}

SupplierRequestClosureScreen.propTypes = {
  supplierName: PropTypes.string.isRequired,
}

export default SupplierRequestClosureScreen
