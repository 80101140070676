import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text, Container, Button} from '@chakra-ui/react'

import {useRails} from '../../contexts/rails'
import {LogoHorizontal, ThemeProvider} from '../../components'

const InvalidToken = ({resendStatementPath}) => {
  const {authenticityToken} = useRails()

  return (
    <ThemeProvider>
      <Container
        maxWidth={{base: 'full', lg: '80ch'}}
        py={{base: 'sm', md: 'lg'}}
      >
        <Flex
          justifyContent="space-between"
          direction={{base: 'column', md: 'row'}}
          alignItems={{base: 'start', md: 'end'}}
          gap="sm"
        >
          <Text textStyle="headline2" order={{base: '2', md: '1'}}>
            Link Expired
          </Text>

          <Text
            color="grey.500"
            fontWeight="400"
            fontSize="12px"
            display="flex"
            flexDirection="row"
            order={{base: '1', md: '2'}}
          >
            Powered by
            <LogoHorizontal width={85} height={11} mt="3px" ml="xs" />
          </Text>
        </Flex>
        <Text textStyle="body-copy" pt="md">
          This link has expired as a new version of the statement is available.
          Check your email for the latest version or resend the statement to
          your email.
        </Text>
        <form method="POST" acceptCharset="UTF-8" action={resendStatementPath}>
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <Button
            variant="primary"
            colorScheme="primary"
            size="sm"
            mt="md"
            type="submit"
          >
            Resend latest statement
          </Button>
        </form>
      </Container>
    </ThemeProvider>
  )
}

InvalidToken.propTypes = {
  resendStatementPath: PropTypes.string.isRequired,
}

export default InvalidToken
