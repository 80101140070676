import React from 'react'
import PropTypes from 'prop-types'

import {Tag, TagLabel, TagRightIcon, Tooltip} from '@chakra-ui/react'

import {BoltIcon, DotIcon} from '../../icons'

import {generateHistory} from '../../utils/paymentHistory'

const PaymentStatusTag = ({payment}) => {
  const [latest] = generateHistory(payment)

  if (['payment-received', 'payment-pending'].includes(latest.step)) {
    return (
      <Tooltip
        label="Payment is new. It will be matched and paid out to you."
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="info">
          <TagLabel>New payment</TagLabel>
        </Tag>
      </Tooltip>
    )
  }

  if (latest.step === 'unmatched') {
    return (
      <Tooltip
        label="This payment is unmatched. Please send remittance."
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="warning">
          <TagLabel>Unmatched</TagLabel>
        </Tag>
      </Tooltip>
    )
  }

  if (['matched-passed-back', 'matched'].includes(latest.step)) {
    return (
      <Tooltip
        label="Payment was matched to an invoice."
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="info">
          <TagLabel>Matched</TagLabel>
        </Tag>
      </Tooltip>
    )
  }

  if (['passed-back-marmalade', 'passed-back'].includes(latest.step)) {
    return (
      <Tooltip
        label="This payment was unmatched and paid out to you. You will need to match it in your accounting platform."
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="success">
          <TagLabel>Paid Out</TagLabel>
          <TagRightIcon boxSize="15px" color="mld.warning.700" as={DotIcon} />
        </Tag>
      </Tooltip>
    )
  }

  if (latest.step === 'paid-out-fast') {
    return (
      <Tooltip
        label="This payment was matched to an invoice and paid out in real-time."
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="success">
          <TagLabel>Paid Out</TagLabel>
          <TagRightIcon boxSize="15px" color="mld.success.700" as={BoltIcon} />
        </Tag>
      </Tooltip>
    )
  }

  if (latest.step === 'paid-out') {
    return (
      <Tooltip
        label="This payment was paid out within 2 business days"
        zIndex="tooltip"
        placement="bottom"
      >
        <Tag variant="status" colorScheme="success">
          <TagLabel>Paid Out</TagLabel>
        </Tag>
      </Tooltip>
    )
  }

  return null
}

const PaymentProps = {
  matchedAmount: PropTypes.number.isRequired,
  settledAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  matchedAt: PropTypes.number.isRequired,
  matchFailureAt: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  fullyMatched: PropTypes.bool.isRequired,
  passedBack: PropTypes.bool.isRequired,
  supplierPayoutDetails: {
    paidOutAt: PropTypes.string.isRequired,
    paidOutBy: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  },
}

PaymentStatusTag.propTypes = {
  payment: PropTypes.shape(PaymentProps).isRequired,
}

export default PaymentStatusTag
