import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Flex, Text} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import Input from '../Input'
import {InverseTickedIcon, PadlockIcon} from '../../icons'

const bsbPattern = /[0-9]{3}-?[0-9]{3}/
const accountNumberPattern = /[0-9]{4}-?[0-9]{4,5}/

const directDebitFormSchema = Yup.object().shape({
  bsb: Yup.string()
    .matches(bsbPattern, 'BSB should contain 6 digit')
    .required('Required'),
  accountNumber: Yup.string()
    .matches(accountNumberPattern, 'Invalid account number')
    .required('Required'),
})

const DirectDebit = ({
  paymentAmount,
  onSubmitPayment,
  errorMessage,
  paymentDetails,
  isLoggedIn,
}) => {
  // Condition to allow input editable
  //  1. Customer is logged in and if it has missing payment details
  //  2. Customer not logged in
  //  3. When toggled to use new/different payment details
  const [isEditable, setIsEditable] = React.useState(
    isLoggedIn
      ? paymentDetails && (!paymentDetails.bsb || !paymentDetails.accountNumber)
      : true,
  )

  const formik = useFormik({
    initialValues: {
      bsb: (paymentDetails && paymentDetails.bsb) || '',
      accountNumber: (paymentDetails && paymentDetails.accountNumber) || '',
    },
    validationSchema: directDebitFormSchema,
    onSubmit: async (values) => {
      const {bsb, accountNumber} = values

      await onSubmitPayment({type: 'directDebit', bsb, accountNumber})
    },
  })

  const toggleUpdatePayment = () => {
    setIsEditable(!isEditable)
  }

  const handleInputChange = (e) => {
    // Remove non-numeric value on input change
    const value = e.target.value.replace(/\D+/g, '')

    formik.setFieldValue(e.target.name, value)
  }

  return (
    <Box mt="md">
      <Text mb="sm" fontWeight="medium">
        The best way to pay invoices
      </Text>
      <Text mb="sm">
        <InverseTickedIcon color="secondary.green" size="24px" mr="sm" />
        No fees ever
      </Text>
      <Text mb="sm">
        <InverseTickedIcon color="secondary.green" size="24px" mr="sm" />
        Save all payment details
      </Text>
      <Box as="form" mt="md" mb="xs" onSubmit={formik.handleSubmit}>
        <Input
          name="bsb"
          placeholder="BSB"
          inputMode="numeric"
          type="tel"
          value={formik.values.bsb}
          errorMessage={formik.touched.bsb && formik.errors.bsb}
          isInvalid={Boolean(formik.touched.bsb && formik.errors.bsb)}
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          isDisabled={!isEditable}
        />
        <Input
          name="accountNumber"
          placeholder="Account number"
          inputMode="numeric"
          type="tel"
          value={formik.values.accountNumber}
          errorMessage={
            formik.touched.accountNumber && formik.errors.accountNumber
          }
          isInvalid={Boolean(
            formik.touched.accountNumber && formik.errors.accountNumber,
          )}
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          isDisabled={!isEditable}
        />
        <Text
          textStyle="body-detail"
          color="neutral.60"
          lineHeight="150%"
          mt="xs"
          mb="md"
        >
          By providing your bank account details and confirming this payment,
          you agree to this Direct Debit Request and the Direct Debit Request
          service agreement, and authorise Stripe Payments Australia Pty Ltd ACN
          160 180 343 Direct Debit User ID number 507156 (&quot;Stripe&quot;) to
          debit your account through the Bulk Electronic Clearing System (BECS)
          on behalf of Marmalade (the &quot;Merchant&quot;) for any amounts
          separately communicated to you by the Merchant. You certify that you
          are either an account holder or an authorised signatory on the account
          listed above.
        </Text>
        {errorMessage && (
          <Text my="xs" textStyle="body-detail" color="red.500">
            {errorMessage}
          </Text>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent={
            isLoggedIn && isEditable ? 'flex-end' : 'space-between'
          }
          mb={isLoggedIn && isEditable ? 'md' : '0'}
          gap="sm"
        >
          <Box
            fontSize="14px"
            color="neutral.80"
            display="flex"
            alignItems="center"
            mt={{base: 'sm', md: '0'}}
          >
            Your details are secure <PadlockIcon ml="xs" size="24px" />
          </Box>
          {!(isLoggedIn && isEditable) && (
            <Flex gap="xs" pr="xs">
              <Button
                variant="primary"
                colorScheme="primary"
                type="submit"
                isLoading={formik.isSubmitting}
                isDisabled={!formik.isValid}
              >
                Pay {paymentAmount}
              </Button>
              {isLoggedIn && !isEditable && (
                <Button variant="secondary" onClick={toggleUpdatePayment}>
                  Pay with new details
                </Button>
              )}
            </Flex>
          )}
        </Box>
        {isLoggedIn && isEditable && (
          <Box
            display="flex"
            flexDirection={{base: 'column', md: 'row'}}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="secondary"
              onClick={() => {
                formik.handleReset()

                toggleUpdatePayment()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              colorScheme="primary"
              type="submit"
              isLoading={formik.isSubmitting}
              isDisabled={!formik.isValid}
            >
              Pay {paymentAmount}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

DirectDebit.defaultProps = {
  onSubmitPayment: () => {},
  errorMessage: '',
  paymentDetails: {bsb: '', accountNumber: ''},
  isLoggedIn: false,
}

DirectDebit.propTypes = {
  onSubmitPayment: PropTypes.func,
  paymentAmount: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  paymentDetails: PropTypes.shape({
    bsb: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
  isLoggedIn: PropTypes.bool,
}

export default DirectDebit
