import {colorMode} from '../foundations/colors'

// https://chakra-ui.com/docs/components/checkbox/theming
export default {
  baseStyle: (props) => ({
    fontFamily: ' body',
    fontWeight: 'normal',

    label: {
      marginLeft: '12px',
      color: colorMode('mld.neutral.800', 'mld.neutral.500')(props),
      _checked: {
        color: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
      },
      _disabled: {
        borderColor: colorMode('mld.neutral.600', 'mld.neutral.700')(props),
      },
    },
    control: {
      borderRadius: '1px',
      borderColor: colorMode('mld.neutral.700', 'mld.neutral.500')(props),
      _hover: {
        borderColor: 'mld.primary.200',
      },
      _active: {
        bg: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
        borderColor: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
      },
      _checked: {
        bg: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
        borderColor: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
        _hover: {
          bg: colorMode('mld.neutral.900', 'mld.neutral.50')(props),
          borderColor: 'mld.primary.200',
        },
      },
      _disabled: {
        borderColor: colorMode('mld.neutral.600', 'mld.neutral.700')(props),
        bg: 'transparent',
        _hover: {
          borderColor: colorMode('mld.neutral.600', 'mld.neutral.700')(props),
        },
        _checked: {
          borderColor: 'transparent',
        },
        _active: {
          bg: 'transparent',
        },
      },
      _focusVisible: {
        borderColor: 'mld.primary.200',
      },
    },
  }),
  variants: {
    mld: {
      container: {
        alignItems: 'start',
      },
      control: {
        marginTop: 'xxs',
      },
    },
  },
}
