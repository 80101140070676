import React from 'react'
import PropTypes from 'prop-types'
import {
  Text,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Button,
} from '@chakra-ui/react'

import useFeatureCookie from '../../../../hooks/useFeatureCookie'
import {Card} from '../../../../components'
import {toCurrency} from '../../../../../utils/formatter'
import ReimbursementRequiredInvoicesModal from '../../../../components/ReimbursementRequiredInvoicesModal'
import {PaidIcon} from '../../../../icons'

const ReimbursementRequired = ({amountOwedCents}) => {
  const reimbursementRequiredDisclosure = useDisclosure()
  const [hideOnboarding, setHideOnboarding] = useFeatureCookie(
    'hide_settle_with_cash_in_onboarding',
  )

  return (
    <>
      <ReimbursementRequiredInvoicesModal
        {...reimbursementRequiredDisclosure}
      />

      <Popover
        closeOnBlur={false}
        closeOnEsc={false}
        isOpen={!hideOnboarding}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <div>
            <Card
              bg="neutral.white"
              border="1px"
              borderColor="primary.actionblue"
              mb="md"
            >
              <Flex
                justify="space-between"
                align="center"
                flexWrap="wrap"
                gap="xs"
              >
                <Flex align="center">
                  <PaidIcon
                    fontSize="md"
                    mr="sm"
                    size="24px"
                    color="primary.actionblue"
                  />

                  <Text fontWeight="medium">
                    You owe Marmalade {toCurrency(amountOwedCents)}
                  </Text>
                </Flex>
                <Flex
                  justify="flex-end"
                  alignItems="center"
                  wrap="wrap"
                  gap="sm"
                >
                  <Button
                    variant="tertiary"
                    onClick={reimbursementRequiredDisclosure.onOpen}
                  >
                    View invoices
                  </Button>
                  <Button as="a" variant="primary" href="/cashin">
                    Settle using Cash-in now
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </div>
        </PopoverTrigger>

        <PopoverContent
          px={1}
          py={2}
          borderRadius="lg"
          zIndex="popover"
          _focus={{outline: 'none'}}
        >
          <PopoverArrow />
          <PopoverBody>
            <Text fontWeight="medium">Settle outstanding funds with ease</Text>
            <Text fontSize="sm" mb="xs">
              Customers paid you directly for an invoice you have previously
              cashed-in? You can now use Cash-in to easily and immediately
              settle the amount you owe to Marmalade.
            </Text>
            <Button variant="primary" size="sm" onClick={setHideOnboarding}>
              Got it
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default ReimbursementRequired

ReimbursementRequired.propTypes = {
  amountOwedCents: PropTypes.number.isRequired,
}
