import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Tooltip} from '@chakra-ui/react'
import CustomerRatingDropdown from '../../../../components/CustomerRatingDropdown'
import CustomerRatingsPropType from '../../../../../utils/propTypes/customerRating'

const CustomerCell = ({getValue, row}) => {
  const value = getValue()
  return (
    <Box display="flex" flexDirection="column">
      <Tooltip label={value} zIndex="tooltip" placement="top">
        <Text
          mb="xs"
          fontWeight="medium"
          whiteSpace="nowrap"
          maxWidth={['100px', '200px']}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {value}
        </Text>
      </Tooltip>
      <CustomerRatingDropdown
        score={row.original.customerRating.score}
        feeRangeMin={row.original.customerRating.feeRangeMin}
        feeRangeMax={row.original.customerRating.feeRangeMax}
        topReason={row.original.customerRating.topReason.key}
        additionalInfo={row.original.customerRating.topReason.values}
      />
    </Box>
  )
}

CustomerCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      customerRating: CustomerRatingsPropType.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CustomerCell
