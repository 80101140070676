import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import LogoSymbol from '../Logo/LogoSymbol'

const MPointsCell = ({getValue}) => {
  const {t} = useTranslation('billPayments')
  const {status} = getValue()

  if (status === 'failed') {
    return (
      <Text textStyle="body-small">
        {t('billPayments:billPayment.table.columns.failedMPointsMessage')}
      </Text>
    )
  }
  if (status !== 'successful') {
    return null
  }

  return (
    <Flex direction="column" whiteSpace="nowrap" gap="xs">
      <Flex alignItems="center" gap="xs">
        <LogoSymbol width="20px" height="20px" />
        <Text textStyle="body-small">
          {t('billPayments:billPayment.table.columns.receivedMPointsMessage')}
        </Text>
      </Flex>
    </Flex>
  )
}

MPointsCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export default MPointsCell
