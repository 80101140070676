import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Flex, Text} from '@chakra-ui/react'
import LogoSymbol from '../Logo/LogoSymbol'

const RewardDetails = ({status}) => {
  const {t} = useTranslation('billPayments')
  if (status === 'failed') {
    return (
      <Text textStyle="body-small">
        {t('billPayments:billPayment.drawer.noMPointsEarned')}
      </Text>
    )
  }

  if (status !== 'successful') {
    return null
  }

  return (
    <Flex align="center" gap="xs">
      <LogoSymbol width="30px" height="30px" />
      <Text textStyle="body-small-medium">
        {t('billPayments:billPayment.drawer.mPointsEarned')}
      </Text>
    </Flex>
  )
}

RewardDetails.propTypes = {
  status: PropTypes.string.isRequired,
}

export default RewardDetails
