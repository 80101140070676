export function generateHistory(payment) {
  if (!payment || Object.keys(payment).length === 0) {
    return []
  }

  const {
    matchedAmount,
    settledAt,
    createdAt,
    matchedAt,
    matchFailureAt,
    amount,
    fullyMatched,
    passedBack,
    supplierPayoutDetails: {paidOutAt, paidOutBy, amount: supplierAmount},
  } = payment

  return [
    // Paid out
    {
      visible: paidOutAt && fullyMatched && !passedBack && paidOutBy === 'Fast',
      step: 'paid-out-fast',
      amount: supplierAmount,
      eventTime: paidOutAt,
      rawStatus: 'completed',
    },
    {
      visible:
        paidOutAt && fullyMatched && !passedBack && paidOutBy === 'Standard',
      step: 'paid-out',
      amount: supplierAmount,
      eventTime: paidOutAt,
      rawStatus: 'completed',
    },
    // Passed back
    {
      visible: paidOutAt && passedBack && supplierAmount.cents === 0,
      step: 'passed-back-marmalade',
      amount: supplierAmount,
      eventTime: paidOutAt,
      rawStatus: 'completed',
    },
    {
      visible: paidOutAt && passedBack && supplierAmount.cents > 0,
      step: 'passed-back',
      amount: supplierAmount,
      eventTime: paidOutAt,
      rawStatus: 'completed',
    },
    // Unmatched
    {
      visible: settledAt && matchFailureAt,
      step: 'unmatched',
      amount,
      eventTime: matchFailureAt,
      rawStatus: 'failed',
    },
    // Matched
    {
      visible: settledAt && matchedAt && passedBack,
      step: 'matched-passed-back',
      amount: matchedAmount,
      eventTime: matchedAt,
      rawStatus: 'success',
    },
    {
      visible: settledAt && matchedAt && !passedBack,
      step: 'matched',
      amount: matchedAmount,
      eventTime: matchedAt,
      rawStatus: 'success',
    },
    // New payment
    {
      visible: !!settledAt,
      step: 'payment-received',
      amount,
      eventTime: createdAt,
      rawStatus: 'success',
    },
    {
      visible: !settledAt,
      step: 'payment-pending',
      amount,
      eventTime: createdAt,
      rawStatus: 'pending',
    },
  ].filter(({visible}) => visible)
}
