import React from 'react'
import {Text, Link} from '@chakra-ui/react'

export default () => (
  <Text fontSize="sm" mb="sm" color="primary.errorred">
    We&apos;re unable to connect your bank account at this time, please try
    again later. If this problem persists, please contact our{' '}
    <Link color="secondary.blue" href="mailto:support@withmarmalade.com">
      support team
    </Link>
    .
  </Text>
)
