import {extendTheme} from '@chakra-ui/react'
import components from './components'
import {colors} from './foundations/colors'
import typography from './foundations/typography'

const marmaladeTheme = extendTheme({
  ...typography,
  colors,
  zIndices: {
    dropdown: 1150,
    sticky: 1100,
  },
  space: {
    xxs: '4px',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '48px',
    xl: '96px',
    xxl: '192px',
  },
  components,
})

export default marmaladeTheme
