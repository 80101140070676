import React from 'react'
import PropTypes from 'prop-types'
import {camelize} from 'humps'
import {Box, Divider, Flex, Spinner, Text} from '@chakra-ui/react'
import ResponsiveDataTable from '../../../../components/ResponsiveDataTable'
import OnIntersectWrapper from '../../../../components/OnIntersectWrapper'
import {columnsPropType} from '../../../../components/DataTable/propTypes'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import SortDropdown from './SortDropdown'
import GroupOptions from './GroupOptions'

const InvoicesList = ({
  columns,
  headerContent,
  onSort,
  sortBy,
  sortOptions,
  onGroup,
  groupByValue,
  groupOptions,
  invoicesData,
  emptyContent,
  isLoading,
  isValidating,
  hasNextPage,
  fetchNextPage,
}) => {
  return (
    <Box px={{base: '0', xl: 'lg'}}>
      {headerContent}
      <Flex
        pb="sm"
        gap="sm"
        borderBottom="1px solid"
        borderBottomColor="grey.300"
      >
        <GroupOptions
          onGroup={onGroup}
          value={groupByValue}
          options={groupOptions}
        />
        <Divider
          orientation="vertical"
          height="auto"
          mx="sm"
          display={{base: 'none', md: 'block'}}
        />
        <SortDropdown onSort={onSort} value={sortBy} options={sortOptions} />
      </Flex>

      <ResponsiveDataTable
        variant="cashIn"
        chakraTableProps={{size: 'lg'}}
        columns={columns}
        onSort={onSort}
        sortByValue={
          sortBy
            ? [
                {
                  id: camelize(sortBy.split(' ')[0]),
                  desc: sortBy.split(' ')[1] === 'desc',
                },
              ]
            : []
        }
        groupByValue={groupByValue}
        manualSorting
        enableSorting
        data={invoicesData}
        isLoading={isLoading}
      />
      {!isLoading && invoicesData.length === 0 && emptyContent}
      {!isLoading && hasNextPage && (
        <OnIntersectWrapper onIntersect={fetchNextPage} />
      )}
      {!isLoading && (hasNextPage || isValidating) && (
        <Flex width="100%" justify="center" align="center" pt="sm">
          <Spinner />
          <Text textStyle="body-copy" pl="md">
            Loading more
          </Text>
        </Flex>
      )}
    </Box>
  )
}

InvoicesList.defaultProps = {
  invoicesData: [],
  sortOptions: [],
  groupOptions: [],
  sortBy: null,
  groupByValue: null,
  headerContent: null,
}

InvoicesList.propTypes = {
  columns: columnsPropType.isRequired,
  onSort: PropTypes.func.isRequired,
  emptyContent: PropTypes.node.isRequired,
  headerContent: PropTypes.node,
  invoicesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
      contactName: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      dueAmount: DineroPropShape.isRequired,
      payoutAmount: DineroPropShape.isRequired,
      supplierFeeRate: PropTypes.string,
      supplierFeeAmount: DineroPropShape,
      customerFeeRate: PropTypes.string,
      customerFeeAmount: DineroPropShape,
      feeAmount: DineroPropShape.isRequired,
      feeRate: PropTypes.string.isRequired,
    }),
  ),
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  sortBy: PropTypes.string,
  onGroup: PropTypes.func.isRequired,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  groupByValue: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
}

export default InvoicesList
