const industries = [
  {
    label: 'Agriculture',
    options: [
      'Nursery and Floriculture Production',
      'Mushroom and Vegetable Growing',
      'Fruit and Tree Nut Growing',
      'Sheep, Beef Cattle and Grain Farming',
      'Other Crop Growing',
      'Dairy Cattle Farming',
      'Poultry Farming',
      'Deer Farming',
      'Other Livestock Farming',
    ],
  },
  {
    label: 'Aquaculture',
    options: ['Aquaculture'],
  },
  {
    label: 'Forestry and Logging',
    options: ['Forestry and Logging'],
  },
  {
    label: 'Fishing, Hunting and Trapping',
    options: ['Fishing', 'Hunting and Trapping'],
  },
  {
    label: 'Agriculture, Forestry and Fishing Support Services',
    options: [
      'Forestry Support Services',
      'Agriculture and Fishing Support Services',
    ],
  },
  {
    label: 'Coal Mining',
    options: ['Coal Mining'],
  },
  {
    label: 'Oil and Gas Extraction',
    options: ['Oil and Gas Extraction'],
  },
  {
    label: 'Metal Ore Mining',
    options: ['Metal Ore Mining'],
  },
  {
    label: 'Non-Metallic Mineral Mining and Quarrying',
    options: [
      'Construction Material Mining',
      'Other Non-Metallic Mineral Mining and Quarrying',
    ],
  },
  {
    label: 'Exploration and Other Mining Support Services',
    options: ['Exploration', 'Other Mining Support Services'],
  },
  {
    label: 'Food Product Manufacturing',
    options: [
      'Meat and Meat Product Manufacturing',
      'Seafood Processing',
      'Dairy Product Manufacturing',
      'Fruit and Vegetable Processing',
      'Oil and Fat Manufacturing',
      'Grain Mill and Cereal Product Manufacturing',
      'Bakery Product Manufacturing',
      'Sugar and Confectionery Manufacturing',
      'Other Food Product Manufacturing',
    ],
  },
  {
    label: 'Beverage and Tobacco Product Manufacturing',
    options: [
      'Beverage Manufacturing',
      'Cigarette and Tobacco Product Manufacturing',
    ],
  },
  {
    label: 'Textile, Leather, Clothing and Footwear Manufacturing',
    options: [
      'Textile Manufacturing',
      'Leather Tanning, Fur Dressing and Leather Product Manufacturing',
      'Textile Product Manufacturing',
      'Knitted Product Manufacturing',
      'Clothing and Footwear Manufacturing',
    ],
  },
  {
    label: 'Wood Product Manufacturing',
    options: [
      'Log Sawmilling and Timber Dressing',
      'Other Wood Product Manufacturing',
    ],
  },
  {
    label: 'Pulp, Paper and Converted Paper Product Manufacturing',
    options: [
      'Pulp, Paper and Paperboard Manufacturing',
      'Converted Paper Product Manufacturing',
    ],
  },
  {
    label: 'Printing (including the Reproduction of Recorded Media)',
    options: [
      'Printing and Printing Support Services',
      'Reproduction of Recorded Media',
    ],
  },
  {
    label: 'Petroleum and Coal Product Manufacturing',
    options: ['Petroleum and Coal Product Manufacturing'],
  },
  {
    label: 'Basic Chemical and Chemical Product Manufacturing',
    options: [
      'Basic Chemical Manufacturing',
      'Basic Polymer Manufacturing',
      'Fertiliser and Pesticide Manufacturing',
      'Pharmaceutical and Medicinal Product Manufacturing',
      'Cleaning Compound and Toiletry Preparation Manufacturing',
      'Other Basic Chemical Product Manufacturing',
    ],
  },
  {
    label: 'Polymer Product and Rubber Product Manufacturing',
    options: [
      'Polymer Product Manufacturing',
      'Natural Rubber Product Manufacturing',
    ],
  },
  {
    label: 'Non-Metallic Mineral Product Manufacturing',
    options: [
      'Glass and Glass Product Manufacturing',
      'Ceramic Product Manufacturing',
      'Cement, Lime, Plaster and Concrete Product Manufacturing',
      'Other Non-Metallic Mineral Product Manufacturing',
    ],
  },
  {
    label: 'Primary Metal and Metal Product Manufacturing',
    options: [
      'Basic Ferrous Metal Manufacturing',
      'Basic Ferrous Metal Product Manufacturing',
      'Basic Non-Ferrous Metal Manufacturing',
      'Basic Non-Ferrous Metal Product Manufacturing',
    ],
  },
  {
    label: 'Fabricated Metal Product Manufacturing',
    options: [
      'Iron and Steel Forging',
      'Structural Metal Product Manufacturing',
      'Metal Container Manufacturing',
      'Sheet Metal Product Manufacturing (except Metal Structural and Container Products)',
      'Other Fabricated Metal Product Manufacturing',
    ],
  },
  {
    label: 'Transport Equipment Manufacturing',
    options: [
      'Motor Vehicle and Motor Vehicle Part Manufacturing',
      'Other Transport Equipment Manufacturing',
    ],
  },
  {
    label: 'Machinery and Equipment Manufacturing',
    options: [
      'Professional and Scientific Equipment Manufacturing',
      'Computer and Electronic Equipment Manufacturing',
      'Electrical Equipment Manufacturing',
      'Domestic Appliance Manufacturing',
      'Pump, Compressor, Heating and Ventilation Equipment Manufacturing',
      'Specialised Machinery and Equipment Manufacturing',
      'Other Machinery and Equipment Manufacturing',
    ],
  },
  {
    label: 'Furniture and Other Manufacturing',
    options: ['Furniture Manufacturing', 'Other Manufacturing'],
  },
  {
    label: 'Electricity Supply',
    options: [
      'Electricity Generation',
      'Electricity Transmission',
      'Electricity Distribution',
      'On Selling Electricity and Electricity Market Operation',
    ],
  },
  {
    label: 'Gas Supply',
    options: ['Gas Supply'],
  },
  {
    label: 'Water Supply, Sewerage and Drainage Services',
    options: ['Water Supply, Sewerage and Drainage Services'],
  },
  {
    label: 'Waste Collection, Treatment and Disposal Services',
    options: [
      'Waste Collection Services',
      'Waste Treatment, Disposal and Remediation Services',
    ],
  },
  {
    label: 'Building Construction',
    options: [
      'Residential Building Construction',
      'Non-Residential Building Construction',
    ],
  },
  {
    label: 'Heavy and Civil Engineering Construction',
    options: ['Heavy and Civil Engineering Construction'],
  },
  {
    label: 'Construction Services',
    options: [
      'Land Development and Site Preparation Services',
      'Building Structure Services',
      'Building Installation Services',
      'Building Completion Services',
      'Other Construction Services',
    ],
  },
  {
    label: 'Basic Material Wholesaling',
    options: [
      'Agricultural Product Wholesaling',
      'Mineral, Metal and Chemical Wholesaling',
      'Timber and Hardware Goods Wholesaling',
    ],
  },
  {
    label: 'Machinery and Equipment Wholesaling',
    options: [
      'Specialised Industrial Machinery and Equipment Wholesaling',
      'Other Machinery and Equipment Wholesaling',
    ],
  },
  {
    label: 'Motor Vehicle and Motor Vehicle Parts Wholesaling',
    options: ['Motor Vehicle and Motor Vehicle Parts Wholesaling'],
  },
  {
    label: 'Grocery, Liquor and Tobacco Product Wholesaling',
    options: ['Grocery, Liquor and Tobacco Product Wholesaling'],
  },
  {
    label: 'Other Goods Wholesaling',
    options: [
      'Textile, Clothing and Footwear Wholesaling',
      'Pharmaceutical and Toiletry Goods Wholesaling',
      'Furniture, Floor Covering and Other Goods Wholesaling',
    ],
  },
  {
    label: 'Commission-Based Wholesaling',
    options: ['Commission-Based Wholesaling'],
  },
  {
    label: 'Motor Vehicle and Motor Vehicle Parts Retailing',
    options: [
      'Motor Vehicle Retailing',
      'Motor Vehicle Parts and Tyre Retailing',
    ],
  },
  {
    label: 'Fuel Retailing',
    options: ['Fuel Retailing'],
  },
  {
    label: 'Food Retailing',
    options: ['Supermarket and Grocery Stores', 'Specialised Food Retailing'],
  },
  {
    label: 'Other Store-Based Retailing',
    options: [
      'Furniture, Floor Coverings, Houseware and Textile Goods Retailing',
      'Electrical and Electronic Goods Retailing',
      'Hardware, Building and Garden Supplies Retailing',
      'Recreational Goods Retailing',
      'Clothing, Footwear and Personal Accessory Retailing',
      'Department Stores',
      'Pharmaceutical and Other Store-Based Retailing',
    ],
  },
  {
    label:
      'Non-Store Retailing and Retail Commission-Based Buying and/or Selling',
    options: [
      'Non-Store Retailing',
      'Retail Commission-Based Buying and/or Selling',
    ],
  },
  {
    label: 'Accommodation',
    options: ['Accommodation'],
  },
  {
    label: 'Food and Beverage Services',
    options: [
      'Cafes, Restaurants and Takeaway Food Services',
      'Pubs, Taverns and Bars',
      'Clubs (Hospitality)',
    ],
  },
  {
    label: 'Road Transport',
    options: ['Road Freight Transport', 'Road Passenger Transport'],
  },
  {
    label: 'Rail Transport',
    options: ['Rail Freight Transport', 'Rail Passenger Transport'],
  },
  {
    label: 'Water Transport',
    options: ['Water Freight Transport', 'Water Passenger Transport'],
  },
  {
    label: 'Air and Space Transport',
    options: ['Air and Space Transport'],
  },
  {
    label: 'Other Transport',
    options: [
      'Scenic and Sightseeing Transport',
      'Pipeline and Other Transport',
    ],
  },
  {
    label: 'Postal and Courier Pick-up and Delivery Services',
    options: ['Postal and Courier Pick-up and Delivery Services'],
  },
  {
    label: 'Transport Support Services',
    options: [
      'Water Transport Support Services',
      'Airport Operations and Other Air Transport Support Services',
      'Other Transport Support Services',
    ],
  },
  {
    label: 'Warehousing and Storage Services',
    options: ['Warehousing and Storage Services'],
  },
  {
    label: 'Publishing (except Internet and Music Publishing)',
    options: [
      'Newspaper, Periodical, Book and Directory Publishing',
      'Software Publishing',
    ],
  },
  {
    label: 'Motion Picture and Sound Recording Activities',
    options: [
      'Motion Picture and Video Activities',
      'Sound Recording and Music Publishing',
    ],
  },
  {
    label: 'Broadcasting (except Internet)',
    options: ['Radio Broadcasting', 'Television Broadcasting'],
  },
  {
    label: 'Internet Publishing and Broadcasting',
    options: ['Internet Publishing and Broadcasting'],
  },
  {
    label: 'Telecommunications Services',
    options: ['Telecommunications Services'],
  },
  {
    label:
      'Internet Service Providers, Web Search Portals and Data Processing Services',
    options: [
      'Internet Service Providers and Web Search Portals',
      'Data Processing, Web Hosting and Electronic Information Storage Services',
    ],
  },
  {
    label: 'Library and Other Information Services',
    options: ['Libraries and Archives', 'Other Information Services'],
  },
  {
    label: 'Finance',
    options: [
      'Central Banking',
      'Depository Financial Intermediation',
      'Non-Depository Financing',
      'Financial Asset Investing',
    ],
  },
  {
    label: 'Insurance and Superannuation Funds',
    options: [
      'Life Insurance',
      'Health and General Insurance',
      'Superannuation Funds',
    ],
  },
  {
    label: 'Auxiliary Finance and Insurance Services',
    options: [
      'Auxiliary Finance and Investment Services',
      'Auxiliary Insurance Services',
    ],
  },
  {
    label: 'Rental and Hiring Services (except Real Estate)',
    options: [
      'Motor Vehicle and Transport Equipment Rental and Hiring',
      'Farm Animal and Bloodstock Leasing',
      'Other Goods and Equipment Rental and Hiring',
      'Non-Financial Intangible Assets (Except Copyrights) Leasing',
    ],
  },
  {
    label: 'Property Operators and Real Estate Services',
    options: ['Property Operators', 'Real Estate Services'],
  },
  {
    label:
      'Professional, Scientific and Technical Services (Except Computer System Design and Related Services)',
    options: [
      'Scientific Research Services',
      'Architectural, Engineering and Technical Services',
      'Legal and Accounting Services',
      'Advertising Services',
      'Market Research and Statistical Services',
      'Management and Related Consulting Services',
      'Veterinary Services',
      'Other Professional, Scientific and Technical Services',
    ],
  },
  {
    label: 'Computer System Design and Related Services',
    options: ['Computer System Design and Related Services'],
  },
  {
    label: 'Administrative Services',
    options: [
      'Employment Services',
      'Travel Agency and Tour Arrangement Services',
      'Other Administrative Services',
    ],
  },
  {
    label: 'Building Cleaning, Pest Control and Other Support Services',
    options: [
      'Building Cleaning, Pest Control and Gardening Services',
      'Packaging Services',
    ],
  },
  {
    label: 'Public Administration',
    options: [
      'Central Government Administration',
      'State Government Administration',
      'Local Government Administration',
      'Justice',
      'Government Representation',
    ],
  },
  {
    label: 'Defence',
    options: ['Defence'],
  },
  {
    label: 'Public Order, Safety and Regulatory Services',
    options: ['Public Order and Safety Services', 'Regulatory Services'],
  },
  {
    label: 'Preschool and School Education',
    options: ['Preschool Education', 'School Education'],
  },
  {
    label: 'Tertiary Education',
    options: ['Tertiary Education'],
  },
  {
    label: 'Adult, Community and Other Education',
    options: [
      'Adult, Community and Other Education',
      'Educational Support Services',
    ],
  },
  {
    label: 'Hospitals',
    options: ['Hospitals'],
  },
  {
    label: 'Medical and Other Health Care Services',
    options: [
      'Medical Services',
      'Pathology and Diagnostic Imaging Services',
      'Allied Health Services',
      'Other Health Care Services',
    ],
  },
  {
    label: 'Residential Care Services',
    options: ['Residential Care Services'],
  },
  {
    label: 'Social Assistance Services',
    options: ['Child Care Services', 'Other Social Assistance Services'],
  },
  {
    label: 'Heritage Activities',
    options: ['Museum Operation', 'Parks and Gardens Operations'],
  },
  {
    label: 'Creative and Performing Arts Activities',
    options: ['Creative and Performing Arts Activities'],
  },
  {
    label: 'Sports and Recreation Activities',
    options: [
      'Sports and Physical Recreation Activities',
      'Horse and Dog Racing Activities',
      'Amusement and Other Recreation Activities',
    ],
  },
  {
    label: 'Gambling Activities',
    options: ['Gambling Activities'],
  },
  {
    label: 'Repair and Maintenance',
    options: [
      'Automotive Repair and Maintenance',
      'Machinery and Equipment Repair and Maintenance',
      'Other Repair and Maintenance',
    ],
  },
  {
    label: 'Personal and Other Services',
    options: [
      'Personal Care Services',
      'Funeral, Crematorium and Cemetery Services',
      'Other Personal Services',
      'Religious Services',
      'Civic, Professional and Other Interest Group Services',
    ],
  },
  {
    label:
      'Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities',
    options: [
      'Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities of Households for Own Use',
    ],
  },
]

export default industries
