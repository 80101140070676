import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  FormControl,
  FormHelperText,
  FormLabel,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Link,
  Button,
  Divider,
  Flex,
  Input,
  FormErrorMessage,
  Spacer,
} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'

import SignInLayout from '../../layouts/SignInLayout'
import {LockIcon, EmailIcon, SensitiveIcon} from '../../icons'
import Alert from '../../components/Alert'

const SignInForm = ({
  authenticityToken,
  errorMessage,
  resource,
  infoMessage,
  resourceName,
  signInPath,
  signUpPath,
  resendConfirmationPath,
}) => {
  const {values, touched, errors, handleChange, handleBlur} = useFormik({
    initialValues: {
      [resourceName]: {
        email: (resource && resource.email) || '',
        password: '',
      },
    },
    validationSchema: Yup.object().shape({
      [resourceName]: Yup.object().shape({
        email: Yup.string()
          .email('Please enter a valid email address')
          .required('Required'),
        password: Yup.string().required('Required'),
      }),
    }),
  })

  const emailNeedsConfirmation =
    resourceName === 'customer_login' &&
    errorMessage &&
    errorMessage === 'You have to confirm your email address before continuing.' // Must match devise.failure.unconfirmed value in devise.en.yml

  const emailErrorMessage =
    (touched[resourceName] &&
      touched[resourceName].email &&
      errors[resourceName] &&
      errors[resourceName].email) ||
    ''

  const passwordErrorMessage =
    (touched[resourceName] &&
      touched[resourceName].password &&
      errors[resourceName] &&
      errors[resourceName].password) ||
    ''

  return (
    <form method="POST" acceptCharset="UTF-8" action={signInPath}>
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input type="hidden" name="commit" value="Login" />

      {errorMessage && (
        <Alert
          status="error"
          message={
            <span>
              {errorMessage}{' '}
              {emailNeedsConfirmation && (
                <Link
                  href={resendConfirmationPath}
                  color="secondary.black"
                  textDecoration="underline"
                >
                  Resend confirmation instructions
                </Link>
              )}
            </span>
          }
          mb="md"
        />
      )}

      {infoMessage && <Alert status="info" mb={8} message={infoMessage} />}
      <FormControl pb="md" isInvalid={Boolean(emailErrorMessage)}>
        <FormLabel htmlFor={`${resourceName}_email`} fontSize="base">
          Email
        </FormLabel>
        <InputGroup size="lg">
          <InputLeftElement>
            <EmailIcon />
          </InputLeftElement>
          <Input
            size="lg"
            fontSize="base"
            type="email"
            name={`${resourceName}[email]`}
            id={`${resourceName}_email`}
            aria-describedby="email-helper-text"
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={emailErrorMessage}
            value={values[resourceName].email}
          />
        </InputGroup>
        <FormErrorMessage textAlign="right">
          {emailErrorMessage}
        </FormErrorMessage>
        <FormHelperText hidden id="email-helper-text">
          We&apos;ll never share your email.
        </FormHelperText>
      </FormControl>
      <Spacer />
      <FormControl pb="md" isInvalid={Boolean(passwordErrorMessage)}>
        <FormLabel htmlFor={`${resourceName}_password`} fontSize="base">
          Password
        </FormLabel>
        <InputGroup size="lg">
          <InputRightElement>
            <SensitiveIcon />
          </InputRightElement>
          <Input
            type="password"
            size="lg"
            fontSize="base"
            name={`${resourceName}[password]`}
            id={`${resourceName}_password`}
            aria-describedby="password-helper-text"
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={
              (touched[resourceName] &&
                touched[resourceName].password &&
                errors[resourceName] &&
                errors[resourceName].password) ||
              ''
            }
            value={values[resourceName].password}
          />
        </InputGroup>
        <FormErrorMessage textAlign="right">
          {passwordErrorMessage}
        </FormErrorMessage>
        <FormHelperText hidden id="password-helper-text">
          Enter a secure password!
        </FormHelperText>
      </FormControl>
      <Button
        variant="primary"
        colorScheme="primary"
        size="lg"
        width="100%"
        type="submit"
      >
        Login
      </Button>

      <Divider py="sm" />
      {signUpPath && (
        <Flex justify="center" gap="sm" pt="md">
          <Text textStyle="body-small">No account?</Text>
          <Text
            textStyle="body-small"
            as="a"
            href={signUpPath}
            textDecoration="underline"
          >
            Request a demo
          </Text>
        </Flex>
      )}
    </form>
  )
}

SignInForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  resource: PropTypes.shape({
    email: PropTypes.string,
  }),
  resourceName: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  signUpPath: PropTypes.string,
  resendConfirmationPath: PropTypes.string,
}

SignInForm.defaultProps = {
  errorMessage: '',
  infoMessage: '',
  resource: {
    email: '',
  },
  signUpPath: '',
  resendConfirmationPath: '',
}

const SignInScreen = ({
  authenticityToken,
  errorMessage,
  resource,
  infoMessage,
  resourceName,
  signInPath,
  signUpPath,
  resendConfirmationPath,
  resetPasswordPath,
}) => {
  const {t} = useTranslation('signInPage')

  return (
    <SignInLayout>
      <Box color="mld.neutral.50" width="100%">
        <Text textStyle="headline3" textAlign="center" py="md" mb="sm">
          {t('signInPage:title', {context: resourceName})}
        </Text>
        <SignInForm
          authenticityToken={authenticityToken}
          errorMessage={errorMessage}
          infoMessage={infoMessage}
          resource={resource}
          resourceName={resourceName}
          signInPath={signInPath}
          signUpPath={signUpPath}
          resendConfirmationPath={resendConfirmationPath}
        />
        <Flex justify="center" pt="md">
          <Link
            textStyle="body-small"
            href={resetPasswordPath}
            textDecoration="underline"
          >
            Reset password <LockIcon />
          </Link>
        </Flex>
      </Box>
    </SignInLayout>
  )
}

SignInScreen.propTypes = {
  resource: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  signUpPath: PropTypes.string,
  resendConfirmationPath: PropTypes.string,
  resetPasswordPath: PropTypes.string.isRequired,
}

SignInScreen.defaultProps = {
  signUpPath: '',
  resendConfirmationPath: '',
}

export default SignInScreen
