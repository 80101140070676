import React from 'react'
import PropTypes from 'prop-types'
import {camelize} from 'humps'
import {Trans, useTranslation} from 'react-i18next'
import {Text, UnorderedList, Link, ListIcon, ListItem} from '@chakra-ui/react'
import {CheckCircleIcon} from '@chakra-ui/icons'
import {EmptyCircleIcon} from '../../icons'

const calculateContext = ({
  onTrack,
  tierKey,
  issuanceRequirementPercentage,
  marmaladePaymentRequirementPercentage,
}) => {
  if (onTrack) {
    return 'onTrack'
  }

  if (
    [
      issuanceRequirementPercentage,
      marmaladePaymentRequirementPercentage,
    ].includes('100.0')
  ) {
    return 'allRequired'
  }

  if (tierKey === 'platinum') {
    return tierKey
  }

  return null
}

const RequirementItem = ({
  tierKey,
  requirementKey,
  onTrack,
  unresolvedInvoiceIssueIds,
  ...otherProps
}) => {
  const {t} = useTranslation()

  const context = calculateContext({
    onTrack,
    tierKey,
    ...otherProps,
  })
  const countProps = unresolvedInvoiceIssueIds?.length
    ? {
        count: unresolvedInvoiceIssueIds.length,
      }
    : {}

  return (
    <ListItem>
      <ListIcon
        as={onTrack ? CheckCircleIcon : EmptyCircleIcon}
        color={onTrack ? 'secondary.green' : 'neutral.black'}
      />
      <Text as="span" textStyle="body-small">
        <Trans
          t={t}
          i18nKey={`supplierRewardTiers.requirements.${camelize(
            requirementKey,
          )}`}
          values={{
            ...otherProps,
            ...countProps,
            context,
          }}
          components={{
            span: <Text as="span" textStyle="body-small-medium" />,
            link: <Link href={`/invoices?ids=${unresolvedInvoiceIssueIds}`} />,
          }}
        />
      </Text>
    </ListItem>
  )
}

RequirementItem.defaultProps = {
  unresolvedInvoiceIssueIds: [],
}

RequirementItem.propTypes = {
  tierKey: PropTypes.string.isRequired,
  requirementKey: PropTypes.string.isRequired,
  onTrack: PropTypes.bool.isRequired,
  unresolvedInvoiceIssueIds: PropTypes.arrayOf(PropTypes.string),
}

const SupplierRewardTierRequirements = ({requirements, tierKey}) => {
  return (
    <UnorderedList styleType="none" ml="0" spacing="xxs">
      {requirements.map((requirement) => (
        <RequirementItem
          key={requirement.requirementKey}
          {...requirement}
          tierKey={tierKey}
        />
      ))}
    </UnorderedList>
  )
}

SupplierRewardTierRequirements.propTypes = {
  tierKey: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      requirementKey: PropTypes.string.isRequired,
      onTrack: PropTypes.bool.isRequired,
      unresolvedInvoiceIssueIds: PropTypes.arrayOf(PropTypes.string),
      issuanceRequirementPercentage: PropTypes.string,
      marmaladePaymentRequirementPercentage: PropTypes.string,
    }),
  ).isRequired,
}

export default SupplierRewardTierRequirements
