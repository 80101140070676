import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Text, useDisclosure} from '@chakra-ui/react'
import {toast} from 'react-toastify'

import {cancelInvoiceCashIn} from '../../../../api'
import {useRails} from '../../../contexts/rails'
import {ToastMessage, Dialog} from '../../../components'

const CancelCashIn = ({
  xeroInvoiceUuid,
  updateInvoiceTimeline,
  invoiceNumber,
}) => {
  const {authenticityToken} = useRails()

  const confirmationModal = useDisclosure()
  const failureModal = useDisclosure()

  const cancelCashInInvoice = async () => {
    try {
      const response = await cancelInvoiceCashIn({
        invoiceId: xeroInvoiceUuid,
        authenticityToken,
      })

      if (response.status === 204) {
        toast.dark(
          <ToastMessage>
            Cash-in cancelled for Invoice <strong>{invoiceNumber}</strong>
          </ToastMessage>,
        )

        updateInvoiceTimeline()
      } else {
        failureModal.onOpen()
      }
    } catch (error) {
      toast.error(<ToastMessage>{error.message}</ToastMessage>)
    } finally {
      confirmationModal.onClose()
    }
  }

  return (
    <>
      <Box rounded="lg" color="black" bg="background.purple" p={4} mb={5}>
        <Text>
          You have selected to Cash-in this invoice and it is now being
          processed. You can stop this invoice from being cashed-in up until
          payment is made to your account.
        </Text>

        <Box mt={3}>
          <Button variant="secondary" onClick={confirmationModal.onOpen}>
            Cancel Cash-in
          </Button>
        </Box>
      </Box>

      <Dialog
        closeOnOverlayClick={false}
        onConfirm={cancelCashInInvoice}
        isOpen={confirmationModal.isOpen}
        onClose={confirmationModal.onClose}
        title="Are you sure you want to cancel Cash-in for this invoice?"
        confirmLabel="Cancel this cash-in"
        closeLabel="Close"
      >
        Cancelling this cash-in will mean this payout will not be processed. You
        will not be charged the cash-in fee and will not be able to cash-in this
        invoice in the future.
      </Dialog>

      <Dialog
        isOpen={failureModal.isOpen}
        onClose={failureModal.onClose}
        title="Sorry, you are unable to cancel this cash-in."
        closeLabel="Close"
      >
        The cash-in for this invoice has already been processed and cannot be
        cancelled. Please contact support if you have any questions.
      </Dialog>
    </>
  )
}

CancelCashIn.propTypes = {
  xeroInvoiceUuid: PropTypes.string.isRequired,
  updateInvoiceTimeline: PropTypes.func.isRequired,
  invoiceNumber: PropTypes.string.isRequired,
}

export default CancelCashIn
