import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {fetcher} from '../../../api/fetcher'
import {useRails} from '../../contexts/rails'
import BankIntegrationModal from './BankIntegrationModal'

const AddSupplierBankAccountIntegrationModal = ({
  isOpen,
  onClose,
  initialStep,
  openOnLearnMore,
  displaySuccessView,
  yodleeFlow,
  providerAccountId,
}) => {
  const {organisation} = useRails()
  const [fetchingYodleeConnectionDetails, setFetchingYodleeConnectionDetails] =
    useState(false)
  const [yodleeConnectionDetails, setYodleeConnectionDetails] = useState(null)

  const fetchYodleeConnectionDetails = () => {
    if (!fetchingYodleeConnectionDetails && !yodleeConnectionDetails) {
      setFetchingYodleeConnectionDetails(true)
      fetcher(
        `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/connection_details`,
        {
          method: 'GET',
        },
      )
        .then((response) => {
          setYodleeConnectionDetails(response)
        })
        .catch(() => {})
        .finally(() => {
          setFetchingYodleeConnectionDetails(false)
        })
    }
  }

  if (isOpen) fetchYodleeConnectionDetails()

  return (
    <BankIntegrationModal
      isOpen={isOpen}
      onClose={onClose}
      openOnLearnMore={openOnLearnMore}
      displaySuccessView={displaySuccessView}
      initialStep={initialStep}
      loadingConfig={fetchingYodleeConnectionDetails}
      yodleeConfigName={yodleeConnectionDetails?.yodleeConfigName}
      yodleeFastLinkUrl={yodleeConnectionDetails?.yodleeFastLinkUrl}
      yodleeToken={yodleeConnectionDetails?.yodleeToken}
      yodleeFlow={yodleeFlow}
      providerAccountId={providerAccountId}
    />
  )
}

AddSupplierBankAccountIntegrationModal.defaultProps = {
  openOnLearnMore: false,
  displaySuccessView: true,
  yodleeFlow: '',
  providerAccountId: '',
  initialStep: 'initial',
}

AddSupplierBankAccountIntegrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialStep: PropTypes.string,
  openOnLearnMore: PropTypes.bool,
  displaySuccessView: PropTypes.bool,
  yodleeFlow: PropTypes.string,
  providerAccountId: PropTypes.string,
}

export default AddSupplierBankAccountIntegrationModal
