import React from 'react'
import {useTranslation} from 'react-i18next'
import {camelize} from 'humps'
import DrawerFieldItem from './DrawerFieldItem'
import DrawerSection from './DrawerSection'
import LogoPayto from '../Logo/LogoPayto'
import paymentMethodPropType from '../../../utils/propTypes/paymentMethod'

const PaymentMethodSection = ({paymentMethod}) => {
  const {t} = useTranslation('billPayments')

  const paymentMethodType = camelize(paymentMethod.type)
  const paymentMethodDetails = paymentMethod[paymentMethodType]

  return (
    <DrawerSection
      title={t('billPayments:billPayment.drawer.paymentMethodSectionTitle')}
      logo={<LogoPayto height="25px" width="fit-content" />}
    >
      {paymentMethodType === 'debitAgreement' && (
        <>
          <DrawerFieldItem
            label={t('billPayments:billPayment.drawer.fields.branchCode')}
            value={paymentMethodDetails.branchCode}
          />
          <DrawerFieldItem
            label={t('billPayments:billPayment.drawer.fields.accountNumber')}
            value={paymentMethodDetails.accountNumber}
          />
        </>
      )}
    </DrawerSection>
  )
}

PaymentMethodSection.propTypes = {
  paymentMethod: paymentMethodPropType.isRequired,
}

export default PaymentMethodSection
