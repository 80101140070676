import React from 'react'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'
import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import {ToastMessage} from '../../../../components/Toast'
import {AuthProvider} from '../../../../contexts/authentication'
import {useRails} from '../../../../contexts/rails'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import ReissuanceForm from '../../../../components/ReissuanceForm'
import ViewDetailsButton from './ViewDetailsButton'

const ReissueDrawer = ({
  row,
  isOpen,
  onClose,
  setProcessing,
  onSuccess,
  onStart,
  btnRef,
}) => {
  const {authenticityToken} = useRails()

  return (
    <Drawer
      isOpen={isOpen}
      size="md"
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <AuthProvider authenticityToken={authenticityToken}>
          <ReissuanceForm
            selectedInvoice={{
              id: row.original.id,
              due_amount: {
                cents: row.original.dueAmount.getAmount(),
                currency: row.original.dueAmount.getCurrency(),
              },
              number: row.original.invoiceNumber,
              due_date: row.original.dueDate,
            }}
            handleClose={onClose}
            onClickSubmit={() => {
              onStart(row.original.id)
              setProcessing(true)
              onSuccess(row.original.id)
              toast.dark(
                <ToastMessage>
                  {row.original.invoiceNumber} is now added to Available now
                  tab. Go to Available now tab now to cash-in.
                </ToastMessage>,
              )
            }}
            abnRequired
            recheckEligibility
          />
        </AuthProvider>
      </DrawerContent>
    </Drawer>
  )
}

ReissueDrawer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
      dueAmount: PropTypes.shape(DineroPropShape).isRequired,
      dueDate: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  btnRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]).isRequired,
}

const ReissueButtonCell = ({row, onSuccess, onStart}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [processing, setProcessing] = React.useState(false)
  const btnRef = React.useRef()

  const {requiresReissuanceVerification} = row.original

  return (
    <Flex direction={{base: 'column', md: 'row'}}>
      <Button
        variant="secondary"
        width="100%"
        isDisabled={processing || requiresReissuanceVerification}
        isLoading={processing}
        loadingText="Processing..."
        ref={btnRef}
        onClick={onOpen}
        data-testid="reissue-button"
      >
        Reissue Invoice
      </Button>
      <ViewDetailsButton
        row={row}
        onNextClick={() => {
          onOpen()
        }}
        reissuanceRequired
      />
      <ReissueDrawer
        row={row}
        btnRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        setProcessing={setProcessing}
        onSuccess={onSuccess}
        onStart={onStart}
      />
    </Flex>
  )
}

ReissueButtonCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
      requiresReissuanceVerification: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
}

export default ReissueButtonCell
