import React from 'react'
import PropTypes from 'prop-types'
import {Box, Divider, Text} from '@chakra-ui/react'

import {AuthProvider} from '../../contexts/authentication'
import {useRails} from '../../contexts/rails'
import SupplierLayout from '../../layouts/SupplierLayout'
import withProviders from '../../contexts/withProviders'
import AccountingProviderSettings from './AccountingProviderSettings'
import CustomerPaymentSettings from './CustomerPaymentSettings'
import BankProviderConnectionSettings from './BankProviderConnectionSettings'
import GeneralInformationSettings from './GeneralInformationSettings'

const SupplierSettingsScreen = ({authenticityToken}) => {
  const {accountingProvider} = useRails()

  const settingSections = [
    {
      title: 'General information',
      component: <GeneralInformationSettings />,
      visible: true,
    },
    {
      title: 'Customer payments',
      component: <CustomerPaymentSettings />,
      visible: true,
    },
    {
      title: `${accountingProvider.name} settings`,
      component: <AccountingProviderSettings />,
      visible: true,
    },
    {
      title: 'Bank connection',
      component: <BankProviderConnectionSettings />,
      visible: true,
    },
  ].filter(({visible}) => visible)

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Settings">
        <Text fontSize="lg" fontWeight="medium" px="md" py="sm">
          Settings
        </Text>
        <Divider />
        {settingSections.map((section, index) => (
          <Box px="md" py="sm" key={section.title}>
            <Text fontSize="sm" fontWeight="medium" py="sm">
              {section.title}
            </Text>
            {section.component}
            {index < settingSections.length - 1 && <Divider />}
          </Box>
        ))}
      </SupplierLayout>
    </AuthProvider>
  )
}

SupplierSettingsScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default withProviders(SupplierSettingsScreen)
