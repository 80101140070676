import {useFormikContext} from 'formik'
import {string} from 'yup'
import React from 'react'
import {FormControl, Box, Text} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import {DropdownSelect} from '../../../../components'

const options = [
  {value: 'Owner or Director', label: 'Owner or Director'},
  {value: 'Authorised Tax Agent', label: 'Authorised Tax Agent'},
  {
    value: 'Other Authorised Representative',
    label: 'Other Authorised Representative',
  },
]

export const schema = {
  relationshipToBusiness: string()
    .oneOf(
      options.map(({value}) => value),
      'Relationship to business is required',
    )
    .default(null)
    .nullable(),
}

const RelationshipToBusiness = ({formResourceContext}) => {
  // Wrapping the Formik functions so we can ensure they're within the context of
  // the supplied formResourceContext
  const {
    values: formikValues,
    errors: formikErrors,
    touched: formikTouched,
    setFieldValue: formikSetFieldValue,
  } = useFormikContext()

  // Defining our own functions for contextualising form fields within the supplied formResourceContext
  // This is needed to support resourceful forms (like sign-up) and non-resourceful like 'what-if' onboarding
  // Once 'what-if' is removed this can be refactored out
  const contextualisedFieldName = (field) => {
    if (!formResourceContext) return field

    return `${formResourceContext}[${field}]`
  }
  const values = formResourceContext
    ? formikValues[formResourceContext] || {}
    : formikValues
  const errors = formResourceContext
    ? formikErrors[formResourceContext] || {}
    : formikErrors
  const touched = formResourceContext
    ? formikTouched[formResourceContext] || {}
    : formikTouched
  const setFieldValue = (field, value) => {
    formikSetFieldValue(contextualisedFieldName(field), value)
  }

  return (
    <FormControl>
      <Box mb={4}>
        <DropdownSelect
          name={contextualisedFieldName('relationshipToBusiness')}
          id="relationshipToBusiness"
          variant="mld"
          options={options}
          value={
            values.relationshipToBusiness && {
              label: values.relationshipToBusiness,
              value: values.relationshipToBusiness,
            }
          }
          placeholder="Relationship to business"
          onChange={(option) => {
            setFieldValue('relationshipToBusiness', option?.value)
          }}
          isClearable={false}
        />
        {touched &&
          touched.relationshipToBusiness &&
          errors.relationshipToBusiness && (
            <Text mt={1} textStyle="body-detail" color="red.500">
              {errors.relationshipToBusiness}
            </Text>
          )}
      </Box>
    </FormControl>
  )
}

RelationshipToBusiness.defaultProps = {
  formResourceContext: null,
}

RelationshipToBusiness.propTypes = {
  formResourceContext: PropTypes.string,
}

export default RelationshipToBusiness
