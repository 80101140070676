import React, {useState} from 'react'
import PropTypes from 'prop-types'
import useSWR, {useSWRConfig} from 'swr'
import i18next from 'i18next'
import {get} from 'lodash-es'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tab,
} from '@chakra-ui/react'
import {ChevronDownIcon} from '@chakra-ui/icons'
import SupplierLayout from '../../layouts/SupplierLayout'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'
import {fetcher} from '../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'

import StackedInvoiceTab from '../../components/StackedInvoiceTab'
import Header from './components/Header'
import CashinSummaryFooter from './components/CashinSummaryFooter'
import ReimbursementRequiredBanner from './components/ReimbursementRequiredBanner'
import OverdueInvoicesDetails from '../../components/OverdueInvoicesDetails'
import {useRails} from '../../contexts/rails'
import DineroPropShape from '../../../utils/propTypes/dinero'
import EligibleTab from './components/Tabs/EligibleTab'
import ConditionallyEligibleTab from './components/Tabs/ConditionallyEligibleTab'
import IneligibleTab from './components/Tabs/IneligibleTab'
import CashedInTab from './components/Tabs/CashedInTab'
import IncompleteCashinStepperWarning from '../../components/CashinStepper/IncompleteWarning'

const SmallScreenTabMenu = ({
  isLoading,
  tabs,
  currentTabObject,
  handleTabsChange,
}) => {
  return (
    <Menu gutter="0">
      <MenuButton
        as={Button}
        position="relative"
        bottom="-2px"
        height="fit-content"
        backgroundColor="grey.100"
        py="md"
        mb="sm"
        width="100%"
        color="primary.actionblue"
        _active={{
          backgroundColor: 'neutral.white',
          border: '2px solid',
          borderColor: 'primary.actionblue',
        }}
        rightIcon={<ChevronDownIcon />}
      >
        <StackedInvoiceTab
          isLoading={isLoading}
          {...currentTabObject.tabData}
          selected
        />
      </MenuButton>
      <MenuList rootProps={{width: '100%', px: 'lg'}}>
        {tabs.map(({tabName, tabData}, index) => (
          <MenuItem
            key={`${tabName}MenuItems`}
            display={tabName === currentTabObject.tabName ? 'none' : 'block'}
            minH="48px"
            color="inherit"
            py="sm"
            onClick={() => handleTabsChange(index)}
          >
            <StackedInvoiceTab isLoading={isLoading} {...tabData} selected />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const tabShape = PropTypes.shape({
  tabName: PropTypes.string.isRequired,
  tabData: PropTypes.shape({
    label: PropTypes.string.isRequired,
    count: PropTypes.number,
    amount: PropTypes.shape(DineroPropShape),
  }),
})

SmallScreenTabMenu.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tabs: PropTypes.arrayOf(tabShape).isRequired,
  currentTabObject: tabShape.isRequired,
  handleTabsChange: PropTypes.func.isRequired,
}

const NewSupplierCashInScreen = () => {
  i18next.loadNamespaces('cashinPage')

  const {organisation} = useRails()
  const {mutate} = useSWRConfig()
  const {data, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/unpaid_invoices_summary`,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const tabUrls = [
    'eligible',
    'conditionallyEligible',
    'ineligible',
    'cashedIn',
  ]
  const queryParams = new URLSearchParams(window.location.search)
  const tabParam = queryParams.get('tab')
  const currentTabIndex = tabUrls.findIndex((tab) => tab === tabParam)
  const [tabIndex, setTabIndex] = useState(
    currentTabIndex === -1 ? 0 : currentTabIndex,
  )

  const handleTabsChange = (index) => {
    setTabIndex(index)

    const url = new URL(window.location)
    url.searchParams.set('tab', tabUrls[index])
    window.history.pushState({}, '', url)
  }

  // Hacky way to force the tab components to call their local swr mutate functions
  // This is due to the global mutate not working correctly for useSWRInfinite
  // See here for issue details https://github.com/vercel/swr/issues/2497
  const [tabReloadRequired, setTabReloadRequired] = useState({
    eligible: false,
    conditionallyEligible: false,
    ineligible: false,
    cashedIn: false,
  })

  const [pendingInvoices, setPendingInvoices] = React.useState([])
  const [refreshPending, setRefreshPending] = React.useState(false)
  const dataUpdateOccurred = () => {
    setRefreshPending(false)

    if (pendingInvoices.length === 0) {
      mutate(
        `/api/suppliers/${organisation.currentSupplier.id}/unpaid_invoices_summary`,
      )
      mutate(
        `/api/suppliers/${organisation.currentSupplier.id}/invoice_unavailable_reasons_summary`,
      )
      mutate(
        `/api/suppliers/${organisation.currentSupplier.id}/daily_cashin_summary`,
      )

      setTabReloadRequired({
        ...tabReloadRequired,
        eligible: Date.now(),
        conditionallyEligible: Date.now(),
        ineligible: Date.now(),
        cashedIn: Date.now(),
      })
    }
  }
  const actionStarted = (invoiceId) => {
    setPendingInvoices([...pendingInvoices, invoiceId])
  }
  const actionFinished = (invoiceId) => {
    const updatedList = pendingInvoices.filter((id) => invoiceId !== id)
    setPendingInvoices(updatedList)
    setRefreshPending(true)

    if (pendingInvoices.length === 0) {
      // Wait 2 seconds before revalidating the tables
      setTimeout(() => {
        dataUpdateOccurred()
      }, 2000)
    }
  }
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (pendingInvoices.length === 0 && !refreshPending) {
        dataUpdateOccurred()
      }
    }, 300000) // Refreshes data every 5 minutes if no invoices have actions pending

    return () => {
      // Being good citizen.
      // Otherwise, it reruns again with unexpected behaviour.
      clearTimeout(timeoutId)
    }
  })

  const tabs = [
    {
      tabName: 'eligible',
      component: EligibleTab,
      tabData: {
        label: 'Available now',
        count: get(data, 'eligibleCount'),
        amount: get(data, 'eligibleAmount'),
      },
      reloadRequired: tabReloadRequired.eligible,
    },

    {
      tabName: 'conditionallyEligible',
      component: ConditionallyEligibleTab,
      tabData: {
        label: 'Conditionally available',
        count: get(data, 'conditionallyEligibleCount'),
        amount: get(data, 'conditionallyEligibleAmount'),
      },
      reloadRequired: tabReloadRequired.conditionallyEligible,
    },

    {
      tabName: 'ineligible',
      component: IneligibleTab,
      tabData: {
        label: 'Not available',
        count: get(data, 'ineligibleCount'),
        amount: get(data, 'ineligibleAmount'),
      },
      reloadRequired: tabReloadRequired.ineligible,
    },

    {
      tabName: 'cashedIn',
      component: CashedInTab,
      tabData: {
        label: 'Cashed-in',
        count: get(data, 'cashedInCount'),
        amount: get(data, 'cashedInAmount'),
      },
      reloadRequired: tabReloadRequired.cashedIn,
    },
  ]

  const mobileView = useBreakpointValue({
    base: true,
    md: false,
  })

  const currentTabObject = tabs[tabIndex] || tabs[0]

  return (
    <SupplierLayout state="Cash-in">
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box px={{base: '4', md: 'md'}}>
          <Header />

          <Box mx="md">
            <OverdueInvoicesDetails allowClose={false} />
          </Box>
          <ReimbursementRequiredBanner />
        </Box>

        <IncompleteCashinStepperWarning mx={{base: '4', md: 'lg'}} />

        <Tabs
          variant="curved"
          borderColor="mld.neutral.900"
          isLazy
          lazyBehavior="keepMounted"
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList px={{base: '4', md: 'lg'}}>
            {mobileView ? (
              <SmallScreenTabMenu
                key="smallScreenMenu"
                isLoading={isLoading}
                tabs={tabs}
                currentTabObject={currentTabObject}
                handleTabsChange={handleTabsChange}
              />
            ) : (
              tabs.map(({tabData}, index) => (
                <Tab key={tabData.label}>
                  <StackedInvoiceTab
                    isLoading={isLoading}
                    {...tabData}
                    selected={index === tabIndex}
                  />
                </Tab>
              ))
            )}
          </TabList>

          <TabPanels bg="white">
            {tabs.map(({tabName, component: TabComponent, reloadRequired}) => (
              <TabPanel key={tabName}>
                <TabComponent
                  reloadRequired={reloadRequired}
                  actionStarted={actionStarted}
                  actionFinished={actionFinished}
                  summaryData={data}
                  handleTabsChange={handleTabsChange}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <CashinSummaryFooter />
    </SupplierLayout>
  )
}

export default withProviders(withSWR(NewSupplierCashInScreen))
