import {useCookies} from 'react-cookie'

import {useRails} from '../contexts/rails'

const DEFAULT_EXPIRY = 10 * 365 * 24 * 60 * 60 // Ten years

export default function useFeatureCookie(name, {expiry = DEFAULT_EXPIRY} = {}) {
  const {uniqueUserFeatureCookiePrefix} = useRails()

  const cookieName = `${uniqueUserFeatureCookiePrefix}_${name}`
  const [cookies, setCookie, removeCookie] = useCookies([cookieName])

  const set = () => {
    setCookie(cookieName, new Date().getTime(), {
      path: '/',
      maxAge: expiry,
    })
  }

  const remove = () => removeCookie(cookieName)

  return [Boolean(cookies[cookieName]), set, remove]
}
