import PropTypes from 'prop-types'
import React from 'react'
import {Field, Form, Formik} from 'formik'
import {Box, Text, Button, Input} from '@chakra-ui/react'
import InputFlexWrap from './InputFlexWrap'

const RecipientForm = ({onSubmit, saveButtonText}) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={{email: ''}}>
      {({errors, touched, isSubmitting}) => (
        <Form>
          <InputFlexWrap>
            <Box width="100%" maxWidth="600">
              <Field
                as={Input}
                data-testid="create-recipient-email"
                errorMessage={touched.email && errors.email}
                name="email"
              />
            </Box>
            <Box>
              <Button
                variant="secondary"
                type="submit"
                data-testid="create-recipient-button"
                isLoading={isSubmitting}
              >
                {saveButtonText}
              </Button>
            </Box>
          </InputFlexWrap>
          <Box width="100%" maxWidth="600">
            {errors.email && (
              <Text mt={1} textStyle="body-error">
                {errors.email}
              </Text>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  )
}

RecipientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string.isRequired,
}

export default RecipientForm
