import PropTypes from 'prop-types'
import React from 'react'
import {Box, Flex, Link, Text} from '@chakra-ui/react'
import {formatAbn} from '../../../utils/formatter'

export const CustomerDetails = ({customer}) => {
  return (
    <Box
      flexGrow="1"
      whiteSpace="nowrap"
      mt="md"
      px="md"
      py="sm"
      borderRadius="lg"
      backgroundColor="neutral.smoke"
    >
      <Text fontSize="md" fontWeight="medium" color="neutral.80">
        Customer Details
      </Text>
      <Flex align="center" mt="xs">
        <Box width="200px">
          <Text color="neutral.80">First Name:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">{customer.firstName}</Text>
        </Box>
      </Flex>
      <Flex align="center" mt="xs">
        <Box width="200px">
          <Text color="neutral.80">Last Name:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">{customer.lastName}</Text>
        </Box>
      </Flex>
      <Flex align="center" mt="xs">
        <Box width="200px">
          <Text color="neutral.80">Email:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text
            fontWeight="medium"
            overflowWrap="anywhere"
            whiteSpace="break-spaces"
          >
            <Link href={`mailto:${customer.email}`} target="_blank">
              {customer.email}
            </Link>
          </Text>
        </Box>
      </Flex>
      <Flex align="center" mt="xs">
        <Box width="200px">
          <Text color="neutral.80">Phone:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">
            <Link href={`tel:${customer.phone}`} target="_blank">
              {customer.phone}
            </Link>
          </Text>
        </Box>
      </Flex>
      <Flex align="center" mt="xs">
        <Box width="200px">
          <Text color="neutral.80">ABN:</Text>
        </Box>
        <Box width="calc(100% - 200px)">
          <Text fontWeight="medium">{formatAbn(customer.abn)}</Text>
        </Box>
      </Flex>
      {customer.billingAddress && (
        <Flex align="center" mt="xs">
          <Box width="200px">
            <Text color="neutral.80">Billing address:</Text>
          </Box>
          <Box width="calc(100% - 200px)">
            <Text fontWeight="medium">{customer.billingAddress}</Text>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

CustomerDetails.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    abn: PropTypes.string,
    billingAddress: PropTypes.string,
  }).isRequired,
}

export default CustomerDetails
