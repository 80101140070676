import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import {Box, Flex, Text} from '@chakra-ui/react'
import {useRails} from '../../contexts/rails'

import bronzeBadge from './badges/bronze_badge.svg'
import silverBadge from './badges/silver_badge.svg'
import goldBadge from './badges/gold_badge.svg'
import platinumBadge from './badges/platinum_badge.svg'
import {TickedOutlineIcon} from '../../icons'

const levelBackground = (level) =>
  ({
    bronze: bronzeBadge,
    silver: silverBadge,
    gold: goldBadge,
    platinum: platinumBadge,
  })[level]

const RewardLevelBadge = ({level, current, disabled, scale, ...props}) => {
  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )

  const textColor =
    level.key.toLowerCase() === 'platinum'
      ? 'mld.neutral.900'
      : 'mld.neutral.50'

  return (
    <Box
      p="xs"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minWidth="148px"
      height="168px"
      transform={`scale(${scale})`}
      borderTopRightRadius="19px"
      sx={{
        'background-image': !disabled
          ? `url("${levelBackground(level.key)}")`
          : '',
      }}
      backgroundColor="mld.neutral.500"
      color={textColor}
      {...props}
    >
      <Flex width="100%" justify="space-between">
        <Text
          textStyle={fixedPricingEnabled ? 'headline4' : 'caption-medium'}
          textTransform="uppercase"
        >
          {level.key}
        </Text>
        {current && <TickedOutlineIcon />}
      </Flex>
      {!fixedPricingEnabled && (
        <Box>
          <Text textStyle="caption-medium" fontSize="10px">
            Status Fee Rate
          </Text>
          <Text mb="xxs" textStyle="headline2">
            {level.feeRate * 100}%
          </Text>
        </Box>
      )}
    </Box>
  )
}

RewardLevelBadge.defaultProps = {
  scale: 1,
  disabled: false,
}

RewardLevelBadge.propTypes = {
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    feeRate: PropTypes.string.isRequired,
  }).isRequired,
  scale: PropTypes.number,
  current: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export default RewardLevelBadge
