import React from 'react'
import PropTypes from 'prop-types'
import {Box} from '@chakra-ui/react'

const InvoiceStatusLabel = ({getValue}) => {
  const value = getValue()
  const statusColor = (status = '') => {
    switch (status.toLowerCase()) {
      case 'settled':
      case 'paid': {
        return 'secondary.green'
      }
      case 'overdue': {
        return 'primary.red'
      }
      case 'unpaid': {
        return 'secondary.blue'
      }
      case 'cashed-in': {
        return 'primary.navypurple'
      }
      case 'in dispute':
      default: {
        return 'neutral.black'
      }
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Box
        width="10px"
        height="10px"
        borderRadius="full"
        mr="xs"
        backgroundColor={statusColor(value)}
      />
      <Box>{value}</Box>
    </Box>
  )
}

InvoiceStatusLabel.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export default InvoiceStatusLabel
