import React from 'react'
import {Formik, Form} from 'formik'
import {useTranslation} from 'react-i18next'
import CurrencyInput from 'react-currency-input-field'

import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  Input,
  Text,
} from '@chakra-ui/react'
import {useRails} from '../../contexts/rails'
import useValidationSchema from './hooks/useValidationSchema'
import usePaymentMethods from './hooks/usePaymentMethods'
import PaymentMethodsSection from './PaymentMethodsSection'
import Field from './Field'
import {EmailIcon} from '../../icons'
import useFormSubmit from './hooks/useFormSubmit'

const BillPaymentForm = () => {
  const {t} = useTranslation('billPayments')
  const i18nPrefix = 'billPayments:billPayment.form'
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
  } = useRails()

  const {paymentMethods} = usePaymentMethods(supplierId)
  const {validationSchema} = useValidationSchema()
  const {errorMessage, submitted, onSubmit} = useFormSubmit(supplierId)

  return (
    <Card p="md" borderRadius="md" maxWidth="600px" variant="unstyled">
      <CardHeader display="flex" flexDirection="column" gap="sm" pb="md">
        <Text textStyle="body-small">{t(`${i18nPrefix}.description`)}</Text>
      </CardHeader>
      <Formik
        initialValues={{
          paymentMethodId:
            paymentMethods.length === 1
              ? paymentMethods[0].debitAgreement.id
              : '',
          payeeName: '',
          payeeEmail: '',
          businessNumber: '',
          amount: null,
          branchCode: '',
          accountNumber: '',
          reference: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({dirty, isValid, isSubmitting, setFieldValue, setFieldTouched}) => (
          <Flex as={Form} direction="column">
            <PaymentMethodsSection supplierId={supplierId} />
            <Text textStyle="body-intro-medium" py="sm">
              {t(`${i18nPrefix}.subsections.payeeDetails`)}
            </Text>
            <Field type="text" name="payeeName" />
            <Field type="email" name="payeeEmail" icon={<EmailIcon />} />
            <Field type="text" name="businessNumber" />

            <Text textStyle="body-intro-medium" py="sm">
              {t(`${i18nPrefix}.subsections.paymentDetails`)}
            </Text>
            <Flex justify="space-between" gap="md">
              <Field type="text" name="branchCode" />
              <Field type="text" name="accountNumber" />
            </Flex>
            <Field name="amount">
              <Input
                as={CurrencyInput}
                decimalsLimit={2}
                intlConfig={{locale: 'en-AU', currency: 'AUD'}}
                onValueChange={(_value, name, {float}) => {
                  setFieldValue('amount', (float * 100).toFixed())
                }}
                onBlur={() => {
                  setFieldTouched('amount', true)
                }}
              />
            </Field>
            <Field type="text" name="reference" />

            {errorMessage && (
              <Text color="red.500" mt="sm">
                {errorMessage}
              </Text>
            )}

            <Box pt="md">
              <Button
                variant="primary"
                colorScheme="primary"
                type="submit"
                isLoading={isSubmitting || submitted}
                loadingText={t(`${i18nPrefix}.submitting`)}
                isDisabled={isSubmitting || submitted || !isValid || !dirty}
              >
                {t(`${i18nPrefix}.submitButton`)}
              </Button>
            </Box>
          </Flex>
        )}
      </Formik>
    </Card>
  )
}

export default BillPaymentForm
