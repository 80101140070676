import React from 'react'
import {Box, Text, Flex, Link, Button} from '@chakra-ui/react'
import {WarningIcon} from '@chakra-ui/icons'

import {useRails} from '../../../contexts/rails'
import {requestSupplierAgreement} from '../../../../api'
import StepTitle from './StepTitle'

export const stepName = 'signAgreement'

const calculateStatus = ({
  submittedRequest,
  supplierAgreementRequested,
  supplierAgreementAccepted,
  requestFailed,
}) => {
  if (supplierAgreementAccepted) {
    return 'completed'
  }
  if (submittedRequest || supplierAgreementRequested) {
    return 'emailed'
  }
  if (requestFailed) {
    return 'declined'
  }

  return 'incomplete'
}

const ErrorNotification = () => (
  <Flex alignItems="start">
    <WarningIcon boxSize="13px" color="red.600" mt="xxs" mr="xxs" />
    <Box>
      <Text textStyle="body-small">
        There was an issue requesting your agreement.
      </Text>
      <Text textStyle="body-small">
        Please contact{' '}
        <Link
          href="mailto:support@withmarmalade.com"
          isExternal
          color="secondary.purple"
        >
          Customer Support
        </Link>
      </Text>
    </Box>
  </Flex>
)

export default (stepperData) => {
  const {authenticityToken} = useRails()
  const isCurrentStep = stepperData.currentStep === stepName

  const [submittedRequest, setSubmittedRequest] = React.useState(false)
  const [requestFailed, setRequestFailed] = React.useState(false)

  const onClick = async () => {
    try {
      setRequestFailed(false)
      await requestSupplierAgreement({
        supplierId: stepperData.supplierId,
        authenticityToken,
      })

      setSubmittedRequest(true)
    } catch {
      setRequestFailed(true)
    }
  }

  const notification = requestFailed ? <ErrorNotification /> : undefined

  return {
    name: stepName,
    visible: true,
    status: calculateStatus({...stepperData, submittedRequest, requestFailed}),
    icon: (
      <Text color="inherit" textStyle="body-copy">
        {stepperData.showingBankConnectionStep ? 3 : 2}
      </Text>
    ),
    title: (
      <StepTitle
        title="Sign agreement"
        tooltipParagraphs={[
          "This is the formal agreement you will need to sign to acknowledge our terms and conditions. It will be sent you via email once you click 'send'.",
          'You may have already received the agreement in your email inbox.',
        ]}
      />
    ),
    notification,
    action: (
      <Box>
        <Button
          variant="primary"
          isDisabled={!isCurrentStep}
          size="sm"
          onClick={onClick}
        >
          Request
        </Button>
      </Box>
    ),
  }
}
