import React from 'react'
import useSWR from 'swr'
import {Box, Flex, Image, ListItem, Text, UnorderedList} from '@chakra-ui/react'
import {camelize} from 'humps'
import {useTranslation} from 'react-i18next'
import {uniq} from 'lodash-es'
import arrowImage from '../../../../../../../assets/images/acquisition_decision_arrow.svg'
import {useRails} from '../../../../../contexts/rails'
import {fetcher} from '../../../../../../api'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'

const Pending = () => {
  const {organisation} = useRails()
  const {t} = useTranslation('onboarding')

  const {data = {}} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/acquisition_decision`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const pendingReasonKeys = (data.pendingReasonKeys || []).map(camelize)
  const pendingReasons = uniq(
    pendingReasonKeys.map((reasonKey) =>
      t(
        `onboarding:acquisitionDecisionPendingReasons.${reasonKey}`,
        t('onboarding:acquisitionDecisionPendingReasons.default'),
      ),
    ),
  )

  return (
    <Flex
      p={{base: 'sm', lg: 'lg'}}
      flexDirection="column"
      justify="space-between"
      height="100%"
    >
      <Flex flexDirection="column" gap="md">
        <Text textStyle="caption">
          {t('onboarding:AcquisitionDecisionPending.subHeading')}
        </Text>
        <Text textStyle="headline2" fontSize={{base: '2xl', lg: '48px'}}>
          {t('onboarding:AcquisitionDecisionPending.heading')}
        </Text>
      </Flex>

      <Flex flexDirection="column" gap="md">
        <Flex borderBottom="1px" px="0" py="md" align="center">
          <Image
            src={arrowImage}
            width="32px"
            height="32px"
            mr="sm"
            display={{base: 'none', sm: 'block'}}
          />
          <Box
            as="span"
            flex="1"
            textAlign="left"
            textStyle="headline4"
            fontSize={{base: 'md', md: 'lg'}}
            textTransform="uppercase"
          >
            {t('onboarding:AcquisitionDecisionPending.accordionButtonLabel')}
          </Box>
        </Flex>
        <Box mx={{base: '0', lg: 'lg'}}>
          <UnorderedList pb="sm">
            {pendingReasons.map((reason) => (
              <ListItem>
                <Text>{reason}</Text>
              </ListItem>
            ))}
          </UnorderedList>

          <Text textStyle="headline5" textTransform="uppercase">
            {t('onboarding:AcquisitionDecisionPending.accordionPanelFooter')}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Pending
