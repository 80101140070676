import React from 'react'
import PropTypes from 'prop-types'
import {useDisclosure} from '@chakra-ui/react'
import SupplierRewardsModal from '../components/SupplierRewardsModal'

const SupplierRewardModalContext = React.createContext()

function useSupplierRewardModal() {
  const context = React.useContext(SupplierRewardModalContext)

  if (!context) {
    throw new Error(
      'useSupplierRewardModal must be used within a SupplierRewardsModalProvider',
    )
  }

  return context
}

function SupplierRewardsModalProvider({children}) {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const value = React.useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
    }),
    [isOpen, onClose, onOpen],
  )

  return (
    <SupplierRewardModalContext.Provider value={value}>
      {children}
      <SupplierRewardsModal {...value} />
    </SupplierRewardModalContext.Provider>
  )
}

SupplierRewardsModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export {SupplierRewardsModalProvider, useSupplierRewardModal}
