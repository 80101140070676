import {fetcher, defaultHeaders} from './fetcher'

// eslint-disable-next-line import/prefer-default-export
export const cancelInvoiceCashIn = ({invoiceId, authenticityToken}) => {
  const opts = {
    method: 'POST',
    headers: defaultHeaders({authenticityToken}),
    body: JSON.stringify({authenticityToken}),
  }
  return fetcher(`/api/invoices/${invoiceId}/cancel_cash_in`, opts)
}
