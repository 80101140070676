import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Circle} from '@chakra-ui/react'

import {useTranslation} from 'react-i18next'
import {
  toLocalDate,
  toLocalTime,
  toEpochMillisecond,
} from '../../../utils/formatter'

import {CheckIcon, CrossIcon, ExclamationIcon} from '../../icons'

const ExclamationIconTypes = [
  'became_overdue',
  'customer_payment_reversed',
  'paid_direct',
  'invoice_issue_created',
]

const CrossIconTypes = ['cancelled_cash_in']

const TimelineIcon = ({type}) => {
  if (ExclamationIconTypes.includes(type)) {
    return <ExclamationIcon boxSize={6} />
  }

  if (CrossIconTypes.includes(type)) {
    return <CrossIcon boxSize={6} />
  }

  return <CheckIcon boxSize={8} />
}

TimelineIcon.propTypes = {
  type: PropTypes.string.isRequired,
}

const CircleWithTimelineIcon = ({type, inverse}) => {
  if (inverse) {
    return (
      <Circle
        size="40px"
        border="1px"
        borderColor="black"
        bg="black"
        color="white"
      >
        <TimelineIcon type={type} />
      </Circle>
    )
  }

  return (
    <Circle size="40px" border="1px" borderColor="black" color="black">
      <TimelineIcon type={type} />
    </Circle>
  )
}

CircleWithTimelineIcon.propTypes = {
  type: PropTypes.string.isRequired,
  inverse: PropTypes.bool.isRequired,
}

const InvoiceTimeline = ({timeline}) => {
  const {t} = useTranslation()

  return (
    <Box bg="mld.neutral.100" px="sm" py="xs" mt="xxs" borderRadius="md">
      <Text textStyle="body-intro" textTransform="uppercase">
        {t('invoicesPage:invoiceTimeline.heading')}
      </Text>
      {timeline.map((event, index) => (
        <>
          <Box display="flex" width="100%" py="xs" key={`line-${index + 1}`}>
            {index > 0 && (
              <Box
                borderRight="2px solid"
                borderColor="mld.neutral.500"
                width="30.15%"
                height="50px"
              />
            )}
          </Box>
          <Box display="flex" pb={index + 1 === timeline.length ? 'md' : null}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="20%"
            >
              <Text color="neutral.60">
                {toLocalDate(toEpochMillisecond(event.date))}
                {'\n'}
                {event.date_type === 'datetime'
                  ? toLocalTime(toEpochMillisecond(event.date))
                  : ''}
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="20%"
            >
              <CircleWithTimelineIcon type={event.type} inverse={index === 0} />
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              width="60%"
            >
              <Text>{event.label}</Text>
            </Box>
          </Box>
        </>
      ))}
    </Box>
  )
}

InvoiceTimeline.defaultProps = {
  timeline: [],
}

InvoiceTimeline.propTypes = {
  timeline: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      date: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
}

export default InvoiceTimeline
