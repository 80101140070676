import React from 'react'
import PropTypes from 'prop-types'
import {Box, Icon, Text} from '@chakra-ui/react'

const GettingStartedItem = ({active, onClick, icon, headerText, bodyText}) => {
  return (
    <Box
      display="flex"
      p="xs"
      m="xs"
      borderRadius="lg"
      boxShadow={active ? 'md' : 'none'}
      _hover={{
        boxShadow: 'md',
      }}
      cursor="pointer"
      onClick={onClick}
    >
      <Icon
        as={icon}
        boxSize="24px"
        mr="sm"
        color={active ? 'primary.700' : 'black'}
      />
      <Box>
        <Text textStyle="body-small" fontWeight="medium">
          {headerText}
        </Text>
        {active && (
          <Text textStyle="body-small" color="neutral.700">
            {bodyText}
          </Text>
        )}
      </Box>
    </Box>
  )
}

GettingStartedItem.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape({
    render: PropTypes.func,
  }).isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
}

export default GettingStartedItem
