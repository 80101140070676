import React from 'react'

import PropTypes from 'prop-types'
import {Image, Box, Text, SimpleGrid} from '@chakra-ui/react'
import {Screen, ThemeProvider, LogoHorizontal} from '../../components'
import ConfirmationEmailIllustration from '../../../../assets/images/smiling_email.svg'

const SignUpConfirmationScreen = ({email, resendEmailPath}) => {
  return (
    <ThemeProvider>
      <Screen>
        <Box w="100%">
          <LogoHorizontal width={250} height={100} />
        </Box>
        <SimpleGrid templateColumns="repeat(2, 1fr)" gap={8} mb={8}>
          <Box>
            <Box>
              <Text fontSize="44px" fontFamily="heading" fontWeight="600">
                Confirm email
              </Text>
              <Text>
                Almost there. You should have just received an email at{' '}
                <strong>{email}</strong>
                <br />
                To confirm your account, simply open the email and follow the
                prompts.
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={4}
            >
              <Text
                as="a"
                href={resendEmailPath}
                color="secondary.blue"
                fontSize="sm"
              >
                Didn&apos;t receive this email? Resend
              </Text>
            </Box>
          </Box>

          <Box w="100%" textAlign="center">
            <Image
              w="70%"
              src={ConfirmationEmailIllustration}
              alt="confirmation email illustration"
              margin="0 auto"
            />
          </Box>
        </SimpleGrid>
      </Screen>
    </ThemeProvider>
  )
}

SignUpConfirmationScreen.propTypes = {
  email: PropTypes.string.isRequired,
  resendEmailPath: PropTypes.string.isRequired,
}

export default SignUpConfirmationScreen
