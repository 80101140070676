/* eslint-disable import/prefer-default-export */
import {fetcher, defaultHeaders} from './fetcher'

export const createCustomerAccount = ({email, password, authenticityToken}) => {
  const opts = {
    method: 'POST',
    headers: defaultHeaders({authenticityToken}),
    body: JSON.stringify({
      customer_login: {
        email,
        password,
      },
    }),
  }

  return fetcher(`/api/customers/link_account`, opts)
}

export const viewedUpdatedBankDetails = ({customerId}) => {
  const opts = {
    method: 'POST',
  }
  return fetcher(
    `/api/customers/${customerId}/viewed_updated_bank_details`,
    opts,
  )
}
