import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Flex} from '@chakra-ui/react'
import {InfoIcon} from '@chakra-ui/icons'
import {IconTooltip} from '../..'

const StepTitle = ({title, onTooltipOpen, tooltipParagraphs}) => {
  return (
    <Flex align="center">
      <Text fontWeight="medium" pr="xxs">
        {title}
      </Text>
      <IconTooltip onOpen={onTooltipOpen} icon={<InfoIcon />}>
        {tooltipParagraphs.map((paragraph, index) => (
          <Box key={`step-paragraph-${index + 1}`}>
            <Text textStyle="body-small">{paragraph}</Text>
            {index < tooltipParagraphs.length - 1 && <br />}
          </Box>
        ))}
      </IconTooltip>
    </Flex>
  )
}

StepTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onTooltipOpen: PropTypes.func,
  tooltipParagraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
}

StepTitle.defaultProps = {
  onTooltipOpen: undefined,
}

export default StepTitle
