export default {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 'normal',
  },
  variants: {
    invoiceStatus: {
      fontSize: 'sm',
      lineHeight: '17.5px',
      px: '4px',
      py: '2px',
      minWidth: '24px',
      textAlign: 'center',
      borderRadius: '4px',
      background: 'mld.neutral.200',
    },
    nestedStatus: (props) => {
      const {colorScheme: c} = props

      const colorScheme = [
        'alert',
        'warning',
        'success',
        'info',
        'neutral',
      ].includes(c)
        ? c
        : 'black'

      const colorProps = {
        alert: {
          color: 'mld.alert.700',
        },
        warning: {
          color: 'mld.warning.800',
        },
        success: {
          color: 'mld.success.800',
        },
        info: {
          color: 'mld.neutral.900',
        },
        neutral: {
          color: 'mld.neutral.900',
        },
        black: {
          color: 'mld.neutral.900',
        },
      }[colorScheme]

      return {
        bg: 'white',
        color: colorProps.color,
        fontSize: 'xs',
        lineHeight: '17.5px',
        fontWeight: 'normal',
        px: '2px',
        py: '0px',
        borderRadius: '2px',
        background: 'white',
        ml: 'xxs',
        mr: '0',
        textTransform: 'none',
      }
    },
  },
}
