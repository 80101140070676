import React from 'react'
import {Checkbox as ChakraCheckbox} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import {CheckIcon} from '../../icons'

function CustomIcon({isChecked, size}) {
  const iconSizeMapping = {
    sm: '12px',
    md: '16px',
    lg: '20px',
  }

  const iconSize = iconSizeMapping[size] || iconSizeMapping.md

  return isChecked ? (
    <CheckIcon boxSize={iconSize} data-testid="custom-icon" />
  ) : null
}

CustomIcon.propTypes = {
  isChecked: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

CustomIcon.defaultProps = {
  size: 'md',
  isChecked: false,
}

const Checkbox = ({children, ...props}) => {
  const size = props.size || 'md'
  return (
    <ChakraCheckbox icon={<CustomIcon size={size} />} size={size} {...props}>
      {children}
    </ChakraCheckbox>
  )
}

Checkbox.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

Checkbox.defaultProps = {
  size: 'md',
  children: null,
}

export default Checkbox
