import {Box, Text, useDisclosure, Grid, Stack} from '@chakra-ui/react'
import {get} from 'lodash-es'

import React from 'react'
import useSWR from 'swr'

import {BackButton, EmptyList, DataTable} from '../../components'
import {toEpochMillisecond, toLocalDate} from '../../../utils/formatter'
import DirectPaymentsInstructions from './DirectPaymentsInstructions'
import DirectPaymentsInvoicesDraw from './DirectPaymentsInvoicesDrawer'
import SupplierLayout from '../../layouts/SupplierLayout'
import withProviders from '../../contexts/withProviders'
import withSWR from '../../contexts/withSWR'

const columns = [
  {
    header: 'Customer',
    accessorKey: 'name',
  },
  {
    header: 'Direct payments made',
    accessorKey: 'paid_direct_count',
  },
  {
    header: 'Last reported',
    accessorKey: 'last_reported',
    cell: ({getValue}) => toLocalDate(toEpochMillisecond(getValue())),
  },
]

export const SupplierDirectPaymentsScreen = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [selectedCustomer, setSelectedCustomer] = React.useState(null)

  const showInvoices = ({id, name}) => {
    setSelectedCustomer({id, name})
    onOpen()
  }

  const {data, error} = useSWR('/api/direct_payment_customers')
  const loading = !data && !error

  const customers = get(data, 'customers')
  const emptyList = get(customers, 'length') === 0

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  return (
    <SupplierLayout state="Dashboard">
      {selectedCustomer && (
        <DirectPaymentsInvoicesDraw
          {...selectedCustomer}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      <Grid templateColumns={{lg: '1fr 480px'}} minH="100vh">
        <Box overflowX="auto" display="flex" flexDir="column" h={{lg: '100vh'}}>
          <Stack isInline align="center" p="sm">
            <BackButton as="a" href="/dashboard" />

            <Text fontWeight="medium" fontSize="18px">
              Direct payments
            </Text>
          </Stack>

          <Box flexGrow="1" overflow="scroll" whiteSpace="nowrap">
            <DataTable
              onClickRow={showInvoices}
              columns={columns}
              data={customers}
              isLoading={loading}
              enableSorting
            />

            {emptyList && <EmptyList resourceName="direct payments" />}

            {error && (
              <Box px="md" py="xs">
                Unable to fetch direct payments, please try again later.
              </Box>
            )}
          </Box>
        </Box>

        <Box
          padding="md"
          pt="lg"
          bg="neutral.dust"
          borderColor="neutral.glitter"
          borderLeftWidth={{lg: 1}}
          borderTopWidth={{base: 1, lg: 0}}
          h={{lg: '100vh'}}
          overflowY="scroll"
        >
          <DirectPaymentsInstructions />
        </Box>
      </Grid>
    </SupplierLayout>
  )
}

export default withProviders(withSWR(SupplierDirectPaymentsScreen))
