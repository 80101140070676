import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'

import Input from '../Input'

const DatePicker = ({
  selectedDate,
  minDate,
  maxDate,
  placeholder,
  onChange,
  ...props
}) => (
  <ReactDatePicker
    selected={selectedDate && new Date(selectedDate)}
    customInput={<Input width="100%" />}
    onChange={onChange}
    dateFormat="dd/MM/yyyy"
    placeholderText={placeholder}
    dayClassName={(date) => {
      return date.getTime() === selectedDate ? 'selected-date' : ''
    }}
    minDate={minDate && new Date(minDate)}
    maxDate={maxDate && new Date(maxDate)}
    {...props}
  />
)

DatePicker.defaultProps = {
  selectedDate: undefined,
  minDate: undefined,
  maxDate: undefined,
  placeholder: 'Select date',
  onChange: () => {},
}

DatePicker.propTypes = {
  selectedDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default DatePicker
