import React from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react'

const Dialog = ({
  isOpen,
  onConfirm,
  confirmLabel,
  onClose,
  closeLabel,
  title,
  children,
  isDisabled,
  ...props
}) => (
  <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap {...props}>
    <ModalOverlay zIndex="modal" />
    <ModalContent borderRadius="lg" py="sm">
      <ModalHeader fontSize="22px">{title}</ModalHeader>
      <ModalBody fontSize="sm">{children}</ModalBody>

      <ModalFooter justifyContent="flex-start">
        <Stack isInline>
          {onConfirm && (
            <Button
              variant="primary"
              onClick={onConfirm}
              isDisabled={isDisabled}
            >
              {confirmLabel}
            </Button>
          )}

          {onClose && (
            <Button variant="secondary" onClick={onClose}>
              {closeLabel}
            </Button>
          )}
        </Stack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  onClose: PropTypes.func,
  closeLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
}

Dialog.defaultProps = {
  isDisabled: false,
  onConfirm: null,
  confirmLabel: 'Confirm',
  onClose: null,
  closeLabel: 'Cancel',
}

export default Dialog
