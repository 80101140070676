import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {useFormik} from 'formik'
import {Box, Text, Button} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {ArrowRightIcon} from '../../../../icons'
import {useRails} from '../../../../contexts/rails'
import {Alert, DropdownSelect} from '../../../../components'
import {fetcher, setExpenseAccount} from '../../../../../api'

const ExpenseAccountSelectionForm = ({
  setExpenseAccountSaved,
  complianceCompleted,
}) => {
  const {authenticityToken, organisation} = useRails()
  const {t} = useTranslation('onboarding')
  const [expenseAccountError, setExpenseAccountError] = React.useState('')

  const {data, error} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/expense_accounts`,
    fetcher,
  )

  const mappedData =
    data && data.expense_accounts && data.expense_accounts.length
      ? data.expense_accounts.map((account) => {
          return {
            label: account.Code
              ? `${account.Name} (${account.Code})`
              : account.Name,
            value: account.AccountID,
          }
        })
      : []

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  const formik = useFormik({
    initialValues: {
      account: {
        label: '',
        value: '',
      },
    },
    onSubmit: async (values) => {
      const {account} = values

      try {
        await setExpenseAccount({
          accountId: account.value,
          authenticityToken,
          supplierId: organisation.currentSupplier.id,
        })
        if (complianceCompleted) {
          setExpenseAccountSaved(true)
        } else {
          window.location.href = '/dashboard'
        }
      } catch (e) {
        setExpenseAccountError(e.message)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {(error || expenseAccountError) && (
        <Alert
          status="error"
          message={
            <Box display="flex" flexDirection="column">
              {expenseAccountError && (
                <Text textStyle="body-small" color="red.500">
                  {expenseAccountError}
                </Text>
              )}
              {error &&
                error.message !== 'Unauthorized' &&
                error.name !== 'APINetworkError' && (
                  <Text textStyle="body-small" color="red.500">
                    Unable to retrieve expense accounts
                  </Text>
                )}
            </Box>
          }
          mb={8}
        />
      )}

      {!data && <Box mb={6}>Loading...</Box>}

      {mappedData.length > 0 && (
        <Box mb={8}>
          <DropdownSelect
            options={mappedData}
            name="expenseAccount"
            placeholder="Select expense account"
            variant="mld"
            onChange={(value) => formik.setFieldValue('account', value)}
          />
        </Box>
      )}
      <Button
        variant="primary"
        colorScheme="primary"
        isDisabled={formik.values.account && formik.values.account.value === ''}
        isLoading={formik.isSubmitting}
        data-testid="continue-button"
        size="lg"
        type="submit"
      >
        {t('onboarding:ExpenseAccountSelection.submitButton')}
        <ArrowRightIcon width="24px" height="24px" ml="xs" />
      </Button>
    </form>
  )
}

ExpenseAccountSelectionForm.propTypes = {
  complianceCompleted: PropTypes.bool,
  setExpenseAccountSaved: PropTypes.func,
}

ExpenseAccountSelectionForm.defaultProps = {
  complianceCompleted: false,
  setExpenseAccountSaved: () => {},
}

const ExpenseAccountSelection = () => {
  const {accountingProvider, compliance} = useRails()
  const [expenseAccountSaved, setExpenseAccountSaved] = React.useState(false)
  const {t} = useTranslation('onboarding')

  if (expenseAccountSaved) {
    return (
      <>
        <Text
          color="mld.neutral.900"
          fontSize="48px"
          letterSpacing="-0.5"
          lineHeight="1"
          fontWeight="400"
          mb="md"
        >
          {t('onboarding:ExpenseAccountSelection.completed.heading', {
            accountingProvider: accountingProvider.name,
          })}
        </Text>

        <Text textStyle="body-intro" mb="lg">
          {t('onboarding:ExpenseAccountSelection.completed.body', {
            accountingProvider: accountingProvider.name,
          })}
        </Text>

        <Button
          variant="primary"
          colorScheme="primary"
          onClick={() => {
            window.location.href = '/dashboard'
          }}
          size="lg"
          type="submit"
        >
          {t('onboarding:ExpenseAccountSelection.completed.submitButton')}
          <ArrowRightIcon width="24px" height="24px" ml="xs" />
        </Button>
      </>
    )
  }
  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t('onboarding:ExpenseAccountSelection.heading', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <Text mb="sm" textStyle="body-intro">
        {t('onboarding:ExpenseAccountSelection.body', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <ExpenseAccountSelectionForm
        complianceCompleted={compliance.isQuestionnaireAnswered}
        setExpenseAccountSaved={setExpenseAccountSaved}
      />
    </>
  )
}

export default ExpenseAccountSelection
