import React from 'react'

import PropTypes from 'prop-types'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Box,
  Text,
  Button,
} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {Alert, ThemeProvider, Input, LogoHorizontal} from '../../components'

const ResetPasswordForm = ({
  authenticityToken,
  errorMessage,
  resource,
  resourceError,
  resourceName,
  resetPasswordPath,
}) => {
  const {values, dirty, touched, errors, handleChange, handleBlur} = useFormik({
    initialValues: {
      [resourceName]: {
        email: (resource && resource.email) || '',
        password: '',
      },
    },
    validationSchema: Yup.object().shape({
      [resourceName]: Yup.object().shape({
        email: Yup.string()
          .email('Please enter a valid email address')
          .required('Required'),
      }),
    }),
  })

  return (
    <form method="POST" acceptCharset="UTF-8" action={resetPasswordPath}>
      <Text textStyle="body-copy" mb="sm">
        Forgot your password?
      </Text>

      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input
        type="hidden"
        name="commit"
        value="Send me reset password instructions"
      />

      {(errorMessage || !!Object.keys(resourceError).length) && (
        <Alert
          status="error"
          mb={8}
          message={
            <Box display="flex" flexDirection="column">
              {errorMessage && <Text>{errorMessage}</Text>}
              {Object.keys(resourceError).map((key) => (
                <Text key={key}>
                  {`${(key.slice(0, 1).toUpperCase() + key.slice(1)).replace(
                    /_/g,
                    ' ',
                  )} ${resourceError[key].toLowerCase()}`}
                </Text>
              ))}
            </Box>
          }
        />
      )}

      <FormControl mb={4}>
        <FormLabel htmlFor="email" hidden>
          Email address
        </FormLabel>
        <Input
          type="email"
          name={`${resourceName}[email]`}
          id={`${resourceName}_email`}
          placeholder="Email"
          aria-describedby="email-helper-text"
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={
            (touched[resourceName] &&
              touched[resourceName].email &&
              errors[resourceName] &&
              errors[resourceName].email) ||
            ''
          }
          value={values[resourceName].email}
        />
        <FormHelperText hidden id="email-helper-text">
          We&apos;ll never share your email.
        </FormHelperText>
      </FormControl>

      <Button
        variant="secondary"
        type="submit"
        w="100%"
        isDisabled={!dirty || Object.keys(errors).length}
      >
        Send me reset password instructions
      </Button>
    </form>
  )
}

ResetPasswordForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  resource: PropTypes.shape({
    email: PropTypes.string,
  }),
  resourceError: PropTypes.shape({
    email: PropTypes.string,
  }),
  resetPasswordPath: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
}

ResetPasswordForm.defaultProps = {
  errorMessage: '',
  resource: {
    email: '',
  },
  resourceError: {
    email: '',
  },
}

const ResetPasswordScreen = ({
  authenticityToken,
  errorMessage,
  resource,
  resourceError,
  resourceName,
  resetPasswordPath,
  signInPath,
  unlockAccountInstructionPath,
}) => {
  return (
    <ThemeProvider>
      <Box minH="100vh">
        <Box
          maxWidth="500px"
          position="absolute"
          top="40%"
          left="0"
          right="0"
          transform="translateY(-40%)"
          px="sm"
          margin="0 auto"
        >
          <LogoHorizontal w="323px" h="38px" mb="md" />
          <ResetPasswordForm
            authenticityToken={authenticityToken}
            errorMessage={errorMessage}
            resource={resource}
            resourceError={resourceError}
            resetPasswordPath={resetPasswordPath}
            resourceName={resourceName}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={4}
          >
            <Text as="a" href={signInPath} color="secondary.blue">
              Sign in
            </Text>
            <Text
              as="a"
              href={unlockAccountInstructionPath}
              color="secondary.blue"
              textAlign="right"
              ml={4}
            >
              Didn&apos;t receive unlock instructions?
            </Text>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

ResetPasswordScreen.propTypes = {
  resource: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  resourceError: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  resourceName: PropTypes.string.isRequired,
  resetPasswordPath: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  unlockAccountInstructionPath: PropTypes.string.isRequired,
}

export default ResetPasswordScreen
