import React from 'react'
import PropTypes from 'prop-types'
import {useClipboard, Tooltip, IconButton} from '@chakra-ui/react'
import {CheckIcon, CopyIcon} from '@chakra-ui/icons'

const CopyButton = ({content, label, afterCopy, ...props}) => {
  const {onCopy, hasCopied} = useClipboard(content)

  const onClick = () => {
    onCopy()
    afterCopy()
  }

  const tooltipLabel = hasCopied ? 'Copied to clipboard' : label

  return (
    <Tooltip label={tooltipLabel} zIndex="tooltip" placement="right">
      <span>
        <IconButton
          variant="tertiary"
          aria-label={label}
          onClick={onClick}
          {...props}
        >
          {hasCopied ? <CheckIcon /> : <CopyIcon fontSize="lg" />}
        </IconButton>
      </span>
    </Tooltip>
  )
}

CopyButton.defaultProps = {
  afterCopy: () => {},
}

CopyButton.propTypes = {
  content: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  afterCopy: PropTypes.func,
}

export default CopyButton
