import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {Box} from '@chakra-ui/react'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

// This component can be used to wrap a component and react to when it intersects with the viewport
// Accepts a onIntersect function which will be caused when the state changes with a boolean argument
// which is true if the node is visible/intersecting with the viewport or false if it is not.
const OnIntersectWrapper = ({children, onIntersect}) => {
  const ref = useRef(null)
  const visibleRef = useRef(null)
  const intersectionObserver = useIntersectionObserver(ref, {})
  const scrollIsVisible = !!intersectionObserver?.isIntersecting

  React.useEffect(() => {
    if (visibleRef.current !== scrollIsVisible) {
      // If our local state is different save the state and call callback
      visibleRef.current = scrollIsVisible
      onIntersect(scrollIsVisible)
    }
  }, [onIntersect, scrollIsVisible])

  return <Box ref={ref}>{children}</Box>
}

OnIntersectWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onIntersect: PropTypes.func.isRequired,
}

export default OnIntersectWrapper
