import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {useTranslation} from 'react-i18next'
import {Box, Flex, Grid, GridItem, Spinner, Text} from '@chakra-ui/react'
import {fetcher} from '../../../../../../api'
import {useRails} from '../../../../../contexts/rails'

import backgroundImage from '../../../../../../../assets/images/onboarding_pillars_background.svg'
import Processing from './Processing'
import Supported from './Supported'
import Unsupported from './Unsupported'
import Pending from './Pending'
import {
  DropdownSelect,
  LogoHorizontalSecondary,
} from '../../../../../components'
import {colors} from '../../../../../theme/foundations/colors'

const FooterContent = ({supplierSetupTrackerState}) => {
  const {t} = useTranslation('onboarding')

  if (supplierSetupTrackerState !== 'decision_processing_progress') {
    return null
  }

  return (
    <Text textStyle="body-intro" textAlign="center" color="mld.neutral.500">
      {t('onboarding:AcquisitionDecisionProcessing.footer')}
    </Text>
  )
}

FooterContent.propTypes = {
  supplierSetupTrackerState: PropTypes.string.isRequired,
}

const AcquisitionDecision = ({
  dropdownSelectProps,
  supplierSetupTrackerState,
}) => {
  const {organisation} = useRails()
  const {data = {}, isLoading} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/acquisition_decision`,
    fetcher,
  )
  const [currentState, setCurrentState] = React.useState()

  React.useEffect(() => {
    if (
      ['decision_processing_progress', 'decision_pending'].includes(
        supplierSetupTrackerState,
      ) &&
      currentState &&
      currentState !== data.current_state
    ) {
      window.location.href = '/dashboard'
    }
    setCurrentState(data.current_state)
  }, [data.current_state, currentState, supplierSetupTrackerState])

  const showDropdownSelect = dropdownSelectProps.options.length > 1

  const mainContent = {
    decision_processing_progress: <Processing />,
    decision_pending: <Pending />,
    decision_supported: <Supported />,
    decision_unsupported: <Unsupported />,
  }[supplierSetupTrackerState] || <Processing />

  return (
    <Grid
      width="100vw"
      height="100vh"
      minHeight="100vh"
      px={{base: 'sm', sm: 'md', lg: 'lg', xl: 'xxl'}}
      backgroundColor="mld.neutral.900"
      backgroundImage={backgroundImage}
      backgroundSize="cover"
      backgroundPosition="center"
      flexDirection="column"
      overflow="hidden"
      templateRows="1fr minmax(100px, 7fr) 1fr"
    >
      <GridItem alignContent="center">
        <Flex
          direction={{base: 'column', lg: 'row'}}
          justify={{
            base: 'center',
            lg: showDropdownSelect ? 'space-between' : 'center',
          }}
          align="center"
        >
          {showDropdownSelect && (
            // Hidden component to mirror the actual dropdown and center the logo
            <Box maxWidth="340px" visibility="hidden">
              <DropdownSelect
                name={dropdownSelectProps.name}
                placeholder={dropdownSelectProps.placeholder}
                options={dropdownSelectProps.options}
                value={dropdownSelectProps.value}
                isClearable={false}
                isSearchable={false}
                variant="acquisitionDecision"
                onChange={dropdownSelectProps.onChange}
              />
            </Box>
          )}
          <LogoHorizontalSecondary width="50%" maxWidth="247px" height="28px" />
          {showDropdownSelect && (
            <Box maxWidth="340px">
              <DropdownSelect
                name={dropdownSelectProps.name}
                placeholder={dropdownSelectProps.placeholder}
                options={dropdownSelectProps.options}
                value={dropdownSelectProps.value}
                isClearable={false}
                isSearchable={false}
                variant="acquisitionDecision"
                onChange={dropdownSelectProps.onChange}
              />
            </Box>
          )}
        </Flex>
      </GridItem>
      <GridItem
        borderRadius="lg"
        background={`${colors.mld.neutral[900]}E6`}
        color="mld.neutral.50"
      >
        <Box height="100%" overflow="auto" p="sm">
          {isLoading ? (
            <Flex height="100%" justify="center" align="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            mainContent
          )}
        </Box>
      </GridItem>
      <GridItem alignContent="center">
        {!isLoading && (
          <FooterContent
            supplierSetupTrackerState={
              supplierSetupTrackerState || 'decision_processing_progress'
            }
          />
        )}
      </GridItem>
    </Grid>
  )
}

const dropdownOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

AcquisitionDecision.defaultProps = {
  supplierSetupTrackerState: '',
}

AcquisitionDecision.propTypes = {
  dropdownSelectProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(dropdownOptionShape).isRequired,
    value: dropdownOptionShape.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  supplierSetupTrackerState: PropTypes.string,
}

export default AcquisitionDecision
