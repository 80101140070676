import {camelizeKeys} from 'humps'
import useSWR from 'swr'

import {fetcher} from '../../api'

export default function useFeatureFlags() {
  const {data, error} = useSWR('/api/suppliers/features', fetcher)

  return {
    data: camelizeKeys(data || {}),
    loading: !data && !error,
    error,
  }
}
