import React from 'react'
import PropTypes from 'prop-types'
import BillPaymentStatus from '../BillPaymentStatus'

const StatusCell = ({getValue}) => {
  const status = getValue()

  return <BillPaymentStatus status={status} />
}

StatusCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export default StatusCell
