import React from 'react'
import PropTypes from 'prop-types'
import {Text} from '@chakra-ui/react'

const NoWrapCell = ({getValue}) => {
  const value = getValue()

  return <Text whiteSpace="nowrap">{value}</Text>
}

NoWrapCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export default NoWrapCell
