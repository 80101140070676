/* eslint-disable testing-library/no-node-access */
import React from 'react'
import PropTypes from 'prop-types'
import {Skeleton} from '@chakra-ui/react'

import {toCurrency} from '../../../utils/formatter'
import {
  verifyPayment,
  retrieveInvoicePaymentInfo,
  retrieveSavedCreditCardsInfo,
  confirmDirectDebitPayment,
} from '../../../api'
import PaymentCore from './PaymentCore'

const Payment = ({
  onSuccessDD,
  onErrorDD,
  onSuccessCC,
  onErrorCC,
  xeroInvoiceUUID,
  authenticityToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [paymentInformation, setPaymentInformation] = React.useState({})

  const [directDebitErrMessage, setDirectDebitErrMessage] = React.useState('')
  const [displaySuccessPayment, setDisplaySuccessPayment] =
    React.useState(false)
  const [paymentVerificationErrMessage, setPaymentVerificationErrMessage] =
    React.useState('')
  const [paymentMethodInfo, setPaymentMethodInfo] = React.useState(null)
  const [retrievePaymentTokenErrMessage, setRetrievePaymentTokenErrMessage] =
    React.useState('')

  React.useEffect(() => {
    if (xeroInvoiceUUID) {
      retrieveInvoicePaymentInfo({xeroInvoiceUUID}).then((data) => {
        setPaymentInformation(data)
        setIsLoading(false)
      })
    }
  }, [xeroInvoiceUUID])

  const {
    invoice,
    payerCreditCardSurcharged,
    creditCardSurchargePercentage,
    creditCardSurchargeFixedAmount,
    isCustomerLoggedIn,
    availablePaymentMethods,
    savedPaymentDetails,
    bankAccount,
    status,
    pendingDirectDebit,
  } = paymentInformation

  const paymentDetails = {
    directDebit: savedPaymentDetails &&
      savedPaymentDetails.savedDirectDebitDetails && {
        bsb: savedPaymentDetails.savedDirectDebitDetails.bsb || '',
        accountNumber:
          savedPaymentDetails.savedDirectDebitDetails.accountNumber || '',
      },
    creditCard: paymentMethodInfo,
  }

  React.useEffect(() => {
    if (isCustomerLoggedIn && invoice.customerId) {
      setIsLoading(true)
      retrieveSavedCreditCardsInfo({
        customerId: invoice.customerId,
      })
        .then(({paymentMethods}) => {
          if (paymentMethods.length && paymentMethods[0].id) {
            setPaymentMethodInfo({
              name: paymentMethods[0].name,
              last4: paymentMethods[0].last4,
              brand: paymentMethods[0].brand,
              token: paymentMethods[0].id,
            })
          }
          setIsLoading(false)
        })
        .catch((e) => {
          setRetrievePaymentTokenErrMessage(
            `Fail to retrieve payment details: ${e.message}`,
          )
          setIsLoading(false)
        })
    }
  }, [setPaymentMethodInfo, authenticityToken, isCustomerLoggedIn, invoice])

  const handlePaymentSubmission = React.useCallback(
    async (values) => {
      switch (values.type) {
        case 'directDebit': {
          const {bsb, accountNumber} = values

          const result = await confirmDirectDebitPayment({
            invoiceId: invoice.id,
            bsb: bsb.replace(/-/g, ''),
            accountNumber: accountNumber.replace(/-/g, ''),
            authenticityToken,
          }).catch((e) => {
            setDirectDebitErrMessage(`Fail to process payment: ${e.message}`)
            onErrorDD(e.message)
          })

          if (result && result.success) {
            setDirectDebitErrMessage('')
            setDisplaySuccessPayment(true)
            onSuccessDD(result)
          }

          break
        }
        case 'creditCard': {
          const {paymentIntentId} = values
          // Fire event to verify payment.
          // Transition, regardless of verification status,
          //  since Stripe payment succeeded.
          try {
            const result = await verifyPayment({
              paymentIntentId,
              invoiceId: invoice.id,
              authenticityToken,
            })
            setDisplaySuccessPayment(true)
            setPaymentVerificationErrMessage('')
            onSuccessCC(result)
          } catch (e) {
            setDisplaySuccessPayment(true)
            setPaymentVerificationErrMessage(
              `Fail to verify card payment: ${e.message}`,
            )
            onErrorCC(e.message)
          }

          break
        }
        default:
          break
      }
    },
    [
      invoice,
      authenticityToken,
      onSuccessCC,
      onSuccessDD,
      onErrorCC,
      onErrorDD,
    ],
  )

  if (isLoading) {
    return (
      <Skeleton
        height="350px"
        width="100%"
        m={2}
        data-testid="payment-core-loading"
      />
    )
  }

  if (invoice && invoice.isPaid) {
    return null
  }

  if (invoice && invoice.isVoided) {
    return null
  }

  if (displaySuccessPayment) {
    return null
  }

  if (status === 'not_allowed') {
    return null
  }

  return (
    <PaymentCore
      onSubmitPayment={handlePaymentSubmission}
      invoice={{
        id: invoice.id,
        supplierName: invoice.supplierName,
        supplierCode: invoice.supplierCode,
        amount: toCurrency(invoice.amount.cents),
        dueAmount: toCurrency(invoice.dueAmount.cents),
        creditCardSurchargeAmount: toCurrency(
          invoice.creditCardSurchargeAmount.cents,
        ),
        creditCardPaymentAmount: toCurrency(
          invoice.creditCardPaymentAmount.cents,
        ),
        number: invoice.number,
      }}
      payerCreditCardSurcharged={payerCreditCardSurcharged}
      creditCardSurchargePercentage={creditCardSurchargePercentage}
      creditCardSurchargeFixedAmount={toCurrency(
        creditCardSurchargeFixedAmount,
      )}
      bankAccount={bankAccount}
      errorMessage={{
        directDebit: directDebitErrMessage,
        creditCard:
          retrievePaymentTokenErrMessage || paymentVerificationErrMessage,
      }}
      paymentOptions={availablePaymentMethods || {}}
      isLoggedIn={isCustomerLoggedIn}
      paymentDetails={paymentDetails}
      pendingDirectDebit={pendingDirectDebit}
      authenticityToken={authenticityToken}
    />
  )
}

Payment.defaultProps = {
  onSuccessDD: () => {},
  onErrorDD: () => {},
  onSuccessCC: () => {},
  onErrorCC: () => {},
}

Payment.propTypes = {
  onSuccessDD: PropTypes.func,
  onErrorDD: PropTypes.func,
  onSuccessCC: PropTypes.func,
  onErrorCC: PropTypes.func,
  xeroInvoiceUUID: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
}

export default Payment
