import React from 'react'

import PropTypes from 'prop-types'
import {
  Box,
  Text,
  FormControl,
  FormHelperText,
  FormLabel,
  Button,
} from '@chakra-ui/react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {Alert, ThemeProvider, Input, LogoHorizontal} from '../../components'

const ResendSignUpConfirmationForm = ({
  authenticityToken,
  resource,
  resourceName,
  errorMessage,
  infoMessage,
  confirmationPath,
}) => {
  const {values, touched, dirty, errors, handleChange, handleBlur} = useFormik({
    initialValues: {
      [resourceName]: {
        email: (resource && resource.email) || '',
      },
    },
    validationSchema: Yup.object().shape({
      [resourceName]: Yup.object().shape({
        email: Yup.string()
          .email('Please enter a valid email address')
          .required('Required'),
      }),
    }),
  })

  return (
    <form method="POST" acceptCharset="UTF-8" action={confirmationPath}>
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input type="hidden" name="commit" value="Login" />

      {errorMessage && <Alert status="error" message={errorMessage} mb={8} />}

      {infoMessage && <Alert status="info" message={infoMessage} mb={8} />}

      <FormControl>
        <FormLabel htmlFor="email" hidden>
          Email address
        </FormLabel>
        <Input
          type="email"
          name={`${resourceName}[email]`}
          id={`${resourceName}[email]`}
          placeholder="Email"
          aria-describedby="email-helper-text"
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={
            (touched[resourceName] &&
              touched[resourceName].email &&
              errors[resourceName] &&
              errors[resourceName].email) ||
            ''
          }
          value={values[resourceName].email}
        />
        <FormHelperText hidden id="email-helper-text">
          We&apos;ll never share your email.
        </FormHelperText>
      </FormControl>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={4}
      >
        <Button
          variant="primary"
          colorScheme="primary"
          type="submit"
          flexGrow={1}
          isDisabled={
            !(dirty || values[resourceName].email) || Object.keys(errors).length
          }
        >
          Resend confirmation email
        </Button>
      </Box>
    </form>
  )
}

ResendSignUpConfirmationForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  resource: PropTypes.shape({
    email: PropTypes.string,
  }),
  resourceName: PropTypes.string.isRequired,
  confirmationPath: PropTypes.string.isRequired,
}

ResendSignUpConfirmationForm.defaultProps = {
  errorMessage: '',
  infoMessage: '',
  resource: {
    email: '',
  },
}

const ResendSignUpConfirmationScreen = ({
  authenticityToken,
  resource,
  resourceName,
  infoMessage,
  errorMessage,
  confirmationPath,
}) => {
  return (
    <ThemeProvider>
      <Box minH="100vh">
        <Box
          maxWidth="500px"
          position="absolute"
          top="40%"
          left="0"
          right="0"
          transform="translateY(-40%)"
          px="sm"
          margin="0 auto"
        >
          <LogoHorizontal w="323px" h="38px" mb="md" />
          <Text mb={2}>Resend confirmation email</Text>
          <ResendSignUpConfirmationForm
            authenticityToken={authenticityToken}
            errorMessage={errorMessage}
            infoMessage={infoMessage}
            resource={resource}
            resourceName={resourceName}
            confirmationPath={confirmationPath}
          />
        </Box>
      </Box>
    </ThemeProvider>
  )
}

ResendSignUpConfirmationScreen.propTypes = {
  resource: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  confirmationPath: PropTypes.string.isRequired,
}

export default ResendSignUpConfirmationScreen
