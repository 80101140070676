import React from 'react'
import useSWR from 'swr'
import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
import {get} from 'lodash-es'
import {fetcher} from '../../../api'
import {reissueInvoice} from '../../../api/invoices'
import Skeleton from '../Skeleton'
import {useAuthProvider} from '../../contexts/authentication'
import {SuccessScreen} from './SuccessScreen'
import {FailureScreen} from './FailureScreen'
import {InitialScreen} from './InitialScreen'

const recordMixpanelEvent = (result, amount) => {
  mixpanel.track('Invoice Reissue Process Attempt', {result, amount})
}

export const ReissuanceForm = ({
  selectedInvoice,
  handleClose,
  onClickSubmit,
  recheckEligibility,
  abnRequired,
}) => {
  const {authenticityToken} = useAuthProvider()
  const {
    data: customerData,
    error: customerError,
    mutate: refetchCustomer,
  } = useSWR(`/api/invoices/${selectedInvoice.id}/customer`, fetcher)
  const customer = get(customerData, 'customer')
  const loadingCustomer = !customerData && !customerError

  const [invoiceReissued, setInvoiceReissued] = React.useState(false)
  const [invoiceReissuedError, setInvoiceReissuedError] = React.useState(false)
  const [reissuanceEnabled, setReissuanceEnabled] = React.useState(false)
  const [reissuanceProcessing, setReissuanceProcessing] = React.useState(false)
  if (get(customerData, 'customer.email') && !reissuanceEnabled) {
    setReissuanceEnabled(true)
  }

  const onSubmit = async () => {
    setReissuanceProcessing(true)
    try {
      const params = recheckEligibility
        ? {invoice: {recheck_eligibility: recheckEligibility}}
        : {}
      const response = await reissueInvoice(
        authenticityToken,
        get(selectedInvoice, 'id'),
        params,
      )
      recordMixpanelEvent(true, selectedInvoice.due_amount)
      setInvoiceReissued(true)
      setInvoiceReissuedError(null)
      onClickSubmit(response.invoice)
    } catch (e) {
      recordMixpanelEvent(false, selectedInvoice.due_amount)
      if (e.message === 'incorrect_marmalade_details') {
        setInvoiceReissuedError(
          'This invoice is missing Marmalade payment details. Add the Marmalade details to the invoice then try again.',
        )
      } else {
        setInvoiceReissuedError(
          "We couldn't reissue the invoice due to an error. Please try again later.",
        )
      }
    }
    setReissuanceProcessing(false)
  }
  const updateCustomer = () => {
    refetchCustomer()
    setReissuanceEnabled(true)
  }
  const reissueAttempted = invoiceReissued || invoiceReissuedError

  return (
    <Skeleton
      isLoaded={!loadingCustomer}
      minH="36px"
      maxWidth="600px"
      backgroundColor="neutral.white"
      borderRadius="md"
      p="sm"
    >
      {!reissueAttempted && (
        <InitialScreen
          selectedInvoice={selectedInvoice}
          customer={customer}
          onSubmit={onSubmit}
          updateCustomer={updateCustomer}
          reissuanceProcessing={reissuanceProcessing}
          reissuanceEnabled={reissuanceEnabled}
          handleClose={handleClose}
          abnRequired={abnRequired}
        />
      )}
      {invoiceReissued && (
        <SuccessScreen
          selectedInvoice={selectedInvoice}
          customer={customer}
          handleClose={handleClose}
        />
      )}
      {invoiceReissuedError && (
        <FailureScreen
          selectedInvoice={selectedInvoice}
          customer={customer}
          onSubmit={onSubmit}
          reissuanceError={invoiceReissuedError}
          reissuanceProcessing={reissuanceProcessing}
          reissuanceEnabled={reissuanceEnabled}
          handleClose={handleClose}
        />
      )}
    </Skeleton>
  )
}

ReissuanceForm.defaultProps = {
  handleClose: () => {},
  onClickSubmit: () => {},
  recheckEligibility: false,
  abnRequired: false,
}

ReissuanceForm.propTypes = {
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_amount: PropTypes.shape({
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.string.isRequired,
    due_date: PropTypes.number.isRequired,
  }).isRequired,
  handleClose: PropTypes.func,
  onClickSubmit: PropTypes.func,
  recheckEligibility: PropTypes.bool,
  abnRequired: PropTypes.bool,
}

export default ReissuanceForm
