import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'

const DrawerSection = ({title, logo, children, ...boxProps}) => (
  <Flex direction="column" gap="sm" {...boxProps}>
    <Flex justify="space-between" align="center" gap="sm">
      <Text textStyle="body-intro-medium">{title}</Text>
      {logo}
    </Flex>
    {children}
  </Flex>
)

DrawerSection.defaultProps = {
  logo: null,
}

DrawerSection.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default DrawerSection
