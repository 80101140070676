import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {get} from 'lodash-es'
import {
  Box,
  Flex,
  Text,
  Icon,
  SimpleGrid,
  useDisclosure,
  Divider,
} from '@chakra-ui/react'
import {ChevronUpIcon, ChevronDownIcon} from '@chakra-ui/icons'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'

const TextItem = ({label, value}) => {
  return (
    <Box>
      <Flex
        direction={{base: 'row', sm: 'column'}}
        justifyContent="space-between"
        width={{base: 'auto', sm: 'fit-content'}}
        ml={{base: '0', sm: 'auto'}}
      >
        <Text textStyle="body-small" color="grey.700">
          {label}
        </Text>
        <Text textStyle="body-small" fontWeight="medium" textAlign="right">
          {value}
        </Text>
      </Flex>
    </Box>
  )
}

TextItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const CashinSummaryFooter = () => {
  const {organisation} = useRails()
  const {data} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/daily_cashin_summary`,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {isOpen, onToggle} = useDisclosure()

  return (
    <Box
      position="sticky"
      zIndex="sticky"
      py="sm"
      px={{base: 'xs', md: 'sm', lg: 'md'}}
      bottom="0px"
      backgroundColor="grey.100"
      boxShadow="dark-lg"
      onClick={onToggle}
    >
      <Flex>
        <Flex direction="column" flexGrow="1">
          <Flex
            direction={{base: isOpen ? 'column' : 'row', sm: 'row'}}
            justifyContent="space-between"
            flexGrow="1"
            gap="sm"
            mt={isOpen ? 'sm' : '0'}
          >
            <Flex justifyContent="space-between" my="auto">
              <Text
                flexGrow="1"
                textStyle="body-small"
                fontWeight="medium"
                color="grey.700"
              >
                Today&apos;s Cash-in
              </Text>
              <Icon
                as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                display={{base: isOpen ? 'block' : 'none', sm: 'none'}}
                cursor="pointer"
                ml="sm"
                my="auto"
                h="24px"
                w="24px"
                data-testid="tooltip-icon"
              />
            </Flex>
            {isOpen && (
              <SimpleGrid
                flexGrow="2"
                columns={{base: 1, sm: 2, md: 4, lg: 2, xl: 4}}
                spacing={{base: 8, xl: 10, '2xl': 14}}
              >
                <TextItem
                  label="Number of invoices"
                  value={get(data, 'invoiceCount')}
                />
                <TextItem
                  label="Invoice total"
                  value={get(data, 'totalInvoiceAmount')?.toFormat()}
                />
                <TextItem
                  label="Total Cash-in fee"
                  value={`-${get(
                    data,
                    'totalCashinFeeAmount',
                  )?.toFormat()} (${get(data, 'averageCashinFeeRate')})`}
                />
                <TextItem
                  label="Amount settled by cash-in"
                  value={`-${get(
                    data,
                    'reimbursedThroughCashinAmount',
                  )?.toFormat()}`}
                />
              </SimpleGrid>
            )}
            {!isOpen && (
              <Flex my="auto">
                <Text textStyle="body-small" color="grey.700" mr="xxs">
                  One business day payout
                </Text>
                <Text textStyle="body-small" fontWeight="medium">
                  {get(data, 'totalPayoutAmount')?.toFormat()}
                </Text>
              </Flex>
            )}
          </Flex>
          {isOpen && (
            <Box>
              <Divider my="sm" />

              <Flex justifyContent={{base: 'space-between', sm: 'end'}}>
                <Text textStyle="body-small" color="grey.700" mr="sm">
                  One business day payout
                </Text>
                <Text textStyle="body-small" fontWeight="medium" mb="sm">
                  {get(data, 'totalPayoutAmount')?.toFormat()}
                </Text>
              </Flex>
            </Box>
          )}
        </Flex>

        <Icon
          as={isOpen ? ChevronUpIcon : ChevronDownIcon}
          color="primary.actionblue"
          display={{base: isOpen ? 'none' : 'block', sm: 'block'}}
          cursor="pointer"
          ml="sm"
          mt={isOpen ? 'sm' : 'auto'}
          mb={isOpen ? '0' : 'auto'}
          h="24px"
          w="24px"
          data-testid="tooltip-icon"
        />
      </Flex>
    </Box>
  )
}

export default CashinSummaryFooter
