import React from 'react'
import propTypes from 'prop-types'
import {Box} from '@chakra-ui/react'

const LogoVisa = ({width, height, ...props}) => (
  <Box
    as="svg"
    width={width}
    height={height}
    viewBox="0 0 77 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.2855 0.427734L17.3766 16.3272L16.7546 13.1007C15.3163 9.25213 12.5562 5.7146 8.39673 3.34328L13.8002 23.7133H20.1756L29.6608 0.427734H23.2855Z"
      fill="#0058A0"
    />
    <path
      d="M28.3391 23.7131L32.071 0.388672H38.0965L34.3646 23.7131H28.3391Z"
      fill="#0058A0"
    />
    <path
      d="M56.2117 0.97185C55.0066 0.505362 53.1407 0 50.8082 0C44.8605 0 40.6621 3.14879 40.6232 7.69705C40.5844 11.0402 43.6165 12.9062 45.9101 14.0335C48.2425 15.1609 49.0589 15.8995 49.02 16.9102C48.9812 18.4651 47.1541 19.1649 45.4047 19.1649C42.9946 19.1649 41.7117 18.815 39.7291 17.9598L38.9517 17.6099L38.0964 22.819C39.4959 23.4799 42.1005 24.0241 44.8216 24.063C51.1581 24.063 55.2399 20.9142 55.3176 16.0938C55.3565 13.4504 53.7238 11.429 50.264 9.75737C48.1648 8.6689 46.882 7.96917 46.882 6.8807C46.882 5.90885 47.9704 4.85925 50.3417 4.85925C52.3243 4.82038 53.7238 5.28686 54.8511 5.75335L55.3954 6.02547L56.2117 0.97185Z"
      fill="#0058A0"
    />
    <path
      d="M71.6447 0.427734H66.9798C65.5415 0.427734 64.453 0.855348 63.831 2.37143L54.89 23.7521H61.2265C61.2265 23.7521 62.2761 20.8755 62.5093 20.2535C63.2091 20.2535 69.3511 20.2535 70.2064 20.2535C70.4007 21.0698 70.945 23.7521 70.945 23.7521H76.5428L71.6447 0.427734ZM64.2198 15.472C64.7251 14.1503 66.63 8.94114 66.63 8.94114C66.5911 9.01889 67.1353 7.58055 67.4463 6.72532L67.8351 8.74677C67.8351 8.74677 69.0013 14.3058 69.2345 15.472C68.2627 15.472 65.386 15.472 64.2198 15.472Z"
      fill="#0058A0"
    />
    <path
      d="M11.8953 0.427734H2.17681L2.13794 0.816474C9.67949 2.76017 14.6554 7.5028 16.7546 13.1007L14.6554 2.37143C14.2666 0.894222 13.217 0.466608 11.8953 0.427734Z"
      fill="#FAA61A"
    />
  </Box>
)

LogoVisa.defaultProps = {
  width: 77,
  height: 29,
}

LogoVisa.propTypes = {
  width: propTypes.number,
  height: propTypes.number,
}

export default LogoVisa
