import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Text} from '@chakra-ui/react'
import Alert from '../../Alert'

const OverdueInvoicesBanner = ({disableOverdueInvoicesBanner, overdueDays}) => {
  const showInvoices = () => {
    window.location.href = `/invoices?days_past_due_date=${Math.round(
      overdueDays > 33 ? 33 : 25,
    )}&cashin_status=true&payment_status=unpaid`
  }

  return (
    <Alert
      status="warning"
      isDismissable
      onClose={disableOverdueInvoicesBanner}
      title={`You have cashed-in invoices more than ${
        overdueDays > 33 ? 33 : 25
      } days overdue`}
      message={
        <Box data-testid="overdue-invoices-banner">
          <Text mb="xs">
            {overdueDays > 33
              ? 'We need information on the invoice(s) before you can cash in'
              : 'We will need information on the invoice(s) soon to keep cash in enabled'}
          </Text>
          <Button variant="secondary" onClick={showInvoices}>
            Go to Invoices
          </Button>
        </Box>
      }
    />
  )
}

OverdueInvoicesBanner.propTypes = {
  disableOverdueInvoicesBanner: PropTypes.func.isRequired,
  overdueDays: PropTypes.number.isRequired,
}

export default OverdueInvoicesBanner
