import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import {Box, Tooltip, Icon, useOutsideClick, Text} from '@chakra-ui/react'
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {useTranslation} from 'react-i18next'
import {useRails} from '../../contexts/rails'
import {StarIcon} from '../../icons'

export const CustomerRatingDropdown = ({
  score,
  feeRangeMin,
  feeRangeMax,
  topReason,
  ...additionalInfo
}) => {
  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )

  const {t} = useTranslation('cashinPage')
  const ref = React.useRef()
  const [showDisplayReason, setShowDisplayReason] = React.useState(false)
  useOutsideClick({
    ref,
    handler: () => setShowDisplayReason(false),
  })

  return (
    <Box
      bg="neutral.200"
      px="xxs"
      py="2px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      width="fit-content"
      ref={ref}
      aria-label={`${score} out of 5 stars`}
      onClick={() => {
        setShowDisplayReason(!showDisplayReason)
      }}
    >
      {[...Array(5).keys()].map((value) => (
        <StarIcon
          key={value}
          color={score > value ? 'mld.primary.300' : 'grey.500'}
          h="12px"
          w="12px"
          mr="xxs"
        />
      ))}
      <Tooltip
        isOpen={showDisplayReason}
        label={
          <Box bg="neutral.white" py="xxs" px="xs">
            <Text textStyle="body-small">
              {t(`cashinPage:customer_rating_reasons.${topReason}`, {
                ...additionalInfo,
              })}
            </Text>
            {!fixedPricingEnabled && (
              <Text textStyle="body-small" color="grey.700">
                {t(`cashinPage:customer_fee_range.${score}`, {
                  minFeeRange: feeRangeMin,
                  maxFeeRange: feeRangeMax,
                })}
              </Text>
            )}
          </Box>
        }
        boxShadow="base"
        borderRadius="md"
        bg="neutral.white"
        color="black"
      >
        <Icon
          as={showDisplayReason ? TriangleUpIcon : TriangleDownIcon}
          color="primary.actionblue"
          cursor="pointer"
          mr="xxs"
          h="8px"
          w="8px"
          data-testid="tooltip-icon"
        />
      </Tooltip>
    </Box>
  )
}

CustomerRatingDropdown.propTypes = {
  score: PropTypes.number.isRequired,
  topReason: PropTypes.string.isRequired,
  feeRangeMin: PropTypes.number.isRequired,
  feeRangeMax: PropTypes.number.isRequired,
}

export default CustomerRatingDropdown
