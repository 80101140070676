import React from 'react'
import {useFormik} from 'formik'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  ButtonGroup,
  FormLabel,
  FormControl,
  Collapse,
  Button,
  HStack,
} from '@chakra-ui/react'

import {Input} from '../../components'

const InformPaymentForm = ({invoiceNumber, supplierName, handleSubmit}) => {
  const validate = (values) => {
    const errors = {}

    if (values.type === 'Partial' && !values.amount) {
      errors.amount = 'Required'
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      type: 'Full',
      notes: '',
      amount: undefined,
    },
    validate,
    onSubmit: handleSubmit,
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Text as="h1" textStyle="headline2" py="lg">
        Invoice already paid?
      </Text>
      <Text>
        I have already paid{' '}
        <Box as="span" fontWeight="medium">
          {invoiceNumber}
        </Box>{' '}
        to{' '}
        <Box as="span" fontWeight="medium">
          {supplierName}
        </Box>
      </Text>

      <RadioGroup name="type" defaultValue={formik.values.type}>
        <HStack my="sm">
          <Radio
            value="Full"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <Text fontSize="sm">Full Payment</Text>
          </Radio>

          <Radio
            value="Partial"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <Text fontSize="sm">Partial Payment</Text>
          </Radio>
        </HStack>
      </RadioGroup>

      <Collapse in={formik.values.type === 'Partial'} animateOpacity>
        <FormControl>
          <FormLabel htmlFor="amount" fontSize="sm">
            Amount paid (AUD)
          </FormLabel>
          <Input
            id="amount"
            type="number"
            name="amount"
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            errorMessage={formik.touched.amount && formik.errors.amount}
          />
        </FormControl>
      </Collapse>

      <FormControl>
        <FormLabel htmlFor="notes" fontSize="sm">
          Additional comments (optional)
        </FormLabel>
        <Input
          id="notes"
          name="notes"
          as="textarea"
          minHeight="94px"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </FormControl>

      <ButtonGroup py="sm">
        <Button
          variant="primary"
          colorScheme="primary"
          type="submit"
          isDisabled={!formik.isValid}
        >
          Submit
        </Button>
      </ButtonGroup>
    </form>
  )
}

InformPaymentForm.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default InformPaymentForm
