import React from 'react'
import {Button, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

const CreatedInstructions = () => {
  const {t} = useTranslation('billPayments')

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100%"
      gap="sm"
    >
      <Text textStyle="headline4">
        {t(
          'billPayments:paymentMethods.debitAgreement.instructions.active.title',
        )}
      </Text>
      <Button
        as="a"
        variant="primary"
        colorScheme="primary"
        href="/bill_payments"
        size="lg"
      >
        {t(
          'billPayments:paymentMethods.debitAgreement.instructions.active.callToAction',
        )}
      </Button>
    </Flex>
  )
}

export default CreatedInstructions
