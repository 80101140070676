import React from 'react'
import {useFormik} from 'formik'
import {Box, Text, Button} from '@chakra-ui/react'

import {useRails} from '../../../../contexts/rails'
import {Alert, DropdownSelect} from '../../../../components'
import {createSupplier} from '../../../../../api'

const OrganisationSelectionForm = () => {
  const {authenticityToken, authorisedOrganisations} = useRails()
  const [organisationError, setOrganisationError] = React.useState('')

  const mappedData = authorisedOrganisations.map((organisation) => ({
    label: organisation.product
      ? `${organisation.name} - (${organisation.product})`
      : organisation.name,
    value: organisation.externalOrganisationId,
  }))

  const formik = useFormik({
    initialValues: {
      organisation: {
        label: '',
        value: '',
      },
    },
    onSubmit: async (values) => {
      const {organisation} = values

      try {
        await createSupplier({
          externalOrganisationId: organisation.value,
          authenticityToken,
        })
        window.location.href = '/dashboard'
      } catch (e) {
        setOrganisationError(e.message)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {organisationError && (
        <Alert status="error" message={organisationError} mb={8} />
      )}

      {mappedData.length > 0 && (
        <Box mb={8} id="organisation-selection">
          <DropdownSelect
            options={mappedData}
            name="organisation"
            placeholder="Select organisation"
            onChange={(value) => formik.setFieldValue('organisation', value)}
          />
        </Box>
      )}
      <Button
        variant="primary"
        colorScheme="primary"
        size="lg"
        isDisabled={formik.values.organisation.value === ''}
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Continue
      </Button>
    </form>
  )
}

const OrganisationSelection = () => (
  <>
    <Text mb={8}>
      Next, we’ll need the organisation you want to connect to.
    </Text>
    <OrganisationSelectionForm />
  </>
)

export default OrganisationSelection
