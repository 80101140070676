import React from 'react'
import PropTypes from 'prop-types'
import {
  useDisclosure,
  Box,
  Text,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  Stack,
  Button,
} from '@chakra-ui/react'

import {BankAccountCard, PayidCard} from '../../../components'

const BankAccountDetailsDrawer = ({accountName, accountNumber, bsb, payid}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const bankAccountLabel = `${bsb} / ${accountNumber}`

  return (
    <>
      <Flex align="center" mb="sm" minHeight="40px">
        <Box width="320px">
          <Text>Bank account details</Text>
        </Box>
        <Flex width="calc(100% - 320px)" align="center" justify="space-between">
          <Text fontWeight="medium">{bankAccountLabel}</Text>
          <Button variant="secondary" onClick={onOpen}>
            Details
          </Button>
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex width="100%" align="center" justify="space-between" py="xs">
              <Text flexGrow="1" fontSize="md" fontWeight="medium">
                Bank account details
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody overflowY="scroll">
            <Text fontSize="base" mb="md">
              These are the bank details that you need to give your customers if
              they wish to pay via bank transfer.
            </Text>

            <Stack spacing="sm" mb="sm">
              <BankAccountCard
                dark
                accountName={accountName}
                accountNumber={accountNumber}
                bsb={bsb}
              />

              {payid && <PayidCard dark payid={payid} />}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="secondary" size="lg" mr="sm" onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

BankAccountDetailsDrawer.defaultProps = {
  accountName: '',
  accountNumber: '',
  bsb: '',
  payid: '',
}

BankAccountDetailsDrawer.propTypes = {
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
  bsb: PropTypes.string,
  payid: PropTypes.string,
}

export default BankAccountDetailsDrawer
