import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Text, useDisclosure} from '@chakra-ui/react'

import {AddOverdueDetailsModal, Alert} from '../../components'

const OverdueDetails = ({invoice, overdueMessage, closeDrawer, mutateList}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const showAddDetails = invoice.overDueDays > 25 && invoice.canAddOverdueInfo

  return (
    <>
      {showAddDetails && (
        <Alert
          status="warning"
          message={
            <Box>
              <Text mb="xs">
                This invoice is overdue for over {invoice.overDueDays} days.
                Please add information about the status of your invoices.{' '}
                {invoice.overDueDays < 33 && (
                  <>
                    Once it reaches more than 33 days overdue there may be some
                    restrictions to your cash-in eligibility.
                  </>
                )}
              </Text>

              <Button variant="secondary" onClick={onOpen}>
                Add details
              </Button>
            </Box>
          }
          mb="sm"
        />
      )}

      {overdueMessage && (
        <Alert
          status="warning"
          title={`What should we know about ${invoice.number}?`}
          message={overdueMessage}
          mb="sm"
        />
      )}

      <AddOverdueDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        beforeClose={() => {
          mutateList()
          closeDrawer()
        }}
        invoice={invoice}
      />
    </>
  )
}

OverdueDetails.propTypes = {
  invoice: PropTypes.shape({
    overDueDays: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    canAddOverdueInfo: PropTypes.bool.isRequired,
  }).isRequired,
  overdueMessage: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  mutateList: PropTypes.func.isRequired,
}

OverdueDetails.defaultProps = {
  overdueMessage: null,
}

export default OverdueDetails
