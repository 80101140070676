/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Text,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import {Trans, useTranslation} from 'react-i18next'
import {
  ArrowRightIcon,
  PadlockOutlineIcon,
  ShieldIcon,
} from '../../../../../icons'
import LearnMoreModal from './LearnMoreModal'

const AccountIntegrationWelcome = ({goToNextPage}) => {
  const {t} = useTranslation('onboarding')
  const i18nKeyPrefix = 'onboarding:BankAccountIntegration.welcomeScreen'
  const {
    isOpen: isLearnMoreOpen,
    onClose: onLearnMoreClose,
    onOpen: onLearnMoreOpen,
  } = useDisclosure()

  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="1"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t(`${i18nKeyPrefix}.title`)}
      </Text>

      <Text textStyle="body-intro" mb="md">
        {t(`${i18nKeyPrefix}.subtitle`)}
      </Text>
      <Divider borderColor="mld.neutral.500" />
      <HStack py="md">
        <Icon as={PadlockOutlineIcon} boxSize="24px" />
        <Text textStyle="body-copy" pl="sm">
          {t(`${i18nKeyPrefix}.dataUseMessage`)}
        </Text>
      </HStack>
      <Divider borderColor="mld.neutral.500" />
      <HStack py="md">
        <Icon as={ShieldIcon} boxSize="22px" />
        <Text textStyle="body-copy" pl="sm">
          <Trans
            t={t}
            i18nKey={`${i18nKeyPrefix}.moreAboutYodleeMessage`}
            components={{
              /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
              learnAboutYodleeLink: (
                <Link
                  href="https://www.yodlee.com/company/data-promise"
                  rel="nofollow"
                  textStyle="body-copy"
                  variant="primary"
                  target="_blank"
                />
              ),
            }}
          />
        </Text>
      </HStack>
      <Divider borderColor="mld.neutral.500" />

      <Box pt="md">
        <Text textStyle="body-small" color="mld.neutral.800">
          {t(`${i18nKeyPrefix}.body`)}
        </Text>
      </Box>

      <Button
        variant="primary"
        colorScheme="primary"
        size="lg"
        mb="lg"
        mt="md"
        onClick={goToNextPage}
      >
        {t(`${i18nKeyPrefix}.submitButton`)}
        <ArrowRightIcon width="24px" height="24px" ml="xs" />
      </Button>

      <br />
      <Link
        as="button"
        variant="primary"
        textStyle="button"
        onClick={onLearnMoreOpen}
      >
        {t(`${i18nKeyPrefix}.learnMoreLink`)}
      </Link>
      <LearnMoreModal
        isOpen={isLearnMoreOpen}
        onClose={onLearnMoreClose}
        onBankConnectionModalOpen={goToNextPage}
      />
    </>
  )
}

AccountIntegrationWelcome.defaultProps = {
  goToNextPage: () => {},
}

AccountIntegrationWelcome.propTypes = {
  goToNextPage: PropTypes.func,
}

export default AccountIntegrationWelcome
