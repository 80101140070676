import XeroSmall from '../../../assets/images/xero_small.png'
import QuickbooksSmall from '../../../assets/images/quickbooks_small.png'
import MyobSmall from '../../../assets/images/myob-logo.svg'

export default function useAccountingProviderLogo({accountingProviderName}) {
  let image
  switch (accountingProviderName) {
    case 'MYOB':
      image = MyobSmall
      break
    case 'Quickbooks':
      image = QuickbooksSmall
      break
    case 'Xero':
      image = XeroSmall
      break
    default:
      image = XeroSmall
  }

  return image
}
