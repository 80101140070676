import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import {Formik, Form, Field} from 'formik'
import {object, number, string} from 'yup'
import PropTypes from 'prop-types'
import React from 'react'

import {Textarea, MoneyInput, Dialog} from '../../../components'

const reportPaymentSchema = object().shape({
  notes: string(),
  type: string(),
  amount: number().when('type', {
    is: 'Partial',
    then: () => number().required('Amount is required for partial payments'),
    otherwise: () => number().strip(),
  }),
})

const ReportDirectPaymentForm = ({
  isOpen,
  onClose,
  onSubmit,
  invoiceNumber,
  contactName,
}) => {
  const handleSubmit = (values) => onSubmit(reportPaymentSchema.cast(values))
  const initialValues = {
    type: 'Full',
    notes: '',
    amount: '',
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={reportPaymentSchema}
    >
      {({values, errors, touched, isSubmitting, submitForm}) => (
        <Form>
          <Dialog
            title="Invoice already paid direct to your bank account?"
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={submitForm}
            isDisabled={isSubmitting}
          >
            <Text fontSize="sm">
              I have received payment from <strong>{contactName}</strong> for{' '}
              <strong>{invoiceNumber}</strong>
            </Text>

            <Field as={RadioGroup} name="type">
              <Stack isInline spacing="md" my="sm">
                <Field as={Radio} value="Full" size="lg">
                  <Text fontSize="sm">Full payment</Text>
                </Field>

                <Field as={Radio} value="Partial" size="lg">
                  <Text fontSize="sm">Partial payment</Text>
                </Field>
              </Stack>
            </Field>

            {values.type === 'Partial' && (
              <FormControl width={2 / 3}>
                <FormLabel color="neutral.60" fontSize="sm" htmlFor="amount">
                  Amount paid (AUD)
                </FormLabel>

                <Field
                  as={MoneyInput}
                  type="number"
                  id="amount"
                  name="amount"
                  errorMessage={touched.amount && errors.amount}
                />
              </FormControl>
            )}

            <FormControl>
              <FormLabel color="neutral.60" fontSize="sm" htmlFor="notes">
                Additional comments (optional)
              </FormLabel>

              <Field as={Textarea} type="text" name="notes" id="notes" />
            </FormControl>

            <Text fontSize="sm">
              Selecting mark as paid will mark this invoice as paid and we will
              be in contact to help rectify this.
            </Text>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

ReportDirectPaymentForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invoiceNumber: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
}

export default ReportDirectPaymentForm
