import * as Yup from 'yup'
import isValidABN from 'is-valid-abn'
import {useTranslation} from 'react-i18next'

export default function useValidationSchema() {
  const {t} = useTranslation('billPayments')

  const i18nPrefix = 'billPayments:billPayment.form'

  const validationSchema = Yup.object().shape({
    paymentMethodId: Yup.string().required(
      t(`${i18nPrefix}.fields.paymentMethod.requiredError`),
    ),
    payeeName: Yup.string().required(
      t(`${i18nPrefix}.fields.payeeName.requiredError`),
    ),
    payeeEmail: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t(`${i18nPrefix}.fields.payeeEmail.emailFormatError`),
      )
      .required(t(`${i18nPrefix}.fields.payeeEmail.requiredError`)),
    businessNumber: Yup.string()
      .test(
        'validAbn',
        t(`${i18nPrefix}.fields.businessNumber.invalidError`),
        (value) => isValidABN(value),
      )
      .required(t(`${i18nPrefix}.fields.businessNumber.requiredError`)),
    amount: Yup.number()
      .min(1_00, t(`${i18nPrefix}.fields.amount.minError`))
      .max(25_000_00, t(`${i18nPrefix}.fields.amount.maxError`))
      .required(t(`${i18nPrefix}.fields.amount.requiredError`)),
    branchCode: Yup.string()
      .matches(/^\d{6}$/, t(`${i18nPrefix}.fields.branchCode.lengthError`))
      .required(t(`${i18nPrefix}.fields.branchCode.requiredError`)),
    accountNumber: Yup.string()
      .matches(/^\d{6,9}$/, t(`${i18nPrefix}.fields.accountNumber.lengthError`))
      .required(t(`${i18nPrefix}.fields.accountNumber.requiredError`)),
    reference: Yup.string()
      .max(9, t(`${i18nPrefix}.fields.reference.maxError`))
      .required(t(`${i18nPrefix}.fields.reference.requiredError`)),
  })

  return {
    validationSchema,
  }
}
