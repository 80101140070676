import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {get, capitalize} from 'lodash-es'
import {Box, Text, Flex, UnorderedList, ListItem} from '@chakra-ui/react'
import {InfoOutlineIcon} from '@chakra-ui/icons'

import {useTranslation} from 'react-i18next'
import IconTooltip from '../../../../components/IconTooltip'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import {useSupplierRewardModal} from '../../../../contexts/supplierRewardsModal'

const RewardLevelBadge = ({level}) => {
  return (
    <Flex my="xs" py="xxs">
      <Box
        w="34px"
        h="34px"
        mr="xs"
        borderRadius="md"
        boxShadow="lg"
        background={`mld.rewards.${level.toLowerCase()}`}
      />
      <Text
        my="auto"
        mr="lg"
        textStyle="body-small"
        fontWeight="medium"
        whiteSpace="nowrap"
      >
        {capitalize(level)} status
      </Text>
    </Flex>
  )
}

RewardLevelBadge.propTypes = {
  level: PropTypes.string.isRequired,
}

const HeaderSummary = ({fetchData}) => {
  const {t} = useTranslation('cashinPage')
  const {organisation} = useRails()
  const {data: rewardsData} = useSWR(
    fetchData
      ? `/api/suppliers/${organisation.currentSupplier.id}/supplier_rewards_summary`
      : null,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )
  const {data: summaryData} = useSWR(
    fetchData
      ? `/api/suppliers/${organisation.currentSupplier.id}/unpaid_invoices_summary`
      : null,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )
  const {data: unavailableReasonsData} = useSWR(
    fetchData
      ? `/api/suppliers/${organisation.currentSupplier.id}/invoice_unavailable_reasons_summary`
      : null,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const currentLevelIndex =
    rewardsData &&
    rewardsData.levels.findIndex(
      (level) => level.key === rewardsData.currentLevel,
    )
  const nextLevelData = rewardsData && rewardsData.levels[currentLevelIndex + 1]

  const {onOpen} = useSupplierRewardModal()

  return (
    <Box bg="neutral.white" px="md" py="sm">
      <Text textStyle="body-copy" fontWeight="medium" mb="xs">
        Summary
      </Text>
      <Flex justifyContent="space-between" flexWrap="wrap" gap="sm">
        <Box
          flex="1 1 0"
          py="xs"
          px="sm"
          border="1px solid"
          borderColor="grey.300"
          borderRadius="lg"
        >
          <Text textStyle="body-small" color="neutral.700">
            Current
          </Text>
          {rewardsData && <RewardLevelBadge level={rewardsData.currentLevel} />}
          <Box bgColor="neutral.100" borderRadius="xs" p="xs">
            <Text textStyle="body-small" color="grey.700">
              Total availability
              <IconTooltip icon={<InfoOutlineIcon />}>
                <Text textStyle="body-small">
                  Total invoices amount that are available now for cash-in and
                  conditionally available.
                </Text>
              </IconTooltip>
            </Text>
            <Text textStyle="body-copy" fontWeight="medium">
              {summaryData &&
                summaryData.eligibleAmount
                  .add(summaryData.conditionallyEligibleAmount)
                  .toFormat('$0,0')}
            </Text>
          </Box>
        </Box>
        {rewardsData && nextLevelData && (
          <Box
            flex="1 1 0"
            py="xs"
            px="sm"
            backgroundColor="neutral.100"
            border="1px solid"
            borderColor="grey.300"
            borderRadius="lg"
          >
            <Text textStyle="body-small" color="neutral.800">
              Upgrade to
            </Text>
            <RewardLevelBadge level={nextLevelData.key} />
            <Box bgColor="neutral.white" borderRadius="xs" p="xs">
              <Text textStyle="body-small">
                You&apos;ll be able to Cash-in more!
              </Text>
              <Text
                textStyle="body-small"
                color="primary.actionblue"
                cursor="pointer"
                _hover={{
                  textDecoration: 'underline',
                }}
                onClick={onOpen}
              >
                See how to upgrade
              </Text>
            </Box>
          </Box>
        )}
        <Box
          flex="2 1 0"
          minWidth="350px"
          p="sm"
          backgroundColor="neutral.100"
          border="1px solid"
          borderColor="grey.300"
          borderRadius="lg"
        >
          <Text textStyle="body-small" mb="sm" color="neutral.800">
            Top reasons for{' '}
            <Text as="span" textStyle="body-small" fontWeight="medium">
              Not Available
            </Text>{' '}
            invoices
          </Text>
          <UnorderedList>
            {get(unavailableReasonsData, 'topUnavailableReasons', []).map(
              (reason) => (
                <ListItem>
                  <Text textStyle="body-small">
                    {t(`cashinPage:top_unavailable_reasons.${reason.key}`, {
                      count: reason.count,
                    })}
                  </Text>
                </ListItem>
              ),
            )}
          </UnorderedList>
        </Box>
      </Flex>
    </Box>
  )
}

HeaderSummary.propTypes = {
  fetchData: PropTypes.bool.isRequired,
}

export default HeaderSummary
