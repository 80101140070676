import PropTypes from 'prop-types'

const CustomerRatingsPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  topReason: PropTypes.shape({
    key: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object,
  }).isRequired,
  feeRangeMin: PropTypes.number,
  feeRangeMax: PropTypes.number,
})

export default CustomerRatingsPropType
