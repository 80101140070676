import React from 'react'
import PropTypes from 'prop-types'
import {Avatar, Box, Text, Flex, Button} from '@chakra-ui/react'
import {CheckIcon, TimeIcon, WarningIcon} from '@chakra-ui/icons'

const avatarBackground = (previousOrActive) =>
  previousOrActive ? 'grey.700' : 'grey.300'
const titleColor = (isActive) => (isActive ? 'chakra-body-text' : 'neutral.60')

const StepIcon = ({icon, previousStep, activeStep}) => {
  return (
    <Flex direction="column" align="center" pr="sm">
      <Avatar
        w="28px"
        h="28px"
        bg={avatarBackground(previousStep || activeStep)}
        size="sm"
        color="neutral.white"
        icon={icon}
        sx={{opacity: previousStep ? '0.2' : '1'}}
      />
    </Flex>
  )
}

StepIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  previousStep: PropTypes.bool.isRequired,
  activeStep: PropTypes.bool.isRequired,
}

const ActionMockButton = ({children, bgColor}) => {
  const colorProps = bgColor ? {borderColor: bgColor, bgColor} : {}
  return (
    <Flex
      alignItems="center"
      border="1px"
      borderColor="neutral.20"
      borderRadius="md"
      px="sm"
      py="xxs"
      {...colorProps}
    >
      {children}
    </Flex>
  )
}

ActionMockButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bgColor: PropTypes.string,
}

ActionMockButton.defaultProps = {
  bgColor: undefined,
}

const CallToAction = ({status, action}) => {
  if (status === 'completed') {
    return (
      <Flex align="center">
        <CheckIcon boxSize="10px" mr="xxs" />
        <Text textStyle="body-small">Completed</Text>
      </Flex>
    )
  }

  if (status === 'reviewing') {
    return (
      <Button
        variant="primary"
        size="sm"
        isLoading
        loadingText="In progress..."
      />
    )
  }

  if (status === 'emailed') {
    return (
      <Flex align="center">
        <TimeIcon boxSize="10px" mr="xxs" />
        <Text textStyle="body-small">Check your email</Text>
      </Flex>
    )
  }

  if (status === 'declined') {
    return (
      <Flex align="center">
        <WarningIcon boxSize="12px" mr="xxs" />
        <Text textStyle="body-small">Error</Text>
      </Flex>
    )
  }

  return <Box>{action}</Box>
}

CallToAction.propTypes = {
  status: PropTypes.string.isRequired,
  action: PropTypes.element,
}

CallToAction.defaultProps = {
  action: null,
}

const VerticalStepper = ({steps, currentStep}) => {
  const currentStepIndex = steps.findIndex((step) => step.name === currentStep)
  return (
    <Flex flexDirection="column">
      {steps.map((step, index) => {
        const activeStep = currentStepIndex === index

        return (
          <Flex
            key={step.name}
            justifyContent="space-between"
            alignItems="center"
            minH="50px"
            backgroundColor="white"
            flexWrap="wrap"
            p="sm"
            my="xs"
            gap="sm"
            border="1px"
            borderColor="neutral.20"
            borderRadius="md"
            data-testid={
              step.status === 'completed' ? `completed_step_${index}` : null
            }
          >
            <Flex>
              <StepIcon
                icon={step.icon}
                previousStep={index < currentStepIndex}
                activeStep={activeStep}
                showDivider={index < steps.length - 1}
              />
              <Box color={titleColor(activeStep)}>{step.title}</Box>
            </Flex>
            {step.notification}
            <CallToAction {...step} />
          </Flex>
        )
      })}
    </Flex>
  )
}

VerticalStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      title: PropTypes.element.isRequired,
      action: PropTypes.element,
      notification: PropTypes.element,
    }),
  ).isRequired,
  currentStep: PropTypes.string,
}

VerticalStepper.defaultProps = {
  currentStep: null,
}

export default VerticalStepper
