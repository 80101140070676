import React from 'react'
import PropTypes from 'prop-types'
import withProviders from '../../../contexts/withProviders'
import {useRails} from '../../../contexts/rails'
import Onboarding from './Onboarding'
import Baseline from './Baseline'
import RailsFlashMessages from '../../../components/RailsFlashMessages'

const DashboardSupplierScreen = ({
  flashMessages,
  supplierSetupTrackerState,
}) => {
  const {authenticityToken} = useRails()

  const isOnboardingComplete = supplierSetupTrackerState === 'complete'

  if (isOnboardingComplete)
    return (
      <>
        <RailsFlashMessages flashMessages={flashMessages} />
        <Baseline authenticityToken={authenticityToken} />
      </>
    )
  return (
    <>
      <RailsFlashMessages flashMessages={flashMessages} />
      <Onboarding supplierSetupTrackerState={supplierSetupTrackerState} />
    </>
  )
}

DashboardSupplierScreen.defaultProps = {
  supplierSetupTrackerState: '',
}

DashboardSupplierScreen.propTypes = {
  flashMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  supplierSetupTrackerState: PropTypes.string,
}

export default withProviders(DashboardSupplierScreen)
