import React from 'react'
import {Divider, useBreakpointValue} from '@chakra-ui/react'
import {snakeCase} from 'lodash-es'
import {toEpochMillisecond, toLocalDate} from '../../../../../utils/formatter'
import {useRails} from '../../../../contexts/rails'
import usePaginatedSWR from '../../../../hooks/usePaginatedSWR'
import CustomerCell from '../InvoicesList/CustomerCell'
import InvoiceAmountCell from '../InvoicesList/InvoiceAmountCell'
import InvoiceNumberCell from '../InvoicesList/InvoiceNumberCell'

const ITEMS_PER_PAGE = 50

export default function useTabContent({endpointAction, reloadRequired}) {
  const {organisation} = useRails()
  const endpoint = `/api/suppliers/${organisation.currentSupplier.id}/${endpointAction}`

  const [sortBy, setSortBy] = React.useState(null)
  const [groupBy, setGroupBy] = React.useState(null)

  const onSort = ([sort]) => {
    if (!sort) {
      setSortBy(null)
    } else {
      setSortBy(`${snakeCase(sort.id)} ${sort.desc ? 'desc' : 'asc'}`)
    }
  }
  const sortOptions = [
    {
      value: 'due_date asc',
      label: 'Earliest due date',
      isDisabled: groupBy && groupBy !== 'dueDate',
    },
    {
      value: 'due_date desc',
      label: 'Oldest due date',
      isDisabled: groupBy && groupBy !== 'dueDate',
    },
    {
      label: <Divider />,
      options: [
        {
          value:
            endpointAction === 'cashed_in_invoices'
              ? 'cashin_fee asc'
              : 'invoice_cashin_offer_cashin_fee asc',
          label: 'Cheapest fee %',
          isDisabled: groupBy && groupBy !== 'feeRate',
        },
        {
          value:
            endpointAction === 'cashed_in_invoices'
              ? 'cashin_fee desc'
              : 'invoice_cashin_offer_cashin_fee desc',
          label: 'Highest fee %',
          isDisabled: groupBy && groupBy !== 'feeRate',
        },
      ],
      isDisabled: true,
    },
    {
      label: <Divider />,
      options: [
        {
          value: 'xero_date desc',
          label: 'Recent issue date',
          isDisabled: groupBy && groupBy !== 'xeroDate',
        },
        {
          value: 'xero_date asc',
          label: 'Oldest issue date',
          isDisabled: groupBy && groupBy !== 'xeroDate',
        },
      ],
    },
  ]
  const onGroup = (group) => {
    if (!group) {
      setGroupBy(null)
    } else {
      setGroupBy(group)
      setSortBy(`${snakeCase(group)} asc`)
    }
  }
  const groupOptions = [
    {value: 'contactName', label: 'Customer'},
    {value: 'feeRate', label: 'Fee (%)'},
    {value: 'xeroDate', label: 'Issue date'},
    {value: 'dueDate', label: 'Due date'},
  ]

  const commonColumns = {
    customerColumn: {
      header: 'Customer',
      accessorKey: 'contactName',
      cell: CustomerCell,
      enableSorting: !groupBy || groupBy === 'contactName',
      colSpan: 2,
    },
    invoiceColumn: {
      header: 'Invoice',
      accessorKey: 'invoiceNumber',
      cell: InvoiceNumberCell,
      enableSorting: !groupBy,
      colSpan: 2,
    },
    dueDateColumn: {
      header: 'Due date',
      accessorKey: 'dueDate',
      accessorFn: (rowData) =>
        toLocalDate(toEpochMillisecond(rowData.dueDate), {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
      hidden: useBreakpointValue({base: true, lg: false}),
      enableSorting: !groupBy || groupBy === 'dueDate',
      colSpan: 2,
    },
    invoiceAmountColumn: {
      header: 'Invoice amount',
      accessorKey: 'dueAmountCents',
      accessorFn: (rowData) => rowData.dueAmount.toFormat(),
      cell: InvoiceAmountCell,
      enableSorting: !groupBy,
      colSpan: 2,
    },
    issueDateColumn: {
      header: 'Issue date',
      accessorKey: 'xeroDate',
      accessorFn: (rowData) =>
        toLocalDate(toEpochMillisecond(rowData.xeroDate), {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
      hidden: true,
    },
    feeRateColumn: {
      header: 'Fee rate',
      accessorKey: 'feeRate',
      hidden: true,
    },
  }

  const {data, isLoading, isValidating, mutate, size, setSize, hasNextPage} =
    usePaginatedSWR({
      endpoint,
      queryParams: {'q[s]': sortBy},
      perPage: ITEMS_PER_PAGE,
      swrOptions: {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    })

  const fetchNextPage = (isVisible) => {
    if (!isVisible) {
      return
    }

    setSize(size + 1)
  }

  React.useEffect(() => {
    if (reloadRequired) {
      // When the reloadRequired timestamp changes refresh
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadRequired])

  const invoicesData =
    data && Object.values(data).flatMap(({invoices}) => invoices)

  return {
    commonColumns,
    invoicesData,
    onSort,
    sortBy,
    sortOptions,
    onGroup,
    groupByValue: groupBy,
    groupOptions,
    isLoading,
    isValidating,
    hasNextPage,
    fetchNextPage,
  }
}
