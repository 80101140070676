import React from 'react'
import PropTypes from 'prop-types'

import {Alert} from '../../../components'
import {formatDateString} from '../../../../utils/formatter'

const StatementInformationBanner = ({previousAt, nextAt}) => {
  if (!previousAt || !nextAt) {
    return null
  }

  return (
    <Alert
      status="info"
      isDismissable
      title={`Next statement will be sent on ${formatDateString(nextAt)}`}
      message={`Statement will only be sent to customers with outstanding amount on
              their invoices. Last statement was sent to customers on ${formatDateString(
                previousAt,
              )}.`}
    />
  )
}

StatementInformationBanner.propTypes = {
  previousAt: PropTypes.string.isRequired,
  nextAt: PropTypes.string.isRequired,
}

export default StatementInformationBanner
