import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import pluralize from 'pluralize'
import mixpanel from 'mixpanel-browser'
import {
  Badge,
  Box,
  CloseButton,
  Link,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
} from '@chakra-ui/react'
import {
  toLocalDate,
  toEpochMillisecond,
  toCurrency,
} from '../../../utils/formatter'

import {Alert} from '../../components'
import PaymentActivityHistory from '../../components/PaymentActivityHistory'
import InvoicesList from './InvoicesList'

const NoMatchWarning = ({
  matchFailureAt,
  passedBack,
  amount,
  customerName,
  receivedAt,
}) =>
  !passedBack &&
  !!matchFailureAt && (
    <Alert
      status="error"
      message={
        <>
          We couldn&apos;t match this payment. Please send the remittance to{' '}
          <Link
            onClick={() => {
              mixpanel.track(
                'Payment details banner clicked: Email remittance',
                {
                  amount,
                  customer: customerName,
                  dateReceived: receivedAt,
                },
              )
            }}
            href="mailto:accounts@withmarmalade.com"
            isExternal
            color="secondary.purple"
          >
            accounts@withmarmalade.com
          </Link>
        </>
      }
      mb="sm"
    />
  )

const PassedBackWarning = ({passedBack}) =>
  passedBack && (
    <Alert
      status="warning"
      message="A portion of this payment could not be matched and therefore passed to your bank account."
      mb="sm"
    />
  )

const CashedInWarning = ({cashedIn}) =>
  cashedIn && (
    <Alert
      status="cash-in"
      message="One or more invoices for this payment was cashed-in."
      mb="sm"
    />
  )

const ReimbursedWarning = ({
  reimbursementAmount,
  amount,
  customerName,
  receivedAt,
}) =>
  !!reimbursementAmount.cents && (
    <Alert
      status="warning"
      message={
        <>
          This payment could not be matched and your outstanding balance of{' '}
          <Text as="span" fontWeight="medium">
            {toCurrency(reimbursementAmount.cents)}{' '}
          </Text>
          was settled by reallocating this payment.{' '}
          <Link
            onClick={() => {
              mixpanel.track('Payment details banner clicked: SVP', {
                amount,
                customer: customerName,
                dateReceived: receivedAt,
              })
            }}
            href="https://marmalade.ladesk.com/715120-Why-do-I-have-an-Outstanding-Balance-owing-to-Marmalade"
            isExternal
            whiteSpace="nowrap"
            color="secondary.purple"
          >
            Find out more
          </Link>
        </>
      }
      mb="sm"
    />
  )

const PaymentDrawer = ({isOpen, onClose, payment}) => {
  const {data = {}} = useSWR(`/api/suppliers/payments/${payment.id}`)
  const {payment: rawPayment} = data

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="neutral.glitter"
          >
            <Text textStyle="body-intro-medium" fontSize="md">
              Payment Summary
            </Text>

            <CloseButton onClick={onClose} />
          </Box>
        </DrawerHeader>

        <DrawerBody overflowY="scroll">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="neutral.glitter"
            pb="md"
            mb="sm"
          >
            {payment.customerName ? (
              <Text textStyle="body-copy">{payment.customerName}</Text>
            ) : (
              <Badge
                bg="grey.100"
                color="grey.700"
                textTransform="none"
                fontWeight="normal"
                borderRadius="md"
              >
                New payment
              </Badge>
            )}
            <Text textStyle="body-intro-medium">{payment.amount}</Text>
          </Flex>

          {rawPayment && (
            <CashedInWarning
              cashedIn={rawPayment.cashedIn && !rawPayment.passedBack}
            />
          )}
          {rawPayment && (
            <NoMatchWarning
              matchFailureAt={rawPayment.matchFailureAt}
              passedBack={rawPayment.passedBack}
              amount={toCurrency(rawPayment.amount.cents)}
              customerName={rawPayment.customerName}
              receivedAt={toLocalDate(toEpochMillisecond(rawPayment.createdAt))}
            />
          )}
          {rawPayment && (
            <PassedBackWarning
              passedBack={rawPayment.passedBack && !rawPayment.cashedIn}
            />
          )}
          {rawPayment && (
            <ReimbursedWarning
              reimbursementAmount={
                rawPayment.supplierPayoutDetails.reimbursementAmount
              }
              amount={toCurrency(rawPayment.amount.cents)}
              customerName={rawPayment.customerName}
              receivedAt={toLocalDate(toEpochMillisecond(rawPayment.createdAt))}
            />
          )}

          <PaymentActivityHistory paymentId={payment.id} />

          {rawPayment && rawPayment.matchedInvoiceCount > 0 && (
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                borderTop="1px solid"
                borderColor="neutral.glitter"
                pt="md"
                mt="sm"
              >
                <Text
                  color="secondary.purple"
                  fontWeight="medium"
                  fontSize="sm"
                >
                  Payment for {rawPayment.matchedInvoiceCount}{' '}
                  {pluralize('invoice', rawPayment.matchedInvoiceCount)}
                </Text>
                {rawPayment.matchedInvoiceCount > 10 && (
                  <Link
                    href={`/invoices?payment_id=${payment.id}`}
                    color="secondary.purple"
                    fontWeight="medium"
                    fontSize="sm"
                  >
                    See all
                  </Link>
                )}
              </Flex>
              {rawPayment && rawPayment.matchedInvoiceCount <= 10 && (
                <InvoicesList paymentId={payment.id} />
              )}
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

NoMatchWarning.propTypes = {
  matchFailureAt: PropTypes.number.isRequired,
  passedBack: PropTypes.bool.isRequired,
  amount: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  receivedAt: PropTypes.string.isRequired,
}

PassedBackWarning.propTypes = {
  passedBack: PropTypes.bool.isRequired,
}

CashedInWarning.propTypes = {
  cashedIn: PropTypes.bool.isRequired,
}

ReimbursedWarning.propTypes = {
  reimbursementAmount: PropTypes.shape({
    cents: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  receivedAt: PropTypes.string.isRequired,
}

PaymentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    matchedAt: PropTypes.number.isRequired,
    customerName: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
}

export default PaymentDrawer
