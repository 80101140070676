import React from 'react'
import PropTypes from 'prop-types'

import withProviders from '../../../contexts/withProviders'
import AccountingProviderReconnect from './AccountingProviderReconnect'

const DisconnectionSupplierScreen = ({
  reauth,
  supplierNames,
  accountingProvider,
}) => {
  if (reauth !== true) {
    window.location.href = '/dashboard'
  }

  return (
    <AccountingProviderReconnect
      supplierNames={supplierNames}
      accountingProvider={accountingProvider}
    />
  )
}

DisconnectionSupplierScreen.propTypes = {
  reauth: PropTypes.bool.isRequired,
  supplierNames: PropTypes.string.isRequired,
  accountingProvider: PropTypes.string.isRequired,
}

export default withProviders(DisconnectionSupplierScreen)
