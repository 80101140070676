import React from 'react'
import PropTypes from 'prop-types'
import {Box, CircularProgress, AbsoluteCenter} from '@chakra-ui/react'

import CustomerPaymentBgImage from '../../../../assets/images/customer_payment_bg_screen.png'
import {LogoHorizontal, ThemeProvider} from '../../components'

const LoadingCustomerPaymentScreen = ({fetchUrl}) => {
  React.useEffect(() => {
    window.location.href = fetchUrl
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider>
      <Box
        width="100%"
        background={`url("${CustomerPaymentBgImage}")`}
        minHeight="100vh"
        px="sm"
        py="lg"
      >
        <Box
          padding="lg"
          backgroundColor="neutral.white"
          maxWidth="lg"
          margin="0 auto"
          borderRadius="lg"
          boxShadow="0px 2px 4px rgba(23, 27, 30, 0.1)"
        >
          <LogoHorizontal width={295} height={34} />

          <Box position="relative" h="200px">
            <AbsoluteCenter p="4" color="white" axis="both">
              <CircularProgress
                isIndeterminate
                value={30}
                color="orange"
                thickness="12px"
                data-testid="loading"
              />
            </AbsoluteCenter>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

LoadingCustomerPaymentScreen.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
}

export default LoadingCustomerPaymentScreen
