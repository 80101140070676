import {get} from 'lodash-es'
import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {Box, Text} from '@chakra-ui/react'

import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {Skeleton} from '../../../../components'
import SupplierLayout from '../../../../layouts/SupplierLayout'

import ReimbursementRequired from './ReimbursementRequired'
import OverdueInvoicesDetails from '../../../../components/OverdueInvoicesDetails'
import SupplierRewardsCard from './SupplierRewardsCard'
import GettingStartedCard from './GettingStartedCard'
import {AuthProvider} from '../../../../contexts/authentication'
import CashinStepper from '../../../../components/CashinStepper'
import UnpaidInvoicesCard from './UnpaidInvoicesCard'

const Baseline = ({authenticityToken}) => {
  const {
    isLoading: loadingFeatureData,
    data: {features},
  } = useFeatureFlags()
  const overdueInvoiceInfoEnabled = features && features.overdueInvoiceInfo
  const loadedFeatures = !loadingFeatureData && features
  const {organisation} = useRails()
  const historicalInvoicesFetchCompletedAt = get(
    organisation,
    'currentSupplier.historicalInvoicesFetchCompletedAt',
    false,
  )
  const daysPastMostOverdueInvoiceDueDate = get(
    organisation,
    'currentSupplier.daysPastMostOverdueInvoiceDueDate',
    0,
  )
  const showCashinStepper = get(
    organisation,
    'currentSupplier.showCashinStepper',
    false,
  )
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )

  const {data, error} = useSWR('/api/supplier_dashboard', fetcher)

  const loading = !data && !error

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  const amountOwedCents = get(
    data,
    'dashboard.reimbursement_required_at_cashin_amount.cents',
  )

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Dashboard">
        <Box
          as="main"
          flexGrow="1"
          backgroundColor="neutral.dust"
          py="sm"
          px="md"
          minHeight="100vh"
        >
          <Box maxWidth="1200px" margin="0 auto">
            <Box mb="md">
              <Skeleton isLoaded={!loading} minH="36px">
                <Text fontSize="lg" fontWeight="medium">
                  {`Welcome to Marmalade, ${get(
                    data,
                    'dashboard.supplier_display_name',
                  )}`}
                </Text>
              </Skeleton>
            </Box>

            {error &&
              error.message !== 'Unauthorized' &&
              error.name !== 'APINetworkError' && (
                <div>Error in retrieving dashboard information</div>
              )}
            {amountOwedCents > 0 &&
              !(
                daysPastMostOverdueInvoiceDueDate >= 25 &&
                loadedFeatures &&
                overdueInvoiceInfoEnabled
              ) && <ReimbursementRequired amountOwedCents={amountOwedCents} />}

            <OverdueInvoicesDetails allowClose />

            {!fixedPricingEnabled && (
              <GettingStartedCard showCashinStepper={showCashinStepper} />
            )}

            {showCashinStepper && (
              <Box py="sm">
                <CashinStepper />
              </Box>
            )}

            {historicalInvoicesFetchCompletedAt && <UnpaidInvoicesCard />}

            <SupplierRewardsCard />
          </Box>
        </Box>
      </SupplierLayout>
    </AuthProvider>
  )
}

Baseline.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default Baseline
