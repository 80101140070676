import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {toEpochMillisecond} from '../../../utils/formatter'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const dueMonthLabel = ({offset}) =>
  dayjs().subtract(offset, 'month').format('MMMM')

const dueDateFilter = (invoices, {offset, query, unit = 'day'}) => {
  const date = dayjs().subtract(offset, unit)

  return invoices?.filter((invoice) =>
    dayjs(toEpochMillisecond(invoice.dueDate))[query](date, unit),
  )
}

const dueDateBetweenFilter = (invoices, {start, end, unit = 'day'}) =>
  dueDateFilter(
    dueDateFilter(invoices, {offset: start, query: 'isSameOrBefore', unit}),
    {offset: end, query: 'isSameOrAfter', unit},
  )

export const tabsByMonthDue = (invoices) => [
  {label: 'All', invoices},
  {
    label: 'Current',
    invoices: dueDateFilter(invoices, {
      offset: 0,
      unit: 'month',
      query: 'isSame',
    }),
  },
  {
    label: dueMonthLabel({offset: 1}),
    invoices: dueDateFilter(invoices, {
      offset: 1,
      unit: 'month',
      query: 'isSame',
    }),
  },
  {
    label: dueMonthLabel({offset: 2}),
    invoices: dueDateFilter(invoices, {
      offset: 2,
      unit: 'month',
      query: 'isSame',
    }),
  },
  {
    label: dueMonthLabel({offset: 3}),
    invoices: dueDateFilter(invoices, {
      offset: 3,
      unit: 'month',
      query: 'isSame',
    }),
  },
  {
    label: 'Older',
    invoices: dueDateFilter(invoices, {
      offset: 4,
      unit: 'month',
      query: 'isSameOrBefore',
    }),
  },
]

export const tabsByDaysPastDue = (invoices) => [
  {label: 'All', invoices},
  {
    label: 'Not yet due',
    invoices: dueDateFilter(invoices, {offset: 0, query: 'isAfter'}),
  },
  {
    label: '0-30 Days',
    invoices: dueDateBetweenFilter(invoices, {start: 0, end: 30}),
  },
  {
    label: '31-60 Days',
    invoices: dueDateBetweenFilter(invoices, {start: 31, end: 60}),
  },
  {
    label: '61-90 Days',
    invoices: dueDateBetweenFilter(invoices, {start: 61, end: 90}),
  },
  {
    label: '90+ Days',
    invoices: dueDateFilter(invoices, {offset: 90, query: 'isBefore'}),
  },
]

export const tabsByDueStatus = (invoices) => [
  {label: 'All', invoices},
  {
    label: 'Not yet due',
    invoices: dueDateFilter(invoices, {offset: -3, query: 'isAfter'}),
  },
  {
    label: 'Due soon',
    invoices: dueDateBetweenFilter(invoices, {start: -3, end: 0}),
  },
  {
    label: 'Overdue',
    invoices: dueDateFilter(invoices, {offset: 0, query: 'isBefore'}),
  },
]
