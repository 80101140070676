import React from 'react'
import {Global} from '@emotion/react'
import {ChakraProvider} from '@chakra-ui/react'

import theme from '../../theme'

// React Toastify styling
import 'react-toastify/dist/ReactToastify.css'

// React date picker styling
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-slider/assets/index.css'

/* eslint react/prop-types: 0 */
const ThemeProvider = ({children}) => (
  <ChakraProvider theme={theme}>
    <Global
      styles={{
        '.selected-date': {
          backgroundColor: theme.colors.primary.actionblue,
          color: theme.colors.neutral.white,
        },
        '.Toastify__toast--dark': {
          backgroundColor: '#302b3a',
        },
        '.Toastify__toast-container': {
          width: '440px',
        },
        '.Toastify__toast': {
          borderRadius: '4px',
          minHeight: '56px',
          boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.25)',
        },
      }}
    />
    {children}
  </ChakraProvider>
)

export function withTheme(Component) {
  return (props) => (
    <ThemeProvider>
      <Component {...props} />
    </ThemeProvider>
  )
}

export default ThemeProvider
