import useSWRInfinite from 'swr/infinite'
import {fetcher} from '../../api'
import {camelizeResult, dineroMoneyObjects} from '../../api/serializer'

const DEFAULT_ITEMS_PER_PAGE = 10

// This hook utilises SWR's useSWRInfinite hook to fetch, cache and revalidate multiple pages for an endpoint.
// The hook accepts an API endpoint (without queryParams) and a query param object that will be merged with the pagination params.
// For more information on useSWRInfinite See here https://swr.vercel.app/docs/pagination
const usePaginatedSWR = ({
  endpoint,
  queryParams = {},
  perPage = DEFAULT_ITEMS_PER_PAGE,
  swrOptions = {},
}) => {
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && previousPageData.invoices.length < perPage) {
      return null // reached the end
    }

    const searchParams = new URLSearchParams({
      page: pageIndex + 1,
      per_page: perPage,
      ...queryParams,
    })

    return `${endpoint}?${searchParams}`
  }

  const {
    data, // an array of fetch response values of each page
    error, // same as useSWR's error
    isLoading, // same as useSWR's isLoading
    isValidating, // same as useSWR's isValidating
    mutate, // same as useSWR's bound mutate function but manipulates the data array
    size, // the number of pages that will be fetched and returned
    setSize, // set the number of pages that need to be fetched
  } = useSWRInfinite(getKey, fetcher, {
    // This will prevent next page request re-requesting every page.
    // Note calling mutate will still revalidate all fetched pages.
    revalidateAll: false,
    // Fetching the next page will not also fetch the first page.
    // This option is mainly useful for cursor-based pagination.
    revalidateFirstPage: false,
    refreshInterval: 0,
    use: [camelizeResult, dineroMoneyObjects],
    ...swrOptions,
  })

  const hasNextPage = isLoading || !!(data && data[size - 1]?.invoices?.length)

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    size,
    setSize,
    hasNextPage,
  }
}

export default usePaginatedSWR
