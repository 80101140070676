import React from 'react'
import PropTypes from 'prop-types'
import {
  CloseButton,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import LearnMoreView from '../../../../../components/AddSupplierBankAccountIntegrationModal/Views/LearnMoreView'

const LearnMoreModal = ({isOpen, onClose, onBankConnectionModalOpen}) => {
  const {t} = useTranslation('onboarding')
  const startConnecting = () => {
    onClose()
    onBankConnectionModalOpen()
  }

  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onClose} preserveScrollBarGap>
      <ModalOverlay zIndex="modal" />
      <ModalContent borderRadius="lg" py="sm">
        <ModalHeader fontSize="lg" py="sm" mx="xs">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <Text textStyle="headline-4" textTransform="uppercase">
              {t(
                'onboarding:BankAccountIntegration.welcomeScreen.learnMoreModalTitle',
              )}
            </Text>
            <CloseButton
              onClick={onClose}
              data-testid="close-button"
              size="md"
              pb={0}
            />
          </HStack>
        </ModalHeader>
        <ModalBody px="6" mx="xs">
          <LearnMoreView
            isLoading={false}
            startConnecting={startConnecting}
            tokenLoaded
            onboardingFlow
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

LearnMoreModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onBankConnectionModalOpen: () => {},
}

LearnMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onBankConnectionModalOpen: PropTypes.func,
}

export default LearnMoreModal
