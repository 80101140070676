import React from 'react'
import {SWRConfig} from 'swr'

import {fetcher} from '../../api'

const withSWR = (Component) => (props) => {
  return (
    <SWRConfig
      value={{
        // Interval at 15s
        refreshInterval: 15000,
        fetcher,
      }}
    >
      <Component {...props} />
    </SWRConfig>
  )
}

export default withSWR
