import React from 'react'
import PropTypes from 'prop-types'
import {Button, Flex} from '@chakra-ui/react'
import {fetcher, defaultHeaders} from '../../../../../api'
import {useRails} from '../../../../contexts/rails'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import useFeatureCookie from '../../../../hooks/useFeatureCookie'
import ViewDetailsButton from './ViewDetailsButton'

const afterCashinActions = (authenticityToken) => {
  const opts = {method: 'PUT', headers: defaultHeaders({authenticityToken})}

  // Manual trigger of mouseflow feedback
  fetcher('/api/mouseflow_feedbacks', opts).then((data) => {
    if (data.feedbackId) {
      window._mfq = window._mfq || [] // eslint-disable-line no-underscore-dangle
      window._mfq.push(['activateFeedback', data.feedbackId]) // eslint-disable-line no-underscore-dangle
    }
  })
}

const CashInButtonCell = ({row}) => {
  const {organisation, authenticityToken} = useRails()
  const [processing, setProcessing] = React.useState(false)
  const [processingState, setProcessingState] = React.useState(null)
  const [drawerOpenedExternally, setDrawerOpenedExternally] =
    React.useState(false)

  const {
    actionStarted,
    actionFinished,
    cashinDisabled: invoiceCashinDisabled,
    reimbursementRequiredAmount,
  } = row.original
  const [skipReimbursementConfirmation] = useFeatureCookie(
    'skip_reimbursement_confirmation',
  )

  const cashinDisabled =
    invoiceCashinDisabled ||
    organisation.currentSupplier.cashinDisabled ||
    !reimbursementRequiredAmount

  const cashinInvoice = (invoiceId) => {
    setProcessing(true)
    setProcessingState(null)
    actionStarted(invoiceId)

    fetcher(
      `/api/suppliers/${organisation.currentSupplier.id}/invoices/${invoiceId}/cash_in`,
      {
        method: 'POST',
        headers: defaultHeaders({authenticityToken}),
      },
    )
      .then(() => {
        setProcessingState('success')
        actionFinished(invoiceId)
        afterCashinActions(authenticityToken)
      })
      .catch(() => {
        setProcessingState('failed')
        actionFinished(invoiceId)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <Flex direction={{base: 'column', md: 'row'}}>
      <Button
        variant="secondary"
        onClick={() => {
          if (
            reimbursementRequiredAmount.getAmount() > 0 &&
            !skipReimbursementConfirmation
          ) {
            setDrawerOpenedExternally(true)
          } else {
            cashinInvoice(row.original.id)
          }
        }}
        width={{base: '100%', md: 'auto'}}
        isDisabled={cashinDisabled || processing || processingState}
        isLoading={processing}
        loadingText="Cashing-in"
      >
        {processingState === 'success' && 'Cashed-in'}
        {processingState === 'failed' && 'Cash-in failed'}
        {processingState === null &&
          `Cash-in ${row.original.payoutAmount.toFormat()} now`}
      </Button>
      <ViewDetailsButton
        row={row}
        drawerOpenedExternally={drawerOpenedExternally}
        setDrawerOpenedExternally={setDrawerOpenedExternally}
        cashinInvoice={cashinInvoice}
        cashinProcessing={processing}
        cashinProcessingState={processingState}
      />
    </Flex>
  )
}

CashInButtonCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      reimbursementRequiredAmount: PropTypes.shape(DineroPropShape).isRequired,
      payoutAmount: PropTypes.shape(DineroPropShape).isRequired,
      cashinDisabled: PropTypes.bool.isRequired,
      actionStarted: PropTypes.func.isRequired,
      actionFinished: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CashInButtonCell
