import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import mixpanel from 'mixpanel-browser'
import {Icon, Badge, Link, Text, Flex, Tooltip} from '@chakra-ui/react'
import pluralize from 'pluralize'
import {CheckCircleIcon, WarningTwoIcon} from '@chakra-ui/icons'
import {toCurrency} from '../../../utils/formatter'
import {SubdirectoryArrowRightIcon, AltCheckIcon} from '../../icons'

const InvoiceItem = ({
  invoiceId,
  invoiceNumber,
  icon,
  badgeIcon,
  badgeText,
  badgeBackgroundColour,
  badgeColour,
  amount,
}) => {
  return (
    <Flex justifyContent="space-between" mt="sm">
      <Flex>
        {invoiceId && (
          <Link
            onClick={() => {
              mixpanel.track('Invoice via Payments page')
            }}
            href={`/invoices?invoice_id=${invoiceId}`}
            target="_blank"
            fontSize="sm"
            mr="xs"
            textDecoration="underline"
          >
            {icon}
            {invoiceNumber}
          </Link>
        )}
        {!invoiceId && (
          <Text fontSize="sm" mr="xs">
            {invoiceNumber}
          </Text>
        )}
        {badgeIcon && (
          <Badge
            bg={badgeBackgroundColour}
            border="none"
            borderRadius="md"
            fontWeight="normal"
            px="1"
            textTransform="none"
          >
            <Icon
              as={badgeIcon}
              w={3}
              h={3}
              mb={0.5}
              mr="xxs"
              bg="transparent"
              color={badgeColour}
            />
            {badgeText}
          </Badge>
        )}
      </Flex>
      <Text fontSize="sm">{amount}</Text>
    </Flex>
  )
}

InvoiceItem.defaultProps = {
  invoiceId: null,
  icon: null,
  badgeIcon: null,
}

InvoiceItem.propTypes = {
  invoiceId: PropTypes.string,
  invoiceNumber: PropTypes.string.isRequired,
  icon: PropTypes.node,
  badgeIcon: PropTypes.node,
  badgeText: PropTypes.string.isRequired,
  badgeBackgroundColour: PropTypes.string.isRequired,
  badgeColour: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
}

const InvoicesList = ({paymentId}) => {
  const {data = {}} = useSWR(`/api/suppliers/payments/${paymentId}`)
  const {payment: rawPayment} = data

  const {data: invoiceData = {}} = useSWR(
    `/api/suppliers/payments/${paymentId}/invoices`,
  )

  const badgeBuilder = (invoicePayment, payment) => {
    const badge = {
      badgeIcon: '',
      badgeText: '',
      badgeBackgroundColour: '',
      badgeColour: '',
    }

    if (payment.passedBack) {
      badge.badgeIcon = WarningTwoIcon
      badge.badgeText = 'Outstanding balance settled'
      badge.badgeBackgroundColour = 'background.orange'
      badge.badgeColour = 'primary.orange'
    } else if (invoicePayment.cashedIn) {
      badge.badgeIcon = CheckCircleIcon
      badge.badgeText = 'Cashed-In'
      badge.badgeBackgroundColour = 'background.green'
      badge.badgeColour = 'secondary.green'
    }

    return badge
  }

  return (
    <>
      {rawPayment && rawPayment.passbackAmount.cents > 0 && (
        <InvoiceItem
          invoiceNumber="Passed Back"
          badgeIcon={WarningTwoIcon}
          badgeText="Overpayment"
          badgeBackgroundColour="background.orange"
          badgeColour="primary.orange"
          amount={toCurrency(rawPayment.passbackAmount.cents)}
        />
      )}

      {invoiceData.invoicePayments &&
        invoiceData.invoicePayments.map((invoicePayment) => {
          const badge = badgeBuilder(invoicePayment, rawPayment)

          return (
            <>
              <InvoiceItem
                invoiceId={invoicePayment.invoiceId}
                invoiceNumber={invoicePayment.invoiceNumber}
                icon={
                  invoicePayment.paid && (
                    <Tooltip
                      label="Fully paid invoice"
                      zIndex="tooltip"
                      placement="right"
                    >
                      <AltCheckIcon boxSize="4" mr="xxs" />
                    </Tooltip>
                  )
                }
                badgeIcon={badge.badgeIcon}
                badgeText={badge.badgeText}
                badgeBackgroundColour={badge.badgeBackgroundColour}
                badgeColour={badge.badgeColour}
                amount={toCurrency(invoicePayment.amount.cents)}
              />

              {invoicePayment.additionalPaymentAmount.cents > 0 && (
                <Flex justifyContent="space-between" mt="xs" mb="sm">
                  <Flex>
                    <SubdirectoryArrowRightIcon
                      boxSize="4"
                      color="grey.500"
                      mr="xs"
                    />
                    <Text fontSize="sm" mr="xs" opacity="0.5">
                      {invoicePayment.invoiceNumber}
                    </Text>
                    <Badge
                      bg="neutral.smoke"
                      border="none"
                      borderRadius="md"
                      fontWeight="normal"
                      px="1"
                      textTransform="none"
                    >
                      {invoicePayment.additionalPaymentCount} Additional{' '}
                      {pluralize(
                        'payment',
                        invoicePayment.additionalPaymentCount,
                      )}
                    </Badge>
                  </Flex>
                  <Text fontSize="sm" opacity="0.5">
                    {toCurrency(invoicePayment.additionalPaymentAmount.cents)}
                  </Text>
                </Flex>
              )}

              {invoicePayment.reimbursementPayments.map(
                (reimbursementPayment) => (
                  <InvoiceItem
                    invoiceId={reimbursementPayment.invoiceId}
                    invoiceNumber={reimbursementPayment.invoiceNumber}
                    badgeIcon={WarningTwoIcon}
                    badgeText="Outstanding balance settled"
                    badgeBackgroundColour="background.orange"
                    badgeColour="primary.orange"
                    amount={`-${toCurrency(reimbursementPayment.amount.cents)}`}
                  />
                ),
              )}
            </>
          )
        })}
    </>
  )
}

InvoicesList.propTypes = {
  paymentId: PropTypes.string.isRequired,
}

export default InvoicesList
