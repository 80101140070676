import PropTypes from 'prop-types'

const commonProps = {
  id: PropTypes.string.isRequired,
  paymentMethodableId: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    'created',
    'processing',
    'active',
    'failed',
    'paused',
    'cancelled',
  ]).isRequired,
}

const debitAgreementPropType = PropTypes.shape({
  type: PropTypes.oneOf(['debit_agreement']).isRequired,
  debitAgreement: PropTypes.shape({
    ...commonProps,
    startDate: PropTypes.string.isRequired,
    expiryDate: PropTypes.string.isRequired,
    branchCode: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    paymentNetwork: PropTypes.string.isRequired,
  }).isRequired,
})

const paymentMethodPropType = PropTypes.oneOfType([debitAgreementPropType])

export default paymentMethodPropType
