import React, {Suspense} from 'react'
import PropTypes from 'prop-types'
import {isEmpty, isString} from 'lodash-es'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

function withRails(Component) {
  const WithRailsComponent = ({children}) => children
  WithRailsComponent.displayName = `WithRails(${getDisplayName(Component)}`
  WithRailsComponent.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  return (props) => (
    <WithRailsComponent>
      <RailsProvider {...props}>
        <Component {...props} />
      </RailsProvider>
    </WithRailsComponent>
  )
}

const RailsContext = React.createContext()

/*

  Should be refactored out into an endpoint.
  For now, redirect/full refresh on client to see xero details within the provider.

  e.g.

  GET /api/bank_accounts?selected=true ... [{ id: 'foobar', name: 'account name'}]
  GET /api/integrations/xero/status   ... { isLinked: true, isAccountSelected: true }

*/

function getXero({
  xeroLinked,
  selectedBankAccountUUID,
  selectedExpenseAccountUUID,
}) {
  return {
    isLinked: !!xeroLinked,
    isBankAccountSelected: !!selectedBankAccountUUID,
    isExpenseAccountSelected: !!selectedExpenseAccountUUID,
    bankAccountId: selectedBankAccountUUID,
    expenseAccountId: selectedExpenseAccountUUID,
  }
}

function getCompliance({
  verificationStatus,
  displayVerificationStatus,
  businessIndustry,
  relationshipToBusiness,
  abn,
  organisationName,
}) {
  const isQuestionnaireAnswered = !isEmpty(abn) && isString(abn)

  return {
    verificationStatus,
    isQuestionnaireAnswered,
    displayVerificationStatus,
    businessIndustry,
    relationshipToBusiness,
    abn,
    organisationName,
  }
}

function getRailsState({errors, authenticityToken}) {
  return {
    railsErrors: errors,
    authenticityToken,
  }
}

/*
  Resources
*/
function getUserResource({resource}) {
  if (resource) {
    return {
      first_name: resource.first_name,
      last_name: resource.last_name,
      phone_number: resource.phone_number,
      email: resource.email,
      signup_abn: resource.signup_abn,
      signup_abn_verified: resource.signup_abn_verified,
      signup_business_industry: resource.signup_business_industry,
      signup_organisation_name: resource.signup_organisation_name,
    }
  }
  return {}
}

function getOrganisationDetails(data) {
  const {availableSuppliers, currentSupplier} = data

  return {
    availableSuppliers,
    currentSupplier,
  }
}

function getAccountingProvider(data) {
  if (data.currentSupplier) {
    return data.currentSupplier.accountingProvider
  }
  return {}
}

function useRails() {
  const context = React.useContext(RailsContext)

  if (!context) {
    throw new Error('useRails must be used within a RailsProvider')
  }

  const [data] = context

  const result = React.useMemo(() => {
    const {railsErrors, authenticityToken} = getRailsState(data)

    return {
      uniqueUserFeatureCookiePrefix: data.uniqueUserFeatureCookiePrefix,
      railsErrors,
      authenticityToken,
      directUploadsUrl: data.directUploadsUrl,
      disableMixpanel: data.disableMixpanel,
      accountingProvider: getAccountingProvider(data),
      xero: getXero(data),
      compliance: getCompliance(data),
      organisation: getOrganisationDetails(data),
      resource: {
        user: getUserResource(data),
        name: data.resourceName || '',
        signUpPath: data.signUpPath || '',
      },
      features: data.features || {},
      authorisedOrganisations: data.authorisedOrganisations,
    }
  }, [data])

  return result
}

function RailsProvider({children, ...props}) {
  const value = React.useMemo(() => [props], [props])

  return (
    <RailsContext.Provider value={value} {...props}>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </RailsContext.Provider>
  )
}

RailsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export {withRails, RailsProvider, useRails}
