import React from 'react'
import PropTypes from 'prop-types'
import {Text, Flex, Link, Button} from '@chakra-ui/react'

const FailureView = ({onClose, startConnecting}) => {
  return (
    <>
      <Text textStyle="body-intro-medium">Your account is not connected</Text>
      <Text>
        Something went wrong while we tried to connect your bank account. Please
        try again or contact{' '}
        <Link
          color="primary.actionblue"
          href="mailto:support@withmarmalade.com"
        >
          Customer Support
        </Link>
        .
      </Text>

      <Flex pt="sm" justifyContent="center">
        <Button variant="primary" minWidth="70%" onClick={startConnecting}>
          Try again
        </Button>
      </Flex>

      <Flex mb="sm" justifyContent="center">
        <Button
          variant="secondary"
          minWidth="70%"
          onClick={onClose}
          data-testid="close-button"
        >
          Close
        </Button>
      </Flex>
    </>
  )
}

FailureView.propTypes = {
  onClose: PropTypes.func.isRequired,
  startConnecting: PropTypes.func.isRequired,
}

export default FailureView
