/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types'

export const columnsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    accessorKey: PropTypes.string,
    accessorFn: PropTypes.func,
    cell: PropTypes.func,
  }),
)

export const sortByOptionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired,
  }),
)
