import APINetworkError from './APINetworkError'

const defaultHeaders = ({authenticityToken}) => {
  return {
    'X-CSRF-Token': authenticityToken,
    'Content-Type': 'application/json',
  }
}

const getHeaders = () => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
}

const bodyHeaders = ({
  authenticityToken,
  raiseClientErrors = true,
  body,
  method,
}) => {
  return {
    method,
    headers: defaultHeaders({authenticityToken}),
    body: JSON.stringify(body),
    raiseClientErrors,
  }
}

const postHeaders = (params) => bodyHeaders({...params, method: 'POST'})

const patchHeaders = (params) => bodyHeaders({...params, method: 'PATCH'})

const putHeaders = (params) => bodyHeaders({...params, method: 'PUT'})

const deleteHeaders = (params) => bodyHeaders({...params, method: 'DELETE'})

const fetcher = async (url, {raiseClientErrors = true, ...options} = {}) => {
  try {
    const res = await fetch(url, options)

    const result = await res.json()

    const raisedResponseCodes = raiseClientErrors ? 399 : 499

    if (res.status > raisedResponseCodes) {
      const err = new Error(
        result.error ? result.error.message : res.statusText,
      )
      err.status = res.status
      throw err
    }

    return result
  } catch (e) {
    // This would normally throw TypeError which mostly relates to
    // - CORS (less likely to occur)
    // - Network request abortion during page transition (big possibility when using SWR)
    // Hence, adding custom error implementation for distinct error handling
    if (e.name === 'SyntaxError') {
      throw new APINetworkError('An error occurred.')
    } else {
      throw new APINetworkError(e.message)
    }
  }
}

export {
  fetcher,
  defaultHeaders,
  postHeaders,
  patchHeaders,
  deleteHeaders,
  putHeaders,
  getHeaders,
}
