import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Divider, Image} from '@chakra-ui/react'

import OskoLogo from '../../../../assets/images/osko_logo.png'

const PaymentBankTransfer = ({
  paymentReference,
  accountName,
  accountNumber,
  bsb,
  payid,
}) => {
  return (
    <Box mt="sm" ml="36px">
      <Box mb="sm">
        <Text>{accountName}</Text>
        <Text color="neutral.60">BSB: {bsb}</Text>
        <Text color="neutral.60">Acc No: {accountNumber}</Text>
      </Box>
      <Box maxWidth="250px">
        <Text>
          Please use your supplier code and invoice number as reference:
        </Text>
        <Text fontWeight="medium">{paymentReference}</Text>
      </Box>

      {payid && (
        <>
          <Divider my="sm" />

          <Image w="100px" h="auto" src={OskoLogo} alt="Osko Logo" mb="sm" />

          <Box>
            <Box maxWidth="250px" mb="sm">
              <Text>
                Please use your supplier code and invoice number as reference:
              </Text>
              <Text fontWeight="medium">{paymentReference}</Text>
            </Box>

            <Text mb="sm">
              Make bank transfers in less than a minute. Head to the ‘Pay
              Anyone’ section in your Internet banking or mobile banking app.
            </Text>
            <Text fontWeight="medium">PayID: {payid}</Text>
          </Box>
        </>
      )}
    </Box>
  )
}

PaymentBankTransfer.propTypes = {
  paymentReference: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  bsb: PropTypes.string.isRequired,
  payid: PropTypes.string,
}

PaymentBankTransfer.defaultProps = {
  payid: null,
}

export default PaymentBankTransfer
