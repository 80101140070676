import {colors} from '../foundations/colors'

export default {
  variants: {
    primary: {
      textStyle: 'body-small',
      width: 'fit-content',
      color: colors.mld.neutral[900],
      borderBottom: '1px',
      borderColor: colors.mld.neutral[900],
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
        borderColor: colors.mld.primary[100],
      },
      _focus: {
        textDecoration: 'none',
        borderColor: colors.mld.primary[100],
      },
      _active: {
        textDecoration: 'none',
        color: colors.mld.primary[100],
        borderColor: colors.mld.primary[100],
      },
    },
  },
}
