/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useSWR, {mutate} from 'swr'
import {camelizeKeys} from 'humps'
import {
  Box,
  Flex,
  Divider,
  Button,
  Grid,
  GridItem,
  Link,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {useRails} from '../../../contexts/rails'
import {fetcher} from '../../../../api'
import AddSupplierBankAccountIntegrationModal from '../../../components/AddSupplierBankAccountIntegrationModal'

const BankProviderConnectionSettings = () => {
  const {organisation} = useRails()
  const BANK_ACCOUNT_ENDPOINT = `/api/suppliers/${organisation.currentSupplier.id}/banking_providers/bank_accounts`
  const {data, error} = useSWR(BANK_ACCOUNT_ENDPOINT, fetcher)
  const loading = !data && !error
  const {accounts: providerAccounts} = camelizeKeys(data || {})
  const bankIntegrationConnected = !!(
    providerAccounts && providerAccounts.length
  )

  const {isOpen, onClose, onOpen} = useDisclosure()
  const [openOnLearnMore, setOpenOnLearnMore] = React.useState(false)
  const [yodleeProviderAccountId, setYodleeProviderAccountId] =
    React.useState(null)
  const [yodleeFlow, setYodleeFlow] = React.useState(null)
  const [connectionPending, setConnectionPending] = React.useState(null)

  const openAddBankConnectionModal = ({learnMore = false} = {}) => {
    setOpenOnLearnMore(learnMore)
    onOpen()
  }

  const handleClose = () => {
    setYodleeProviderAccountId(null)
    setYodleeFlow(null)
    onClose()
    setConnectionPending(true)
    mutate(BANK_ACCOUNT_ENDPOINT, undefined, true)
  }

  if (loading) {
    return (
      <Skeleton data-testid="bank-providers-skeleton-loader" minHeight="40px" />
    )
  }

  return (
    <Flex align="flex-start" mb="sm" minHeight="40px">
      <Box width="320px">
        <Flex direction="column">
          <Text mb="xs">Connected accounts</Text>
          {!bankIntegrationConnected && (
            <Text textStyle="body-detail">
              If you wish to disconnect
              <br />
              please contact
              <Link
                as="a"
                pl="xxs"
                href="mailto:support@withmarmalade.com"
                color="secondary.purple"
              >
                Customer Support
              </Link>
            </Text>
          )}
          {bankIntegrationConnected && (
            <Text textStyle="body-detail">
              <Link
                href="#"
                color="secondary.purple"
                onClick={() => openAddBankConnectionModal({learnMore: true})}
              >
                How does it work?
              </Link>
            </Text>
          )}
        </Flex>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        alignSelf="flex-start"
        justifyContent="space-between"
        width="calc(100% - 320px)"
        id="banking_provider_connection"
      >
        {!connectionPending && !bankIntegrationConnected && (
          <Text fontWeight="medium">Not connected</Text>
        )}
        {connectionPending && !bankIntegrationConnected && (
          <Text fontWeight="medium">Pending...</Text>
        )}
        {bankIntegrationConnected && (
          <Grid>
            {providerAccounts.map(
              ({providerName, providerAccountId, bankAccounts}, index) => {
                const openEditBankConnectionModal = ({
                  learnMore = false,
                } = {}) => {
                  setYodleeFlow('edit')
                  setYodleeProviderAccountId(providerAccountId)
                  setOpenOnLearnMore(learnMore)
                  onOpen()
                }

                return (
                  <>
                    {index !== 0 && <Divider variant="dark" my="sm" />}
                    <Grid templateColumns="repeat(3, 1fr)" gap="sm">
                      <GridItem>
                        <Text>{providerName}</Text>
                      </GridItem>
                      <GridItem>
                        {bankAccounts.map(({accountNumber}) => (
                          <Text key={accountNumber} fontWeight="medium">
                            {accountNumber}
                          </Text>
                        ))}
                      </GridItem>
                      <GridItem>
                        <Link ml="sm" onClick={openEditBankConnectionModal}>
                          Edit
                        </Link>
                      </GridItem>
                    </Grid>
                  </>
                )
              },
            )}
          </Grid>
        )}
        <AddSupplierBankAccountIntegrationModal
          isOpen={isOpen}
          onClose={handleClose}
          openOnLearnMore={openOnLearnMore}
          yodleeFlow={yodleeFlow}
          providerAccountId={yodleeProviderAccountId}
        />
        <Button variant="secondary" onClick={openAddBankConnectionModal}>
          Add Additional Accounts
        </Button>
      </Box>
    </Flex>
  )
}

export default BankProviderConnectionSettings
