import React from 'react'
import useSWR from 'swr'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {
  Text,
  Button,
  Flex,
  Box,
  Skeleton,
  Card,
  Stack,
  CardBody,
} from '@chakra-ui/react'
import {CheckIcon} from '@chakra-ui/icons'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'
import {useRails} from '../../../../../contexts/rails'
import {fetcher, logBankIntegrationEvent} from '../../../../../../api'
import {ArrowRightIcon} from '../../../../../icons'

const Success = ({goToNextPage}) => {
  const {t} = useTranslation('onboarding')
  const i18nKeyPrefix = 'onboarding:BankAccountIntegration.successScreen'
  const {organisation, authenticityToken} = useRails()
  const [dataFetched, setDataFetched] = React.useState(false)
  const [dataNotFound, setDataNotFound] = React.useState(false)
  const [currentTimeoutId, setCurrentTimeoutId] = React.useState(null)
  const BANK_ACCOUNT_ENDPOINT = `/api/suppliers/${organisation.currentSupplier.id}/banking_aggregators/bank_accounts`
  const {data, error, isLoading} = useSWR(BANK_ACCOUNT_ENDPOINT, fetcher, {
    use: [camelizeResult, dineroMoneyObjects],
    refreshInterval: 5000,
    isPaused: () => {
      return dataFetched || dataNotFound
    },
  })
  const {accounts: bankAccounts} = data || {}

  React.useEffect(() => {
    if (currentTimeoutId && bankAccounts && bankAccounts.length) {
      clearTimeout(currentTimeoutId)
    }
    if (currentTimeoutId) {
      return
    }
    const timeoutId = setTimeout(() => {
      setDataNotFound(true)
    }, 60000)
    setCurrentTimeoutId(timeoutId)
  }, [bankAccounts, currentTimeoutId])

  React.useEffect(() => {
    if (error) {
      setDataNotFound(true)
    }
  }, [error])

  React.useEffect(() => {
    if (bankAccounts && bankAccounts.length) {
      setDataFetched(true)
    }
  }, [bankAccounts])

  React.useEffect(() => {
    if (dataNotFound) {
      logBankIntegrationEvent({
        authenticityToken,
        eventType: 'onDataLoad',
        eventBody: 'Unable to load bank account data',
        supplierId: organisation.currentSupplier.id,
      })
    }
  }, [dataNotFound, authenticityToken, organisation.currentSupplier.id])

  return (
    <Flex direction="column" gap="md">
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="1"
        lineHeight="1"
        fontWeight="400"
      >
        {t(`${i18nKeyPrefix}.title`)}
      </Text>
      <Text textStyle="body-intro">{t(`${i18nKeyPrefix}.message`)}</Text>
      {dataNotFound && (
        <Text textStyle="header5" pb="xs">
          {t(`${i18nKeyPrefix}.notFoundMessage`)}
        </Text>
      )}
      {!dataNotFound && (
        <Flex direction="column" gap="xs">
          <Text textStyle="header5" textTransform="uppercase" pb="xs">
            {t(`${i18nKeyPrefix}.accountsSubtitle`)}
          </Text>
          {isLoading || !bankAccounts || bankAccounts.length === 0 ? (
            <Stack gap="sm">
              <Skeleton height="64px" />
            </Stack>
          ) : (
            <>
              {bankAccounts.map(({accountName, accountNumber}) => {
                return (
                  <Card
                    key={accountNumber}
                    variant="outline"
                    size="sm"
                    borderRadius="lg"
                  >
                    <CardBody>
                      <Flex justifyContent="space-between" align="center">
                        <Stack>
                          <Text textStyle="body-small-medium">
                            {accountName}
                          </Text>
                          <Text textStyle="body-small" color="mld.neutral.700">
                            {t(`${i18nKeyPrefix}.accountItemNumber`, {
                              accountNumber,
                            })}
                          </Text>
                        </Stack>
                        <Flex
                          width="24px"
                          height="24px"
                          borderRadius="full"
                          bg="mld.primary.200"
                          justify="center"
                          align="center"
                        >
                          <CheckIcon />
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                )
              })}
              <Text textStyle="body-detail" textAlign="end">
                {t(`${i18nKeyPrefix}.accountItemsFooter`)}
              </Text>
            </>
          )}
        </Flex>
      )}
      <Box>
        <Button
          variant="primary"
          colorScheme="primary"
          size="lg"
          onClick={goToNextPage}
        >
          {t(`${i18nKeyPrefix}.ctaButton`)}
          <ArrowRightIcon width="24px" height="24px" ml="xs" />
        </Button>
      </Box>
    </Flex>
  )
}

Success.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
}

export default Success
