import constructFilterDetails from './constructFilterDetails'

const preFilterQuery = (queryParams) => {
  const searchParams = new URLSearchParams(queryParams)

  const filterKeys = ['customer_id', 'include_no_outstanding']

  let filterOption = {}

  filterKeys.forEach((key) => {
    if (searchParams.has(key)) {
      if (key === 'customer_id') {
        filterOption = {
          ...filterOption,
          customer: {
            value: searchParams.get(key),
          },
        }
      }
      if (key === 'include_no_outstanding') {
        filterOption = {
          ...filterOption,
          includeNoOutstanding: searchParams.get(key) === 'true',
        }
      }
    }
  })

  return constructFilterDetails(filterOption)
}

export default preFilterQuery
