import React from 'react'
import mixpanel from 'mixpanel-browser'
import {Box, Button, Text, Tooltip} from '@chakra-ui/react'
import {toast} from 'react-toastify'

import StepTitle from './StepTitle'
import {ToastMessage} from '../../Toast'

import {useRails} from '../../../contexts/rails'
import {requestVerification} from '../../../../api'

export const stepName = 'idVerification'

const recordTooltipMixpanelEvent = () => {
  mixpanel.track('Tooltip: Complete ID verification')
}

const recordVerifyMixpanelEvent = () => {
  mixpanel.track('Modal: Verify ID now')
}

const calculateStatus = ({verificationStatus}) => {
  if (verificationStatus === 'verified') {
    return 'completed'
  }
  if (verificationStatus === 'pending') {
    return 'reviewing'
  }

  return 'incomplete'
}

export default (stepperData) => {
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
    authenticityToken,
  } = useRails()
  const [buttonLabel, setButtonLabel] = React.useState('Verify')
  const isCurrentStep = stepperData.currentStep === stepName
  const {canVerifyIdentity} = stepperData
  const canVerifyIdentityMessage = canVerifyIdentity
    ? ''
    : 'You can verify you ID once we have processed your accounting data. Check back soon.'
  const handleVerifyClick = () => {
    setButtonLabel('Pending...')
    recordVerifyMixpanelEvent()

    requestVerification({authenticityToken, supplierId})
      .then(() => {
        toast.dark(
          <ToastMessage>
            FirstAML has sent you an email to start the KYC process.
          </ToastMessage>,
        )
      })
      .catch(() => {
        toast.error(
          <ToastMessage>
            Something went wrong. Please try again or contact support.
          </ToastMessage>,
        )
      })
  }
  const isPending = buttonLabel === 'Pending...'

  return {
    name: stepName,
    visible: true,
    status: calculateStatus(stepperData),
    icon: (
      <Text color="inherit" textStyle="body-copy">
        {stepperData.showingBankConnectionStep ? 2 : 1}
      </Text>
    ),
    title: (
      <StepTitle
        title="Verify ID"
        onTooltipOpen={recordTooltipMixpanelEvent}
        tooltipParagraphs={[
          'This is the essential step before you can Cash-in your invoices. You can continue your ID verification on FirstAML, or contact Customer Support to complete it manually.',
        ]}
      />
    ),
    action: canVerifyIdentity ? (
      <Box>
        <Button
          variant="primary"
          isDisabled={!isCurrentStep || isPending}
          size="sm"
          onClick={handleVerifyClick}
        >
          {buttonLabel}
        </Button>
      </Box>
    ) : (
      <Tooltip
        label={canVerifyIdentityMessage}
        zIndex="tooltip"
        placement="left"
        bg="neutral.white"
        width="300px"
        color="grey.700"
        fontSize="xs"
        boxShadow="lg"
      >
        <Box data-testid="id-verification-tooltip">
          <Button variant="primary" isDisabled size="sm">
            Verify
          </Button>
        </Box>
      </Tooltip>
    ),
  }
}
