import {Field, useFormikContext} from 'formik'
import React from 'react'
import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import {useAsyncFn} from 'react-use'
import PropTypes from 'prop-types'

import {lookupOrganisationName} from '../../../../../api'
import {Input} from '../../../../components'

const AbnLookup = ({formResourceContext}) => {
  // Wrapping the Formik functions so we can ensure they're within the context of
  // the supplied formResourceContext
  const {
    values: formikValues,
    errors: formikErrors,
    touched: formikTouched,
    setFieldValue: formikSetFieldValue,
  } = useFormikContext()

  // Defining our own functions for contextualising form fields within the supplied formResourceContext
  // This is needed to support resourceful forms (like sign-up) and non-resourceful like 'what-if' onboarding
  // Once 'what-if' is removed this can be refactored out
  const contextualisedFieldName = (field) => {
    if (!formResourceContext) return field

    return `${formResourceContext}[${field}]`
  }
  const values = formResourceContext
    ? formikValues[formResourceContext] || {}
    : formikValues
  const errors = formResourceContext
    ? formikErrors[formResourceContext] || {}
    : formikErrors
  const touched = formResourceContext
    ? formikTouched[formResourceContext] || {}
    : formikTouched
  const setFieldValue = (field, value) => {
    formikSetFieldValue(contextualisedFieldName(field), value)
  }

  const [lookup, doLookup] = useAsyncFn(async () => {
    const response = await lookupOrganisationName(values.abn)
    setFieldValue('organisationName', response.organisation_name ?? '')
    return response.organisation_name
  }, [values.abn, setFieldValue])

  return (
    <>
      <FormControl>
        <FormLabel hidden htmlFor={contextualisedFieldName('abn')}>
          ABN
        </FormLabel>

        <InputGroup size="lg">
          <Field
            as={Input}
            name={contextualisedFieldName('abn')}
            type="number"
            placeholder="ABN"
            errorMessage={touched.abn && errors.abn}
            pr="5rem"
          />

          <InputRightElement width="4.5rem" pr="0.5rem">
            <Button
              isDisabled={!!errors.abn}
              variant="tertiary"
              size="xs"
              isLoading={lookup.loading}
              onClick={doLookup}
            >
              Verify
            </Button>
          </InputRightElement>
        </InputGroup>

        {!errors.abn && lookup.value === null && (
          <Text textStyle="body-detail" color="red.500" mt="-xs" mb="sm">
            No matched ABN records found. Please enter the correct ABN of your
            business and try search again.
          </Text>
        )}

        {!errors.abn && lookup.error && (
          <Text textStyle="body-detail" color="red.500" mt="-xs" mb="sm">
            We can’t verify your ABN at the moment. Please enter your Business
            name below, and we will verify your ABN later.
          </Text>
        )}
      </FormControl>

      <FormControl>
        <FormLabel hidden htmlFor={contextualisedFieldName('organisationName')}>
          Company name
        </FormLabel>

        <Field
          as={Input}
          name={contextualisedFieldName('organisationName')}
          type="text"
          placeholder="Company name"
          isReadOnly={!lookup.error}
          errorMessage={touched.organisationName && errors.organisationName}
        />

        {values.organisationName && !lookup.error && (
          <FormHelperText mt="-xs" mb="sm" fontSize="xs">
            Not your business? Make sure you’ve entered the correct ABN and try
            again.
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}

AbnLookup.defaultProps = {
  formResourceContext: null,
}

AbnLookup.propTypes = {
  formResourceContext: PropTypes.string,
}

export default AbnLookup
