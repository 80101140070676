import PropTypes from 'prop-types'
import React, {useState, useCallback} from 'react'
import {Divider, Box, Text, Skeleton, Button} from '@chakra-ui/react'
import useSWR from 'swr'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

import {
  fetcher,
  postHeaders,
  patchHeaders,
  deleteHeaders,
} from '../../../../api'
import {camelizeResult} from '../../../../api/serializer'
import CreateRecipientForm from './CreateRecipientForm'
import UpdateRecipientForm from './UpdateRecipientForm'
import InputFlexWrap from './InputFlexWrap'
import {ToastMessage} from '../../../components'
import {CheckIcon} from '../../../icons'

const onError = (t, result, setFieldError) => {
  Object.entries(result.errors).forEach(([key, errors]) => {
    setFieldError(
      key,
      errors.map(({error, value}) =>
        t(`customers:Statements.RecipientForm.errors.${error}`, {value}),
      ),
    )
  })
}

const onToast = (message) => {
  toast.dark(
    <ToastMessage>
      <CheckIcon mr={1} color="mld.success.700" />
      {message}
    </ToastMessage>,
  )
}

export const PayerStatementRecipients = ({
  supplierId,
  customerId,
  customerEmail,
  authenticityToken,
}) => {
  const {t} = useTranslation('customers')

  const [showInput, setShowInput] = useState(false)

  const url = `/api/suppliers/${supplierId}/customers/${customerId}/payer_statement_recipients`

  const {data, mutate, isLoading} = useSWR(url, fetcher, {
    use: [camelizeResult],
  })

  const onCreate = useCallback(
    async (values, setFieldError) => {
      const result = await fetcher(
        url,
        postHeaders({
          authenticityToken,
          raiseClientErrors: false,
          body: values,
        }),
      )

      if (!result.success) {
        onError(t, result, setFieldError)
        return
      }

      setShowInput(false)

      mutate({
        recipients: [...data.recipients, values],
      })

      onToast(t('customers:Statements.RecipientForm.toast.added'))
    },
    [t, url, data, mutate, authenticityToken],
  )

  const onUpdate = useCallback(
    async (recipient, values, setFieldError) => {
      const result = await fetcher(
        `${url}/${recipient.id}`,
        patchHeaders({
          authenticityToken,
          raiseClientErrors: false,
          body: values,
        }),
      )

      if (!result.success) {
        onError(t, result, setFieldError)
        return
      }

      mutate()

      onToast(t('customers:Statements.RecipientForm.toast.updated'))
    },
    [t, url, mutate, authenticityToken],
  )

  const onRemove = useCallback(
    async (recipient) => {
      await fetcher(
        `${url}/${recipient.id}`,
        deleteHeaders({authenticityToken, raiseClientErrors: false}),
      )

      mutate({
        recipients: data.recipients.filter(({id}) => id !== recipient.id),
      })

      onToast(t('customers:Statements.RecipientForm.toast.removed'))
    },
    [t, url, data, mutate, authenticityToken],
  )

  return (
    <Box>
      <Box pb="2" pt="5">
        <Text fontSize="md" fontWeight="medium" color="neutral.80">
          {t('customers:Statements.RecipientForm.title')}
        </Text>
      </Box>
      <Box pb="2">
        <Text maxWidth="1000" whiteSpace="wrap" fontSize="sm">
          {t('customers:Statements.RecipientForm.description')}
        </Text>
      </Box>
      <Box pb="5">
        <Text fontWeight="medium">{customerEmail}</Text>
      </Box>
      <Box pb="5">
        <Divider />
      </Box>
      {(showInput || data?.recipients.length > 0) && (
        <Box>
          <Text>Email address</Text>
        </Box>
      )}
      <Box data-testid="recipients">
        <Skeleton isLoaded={!isLoading}>
          {data?.recipients.map((recipient, index) => (
            <UpdateRecipientForm
              index={index}
              key={`${recipient.id}-${recipient.updatedAt}`}
              onSubmit={(values, {setFieldError}) =>
                onUpdate(recipient, values, setFieldError)
              }
              email={recipient.email}
              onRemove={() => onRemove(recipient)}
              saveButtonText={t(
                'customers:Statements.RecipientForm.buttons.save',
              )}
              savedButtonText={t(
                'customers:Statements.RecipientForm.buttons.saved',
              )}
              removeButtonText={t(
                'customers:Statements.RecipientForm.buttons.remove',
              )}
            />
          ))}
        </Skeleton>
      </Box>
      {showInput ? (
        <Box>
          <CreateRecipientForm
            onSubmit={(values, {setFieldError}) =>
              onCreate(values, setFieldError)
            }
            saveButtonText={t(
              'customers:Statements.RecipientForm.buttons.save',
            )}
          />
        </Box>
      ) : (
        <InputFlexWrap>
          <Box>
            {data?.recipients.length === 0 ? (
              <Button
                onClick={() => setShowInput(true)}
                variant="secondary"
                data-testid="add-additional-recipient-button"
              >
                {t('customers:Statements.RecipientForm.buttons.addAdditional')}
              </Button>
            ) : (
              <Button
                onClick={() => setShowInput(true)}
                variant="tertiary"
                data-testid="add-another-recipient-button"
              >
                {t('customers:Statements.RecipientForm.buttons.addAnother')}
              </Button>
            )}
          </Box>
        </InputFlexWrap>
      )}
    </Box>
  )
}

PayerStatementRecipients.propTypes = {
  supplierId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  customerEmail: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
}

export default PayerStatementRecipients
