import React from 'react'
import PropTypes from 'prop-types'
import {Box, Link} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'

export const Card = ({dark, ...props}) => (
  <Box
    border="1px solid"
    borderColor={dark ? 'neutral.dust' : 'neutral.glitter'}
    backgroundColor={dark ? 'neutral.dust' : 'neutral.white'}
    borderRadius="lg"
    padding="sm"
    {...props}
  />
)

Card.propTypes = {
  dark: PropTypes.bool,
}

Card.defaultProps = {
  dark: false,
}

export const LinkCard = ({dark, compact, children, ...props}) => (
  <Box role="group">
    <Link
      w="full"
      display="block"
      borderRadius="lg"
      _hover={{textDecoration: 'none'}}
      _focus={{boxShadow: '0 0 0 3px rgba(73,70,233,0.25)'}}
      {...props}
    >
      <Card
        dark={dark}
        display="flex"
        alignItems="center"
        px="md"
        py="sm"
        minHeight={compact ? '0' : '75px'}
        textAlign="left"
        _groupHover={{bg: 'neutral.light', borderColor: 'neutral.20'}}
      >
        <Box flexGrow="1">{children}</Box>

        <Box color="neutral.40" _groupHover={{color: 'neutral.60'}} pl="sm">
          <ChevronRightIcon fontSize="lg" ml="auto" />
        </Box>
      </Card>
    </Link>
  </Box>
)

LinkCard.defaultProps = {
  ...Card.defaultProps,
  compact: false,
}

LinkCard.propTypes = {
  ...Card.propTypes,
  children: PropTypes.node.isRequired,
  compact: PropTypes.bool,
}
