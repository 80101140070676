import React from 'react'
import PropTypes from 'prop-types'
import Dinero from 'dinero.js'
import {Flex, Badge, Text, Skeleton} from '@chakra-ui/react'

import DineroPropShape from '../../../utils/propTypes/dinero'

export const StackedInvoiceTab = ({
  label,
  isLoading,
  count,
  amount,
  selected,
}) => {
  return (
    <Flex direction="column" align="flex-start" width="100%" pt="xxs" gap="xs">
      <Text
        display="flex"
        textStyle="body-detail"
        wrap="nowrap"
        alignItems="center"
        justifyContent="flex-start"
        textTransform="uppercase"
      >
        {label}
        {!isLoading && (
          <Badge
            ml="xs"
            height="fit-content"
            textStyle="body-detail-medium"
            borderRadius="md"
            color="inherit"
            bg={selected ? 'mld.neutral.500' : 'mld.neutral.50'}
          >
            {count}
          </Badge>
        )}
      </Text>
      <Skeleton isLoaded={!isLoading} width="100%" pb="xxs">
        <Text
          textAlign="start"
          textStyle="headline2"
          color={selected ? 'mld.primary.200' : 'inherit'}
        >
          {amount?.toFormat('$0,0')}
        </Text>
      </Skeleton>
    </Flex>
  )
}

StackedInvoiceTab.defaultProps = {
  count: 0,
  amount: new Dinero({}),
}

StackedInvoiceTab.propTypes = {
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number,
  amount: PropTypes.shape(DineroPropShape),
  selected: PropTypes.bool.isRequired,
}

export default StackedInvoiceTab
