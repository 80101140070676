import React from 'react'
import {Flex, Image, ListItem, OrderedList, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import PayToHowToAuthorise from '../../../../assets/images/payto_how_to_authorise.jpg'

const ProcessingInstructions = () => {
  const {t} = useTranslation('billPayments')

  const i18nPrefix =
    'billPayments:paymentMethods.debitAgreement.instructions.processing'

  return (
    <Flex direction="column" align="center" height="100%" gap="sm">
      <Text textStyle="headline4">{t(`${i18nPrefix}.title`)}</Text>
      <OrderedList>
        {[...Array(4)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index}>
            {t(`${i18nPrefix}.steps.step${index + 1}`)}
          </ListItem>
        ))}
      </OrderedList>
      <Image src={PayToHowToAuthorise} />
    </Flex>
  )
}

export default ProcessingInstructions
