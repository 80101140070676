import idVerification from './idVerification'
import signAgreement from './signAgreement'
import connectBankAccount from './connectBankAccount'
import updateInvoiceTemplate from './updateInvoiceTemplate'

export default (stepperData) => {
  const showingBankConnectionStep = stepperData?.approvedBankConnectionRequired

  const decoratedData = {...stepperData, showingBankConnectionStep}

  return [
    connectBankAccount(decoratedData),
    idVerification(decoratedData),
    signAgreement(decoratedData),
    updateInvoiceTemplate(decoratedData),
  ].filter((step) => step.visible)
}
