import React from 'react'
import PropTypes from 'prop-types'
import {Divider, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'
import BillPaymentForm from '../../components/BillPaymentForm'

const CreateBillPaymentScreen = withProviders(({authenticityToken}) => {
  const {t} = useTranslation('billPayments')
  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Pay bills">
        <Flex flexDirection="column" height="100vh" p="md">
          <Text as="h2" textStyle="headline2" px="md">
            {t('billPayments:billPayment.form.title')}
          </Text>

          <Divider my="md" />

          <BillPaymentForm />
        </Flex>
      </SupplierLayout>
    </AuthProvider>
  )
})

CreateBillPaymentScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default CreateBillPaymentScreen
