import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Grid, GridItem, Text} from '@chakra-ui/react'
import {CheckCircleIcon} from '@chakra-ui/icons'
import {toEpochMillisecond, toLocalDate} from '../../../../../utils/formatter'
import ViewDetailsButton from './ViewDetailsButton'

const CashedInAmountCell = ({getValue, row}) => (
  <>
    <Flex display={{base: 'none', md: 'flex'}}>
      <Text textStyle="body-copy" fontWeight="medium">
        {getValue()}
      </Text>
      <ViewDetailsButton row={row} />
    </Flex>
    <Grid display={{base: 'grid', md: 'none'}} templateColumns="repeat(3, 1fr)">
      <Text
        textStyle="body-small"
        fontWeight="medium"
        color="grey.700"
        my="auto"
      >
        <CheckCircleIcon color="secondary.green" mr="xxs" mb={0.5} /> Cashed-in
      </Text>
      <Text textStyle="body-detail" my="auto">
        {toLocalDate(toEpochMillisecond(row.original.cashedInDate), {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}
      </Text>
      <Flex>
        <Text textStyle="body-copy" fontWeight="medium" my="auto">
          {getValue()}
        </Text>
      </Flex>
      <GridItem colSpan="3">
        <ViewDetailsButton row={row} />
      </GridItem>
    </Grid>
  </>
)

CashedInAmountCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      cashedInDate: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CashedInAmountCell
