import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'formik'
import {useTranslation} from 'react-i18next'

import {Flex, Radio, RadioGroup, Spinner, Text} from '@chakra-ui/react'
import usePaymentMethods from './hooks/usePaymentMethods'

const PaymentMethodsSection = ({supplierId}) => {
  const {t} = useTranslation('billPayments')
  const i18nPrefix = 'billPayments:billPayment.form'

  const {paymentMethods, groupedPaymentMethods, isLoading} =
    usePaymentMethods(supplierId)

  const [paymentMethodId, setPaymentMethodId] = React.useState(null)

  return (
    <>
      <Text textStyle="body-intro-medium" pb="sm">
        {t(`${i18nPrefix}.subsections.paymentMethod`)}
      </Text>
      {isLoading && paymentMethods.length === 0 ? (
        <Flex gap="sm">
          <Spinner />
          <Text textStyle="body-small" pl="sm">
            {t(`${i18nPrefix}.loadingPaymentMethods`)}
          </Text>
        </Flex>
      ) : (
        <Field as={Flex} direction="column" gap="sm" name="paymentMethodId">
          <RadioGroup
            name="paymentMethodId"
            onChange={setPaymentMethodId}
            value={paymentMethodId}
          >
            <Flex direction="column">
              {groupedPaymentMethods.debitAgreement.map(({debitAgreement}) => (
                <Radio
                  key={debitAgreement.id}
                  value={debitAgreement.id}
                  defaultChecked={paymentMethods.length === 1}
                >
                  {t(`${i18nPrefix}.fields.paymentMethod.debitAgreementLabel`, {
                    context: debitAgreement.paymentNetwork,
                    branchCode: debitAgreement.branchCode,
                    accountNumber: debitAgreement.accountNumber,
                  })}
                </Radio>
              ))}
            </Flex>
          </RadioGroup>
        </Field>
      )}
    </>
  )
}

PaymentMethodsSection.propTypes = {
  supplierId: PropTypes.string.isRequired,
}

export default PaymentMethodsSection
