import React from 'react'
import {Box, Text} from '@chakra-ui/react'
import {QuestionIcon} from '@chakra-ui/icons'
import PropTypes from 'prop-types'

const Help = ({children, heading, ...props}) => (
  <Box
    bg="background.purple"
    borderRadius="lg"
    p="sm"
    pr="md"
    display="flex"
    {...props}
  >
    <QuestionIcon color="primary.actionhover" size="24px" mr="sm" />

    <Box>
      <Text fontSize="18px" fontWeight="medium" mb="sm">
        {heading}
      </Text>

      <Box color="neutral.60" mb="sm">
        {children}
      </Box>
    </Box>
  </Box>
)

Help.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
}

export default Help
