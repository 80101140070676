import React, {useState} from 'react'
import {
  Box,
  Text,
  Button,
  Card,
  CardBody,
  Flex,
  Stack,
  Avatar,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

import {EmailIcon} from '../../../../icons'
import {useRails} from '../../../../contexts/rails'
import {defaultHeaders, fetcher} from '../../../../../api'

const DecisionProcessExpectation = () => {
  const {authenticityToken, organisation} = useRails()
  const {t} = useTranslation('onboarding')

  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    await fetcher(
      `/api/suppliers/${organisation.currentSupplier.id}/acquisition_decision/move_to_progress`,
      {
        method: 'POST',
        headers: defaultHeaders({authenticityToken}),
      },
    )
    window.location.href = '/dashboard'
  }

  return (
    <Flex direction="column" gap="md" height="100%">
      <Text textStyle="headline2" fontSize={{base: '2xl', md: '48px'}}>
        {t('onboarding:DecisionProcessExpectation.heading')}
      </Text>
      <Text textStyle="body-intro">
        {t('onboarding:DecisionProcessExpectation.body')}
      </Text>
      <Card variant="outline" size="sm">
        <CardBody>
          <Flex align="center" gap="sm">
            <Avatar
              boxSize="40px"
              backgroundColor="mld.neutral.100"
              icon={<EmailIcon color="black" size="24px" />}
            />
            <Stack gap="0" overflow="auto">
              <Text as="h6" textStyle="body-small-medium">
                {t('onboarding:DecisionProcessExpectation.emailCardTitle')}
              </Text>
              <Text textStyle="body-small" color="mld.neutral.700">
                {organisation.currentSupplier.contactEmail}
              </Text>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
      <Box>
        <Button
          variant="primary"
          colorScheme="primary"
          onClick={onClick}
          isLoading={loading}
          isDisabled={loading}
        >
          {t('onboarding:DecisionProcessExpectation.ctaButton')}
        </Button>
      </Box>
    </Flex>
  )
}

export default DecisionProcessExpectation
