/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React from 'react'
import {
  Text,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Tag,
  TableCaption,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {capitalize} from 'lodash-es'

const asPercentage = (rate) => `${(rate * 100.0).toFixed(2)}%`

const FeeCell = ({eligible, amount}) => {
  if (!eligible) {
    return (
      <Text textStyle="body-small" color="mld.neutral.700">
        Ineligble
      </Text>
    )
  }

  return <Text textStyle="body-small">{asPercentage(amount)}</Text>
}

FeeCell.propTypes = {
  eligible: PropTypes.bool.isRequired,
  amount: PropTypes.string.isRequired,
}

const EstimationsTable = ({
  pricingEstimations,
  currentTierKey,
  highestTierKey,
}) => {
  const {t} = useTranslation()

  const stripedColumnBgColor = '#D9D9D920'

  return (
    <TableContainer>
      <Table variant="simple" whiteSpace="break-spaces">
        <Thead verticalAlign="bottom" position="sticky">
          <Tr>
            <Th pl="0" textTransform="uppercase" minWidth="200px">
              {t(
                'cashinPage:pricingEstimationsModal.tableHeaders.customerLabel',
              )}
            </Th>
            <Th
              textTransform="uppercase"
              backgroundColor={stripedColumnBgColor}
            >
              <Flex direction="column" gap="xs">
                <Tag
                  width="fit-content"
                  textStyle="body-detail"
                  backgroundColor="mld.neutral.200"
                >
                  {t(
                    'cashinPage:pricingEstimationsModal.tableHeaders.currentPriceTag',
                  )}
                </Tag>
                <Text>
                  {t(
                    'cashinPage:pricingEstimationsModal.tableHeaders.currentPriceLabel',
                    {
                      currentTier: capitalize(currentTierKey),
                    },
                  )}
                </Text>
              </Flex>
            </Th>
            <Th textTransform="uppercase">
              {t(
                'cashinPage:pricingEstimationsModal.tableHeaders.fullAdoptionPriceLabel',
                {
                  highestTier: capitalize(highestTierKey),
                },
              )}
            </Th>
            <Th
              textTransform="uppercase"
              backgroundColor={stripedColumnBgColor}
            >
              {t(
                'cashinPage:pricingEstimationsModal.tableHeaders.bestPossiblePriceLabel',
              )}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {pricingEstimations.map((customerEstimation) => {
            return (
              <Tr key={customerEstimation.customerId}>
                <Td pl="0">
                  <Flex
                    direction="column"
                    wordBreak="break-word"
                    whiteSpace="normal"
                    gap="sm"
                  >
                    <Text textStyle="body-small">
                      {customerEstimation.customerName}
                    </Text>
                    <Text textStyle="body-detail" color="mld.neutral.700">
                      {customerEstimation.mainDriverReason}
                    </Text>
                  </Flex>
                </Td>
                <Td backgroundColor={stripedColumnBgColor}>
                  <FeeCell {...customerEstimation.currentPrice} />
                </Td>
                <Td>
                  <FeeCell {...customerEstimation.fullAdoptionPrice} />
                </Td>
                <Td
                  backgroundColor={stripedColumnBgColor}
                  color="mld.primary.200"
                >
                  <FeeCell {...customerEstimation.bestPrice} />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
        {pricingEstimations.length === 0 && (
          <TableCaption>
            <Text py="lg">
              {t('cashinPage:pricingEstimationsModal.noEstimations')}
            </Text>
          </TableCaption>
        )}
      </Table>
    </TableContainer>
  )
}

EstimationsTable.propTypes = {
  pricingEstimations: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.string.isRequired,
      customerName: PropTypes.string.isRequired,
      mainDriverReason: PropTypes.string.isRequired,
      currentPrice: PropTypes.shape({
        eligible: PropTypes.bool.isRequired,
        amount: PropTypes.string.isRequired,
      }),
      fullAdoptionPrice: PropTypes.shape({
        eligible: PropTypes.bool.isRequired,
        amount: PropTypes.string.isRequired,
      }),
      bestPrice: PropTypes.shape({
        eligible: PropTypes.bool.isRequired,
        amount: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  currentTierKey: PropTypes.string.isRequired,
  highestTierKey: PropTypes.string.isRequired,
}

export default EstimationsTable
