import React from 'react'
import {createIcon} from '@chakra-ui/react'

export const BoltIcon = createIcon({
  displayName: 'BoltIcon',
  viewBox: '0 0 15 15',
  path: (
    <path
      d="M6.8723 13.125H6.2473L6.8723 8.75H4.6848C4.1348 8.75 4.47855 8.28125 4.49105 8.2625C5.2973 6.8375 6.5098 4.7125 8.12855 1.875H8.75355L8.12855 6.25H10.3223C10.5723 6.25 10.7098 6.36875 10.5723 6.6625C8.10355 10.9687 6.8723 13.125 6.8723 13.125Z"
      fill="currentColor"
    />
  ),
})
