import React from 'react'
import PropTypes from 'prop-types'
import {Flex} from '@chakra-ui/react'
import CreatedInstructions from './CreatedInstructions'
import ProcessingInstructions from './ProcessingInstructions'
import PausedInstructions from './PausedInstructions'
import ActiveInstructions from './ActiveInstructions'

const DebitAgreementInstructions = ({status, ...boxProps}) => {
  const ContentComponent = {
    created: CreatedInstructions,
    processing: ProcessingInstructions,
    paused: PausedInstructions,
    active: ActiveInstructions,
  }[status]

  if (!ContentComponent) {
    return null
  }

  return (
    <Flex
      {...boxProps}
      p="md"
      direction="column"
      gap="md"
      bgColorC="mld.neutral.50"
      border="1px solid"
      borderColor="mld.neutral.200"
      borderRadius="base"
      width="100%"
      minW="400px"
      maxW="48%"
    >
      <ContentComponent />
    </Flex>
  )
}

DebitAgreementInstructions.propTypes = {
  status: PropTypes.string.isRequired,
}

export default DebitAgreementInstructions
