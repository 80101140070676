import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Box, Flex, Text, useBreakpointValue} from '@chakra-ui/react'
import useTabContent from './useTabContent'
import InvoicesList from '../InvoicesList'
import EmptyState from '../InvoicesList/EmptyState'
import ViewDetailsButton from '../InvoicesList/ViewDetailsButton'

const EmptyContent = ({handleTabsChange}) => {
  return (
    <EmptyState
      tabName="ineligibleTab"
      onClick={() => {
        handleTabsChange(0)
      }}
    />
  )
}

EmptyContent.propTypes = {
  handleTabsChange: PropTypes.func.isRequired,
}

const IneligibilityReasonCell = ({row, getValue}) => {
  const {t} = useTranslation()
  const {key: reasonKey, ...args} = getValue()
  return (
    <Flex direction={{base: 'column', md: 'row'}}>
      <Box borderRadius="sm" bg="neutral.100" p="xs" width="100%">
        <Text textStyle="body-small">
          {t(`cashinPage:unavailable_reasons.${reasonKey}`, args.values)}
        </Text>
      </Box>
      <ViewDetailsButton row={row} />
    </Flex>
  )
}

IneligibilityReasonCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const IneligibleTab = ({reloadRequired, handleTabsChange}) => {
  const {commonColumns, ...listProps} = useTabContent({
    endpointAction: 'ineligible_invoices',
    reloadRequired,
  })

  const reasonColumn = {
    header: 'Reason for not available',
    accessorKey: 'ineligibilityReason',
    cell: IneligibilityReasonCell,
    enableSorting: false,
    wrap: useBreakpointValue({base: true, md: false}),
    colSpan: useBreakpointValue({base: 6, md: 3}),
  }

  const columns = [
    commonColumns.customerColumn,
    commonColumns.invoiceColumn,
    commonColumns.dueDateColumn,
    commonColumns.invoiceAmountColumn,
    reasonColumn,
    commonColumns.issueDateColumn,
    commonColumns.feeRateColumn,
  ]

  // Set default sort option
  if (!listProps.sortBy) {
    listProps.onSort([
      {
        id: 'invoice_cashin_offer_cashin_fee',
        desc: false,
      },
    ])
  }

  return (
    <InvoicesList
      columns={columns}
      emptyContent={<EmptyContent handleTabsChange={handleTabsChange} />}
      {...listProps}
    />
  )
}

IneligibleTab.propTypes = {
  reloadRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  handleTabsChange: PropTypes.func.isRequired,
}

export default IneligibleTab
