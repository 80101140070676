const authenticityToken = () =>
  document.head.getElementsByTagName('meta')['csrf-token']?.content

export default (provider) => {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = `/auth/${provider.toLowerCase()}_oauth2`
  document.body.appendChild(form)

  const tokenInput = document.createElement('input')
  tokenInput.type = 'hidden'
  tokenInput.name = 'authenticity_token'
  tokenInput.value = authenticityToken()
  form.appendChild(tokenInput)

  form.submit()
}
