import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

i18n
  .use(
    resourcesToBackend(async (language, ns) =>
      import(`./locales/${language}/${ns}.json`),
    ),
  )
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
