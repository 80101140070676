import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  Button,
} from '@chakra-ui/react'
import {updateSupplierSettings} from '../../../../api'
import {Input} from '../../../components'
import {useAuthProvider} from '../../../contexts/authentication'

const DisplayNameSettingDrawer = ({
  displayDrawer,
  supplierDisplayName,
  onClickSave,
  onClickCancel,
}) => {
  const {authenticityToken, updateAuthenticityToken} = useAuthProvider()
  const [supplierDisplayNameValue, setSupplierDisplayNameValue] =
    React.useState(supplierDisplayName)
  const [displayNameError, setDisplayNameError] = React.useState('')
  const [isSendingRequest, setIsSendingRequest] = React.useState(false)
  const onClose = () => {
    setSupplierDisplayNameValue(supplierDisplayName)
    setDisplayNameError('')
    onClickCancel()
  }

  return (
    <Drawer
      isOpen={displayDrawer}
      placement="right"
      size="md"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="xs"
          >
            <Text flexGrow="1" fontSize="md" fontWeight="medium">
              Business Name
            </Text>
          </Box>
        </DrawerHeader>
        <DrawerBody overflowY="scroll">
          <Text mb="md">
            Edit or update your business name. The updated business name will
            only be used internally on the Marmalade website.
          </Text>
          {displayNameError && (
            <Text fontSize="sm" mb="sm" color="primary.errorred">
              Fail to update business name: {displayNameError}
            </Text>
          )}
          <Input
            name="businessName"
            data-testid="businessName"
            placeholder="Business Name"
            value={supplierDisplayNameValue}
            onChange={(e) => setSupplierDisplayNameValue(e.target.value)}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button variant="secondary" size="lg" mr="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            colorScheme="primary"
            size="lg"
            onClick={async () => {
              setIsSendingRequest(true)

              try {
                const {authenticity_token: token} =
                  await updateSupplierSettings({
                    displayName: supplierDisplayNameValue,
                    authenticityToken,
                  })

                updateAuthenticityToken(token)
                setIsSendingRequest(false)
                onClickSave()
              } catch (e) {
                setIsSendingRequest(false)
                setDisplayNameError(e.message)
              }
            }}
            isDisabled={supplierDisplayNameValue === supplierDisplayName}
            isLoading={isSendingRequest}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

DisplayNameSettingDrawer.defaultProps = {
  displayDrawer: false,
  onClickSave: () => {},
  onClickCancel: () => {},
}

DisplayNameSettingDrawer.propTypes = {
  displayDrawer: PropTypes.bool,
  supplierDisplayName: PropTypes.string.isRequired,
  onClickSave: PropTypes.func,
  onClickCancel: PropTypes.func,
}

export default DisplayNameSettingDrawer
