import React from 'react'
import {Skeleton, useTheme} from '@chakra-ui/react'

export default (props) => {
  const theme = useTheme()

  return (
    <Skeleton
      startColor={theme.colors.skeleton.light}
      endColor={theme.colors.skeleton.dark}
      {...props}
    />
  )
}
