import React from 'react'
import PropTypes from 'prop-types'
import useSWRImmutable from 'swr'
import {capitalize} from 'lodash-es'
import {
  Avatar,
  chakra,
  Box,
  Divider,
  Flex,
  Image,
  List,
  ListItem,
  Link,
  Text,
} from '@chakra-ui/react'
import RewardLevelBadge from '../../../../../components/SupplierRewardsModal/RewardLevelBadge'
import {
  InsightsIcon,
  RoundedPaidIcon,
  SavingsIcon,
  ChecklistIcon,
  ReceiptIcon,
  UpcomingIcon,
} from '../../../../../icons'
import {useRails} from '../../../../../contexts/rails'
import {fetcher} from '../../../../../../api'
import {
  camelizeResult,
  dineroMoneyObjects,
} from '../../../../../../api/serializer'
import gettingStartedCashInImg from '../../../../../../../assets/images/getting_started_cash_in.svg'
import GettingStartedItem from './GettingStartedItem'
import {useSupplierRewardModal} from '../../../../../contexts/supplierRewardsModal'

const GettingStartedCard = ({showCashinStepper}) => {
  const {organisation} = useRails()
  const {data} = useSWRImmutable(
    `/api/suppliers/${organisation.currentSupplier.id}/supplier_rewards_summary`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
      revalidateOnFocus: false,
    },
  )

  const [selectedItem, setSelectedItem] = React.useState('status')
  const {onOpen} = useSupplierRewardModal()
  const currentLevelData =
    data && data.levels.find((level) => level.key === data.currentLevel)

  return (
    <Box
      bg="neutral.white"
      p="md"
      my="md"
      border="1px solid"
      borderColor="neutral.glitter"
      borderRadius="lg"
    >
      {showCashinStepper ? (
        <Text fontSize="md" fontWeight="medium">
          Get started with Marmalade{' '}
          <span role="img" aria-label="Tada!" style={{paddingRight: '4px'}}>
            &#127818;
          </span>
        </Text>
      ) : (
        <Text fontSize="md" fontWeight="medium">
          What&apos;s new{' '}
          <span role="img" aria-label="Tada!" style={{paddingRight: '4px'}}>
            &#10024;
          </span>
        </Text>
      )}
      <Text textStyle="body-small">
        Marmalade is the perfect solution for getting paid early on your
        invoices.
      </Text>
      <Divider my="sm" />
      <Flex
        direction={{base: 'column', lg: 'row'}}
        justifyContent="space-between"
        gap={{base: 'sm', lg: 'lg'}}
      >
        <Box flex="1 1 0">
          <GettingStartedItem
            onClick={() => {
              setSelectedItem('status')
            }}
            active={selectedItem === 'status'}
            icon={InsightsIcon}
            headerText="Discover your status"
            bodyText="Your status determines your cash-in fee rate."
          />
          <GettingStartedItem
            onClick={() => {
              setSelectedItem('cash-in')
            }}
            active={selectedItem === 'cash-in'}
            icon={RoundedPaidIcon}
            headerText={
              showCashinStepper ? "What is 'Cash-in'?" : "'Cash-in' page update"
            }
            bodyText={
              showCashinStepper
                ? 'Cash-in refers to the process of receiving early payment for an invoice through Marmalade.'
                : 'Cash-in page has all of your unpaid invoices into 4 categories'
            }
          />
          <GettingStartedItem
            onClick={() => {
              setSelectedItem('fee')
            }}
            active={selectedItem === 'fee'}
            icon={SavingsIcon}
            headerText="How to reduce your cash-in fee"
            bodyText="Reduce cash-in fee by moving up the Status level."
          />
        </Box>
        <Divider display={{base: 'block', md: 'none'}} />
        <Box flex="2 1 0" my="auto" pt={{base: 'sm', md: '0'}}>
          {selectedItem === 'status' && (
            <Flex>
              <Box mr="md">
                {data && (
                  <RewardLevelBadge
                    key={currentLevelData.key}
                    level={currentLevelData}
                    current
                    scale={0.8}
                  />
                )}
              </Box>
              <Box>
                {showCashinStepper ? (
                  <Text textStyle="body-small">
                    Your current status is &apos;
                    <b>{data && capitalize(data.currentLevel)}</b>&apos;, which
                    means you can start enjoying the benefits of getting paid
                    early on your invoices.
                  </Text>
                ) : (
                  <Text textStyle="body-small">
                    Your current status is &apos;
                    <b>{data && capitalize(data.currentLevel)}</b>&apos;, which
                    means you will be able to cash-in at rate of{' '}
                    {data && currentLevelData.feeRate * 100}% plus customer fee
                    rate. As you move up the status, you can reduce more of your
                    status fee rate!
                    <Link
                      href="https://marmalade.ladesk.com/933581-Maximise-Your-Status"
                      isExternal
                      ml="xs"
                      color="primary.700"
                      _hover={{textDecoration: 'none'}}
                    >
                      Find out more
                    </Link>
                  </Text>
                )}

                <Text textStyle="body-small" my="md">
                  <b>Cash-in fee rate</b>{' '}
                  <chakra.span color="neutral.700">
                    ({data && data.eligibleFeeRange})
                  </chakra.span>{' '}
                  = <b>Status fee rate</b> + <b>Customer fee rate</b>
                </Text>
                <Text textStyle="body-small">
                  Go to &apos;
                  <Text
                    as="span"
                    textStyle="body-small"
                    color="primary.700"
                    cursor="pointer"
                    _hover={{textDecoration: 'underline'}}
                    onClick={onOpen}
                  >
                    {data && capitalize(data.currentLevel)} Status
                  </Text>
                  &apos; on the left navigation to check it out.
                </Text>
              </Box>
            </Flex>
          )}
          {selectedItem === 'cash-in' && (
            <>
              <Image
                width="100%"
                src={gettingStartedCashInImg}
                alt="Cash-in example"
              />
              {showCashinStepper ? (
                <Text textStyle="body-small" my="sm">
                  Cash-in is a feature that allows you to receive{' '}
                  <b>on-demand payment</b> for your unpaid invoices.
                </Text>
              ) : (
                <>
                  <Text textStyle="body-small" my="sm">
                    We now have 4 categories of unpaid invoices listed on the
                    &apos;Cash-in&apos; page:
                  </Text>
                  <List textStyle="body-small" mb="sm">
                    <ListItem>
                      <b>Available now</b> : Invoices ready for cash-in
                    </ListItem>
                    <ListItem>
                      <b>Conditionally available</b> : Invoices you need to
                      provide missing customer details or reissue to contain
                      Marmalade&apos;s payment information
                    </ListItem>
                    <ListItem>
                      <b>Not available</b> : Invoices that are not available for
                      cash-in
                    </ListItem>
                    <ListItem>
                      <b>Cashed-in</b> : All of your unpaid, cashed-in invoices
                    </ListItem>
                  </List>
                </>
              )}

              <Text textStyle="body-small">
                Go to &apos;
                <Link color="primary.700" href="/cashin">
                  Cash-in page
                </Link>
                &apos; to check it out.
              </Text>
            </>
          )}
          {selectedItem === 'fee' && (
            <>
              <Text textStyle="body-small" mb="xxs">
                You can reduce your cash-in fee over time by using Marmalade
                more!
              </Text>
              <Text textStyle="body-small">
                Here are the steps you can take:
              </Text>
              <Flex mt="sm" gap="sm" flexWrap="wrap">
                <Box
                  width="200px"
                  p="md"
                  mr="md"
                  borderRadius="sm"
                  border="1px solid"
                  borderColor="neutral.300"
                >
                  <Avatar
                    mb="xs"
                    bg="background.yellow"
                    aria-label="invoices-issued-icon"
                    icon={<ReceiptIcon color="yellow.500" fontSize="24px" />}
                  />
                  <Text textStyle="body-copy" fontWeight="medium">
                    Issue invoices
                  </Text>
                  <Text textStyle="body-detail" color="neutral.700">
                    Issue all invoices with Marmalade payment details
                  </Text>
                </Box>
                <Box
                  width="200px"
                  p="md"
                  mr="md"
                  borderRadius="sm"
                  border="1px solid"
                  borderColor="neutral.300"
                >
                  <Avatar
                    mb="xs"
                    bg="background.blue"
                    aria-label="payments-received-icon"
                    icon={<UpcomingIcon color="blue.400" fontSize="24px" />}
                  />
                  <Text textStyle="body-copy" fontWeight="medium">
                    Get paid
                  </Text>
                  <Text textStyle="body-detail" color="neutral.700">
                    Get all your invoices paid into your Marmalade account
                  </Text>
                </Box>
                <Box
                  width="200px"
                  p="md"
                  border="1px solid"
                  borderRadius="sm"
                  borderColor="neutral.300"
                >
                  <Avatar
                    mb="xs"
                    bg="background.orange"
                    aria-label="resolve-issues-icon"
                    icon={<ChecklistIcon color="orange.500" fontSize="24px" />}
                  />
                  <Text textStyle="body-copy" fontWeight="medium">
                    Resolve issues
                  </Text>
                  <Text textStyle="body-detail" color="neutral.700">
                    Resolve issues raised on Cashed-in invoices that are overdue
                  </Text>
                </Box>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

GettingStartedCard.propTypes = {
  showCashinStepper: PropTypes.bool.isRequired,
}

export default GettingStartedCard
