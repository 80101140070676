import React from 'react'
import PropTypes from 'prop-types'

import {camelizeKeys} from 'humps'

import {Box} from '@chakra-ui/react'
import {LogoHorizontal, ThemeProvider} from '../../components'
import {informPayment} from '../../../api'
import InformPaymentForm from './InformPaymentForm'
import InformPaymentSuccess from './InformPaymentSuccess'

const CustomerInformPaymentScreen = ({
  authenticityToken,
  invoiceId,
  invoiceNumber,
  supplierName,
  supplierCode,
  bankAccount,
}) => {
  const [successResponse, setSuccessResponse] = React.useState(false)

  const handleSubmit = async (values) => {
    const {type, amount, notes} = values
    const res = await informPayment({
      type,
      amount,
      notes,
      invoiceId,
      authenticityToken,
    })

    setSuccessResponse(res.status === 204)
  }

  return (
    <ThemeProvider>
      <Box minH="100vh">
        <Box
          maxWidth="500px"
          position="absolute"
          top="40%"
          left="0"
          right="0"
          transform="translateY(-40%)"
          px="sm"
          margin="0 auto"
        >
          <LogoHorizontal width={181} height={22} />
          {successResponse ? (
            <InformPaymentSuccess
              invoiceNumber={invoiceNumber}
              supplierName={supplierName}
              supplierCode={supplierCode}
              bankAccount={camelizeKeys(bankAccount)}
            />
          ) : (
            <InformPaymentForm
              invoiceNumber={invoiceNumber}
              supplierName={supplierName}
              handleSubmit={handleSubmit}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

CustomerInformPaymentScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierCode: PropTypes.string.isRequired,
  bankAccount: PropTypes.shape({
    account_name: PropTypes.string.isRequired,
    account_number: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
    payid: PropTypes.string,
  }).isRequired,
}

export default CustomerInformPaymentScreen
