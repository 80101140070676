const constructFilterDetails = (filterOption) => {
  const {customer, includeNoOutstanding, invoiceStatus, statementStatus} =
    filterOption
  let params = []
  let activeFilters = 0

  if (customer && customer.value) {
    params = params.concat(`q[id_eq]=${customer.value}`)
    activeFilters += 1
  }

  if (includeNoOutstanding) {
    activeFilters += 1
  } else {
    params = params.concat(`q[outstanding_invoice_count_not_eq]=0`)
  }

  if (invoiceStatus && invoiceStatus.length) {
    invoiceStatus.forEach((status) => {
      params = params.concat(`q[outstanding_invoice_status_in][]=${status}`)
    })
    activeFilters += 1
  }

  if (statementStatus) {
    switch (statementStatus) {
      case 'opened':
        params = params.concat(`q[latest_statement_opened_at_null]=false`)
        break
      case 'sent_not_opened':
        params = params.concat(`q[latest_statement_sent_at_null]=false`)
        params = params.concat(`q[latest_statement_opened_at_null]=true`)
        break
      default:
        break
    }
    activeFilters += 1
  }

  params = params.join('&')

  return {params, activeFilters, filterOption}
}

export default constructFilterDetails
