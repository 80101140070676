import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {get} from 'lodash-es'
import {useBreakpointValue} from '@chakra-ui/react'
import {CheckCircleIcon} from '@chakra-ui/icons'
import {useRails} from '../../../../contexts/rails'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import useTabContent from './useTabContent'
import InvoicesList from '../InvoicesList'
import CashInButtonCell from '../InvoicesList/CashInButtonCell'
import EmptyState from '../InvoicesList/EmptyState'

const emptyMessageContext = (conditionallyEligibleCount, ineligibleCount) => {
  if (conditionallyEligibleCount > 0) {
    return 'hasConditional'
  }
  if (ineligibleCount > 0) {
    return 'hasIneligible'
  }

  return 'noInvoices'
}

const EmptyContent = ({
  handleTabsChange,
  conditionallyEligibleCount,
  ineligibleCount,
}) => {
  const messageContext = emptyMessageContext(
    conditionallyEligibleCount,
    ineligibleCount,
  )
  let onClick = null
  if (conditionallyEligibleCount > 0) {
    onClick = () => {
      handleTabsChange(1)
    }
  }
  if (ineligibleCount > 0) {
    onClick = () => {
      handleTabsChange(2)
    }
  }

  return (
    <EmptyState
      tabName="eligibleTab"
      messageContext={messageContext}
      messageCount={conditionallyEligibleCount}
      onClick={onClick}
    />
  )
}

EmptyContent.propTypes = {
  conditionallyEligibleCount: PropTypes.number.isRequired,
  ineligibleCount: PropTypes.number.isRequired,
  handleTabsChange: PropTypes.func.isRequired,
}

const EligibleTab = ({
  reloadRequired,
  actionStarted,
  actionFinished,
  handleTabsChange,
  summaryData,
}) => {
  const {commonColumns, invoicesData, ...listProps} = useTabContent({
    endpointAction: 'eligible_invoices',
    reloadRequired,
  })

  const columns = [
    commonColumns.customerColumn,
    commonColumns.invoiceColumn,
    commonColumns.dueDateColumn,
    commonColumns.invoiceAmountColumn,
    commonColumns.issueDateColumn,
    commonColumns.feeRateColumn,
    {
      header: (
        <>
          <CheckCircleIcon color="secondary.green" mr="xxs" mb={0.5} /> Cash-in
        </>
      ),
      accessorKey: 'eligible',
      cell: CashInButtonCell,
      enableSorting: false,
      wrap: useBreakpointValue({base: true, md: false}),
      colSpan: useBreakpointValue({base: 6, md: 3}),
    },
  ]

  const {organisation} = useRails()
  const {data} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/reimbursement_required`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const decoratedInvoices = invoicesData?.map((invoice) => ({
    ...invoice,
    reimbursementRequiredAmount: data?.reimbursementRequiredAmount,
    actionStarted,
    actionFinished,
  }))

  // Set default sort option
  if (!listProps.sortBy) {
    listProps.onSort([
      {
        id: 'invoice_cashin_offer_cashin_fee',
        desc: false,
      },
    ])
  }

  return (
    <InvoicesList
      columns={columns}
      invoicesData={decoratedInvoices}
      emptyContent={
        <EmptyContent
          handleTabsChange={handleTabsChange}
          conditionallyEligibleCount={get(
            summaryData,
            'conditionallyEligibleCount',
            0,
          )}
          ineligibleCount={get(summaryData, 'ineligibleCount', 0)}
        />
      }
      {...listProps}
    />
  )
}

EligibleTab.propTypes = {
  actionStarted: PropTypes.func.isRequired,
  actionFinished: PropTypes.func.isRequired,
  reloadRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  handleTabsChange: PropTypes.func.isRequired,
  summaryData: PropTypes.shape({
    conditionallyEligibleCount: PropTypes.number.isRequired,
    ineligibleCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default EligibleTab
