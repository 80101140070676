import constructFilterDetails from './constructFilterDetails'

const preFilterQuery = (queryParams) => {
  const searchParams = new URLSearchParams(queryParams)

  const filterKeys = [
    'payment_id',
    'invoice_id',
    'customer_id',
    'issue_date_from',
    'issue_date_to',
    'due_date_from',
    'due_date_to',
    'days_past_due_date',
    'cashin_status',
    'payment_status',
    'duedate_status',
  ]

  let filterOption = {
    paymentStatus: [],
    dueDateStatus: [],
    dueDate: {},
  }

  filterKeys.forEach((key) => {
    if (searchParams.has(key)) {
      if (key === 'customer_id') {
        filterOption = {
          ...filterOption,
          customer: {
            value: searchParams.get(key),
          },
        }
      }

      if (key === 'invoice_id') {
        filterOption = {
          ...filterOption,
          invoice: {
            value: searchParams.get(key),
          },
        }
      }

      if (key === 'issue_date_from') {
        filterOption = {
          ...filterOption,
          issueDate: {
            ...filterOption.issueDate,
            from: parseInt(searchParams.get(key), 10),
          },
        }
      }

      if (key === 'issue_date_to') {
        filterOption = {
          ...filterOption,
          issueDate: {
            ...filterOption.issueDate,
            to: parseInt(searchParams.get(key), 10),
          },
        }
      }

      if (key === 'due_date_from') {
        filterOption = {
          ...filterOption,
          dueDate: {
            ...filterOption.dueDate,
            from: parseInt(searchParams.get(key), 10),
          },
        }
      }

      if (key === 'due_date_to') {
        filterOption = {
          ...filterOption,
          dueDate: {
            ...filterOption.dueDate,
            to: parseInt(searchParams.get(key), 10),
          },
        }
      }

      if (key === 'days_past_due_date') {
        filterOption = {
          ...filterOption,
          daysPastDueDate: {
            ...filterOption.daysPastDueDate,
            value: parseInt(searchParams.get(key), 10),
          },
        }
      }

      if (key === 'cashin_status') {
        filterOption = {
          ...filterOption,
          cashinStatus: [searchParams.get(key)],
        }
      }

      if (key === 'payment_status') {
        filterOption = {
          ...filterOption,
          paymentStatus: searchParams.get(key).split(','),
        }
      }

      if (key === 'duedate_status') {
        filterOption = {
          ...filterOption,
          dueDateStatus: searchParams.get(key).split(','),
        }
      }

      if (key === 'payment_id') {
        filterOption = {
          ...filterOption,
          paymentId: searchParams.get(key),
        }
      }
    }
  })

  return constructFilterDetails(filterOption)
}

export default preFilterQuery
