import React from 'react'
import PropTypes from 'prop-types'
import {Divider, Flex, Text} from '@chakra-ui/react'
import {CheckCircleIcon, WarningIcon} from '@chakra-ui/icons'
import {camelize} from 'humps'
import useSWR from 'swr'
import {useTranslation} from 'react-i18next'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'
import BankAccountCard from '../../components/BankAccountCard'
import {useRails} from '../../contexts/rails'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import {fetcher} from '../../../api'
import DebitAgreementInstructions from '../../components/DebitAgreementInstructions'

const Field = ({label, value}) => (
  <Flex direction="column" gap="sm">
    <Text textStyle="body-intro-medium">{label}</Text>
    <Text textStyle="body-small">{value}</Text>
  </Flex>
)

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

const StatusIcon = ({status}) => {
  if (status === 'active') {
    return (
      <CheckCircleIcon
        data-testid="active-status-icon"
        color="mld.success.700"
      />
    )
  }

  if (['processing', 'created', 'paused'].includes(status)) {
    return (
      <WarningIcon data-testid="warning-status-icon" color="mld.warning.700" />
    )
  }

  return <WarningIcon data-testid="failed-status-icon" color="mld.alert.700" />
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
}

const PaymentMethodScreen = withProviders(
  ({
    authenticityToken,
    paymentMethodId,
    paymentMethod: initialPaymentMethod,
  }) => {
    const {t} = useTranslation('billPayments')
    const {organisation} = useRails()
    const {data: paymentMethod} = useSWR(
      `/api/suppliers/${organisation.currentSupplier.id}/payment_methods/${paymentMethodId}`,
      fetcher,
      {
        refreshInterval: 5000,
        fallbackData: initialPaymentMethod,
        keepPreviousData: true,
        use: [camelizeResult, dineroMoneyObjects],
      },
    )

    const parsedPaymentMethod = paymentMethod[camelize(paymentMethod.type)]

    return (
      <AuthProvider authenticityToken={authenticityToken}>
        <SupplierLayout state="Pay bills">
          <Flex flexDirection="column" p="md">
            <Text as="h2" textStyle="headline2">
              {t('billPayments:paymentMethods.debitAgreement.title')}
            </Text>

            <Divider my="md" />
            <Flex gap="md" flexWrap="wrap" justify="space-between">
              <Flex
                p="md"
                direction="column"
                gap="md"
                bgColor="mld.neutral.200"
                borderRadius="base"
                width="100%"
                minW="400px"
                maxW="48%"
              >
                <Flex justify="space-between">
                  <Field
                    value={t(
                      `billPayments:paymentMethods.debitAgreement.statusDescription.${parsedPaymentMethod.status}`,
                    )}
                  />
                  <StatusIcon status={parsedPaymentMethod.status} />
                </Flex>
                <Field
                  label={t(
                    'billPayments:paymentMethods.debitAgreement.bankDetailsLabel',
                  )}
                  value={
                    <BankAccountCard
                      bsb={parsedPaymentMethod.branchCode}
                      accountNumber={parsedPaymentMethod.accountNumber}
                    />
                  }
                />
                <Field
                  label={t(
                    'billPayments:paymentMethods.debitAgreement.expiryLabel',
                  )}
                  value={parsedPaymentMethod.expiryDate}
                />
              </Flex>
              <DebitAgreementInstructions status={parsedPaymentMethod.status} />
            </Flex>
          </Flex>
        </SupplierLayout>
      </AuthProvider>
    )
  },
)

PaymentMethodScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default PaymentMethodScreen
