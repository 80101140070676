import {camelizeKeys} from 'humps'
import useSWR from 'swr'
import {useRails} from '../../contexts/rails'
import {fetcher} from '../../../api'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import calculateCurrentStep from './calculateCurrentStep'
import generateCashinSteps from './steps'

export default function useStepperData({pauseFetching = false} = {}) {
  const {accountingProvider} = useRails()
  const {data, isLoading: loadingData} = useSWR(
    !pauseFetching ? '/api/cashin_stepper' : null,
    fetcher,
  )

  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()
  const stepperData = {
    ...camelizeKeys(data?.stepper || {}),
    features: features || {},
  }

  const isLoading = loadingData || loadingFeatures

  const currentStep = calculateCurrentStep(stepperData)
  const cashinSteps = generateCashinSteps({
    ...stepperData,
    currentStep,
    accountingProvider,
  })

  return {
    isLoading,
    cashinSteps,
    currentStep,
  }
}
