import PropTypes from 'prop-types'
import React from 'react'
import {WarningTwoIcon} from '@chakra-ui/icons'
import {
  CloseButton,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  Box,
  Text,
  Stack,
  Link,
  Skeleton,
} from '@chakra-ui/react'

import {BankAccountCard, PayidCard} from '..'
import {Card} from '../Card'
import {toCurrency} from '../../../utils/formatter'
import {useRails} from '../../contexts/rails'
import useBankAccount from '../../hooks/useBankAccount'

const RemitManuallyModal = ({amount, isOpen, onClose}) => {
  const {organisation} = useRails()

  const {data, error, loading} = useBankAccount()

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose} preserveScrollBarGap>
      <ModalOverlay zIndex="modal" />
      <ModalContent borderRadius="lg" py="sm">
        <ModalHeader fontSize="22px">
          <Stack isInline justifyContent="space-between">
            <span>Remit funds using bank transfer</span>
            <CloseButton onClick={onClose} />
          </Stack>
        </ModalHeader>

        <ModalBody fontSize="sm">
          <Text>
            Please reimburse the following amount to the bank account below.
            Please use the supplied reference.
          </Text>

          <Stack isInline justifyContent="space-between" my="md" px="sm">
            <Box>
              <Text fontSize="sm" color="neutral.60">
                Total amount to be reimbursed
              </Text>

              <Text fontWeight="medium" data-testid="remit-manually-amount">
                {toCurrency(amount)}
              </Text>
            </Box>

            <Box textAlign="right">
              <Text fontSize="sm" color="neutral.60">
                Reference
              </Text>

              <Text fontWeight="medium">
                {organisation.currentSupplier.shortCode} Reimburse
              </Text>
            </Box>
          </Stack>

          <Stack spacing="sm" mb="sm">
            {error && (
              <Text fontSize="sm" fontWeight="medium">
                Unable to fetch bank details, please try again later.
              </Text>
            )}

            {!error && (
              <Stack spacing="sm" mb="sm">
                <Skeleton isLoaded={!loading}>
                  <BankAccountCard
                    accountName={data.accountName || ' '}
                    accountNumber={data.accountNumber || ' '}
                    bsb={data.bsb || ' '}
                  />
                </Skeleton>

                <Skeleton isLoaded={!loading}>
                  {data.payid && <PayidCard payid={data.payid} />}
                </Skeleton>
              </Stack>
            )}
          </Stack>

          <Card bg="background.red" border="0">
            <Box display="flex" alignItems="">
              <WarningTwoIcon
                color="primary.destructive"
                fontSize="md"
                mr="sm"
              />

              <Box>
                <Text fontWeight="medium" mb="xs">
                  Cash-in will be affected
                </Text>

                <Text fontSize="sm">
                  Remitting funds using bank transfer may take 2-3 days to
                  process. The fastest way to start cashing-in again is to{' '}
                  <Link fontWeight="medium" href="/cashin">
                    settle via cash-in
                  </Link>
                  .
                </Text>
              </Box>
            </Box>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

RemitManuallyModal.defaultProps = {
  amount: undefined,
}

RemitManuallyModal.propTypes = {
  amount: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RemitManuallyModal
