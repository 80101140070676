import React from 'react'
import PropTypes from 'prop-types'
import {Box, IconButton, Text, useDisclosure} from '@chakra-ui/react'

import InvoiceFilterOptions from './InvoiceFilterOptions'
import constructFilterDetails from './constructFilterDetails'
import preFilterQuery from './preFilterQuery'
import {FilterIcon} from '../../../icons'

const InvoiceFilter = ({
  activeFilters,
  filterOption,
  setFilterParams,
  setFilterOption,
  setActiveFilters,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const handleClickApplyFilter = (value) => {
    const {
      filterOption: filterOptionState,
      params,
      activeFilters: totalActiveFilters,
    } = constructFilterDetails(value)

    setFilterParams(params)
    onClose()
    setFilterOption(filterOptionState)
    setActiveFilters(totalActiveFilters)
  }

  const handleClickResetFilter = () => {
    setFilterParams('')
    onClose()
    setFilterOption({})
    setActiveFilters(0)
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        {Boolean(activeFilters) && (
          <Text
            fontWeight="medium"
            color="neutral.white"
            backgroundColor="primary.actionblue"
            borderRadius="full"
            height="25px"
            width="25px"
            textAlign="center"
          >
            {activeFilters}
          </Text>
        )}

        <Text ml="xs" color="neutral.80">
          Filter invoices
        </Text>

        <IconButton
          variant="tertiary"
          onClick={onOpen}
          isRound
          fontSize="lg"
          ml="xs"
          aria-label="invoice filter"
        >
          <FilterIcon />
        </IconButton>
      </Box>

      <InvoiceFilterOptions
        onClickApply={handleClickApplyFilter}
        onClickReset={handleClickResetFilter}
        filterOption={filterOption}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

InvoiceFilter.propTypes = {
  activeFilters: PropTypes.number.isRequired,
  filterOption: InvoiceFilterOptions.propTypes.filterOption.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  setFilterOption: PropTypes.func.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
}

export {preFilterQuery}

export default InvoiceFilter
