import React from 'react'
import PropTypes from 'prop-types'
import {ChevronRightIcon, ChevronDownIcon} from '@chakra-ui/icons'
import {
  Button,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react'
import AllocateCreditNotePanel from '../../components/AllocateCreditNotePanel'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import ReissuanceForm from '../../components/ReissuanceForm'
import {AddOverdueDetailsModal} from '../../components'

const ReissueDrawer = ({invoice, mutate}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const handleClick = (event) => {
    event.stopPropagation()
    onOpen()
  }
  const handleClose = () => {
    mutate()
    onClose()
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          {invoice && (
            <ReissuanceForm
              selectedInvoice={invoice}
              handleClose={handleClose}
            />
          )}
        </DrawerContent>
      </Drawer>

      <MenuItem onClick={handleClick}>Reissue invoice</MenuItem>
    </>
  )
}

ReissueDrawer.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
}

const InvoiceActionButton = ({invoice, mutate}) => {
  const {
    data: {features},
    loading: loadingFeatures,
  } = useFeatureFlags()

  const overdueInvoiceInfoEnabled =
    !loadingFeatures && features && features.overdueInvoiceInfo
  const creditNotesEnabled =
    !loadingFeatures && features && features.creditNotes

  const handleClick = (event) => {
    event.stopPropagation()
  }

  const MenuDisclosure = useDisclosure()

  const showOverdueInfoButton =
    overdueInvoiceInfoEnabled && invoice.canAddOverdueInfo
  const OverdueModalDisclosure = useDisclosure()

  const allocateButton = ({isDisabled, openDrawer}) => (
    <MenuItem
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation()
        openDrawer()
      }}
    >
      Allocate Credit Note
    </MenuItem>
  )

  const actions = [
    {
      key: 'viewDetails',
      isVisible: true,
      label: 'View Details',
    },
    {
      key: 'allocateCreditNote',
      isVisible: creditNotesEnabled,
      component: (
        <AllocateCreditNotePanel
          invoiceId={invoice.id}
          triggerButton={allocateButton}
          delayFetch={MenuDisclosure.isOpen}
        />
      ),
    },
    {
      key: 'canAddOverdueInfo',
      isVisible: showOverdueInfoButton,
      label: (
        <>
          Add details{' '}
          <Box
            as="span"
            my="auto"
            ml="xs"
            borderRadius="full"
            w="8px"
            h="8px"
            backgroundColor="red.600"
          />
        </>
      ),
      onClick: (e) => {
        e.stopPropagation()
        OverdueModalDisclosure.onOpen()
      },
    },
    {
      key: 'reissue',
      isVisible: invoice.canReissue && !invoice.reissuedAt,
      component: <ReissueDrawer invoice={invoice} mutate={mutate} />,
    },
  ].filter((action) => action.isVisible)

  return (
    <>
      {showOverdueInfoButton && (
        <AddOverdueDetailsModal
          isOpen={OverdueModalDisclosure.isOpen}
          beforeClose={mutate}
          invoice={invoice}
          onClose={OverdueModalDisclosure.onClose}
        />
      )}
      <Menu {...MenuDisclosure}>
        {({isOpen}) => (
          <>
            <MenuButton
              as={Button}
              variant="secondary"
              onClick={handleClick}
              rightIcon={isOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
              size="sm"
            >
              <Flex>
                Action
                {showOverdueInfoButton ? (
                  <Box
                    as="span"
                    position="absolute"
                    right="8px"
                    top="8px"
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    backgroundColor="red.600"
                  />
                ) : (
                  ''
                )}
              </Flex>
            </MenuButton>
            <MenuList>
              {actions.map(({key, label, onClick, component}) => {
                return (
                  <React.Fragment key={key}>
                    {component || (
                      <MenuItem onClick={onClick}>{label}</MenuItem>
                    )}
                  </React.Fragment>
                )
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  )
}

InvoiceActionButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    due_amount: PropTypes.shape({
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    canAddOverdueInfo: PropTypes.bool.isRequired,
    canReissue: PropTypes.bool.isRequired,
    reissuedAt: PropTypes.number,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
}

export default InvoiceActionButton
