import React from 'react'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'
import {get, map} from 'lodash-es'
import i18next from 'i18next'
import {useRails} from '../../../../contexts/rails'
import {switchOrganisation} from '../../../../../api'
import AccountIntegration from './AccountIntegration/index'
import BankAccountSelection from './BankAccountSelection'
import Compliance from './Compliance'
import ExpenseAccountSelection from './ExpenseAccountSelection'
import UnsupportedPlan from './UnsupportedPlan'
import OrganisationSelection from './OrganisationSelection'
import BankAccountIntegration from './BankAccountIntegration'
import AcquisitionDecision from './AcquisitionDecision'
import SideNavLayout from './SideNavLayout'
import DecisionProcessExpectation from './DecisionProcessExpectation'

const calculateStep = (railsData) => {
  const {
    xero,
    authorisedOrganisations,
    organisation,
    supplierSetupTrackerState,
  } = railsData

  const currentSteps = {
    select_payout_account: 'BankAccountSelection',
    select_expense_account: 'ExpenseAccountSelection',
    compliance: 'Compliance',
    bank_account_connection_begin: 'BankAccountIntegration',
    bank_account_connection_succeeded: 'BankAccountIntegration',
    bank_account_connection_not_connected: 'BankAccountIntegration',
    bank_account_connection_failed: 'BankAccountIntegration',
    decision_processing_expectations: 'DecisionProcessExpectation',
    decision_processing_progress: 'AcquisitionDecision',
    decision_unsupported: 'AcquisitionDecision',
    decision_supported: 'AcquisitionDecision',
    decision_pending: 'AcquisitionDecision',
  }
  const isSupportedPlan = organisation?.currentSupplier?.supportedPlan !== false

  if (
    supplierSetupTrackerState === 'connect_accounting_provider' &&
    authorisedOrganisations
  )
    return 'OrganisationSelection'

  // There is an extra guard clause here in case all of a logins supplier records are closed
  if (
    supplierSetupTrackerState === 'connect_accounting_provider' ||
    !xero.isLinked
  )
    return 'AccountIntegration'

  if (!isSupportedPlan) return 'UnsupportedPlan'

  return currentSteps[supplierSetupTrackerState] || null
}

const OnboardingStep = (props) => {
  const railsData = useRails()
  const currentStep = calculateStep({...railsData, ...props})

  if (currentStep === 'OrganisationSelection')
    return <OrganisationSelection {...props} />
  if (currentStep === 'AccountIntegration')
    return <AccountIntegration {...props} />
  if (currentStep === 'UnsupportedPlan') return <UnsupportedPlan {...props} />
  if (currentStep === 'BankAccountSelection')
    return <BankAccountSelection {...props} />
  if (currentStep === 'ExpenseAccountSelection')
    return <ExpenseAccountSelection {...props} />
  if (currentStep === 'Compliance') return <Compliance {...props} />
  if (currentStep === 'BankAccountIntegration')
    return <BankAccountIntegration {...props} />
  if (currentStep === 'DecisionProcessExpectation')
    return <DecisionProcessExpectation {...props} />
  if (currentStep === 'AcquisitionDecision')
    return <AcquisitionDecision {...props} />
  return null
}

const Onboarding = ({supplierSetupTrackerState}) => {
  i18next.loadNamespaces('onboarding')
  const railsData = useRails()
  const {organisation, authenticityToken} = railsData
  const currentStep = calculateStep({...railsData, supplierSetupTrackerState})

  const currentSupplier = {
    label: get(organisation, 'currentSupplier.name', ''),
    value: get(organisation, 'currentSupplier.id', ''),
  }

  const availableSupplierOptions = map(
    organisation.availableSuppliers,
    (supplier) => ({label: supplier.name, value: supplier.id}),
  )

  const handleOrganisationChange = ({value}) => {
    if (value !== 'new-organisation') {
      switchOrganisation({authenticityToken, supplierId: value})
        .then(() => {
          // Reload the page
          window.location.reload()
        })
        .catch(() => {
          toast.error(
            'Oops! Something went wrong when trying to select an organisation.',
          )
        })
    }
  }

  const dropdownSelectProps = {
    name: 'organisation',
    placeholder: 'Supplier organisation',
    options: availableSupplierOptions,
    value: currentSupplier,
    onChange: handleOrganisationChange,
  }

  return currentStep !== 'AcquisitionDecision' ? (
    <SideNavLayout
      authenticityToken={authenticityToken}
      dropdownSelectProps={dropdownSelectProps}
      signOutAction="/supplier_logins/sign_out"
    >
      <OnboardingStep supplierSetupTrackerState={supplierSetupTrackerState} />
    </SideNavLayout>
  ) : (
    <OnboardingStep
      supplierSetupTrackerState={supplierSetupTrackerState}
      dropdownSelectProps={dropdownSelectProps}
    />
  )
}

Onboarding.propTypes = {
  supplierSetupTrackerState: PropTypes.string.isRequired,
}

export default Onboarding
