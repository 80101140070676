import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {Box, useDisclosure} from '@chakra-ui/react'
import useSWR from 'swr'
import {fetcher} from '../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import DataTable from '../DataTable'
import BillPaymentsDrawer from '../BillPaymentsDrawer'
import useColumns from './useColumns'

const BillPaymentsTable = ({supplierId}) => {
  const {data, isLoading} = useSWR(
    `/api/suppliers/${supplierId}/bill_payments`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {billPayments = []} = data || {}

  const {columns} = useColumns()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [selectedPaymentId, setSelectedPaymentId] = React.useState(null)
  const selectedPayment = billPayments.find(({id}) => id === selectedPaymentId)

  const showPayment = useCallback(
    ({id}) => {
      setSelectedPaymentId(id)
      onOpen()
    },
    [onOpen],
  )
  const chakraTableProps = {size: 'md'}
  const sortByValue = [{id: 'createdAt', desc: true}]

  return (
    <>
      <BillPaymentsDrawer
        billPayment={selectedPayment}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box
        overflowX="auto"
        border="1px solid"
        borderRadius="md"
        borderColor="mld.neutral.200"
      >
        <DataTable
          chakraTableProps={chakraTableProps}
          columns={columns}
          enableSorting
          onClickRow={showPayment}
          sortByValue={sortByValue}
          data={billPayments}
          isLoading={isLoading}
        />
      </Box>
    </>
  )
}

BillPaymentsTable.propTypes = {
  supplierId: PropTypes.string.isRequired,
}

export default BillPaymentsTable
