import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {toast} from 'react-toastify'
import {
  Box,
  Flex,
  Text,
  Skeleton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import {ToastMessage} from '../../../../components/Toast'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import {AuthProvider} from '../../../../contexts/authentication'
import {useRails} from '../../../../contexts/rails'
import InvoiceCustomerDetailForm from '../../InvoiceCustomerDetailForm'
import ViewDetailsButton from './ViewDetailsButton'

const AddDetailsDrawer = ({
  row,
  isOpen,
  onClose,
  setProcessing,
  onSuccess,
  onStart,
  btnRef,
}) => {
  const {authenticityToken} = useRails()
  const {data, error, isLoading} = useSWR(
    isOpen ? `/api/invoices/${row.original.id}/customer` : null,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  return (
    <Drawer
      isOpen={isOpen}
      size="md"
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex py="xs">
            <Text textStyle="body-intro-medium">
              {row.original.invoiceNumber}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Box
            py="sm"
            borderY="1px solid"
            borderColor="neutral.glitter"
            display="flex"
            justifyContent="space-between"
            mb="sm"
          >
            <Text>{row.original.contactName}</Text>
            <Text fontWeight="medium">{row.original.dueAmount.toFormat()}</Text>
          </Box>
          <Text mb="xs" fontWeight="medium">
            You can almost cash this invoice in!
          </Text>
          <Text mb="md" fontSize="sm">
            In order to make this invoice eligible to cash-in, you will need to
            complete the following details:
          </Text>
          {error && (
            <Text fontSize="sm" mb="sm" color="primary.errorred">
              Error in fetching customer detail: {error}
            </Text>
          )}
          {isLoading &&
            Array.from({length: 3}).map((_, i) => (
              <Skeleton key={`skeleton-${i + 1}`} mb="sm" height="32px" />
            ))}
          <AuthProvider authenticityToken={authenticityToken}>
            {data && (
              <InvoiceCustomerDetailForm
                customer={data.customer}
                invoiceId={row.original.id}
                onClickSubmit={() => {
                  onStart(row.original.id)
                  setProcessing(true)
                  onSuccess(row.original.id)
                  onClose()

                  toast.dark(
                    <ToastMessage>
                      {row.original.invoiceNumber} is now added to Available now
                      tab. Go to Available now tab now to cash-in.
                    </ToastMessage>,
                  )
                }}
                onClickCancel={() => {
                  onClose()
                }}
              />
            )}
          </AuthProvider>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

AddDetailsDrawer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactName: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
      dueAmount: PropTypes.shape(DineroPropShape).isRequired,
    }).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  btnRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]).isRequired,
}

const AddDetailsButtonCell = ({row, onSuccess, onStart}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [processing, setProcessing] = React.useState(false)
  const btnRef = React.useRef()

  return (
    <Flex direction={{base: 'column', md: 'row'}}>
      <Button
        variant="secondary"
        width="100%"
        isDisabled={processing}
        isLoading={processing}
        loadingText="Processing..."
        data-testid="add-details-button"
        ref={btnRef}
        onClick={onOpen}
      >
        Provide Details
      </Button>
      <ViewDetailsButton
        row={row}
        onNextClick={() => {
          onOpen()
        }}
        detailsRequired
      />
      <AddDetailsDrawer
        row={row}
        btnRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        setProcessing={setProcessing}
        onSuccess={onSuccess}
        onStart={onStart}
      />
    </Flex>
  )
}

AddDetailsButtonCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
}

export default AddDetailsButtonCell
