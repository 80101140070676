import React from 'react'
import {Box, Text, Image} from '@chakra-ui/react'
import {Screen, LogoHorizontal, ThemeProvider} from '../../../components'
import NotFound from '../../../../../assets/images/404_V2_Purple.png'

const CustomerInformPaymentNotFoundScreen = () => (
  <ThemeProvider>
    <Screen>
      <Box>
        <LogoHorizontal width={250} height={100} />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Text as="h1" textStyle="headline2" pb="sm">
              Sorry, this link is no longer available.
            </Text>

            <Text>
              It appears that this link is no longer available. If you have any
              questions
              <br />
              you can contact support at support@withmarmalade.com.
            </Text>
          </Box>

          <Image
            display={{base: 'none', md: 'inline-flex'}}
            w="296px"
            h="416px"
            src={NotFound}
            alt="Not found"
          />
        </Box>
      </Box>
    </Screen>
  </ThemeProvider>
)

export default CustomerInformPaymentNotFoundScreen
