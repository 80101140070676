import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import mixpanel from 'mixpanel-browser'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import {
  Box,
  CloseButton,
  Flex,
  Text,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import {viewedUpdatedBankDetails} from '../../../api/customers'
import {LogoHorizontal, ThemeProvider, BankAccountCard} from '../../components'
import InvalidToken from './InvalidToken'
import withProviders from '../../contexts/withProviders'
import {useRails} from '../../contexts/rails'

const PayerStatementScreen = ({
  statementGeneratedAt,
  nullStatement,
  bankAccount,
  supplierName,
  payerId,
  updatedBankDetailsViewedAt,
  tokenValid,
  resendStatementPath,
  statementPdfPath,
  statementPdfDownloadPath,
}) => {
  const {disableMixpanel} = useRails()
  dayjs.extend(relativeTime)
  const [
    statementGeneratedAtRelativeTime,
    setStatementGeneratedAtRelativeTime,
  ] = React.useState(dayjs().to(dayjs(statementGeneratedAt)))
  useEffect(() => {
    const interval = setInterval(() => {
      setStatementGeneratedAtRelativeTime(
        dayjs().to(dayjs(statementGeneratedAt)),
      )
    }, 15000) // 15 seconds in milliseconds

    return () => clearInterval(interval)
  }, [statementGeneratedAt])

  const {isOpen, onClose: closeModal} = useDisclosure({
    defaultIsOpen: !updatedBankDetailsViewedAt,
  })

  if (!disableMixpanel) {
    mixpanel.identify(payerId)
  }

  const onClose = () => {
    mixpanel.track('Statement Bank Details Modal Dimiss', {}, {}, () => {
      closeModal()
    })
  }
  const clickGotItButton = () => {
    mixpanel.track('Statement Bank Details Modal Dimiss', {}, {}, () => {
      viewedUpdatedBankDetails({customerId: payerId})
      closeModal()
    })
  }
  const afterCopy = () => {
    mixpanel.track('Statement Bank Details Modal Copy', {}, {}, () => {
      viewedUpdatedBankDetails({customerId: payerId})
    })
  }

  if (!tokenValid) {
    return <InvalidToken resendStatementPath={resendStatementPath} />
  }

  return (
    <ThemeProvider>
      <Modal isOpen={isOpen} size="lg" onClose={onClose} preserveScrollBarGap>
        <ModalOverlay zIndex="modal" />
        <ModalContent borderRadius="lg" p="md">
          <ModalHeader fontSize="lg">
            <Stack isInline justifyContent="space-between">
              <span>Bank transfer details have changed</span>
              <CloseButton onClick={onClose} />
            </Stack>
          </ModalHeader>
          <ModalBody>
            <Text mb="md">
              Please use the details in the statement for each invoice if paying
              by bank transfer so that your payment is accurately recorded.
            </Text>
            <BankAccountCard
              dark
              accountName={bankAccount.account_name}
              accountNumber={bankAccount.account_number}
              bsb={bankAccount.bsb}
              afterCopy={afterCopy}
            />
            <Button
              variant="primary"
              colorScheme="primary"
              size="lg"
              width="100%"
              type="submit"
              textTransform="uppercase"
              onClick={clickGotItButton}
              my="md"
            >
              OK, got it
            </Button>
            <Text fontSize="12px" color="grey.700">
              If you have any questions or concerns about changing bank transfer
              details, you may reach out to {supplierName}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box m={{base: 'xs', md: 'md'}}>
        <Text
          color="grey.500"
          fontWeight="400"
          fontSize="12px"
          display="flex"
          flexDirection="row"
        >
          Powered by
          <LogoHorizontal width={85} height={11} mb="sm" mt="3px" ml="xs" />
        </Text>

        <Flex
          justifyContent="space-between"
          mb="md"
          flexDirection={{base: 'column', md: 'row'}}
        >
          {nullStatement && (
            <Text fontSize="16px" my="md">
              There is no statement to generate as you do not have any open
              invoices
            </Text>
          )}
          {!nullStatement && (
            <>
              <Text fontSize="16px">
                Your account statement is available now, please download PDF.
              </Text>
              <Text
                fontSize="16px"
                mr={{base: '0', md: '300px'}}
                mt={{base: 'xs', md: '0'}}
              >
                <Box as="span" color="grey.700">
                  Generated
                </Box>{' '}
                {statementGeneratedAtRelativeTime}
              </Text>
            </>
          )}
        </Flex>
        <Button
          as="a"
          variant="primary"
          colorScheme="primary"
          href={statementPdfDownloadPath}
          display={{base: nullStatement ? 'none' : 'flex', md: 'none'}}
          p="md"
        >
          Download Statement
        </Button>
      </Box>

      <Flex m={{base: 'xs', md: 'md'}}>
        {!nullStatement && (
          <iframe
            title="payer-statement-pdf"
            width="100%"
            style={{height: '100vh', border: '1px solid #E9E9F0'}}
            src={`${statementPdfPath}#toolbar=0&navpanes=0`}
          />
        )}

        <Box mx="md">
          <Button
            as="a"
            variant="primary"
            colorScheme="primary"
            href={statementPdfDownloadPath}
            p="md"
            display={{base: 'none', md: nullStatement ? 'none' : 'flex'}}
          >
            Download Statement
          </Button>
        </Box>
      </Flex>
    </ThemeProvider>
  )
}

PayerStatementScreen.defaultProps = {
  statementGeneratedAt: '',
  updatedBankDetailsViewedAt: '',
  resendStatementPath: '',
}

PayerStatementScreen.propTypes = {
  statementGeneratedAt: PropTypes.string,
  nullStatement: PropTypes.bool.isRequired,
  supplierName: PropTypes.string.isRequired,
  payerId: PropTypes.string.isRequired,
  updatedBankDetailsViewedAt: PropTypes.string,
  bankAccount: PropTypes.shape({
    account_name: PropTypes.string.isRequired,
    account_number: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
  }).isRequired,
  tokenValid: PropTypes.bool.isRequired,
  resendStatementPath: PropTypes.string,
  statementPdfPath: PropTypes.string.isRequired,
  statementPdfDownloadPath: PropTypes.string.isRequired,
}

export default withProviders(PayerStatementScreen)
