import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text} from '@chakra-ui/react'
import FeeBreakdown from './FeeBreakdown'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import CustomerRatingsPropType from '../../../../../utils/propTypes/customerRating'

const InvoiceAmountCell = ({getValue, row}) => (
  <Box display="flex" flexDirection="column">
    <Text mb="xxs" fontWeight="medium">
      {getValue()}
    </Text>
    <FeeBreakdown
      id={row.original.id}
      customerRating={row.original.customerRating}
      feeAmount={row.original.feeAmount?.toFormat()}
      feeRate={row.original.feeRate}
      showBreakdown={row.original.showBreakdown}
      priceWithinMaximumLimit={row.original.priceWithinMaximumLimit}
      maximumFeeRate={row.original.maximumFeeRate}
      bestPriceRate={row.original.bestPriceRate}
      cashedIn={Boolean(row.original.cashedInDate)}
    />
  </Box>
)

InvoiceAmountCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      feeRate: PropTypes.string.isRequired,
      feeAmount: PropTypes.shape(DineroPropShape).isRequired,
      showBreakdown: PropTypes.bool.isRequired,
      customerRating: CustomerRatingsPropType.isRequired,
      priceWithinMaximumLimit: PropTypes.bool.isRequired,
      maximumFeeRate: PropTypes.string.isRequired,
      bestPriceRate: PropTypes.string,
      cashedInDate: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default InvoiceAmountCell
