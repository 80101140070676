import {toEpochSecond} from '../../../../utils/formatter'

const constructFilterDetails = (filterOption) => {
  const {
    customer,
    invoice,
    paymentId,
    issueDate,
    dueDate,
    dueAmount,
    daysPastDueDate,
    cashinStatus,
    status,
    paymentStatus,
    dueDateStatus,
  } = filterOption
  let params = []
  let activeFilters = 0

  if (paymentId) {
    params = params.concat(`payment_id=${paymentId}`)
  }

  if (customer && customer.value) {
    params = params.concat(`customer_id=${customer.value}`)
    activeFilters += 1
  }

  if (invoice && invoice.value) {
    params = params.concat(`invoice_id=${invoice.value}`)
    activeFilters += 1
  }

  if (issueDate) {
    if (issueDate.from) {
      params = params.concat(`issue_date_from=${toEpochSecond(issueDate.from)}`)
      activeFilters += 1
    }

    if (issueDate.to) {
      params = params.concat(`issue_date_to=${toEpochSecond(issueDate.to)}`)
      activeFilters += 1
    }
  }

  if (dueDate) {
    if (dueDate.from) {
      params = params.concat(`due_date_from=${toEpochSecond(dueDate.from)}`)
      activeFilters += 1
    }

    if (dueDate.to) {
      params = params.concat(`due_date_to=${toEpochSecond(dueDate.to)}`)
      activeFilters += 1
    }
  }

  if (dueAmount) {
    if (dueAmount.from) {
      params = params.concat(`due_cents_from=${dueAmount.from}`)
      activeFilters += 1
    }

    if (dueAmount.to) {
      params = params.concat(`due_cents_to=${dueAmount.to}`)
      activeFilters += 1
    }
  }

  if (daysPastDueDate && daysPastDueDate.value) {
    params = params.concat(`days_past_due_date=${daysPastDueDate.value}`)
    activeFilters += 1
  }

  if (cashinStatus && cashinStatus.length) {
    params = params.concat(`cashin_status=${cashinStatus.join(',')}`)
    activeFilters += cashinStatus.length
  }

  if (status && status.length) {
    params = params.concat(`status=${status.join(',')}`)
    activeFilters += status.length
  }

  if (paymentStatus && paymentStatus.length) {
    params = params.concat(`payment_status=${paymentStatus.join(',')}`)
    activeFilters += paymentStatus.length
  }

  if (dueDateStatus && dueDateStatus.length) {
    params = params.concat(`duedate_status=${dueDateStatus.join(',')}`)
    activeFilters += dueDateStatus.length
  }

  params = params.join('&')

  return {params, activeFilters, filterOption}
}

export default constructFilterDetails
