import {useEffect, useState} from 'react'

const useYodlee = ({
  containerId,
  createScriptTag = true,
  fastLinkOptions: {
    fastLinkURL,
    token,
    configName,
    providerAccountId,
    flow,
    userExperienceFlow = 'Verification',
  },
  onSuccess,
  onError,
  onExit,
  onEvent,
}) => {
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [active, setActive] = useState(false)

  useEffect(() => {
    let script
    if (createScriptTag) {
      script = document.createElement('script')

      script.id = 'yodlee-fastlink-script'
      script.src = 'https://cdn.yodlee.com/fastlink/v4/initialize.js'
      script.async = true
      script.defer = true
      script.onload = () => setReady(true)
      script.onerror = () =>
        setError('Yodlee FastLink library could not be loaded!')

      document.body.appendChild(script)
    }

    return () => {
      window.fastlink?.close() /* eslint-disable-line no-unused-expressions */
      if (createScriptTag) {
        document.body.removeChild(script)
      }
    }
  }, [createScriptTag])

  const reset = () => {
    setActive(false)
  }

  const init = (currentToken) => {
    const getTokenString = (t) => {
      switch (t.tokenType) {
        case 'AccessToken': {
          return {accessToken: `Bearer ${t.tokenValue}`}
        }
        case 'JwtToken': {
          return {jwtToken: `Bearer ${t.tokenValue}`}
        }
        default: {
          return {}
        }
      }
    }

    setActive(true)

    const params = {
      userExperienceFlow,
      configName,
    }

    if (flow) {
      params.flow = flow
    }
    if (providerAccountId) {
      params.providerAccountId = providerAccountId
    }

    window.fastlink.open(
      {
        fastLinkURL,
        params,
        ...getTokenString(currentToken || token),
        onSuccess: (customerData) => {
          setData(customerData)
          return onSuccess && onSuccess(customerData)
        },
        onError: (fastLinkError) => {
          setError(fastLinkError)
          return onError && onError(fastLinkError)
        },
        onClose: (closeData) => {
          reset()
          return onExit && onExit(closeData)
        },
        onEvent: (eventData) => {
          return onEvent && onEvent(eventData)
        },
      },
      containerId,
    )
  }

  return {
    init,
    data,
    error,
    ready,
    active,
    reset,
  }
}

export default useYodlee
