import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Text, Button, Flex, Box} from '@chakra-ui/react'
import {ArrowRightIcon} from '../../../../../icons'

const NotConnected = ({goToNextPage}) => {
  const {t} = useTranslation('onboarding')
  const i18nKeyPrefix = 'onboarding:BankAccountIntegration.notConnectedScreen'
  return (
    <Flex direction="column" gap="md">
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="1"
        lineHeight="1"
        fontWeight="400"
      >
        {t(`${i18nKeyPrefix}.title`)}
      </Text>
      <Text textStyle="body-intro">{t(`${i18nKeyPrefix}.message`)}</Text>

      <Box>
        <Button
          variant="primary"
          colorScheme="primary"
          size="lg"
          onClick={goToNextPage}
        >
          {t(`${i18nKeyPrefix}.ctaButton`)}
          <ArrowRightIcon width="24px" height="24px" ml="xs" />
        </Button>
      </Box>
    </Flex>
  )
}

NotConnected.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
}

export default NotConnected
