import {Box, Text, Link} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import {toast, ToastContainer as BaseToastContainer} from 'react-toastify'

export const ToastMessage = ({
  children,
  linkHref,
  linkLabel,
  'data-testid': dataTestId,
}) => {
  return (
    <Box
      px="sm"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      fontFamily="body"
      data-testid={dataTestId}
    >
      <Text fontSize="sm" as="span">
        {children}
      </Text>

      {linkHref && (
        <Link
          href={linkHref}
          fontSize="sm"
          fontWeight="medium"
          color="primary.yellow"
          textTransform="uppercase"
        >
          {linkLabel}
        </Link>
      )}
    </Box>
  )
}

ToastMessage.propTypes = {
  linkHref: PropTypes.string,
  linkLabel: PropTypes.string,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
}

ToastMessage.defaultProps = {
  linkHref: null,
  linkLabel: 'View',
  'data-testid': undefined,
}

export const ToastContainer = (props) => (
  <BaseToastContainer
    enableMultiContainer
    position={toast.POSITION.BOTTOM_RIGHT}
    autoClose={6000}
    closeButton={false}
    closeOnClick={false}
    draggable={false}
    hideProgressBar
    pauseOnHover
    {...props}
  />
)

export default ToastMessage
