import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Image,
  SkeletonText,
  Text,
} from '@chakra-ui/react'
import {WarningIcon} from '@chakra-ui/icons'

import {useTranslation} from 'react-i18next'
import CopyButton from '../CopyButton'
import {StarTickIcon} from '../../icons'

import AnzLogo from '../../../../assets/images/banking_provider_logos/anz_logo.svg'
import WestpacLogo from '../../../../assets/images/banking_provider_logos/westpac_logo.svg'
import NabLogo from '../../../../assets/images/banking_provider_logos/nab_logo.svg'
import CommonwealthLogo from '../../../../assets/images/banking_provider_logos/commonwealth_bank_logo.svg'
import DefaultLogo from '../../../../assets/images/banking_provider_logos/default_logo.svg'

const statusColor = (status) => {
  if (status === 'verified') {
    return 'mld.success.50'
  }

  if (status === 'unverified') {
    return 'mld.alert.50'
  }

  // Else return white because no status info is shown
  return 'mld.neutral.50'
}

const StatusHeader = ({status}) => {
  const {t} = useTranslation()

  if (status === 'hidden') {
    return null
  }

  return (
    <>
      <CardHeader
        py="xxs"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        borderRadius="inherit"
        bgColor={statusColor(status)}
        gap="sm"
      >
        <Text textStyle="body-detail-medium" textTransform="uppercase">
          {t(`BankAccountCard.status.${status}`)}
        </Text>
        {status === 'verified' ? (
          <StarTickIcon boxSize="16px" />
        ) : (
          <WarningIcon boxSize="14px" color="mld.alert.700" />
        )}
      </CardHeader>
      <Divider />
    </>
  )
}

StatusHeader.propTypes = {
  status: PropTypes.oneOf(['hidden', 'verified', 'unverified']).isRequired,
}

const getBankLogo = ({providerName}) => {
  let image
  switch (providerName) {
    case 'ANZ':
      image = AnzLogo
      break
    case 'Commonwealth Bank':
      image = CommonwealthLogo
      break
    case 'NAB Connect':
      image = NabLogo
      break
    case 'NAB Internet':
      image = NabLogo
      break
    case 'Westpac':
      image = WestpacLogo
      break
    default:
      image = DefaultLogo
  }

  return image
}

const BankAccountCard = ({
  accountNumber,
  bsb,
  providerName,
  status,
  showCopyButtons,
  isLoading,
}) => {
  const bankLogo = getBankLogo({providerName})

  return (
    <Card
      width="fit-content"
      size="md"
      borderColor="mld.neutral.600"
      variant="outline"
    >
      <StatusHeader status={status} />
      <CardBody display="flex" flexWrap="wrap" alignItems="center" gap="md">
        {providerName !== 'hidden' && (
          <Image src={bankLogo} maxW="60px" maxH="30px" alt={providerName} />
        )}
        <Flex direction="column">
          <Text textStyle="body-detail">BSB</Text>
          <SkeletonText
            isLoaded={!isLoading}
            mt="xxs"
            noOfLines={1}
            skeletonHeight="27px"
          >
            <Flex align="center">
              <Text textStyle="body-intro">{bsb}</Text>
              {showCopyButtons && (
                <CopyButton
                  color="mld.neutral.700"
                  label="Copy BSB"
                  size="sm"
                  content={bsb}
                />
              )}
            </Flex>
          </SkeletonText>
        </Flex>
        <Flex direction="column">
          <Text textStyle="body-detail">Account</Text>
          <SkeletonText
            isLoaded={!isLoading}
            mt="xxs"
            noOfLines={1}
            skeletonHeight="27px"
          >
            <Flex align="center">
              <Text textStyle="body-intro">{accountNumber}</Text>
              {showCopyButtons && (
                <CopyButton
                  color="mld.neutral.700"
                  label="Copy account number"
                  size="sm"
                  content={accountNumber}
                />
              )}
            </Flex>
          </SkeletonText>
        </Flex>
      </CardBody>
    </Card>
  )
}

BankAccountCard.defaultProps = {
  status: 'hidden',
  providerName: 'default',
  isLoading: false,
  showCopyButtons: false,
}

BankAccountCard.propTypes = {
  providerName: PropTypes.string,
  accountNumber: PropTypes.string.isRequired,
  bsb: PropTypes.string.isRequired,
  showCopyButtons: PropTypes.bool,
  isLoading: PropTypes.bool,
  status: PropTypes.oneOf(['hidden', 'verified', 'unverified']),
}

export default BankAccountCard
