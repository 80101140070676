import React from 'react'
import PropTypes from 'prop-types'
import {Divider, Flex, Text} from '@chakra-ui/react'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'

const BillPaymentScreen = withProviders(({authenticityToken}) => {
  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Pay bills">
        <Flex flexDirection="column" height="100vh" p="md">
          <Text as="h2" textStyle="headline2">
            Pay Bills
          </Text>

          <Divider my="md" />
        </Flex>
      </SupplierLayout>
    </AuthProvider>
  )
})

BillPaymentScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default BillPaymentScreen
