import React from 'react'
import {Box, Flex, Text} from '@chakra-ui/react'
import propTypes from 'prop-types'

const Step = ({
  label,
  isCurrentState = false,
  isBeforeCurrentState = false,
}) => {
  const getStateBackgroundColour = (isBeforeCurrent, isCurrent) => {
    switch (true) {
      case isBeforeCurrent && !isCurrent:
        return 'primary.actionblue'
      case !isBeforeCurrent && !isCurrent:
        return 'neutral.20'
      default:
        return 'neutral.white'
    }
  }

  return (
    <Flex
      direction={['column', 'row']}
      gap={['0.3rem', '0.6rem']}
      align="center"
      justify="center"
    >
      <Box
        w="18px"
        h="18px"
        flexShrink={0}
        borderRadius="full"
        borderColor={
          !isBeforeCurrentState && !isCurrentState
            ? 'neutral.20'
            : 'primary.actionblue'
        }
        borderWidth="2px"
        bg={getStateBackgroundColour(isBeforeCurrentState, isCurrentState)}
      />
      <Text
        fontSize="14px"
        textAlign="center"
        color={
          !isBeforeCurrentState && !isCurrentState
            ? 'neutral.20'
            : 'neutral.black'
        }
      >
        {label}
      </Text>
    </Flex>
  )
}

Step.propTypes = {
  label: propTypes.string.isRequired,
  isCurrentState: propTypes.bool.isRequired,
  isBeforeCurrentState: propTypes.bool.isRequired,
}

export default Step
