import PropTypes from 'prop-types'
import React from 'react'
import {Field, Form, Formik} from 'formik'
import {Box, Text, Button, Input} from '@chakra-ui/react'
import InputFlexWrap from './InputFlexWrap'
import {DeleteIcon} from '../../../icons/DeleteIcon'

const RecipientForm = ({
  onSubmit,
  onRemove,
  email,
  index,
  saveButtonText,
  savedButtonText,
  removeButtonText,
}) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={{email}}>
      {({errors, touched, dirty, isSubmitting}) => (
        <Form>
          <InputFlexWrap>
            <Box width="100%" maxWidth="600">
              <Field
                as={Input}
                errorMessage={touched.email && errors.email}
                name="email"
                data-testid={`update-recipient-email-${index}`}
              />
            </Box>
            <Box>
              <Button
                variant="secondary"
                type="submit"
                data-testid="update-recipient-button"
                isLoading={isSubmitting}
                isDisabled={!dirty}
              >
                {dirty ? saveButtonText : savedButtonText}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={onRemove}
                variant="tertiary"
                data-testid={`remove-recipient-${index}`}
                isLoading={isSubmitting}
              >
                {removeButtonText}
                <DeleteIcon ml="xs" />
              </Button>
            </Box>
          </InputFlexWrap>
          <Box width="100%" maxWidth="600">
            {errors.email && (
              <Text mt={1} textStyle="body-error">
                {errors.email}
              </Text>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  )
}

RecipientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  saveButtonText: PropTypes.string.isRequired,
  savedButtonText: PropTypes.string.isRequired,
  removeButtonText: PropTypes.string.isRequired,
}

export default RecipientForm
