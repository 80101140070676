import React from 'react'
import {Box} from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const OldTable = (props) => (
  <Box
    width="100%"
    as="table"
    mb="sm"
    position="relative"
    css={() => ({
      borderCollapse: 'separate',
      borderSpacing: 0,
    })}
    {...props}
  />
)

export const OldRow = ({applyHoverStyles, ...props}) => (
  <Box
    as="tr"
    css={(theme) => {
      const baseStyles = {
        height: '1px',
        '& > td': {
          height: 'inherit',
        },
        '& > td > div': {
          height: '100%',
          minHeight: '60px',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'transparent',
          borderBottomColor: theme.colors.neutral.glitter,
        },
        '&:last-child > td > div': {
          borderBottomColor: 'transparent',
        },
      }

      if (!applyHoverStyles) return baseStyles

      return {
        ...baseStyles,
        '&:hover > td > div': {
          height: 'calc(100% + 1px)',
          minHeight: '61px',
          marginTop: '-1px',
          paddingTop: '1px',
          backgroundColor: theme.colors.neutral.smoke,
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: theme.colors.neutral.glitter,
          cursor: 'pointer',
        },
        '&:hover > td:first-of-type > div': {
          marginLeft: theme.space.xs,
          paddingLeft: `calc(${theme.space.sm} - 1px)`,
          borderLeft: '1px solid',
          borderColor: theme.colors.neutral.glitter,
          borderRadius: '8px 0 0 8px',
          cursor: 'pointer',
        },
        '&:hover > td:last-child > div': {
          marginRight: theme.space.xs,
          paddingRight: `calc(${theme.space.sm} - 1px)`,
          borderRight: '1px solid',
          borderColor: theme.colors.neutral.glitter,
          borderRadius: '0 8px 8px 0',
          cursor: 'pointer',
        },
        '&:last-child:not(:hover) > td > div': {
          borderBottomColor: 'transparent',
        },
      }
    }}
    {...props}
  />
)

OldRow.propTypes = {
  applyHoverStyles: PropTypes.bool,
}

OldRow.defaultProps = {
  applyHoverStyles: false,
}

export const OldCell = ({children, ...props}) => (
  <Box
    as="td"
    css={(theme) => ({
      padding: 0,
      '&:first-of-type > div': {
        marginLeft: theme.space.md,
      },
      '&:last-child > div': {
        marginRight: theme.space.md,
      },
    })}
    {...props}
  >
    <Box pr="sm" display="flex" alignItems="center">
      {children}
    </Box>
  </Box>
)

OldCell.propTypes = {
  children: PropTypes.node.isRequired,
}

export const OldHeader = ({children, ...props}) => (
  <Box
    as="th"
    position="sticky"
    zIndex="1"
    top="0"
    bg="white"
    color="neutral.60"
    fontSize="sm"
    _first={{paddingLeft: 'md'}}
    _last={{paddingRight: 'md'}}
    borderBottom="1px solid"
    borderBottomColor="neutral.glitter"
    paddingTop="sm"
    paddingBottom="sm"
    {...props}
  >
    <Box display="flex" alignItems="center" minWidth="120px" pr="sm">
      {children}
    </Box>
  </Box>
)

OldHeader.propTypes = {
  children: PropTypes.node.isRequired,
}
