import React from 'react'
import {get} from 'lodash-es'

import {Button, Flex, Text} from '@chakra-ui/react'
import {useRails} from '../../contexts/rails'
import {Alert} from '..'
import useStepperData from './useStepperData'

const IncompleteWarning = ({...props}) => {
  const {organisation} = useRails()

  const showCashinStepper = get(
    organisation,
    'currentSupplier.showCashinStepper',
    false,
  )

  const {isLoading, cashinSteps, currentStep} = useStepperData({
    pauseFetching: !showCashinStepper,
  })

  const completedAllSteps = currentStep === null

  if (!showCashinStepper || completedAllSteps) {
    return <div hidden data-testid="cashin-stepper-complete" />
  }

  if (isLoading) {
    return <div hidden data-testid="incomplete-warning-loading" />
  }

  return (
    <Alert
      status="warning"
      message={
        <Flex align="center" gap="sm">
          <Text>
            Complete your &apos;{cashinSteps.length} steps to set up&apos; to
            start reissuing and cash-in
          </Text>
          <Button
            as="a"
            href="/dashboard#cashinStepper"
            variant="secondary"
            size="xs"
          >
            Go to set up
          </Button>
        </Flex>
      }
      {...props}
    />
  )
}

export default IncompleteWarning
