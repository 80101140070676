import Dinero from 'dinero.js'
import PropTypes from 'prop-types'

// Create a Dinero object and create a PropType definiton from it
const DineroPropShape = Object.getOwnPropertyNames(Dinero({})).reduce(
  (result, propName) => ({
    ...result,
    [propName.toString()]: PropTypes.func.isRequired,
  }),
  {},
)

export default DineroPropShape
