import React from 'react'
import {createIcon} from '@chakra-ui/react'

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12.298 14.7788C12.1211 14.9101 11.8789 14.9101 11.702 14.7788L7.15225 11.4015C6.76542 11.1143 6.96851 10.5 7.45027 10.5L16.5497 10.5C17.0315 10.5 17.2346 11.1143 16.8478 11.4015L12.298 14.7788Z"
      fill="currentColor"
    />
  ),
})
