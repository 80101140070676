import React from 'react'
import propTypes from 'prop-types'
import _styled from '@emotion/styled'

import {
  Box,
  Text,
  Input as BaseInput,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'

const getHeight = (props) => props.h || props.height || '48px'

const baseStyle = (props) => ({
  height: getHeight(props),
})

// Bit of a gross hack due to the emotion/styled still being commonjs only
// https://github.com/emotion-js/emotion/issues/2730#issuecomment-1298407552
const styled = _styled.default || _styled
const StyledInput = styled(BaseInput)`
  ${baseStyle}
`

/**
 * Compose inputs using Chakra components over using this.
 * @deprecated
 */
export const Input = React.forwardRef(
  ({name, errorMessage, mb, ...props}, ref) => {
    return (
      <Box display="inline-block" w="100%" mb={mb}>
        <StyledInput
          ref={ref}
          name={name}
          id={name}
          {...props}
          isInvalid={Boolean(errorMessage)}
        />

        {errorMessage && (
          <Text mt={1} textStyle="body-error">
            {errorMessage}
          </Text>
        )}
      </Box>
    )
  },
)

/**
 * Compose inputs using Chakra components over using this.
 * @deprecated
 */
export const Textarea = (props) => (
  <Input h="auto" py="xs" as="textarea" {...props} />
)

/**
 * Compose inputs using Chakra components over using this.
 * @deprecated
 */
export const MoneyInput = (props) => (
  <InputGroup>
    <InputLeftElement
      color="neutral.60"
      fontSize="md"
      height={getHeight(props)}
    >
      $
    </InputLeftElement>

    <Input pl="32px" {...props} />
  </InputGroup>
)

Input.defaultProps = {
  name: '',
  errorMessage: '',
  mb: 'sm',
}

Input.propTypes = {
  name: propTypes.string,
  errorMessage: propTypes.string,
  mb: propTypes.string,
}

export default Input
