import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash-es'
import {Flex, Text, Link, useBreakpointValue} from '@chakra-ui/react'
import useTabContent from './useTabContent'
import InvoicesList from '../InvoicesList'
import ConditionallyEligibleButtonCell from '../InvoicesList/ConditionallyEligibleButtonCell'
import EmptyState from '../InvoicesList/EmptyState'

const EmptyContent = ({handleTabsChange, eligibleCount}) => {
  return (
    <EmptyState
      tabName="conditionallyEligibleTab"
      messageContext={eligibleCount > 0 ? 'hasEligible' : 'noInvoices'}
      onClick={() => {
        if (eligibleCount > 0) {
          handleTabsChange(0)
        } else {
          handleTabsChange(2)
        }
      }}
    />
  )
}

EmptyContent.propTypes = {
  handleTabsChange: PropTypes.func.isRequired,
  eligibleCount: PropTypes.number.isRequired,
}

const InfoBanner = () => {
  return (
    <Flex
      bg="warning.100"
      borderRadius="lg"
      px="sm"
      py="xxs"
      mt="xxs"
      mb="sm"
      flexWrap="wrap"
      gap="xs"
    >
      <Text textStyle="body-small" color="warning.900">
        Invoices can be made available by providing the customer’s <b>ABN</b> &{' '}
        <b>email</b> when you cash-in OR reissuing the invoice.
      </Text>
      <Link
        href="https://marmalade.ladesk.com/361140-Cash-in-Conditionally-Available-Invoices"
        isExternal
        textStyle="body-small"
        color="primary.700"
      >
        Find out more
      </Link>
    </Flex>
  )
}

const ConditionallyEligibleTab = ({
  reloadRequired,
  handleTabsChange,
  actionStarted,
  actionFinished,
  summaryData,
}) => {
  const {commonColumns, invoicesData, ...listProps} = useTabContent({
    endpointAction: 'conditionally_eligible_invoices',
    reloadRequired,
  })

  const columns = [
    commonColumns.customerColumn,
    commonColumns.invoiceColumn,
    commonColumns.dueDateColumn,
    commonColumns.invoiceAmountColumn,
    commonColumns.issueDateColumn,
    commonColumns.feeRateColumn,
    {
      header: '',
      accessorKey: 'conditionallyEligible',
      cell: ConditionallyEligibleButtonCell,
      enableSorting: false,
      wrap: useBreakpointValue({base: true, md: false}),
      colSpan: useBreakpointValue({base: 6, md: 3}),
    },
  ]

  const decoratedInvoices = invoicesData?.map((invoice) => ({
    ...invoice,
    actionStarted,
    actionFinished,
  }))

  // Set default sort option
  if (!listProps.sortBy) {
    listProps.onSort([
      {
        id: 'invoice_cashin_offer_cashin_fee',
        desc: false,
      },
    ])
  }

  return (
    <InvoicesList
      columns={columns}
      invoicesData={decoratedInvoices}
      emptyContent={
        <EmptyContent
          handleTabsChange={handleTabsChange}
          eligibleCount={get(summaryData, 'eligibleCount', 0)}
        />
      }
      headerContent={<InfoBanner />}
      {...listProps}
    />
  )
}

ConditionallyEligibleTab.propTypes = {
  actionStarted: PropTypes.func.isRequired,
  actionFinished: PropTypes.func.isRequired,
  reloadRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  handleTabsChange: PropTypes.func.isRequired,
  summaryData: PropTypes.shape({
    eligibleCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default ConditionallyEligibleTab
