import React from 'react'
import PropTypes from 'prop-types'
import Dinero from 'dinero.js'
import useSWR from 'swr'
import {
  Button,
  Collapse,
  Flex,
  Text,
  Divider,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
} from '@chakra-ui/react'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  WarningIcon,
} from '@chakra-ui/icons'
import {get} from 'lodash-es'
import Checkbox from '../../../../components/Checkbox'
import {fetcher} from '../../../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../../../api/serializer'
import {useRails} from '../../../../contexts/rails'
import useFeatureCookie from '../../../../hooks/useFeatureCookie'
import DineroPropShape from '../../../../../utils/propTypes/dinero'
import CustomerRatingsPropType from '../../../../../utils/propTypes/customerRating'
import {DisplayInvoiceDetails} from '../../../../components/InvoiceDetails'

const ViewDetailsDrawer = ({
  row,
  isOpen,
  onClose,
  drawerOpenedExternally,
  onNextClick,
  reissuanceRequired,
  detailsRequired,
  cashinInvoice,
  cashinProcessing,
  cashinProcessingState,
  btnRef,
}) => {
  const skipReimbursementCookieExpiry = 60 * 60 // One hour
  const [skipReimbursementConfirmation, setSkipReimbursementConfirmation] =
    useFeatureCookie('skip_reimbursement_confirmation', {
      expiry: skipReimbursementCookieExpiry,
    })
  const [
    skipReimbursementConfirmationChecked,
    setSkipReimbursementConfirmationChecked,
  ] = React.useState(false)

  const toggleSkipReimbursementConfirmation = () =>
    setSkipReimbursementConfirmationChecked(
      !skipReimbursementConfirmationChecked,
    )

  const reimbursementRequiredAmountCents = row.original
    .reimbursementRequiredAmount
    ? row.original.reimbursementRequiredAmount.getAmount()
    : 0

  const cashinReimbursement = Dinero({
    amount: Math.min(
      row.original.payoutAmount.getAmount(),
      reimbursementRequiredAmountCents,
    ),
    currency: row.original.payoutAmount.getCurrency(),
  })

  const payoutAmount = Dinero({
    amount: Math.max(
      0,
      row.original.payoutAmount.getAmount() - reimbursementRequiredAmountCents,
    ),
    currency: row.original.payoutAmount.getCurrency(),
  })

  const {isOpen: feeRateIsOpen, onToggle: feeRateOnToggle} = useDisclosure()

  const {organisation} = useRails()
  const fixedPricingEnabled = get(
    organisation,
    'currentSupplier.fixedPricingEnabled',
    false,
  )
  const {data} = useSWR(
    feeRateIsOpen
      ? `/api/suppliers/${organisation.currentSupplier.id}/invoices/${row.original.id}/fee_breakdown`
      : null,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const cashinDisabled =
    row.original.cashinDisabled || organisation.currentSupplier.cashinDisabled

  return (
    <Drawer
      isOpen={isOpen}
      size="md"
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex py="xs">
            <Text textStyle="body-intro-medium">
              {row.original.invoiceNumber}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {(reissuanceRequired || detailsRequired) && (
            <Flex backgroundColor="orange.50" p="sm" borderRadius="md" mb="sm">
              <WarningIcon boxSize="4" my="auto" color="orange.500" />
              <Text textStyle="body-small" color="neutral.800" ml="sm">
                {reissuanceRequired &&
                  'This invoice does not contain Marmalade payment information. Please reissue the invoice to cash-in.'}
                {detailsRequired &&
                  'This invoice is missing customer’s ABN and email. Please provide the information to cash-in.'}
              </Text>
            </Flex>
          )}

          <DisplayInvoiceDetails
            invoice={{
              id: row.original.id,
              number: row.original.invoiceNumber,
              customerId: row.original.customerRating.id,
              contactName: row.original.contactName,
              issueDate: row.original.xeroDate,
              dueDate: row.original.dueDate,
            }}
            customerRating={row.original.customerRating}
          />

          <Divider my="sm" />

          <Flex justifyContent="space-between">
            <Text textStyle="body-copy" color="grey.700">
              Invoice total
            </Text>
            <Text
              textStyle="body-copy"
              textAlign="right"
              fontWeight="medium"
              color="grey.900"
            >
              {row.original.dueAmount.toFormat()}
            </Text>
          </Flex>
          {row.original.priceWithinMaximumLimit && (
            <Flex direction="column">
              <Flex
                width="100%"
                justifyContent="space-between"
                mt="sm"
                onClick={feeRateOnToggle}
              >
                <Text textStyle="body-copy" color="grey.700">
                  Cash-in fee <b>@ {row.original.feeRate}</b>
                  {row.original.showBreakdown && !fixedPricingEnabled && (
                    <Icon
                      aria-label="Click to see fee breakdown"
                      ml="xxs"
                      my="auto"
                      color="primary.actionblue"
                      boxSize="6"
                      as={feeRateIsOpen ? ChevronUpIcon : ChevronDownIcon}
                    />
                  )}
                </Text>
                <Text
                  textStyle="body-copy"
                  textAlign="right"
                  fontWeight="medium"
                  color="grey.900"
                >
                  -{row.original.feeAmount.toFormat()}
                </Text>
              </Flex>
              {row.original.showBreakdown && !fixedPricingEnabled && (
                <Collapse in={feeRateIsOpen}>
                  <Flex
                    direction="column"
                    p="sm"
                    mt="sm"
                    backgroundColor="neutral.100"
                    borderRadius="base"
                  >
                    <Flex justifyContent="space-between" width="100%">
                      <Text textStyle="body-small" color="grey.700">
                        Status fee <b>@ {data && data.supplierFeeRate}</b>
                      </Text>
                      <Text
                        textStyle="body-copy"
                        textAlign="right"
                        color="grey.900"
                      >
                        {data && data.supplierFeeAmount.toFormat()}
                      </Text>
                    </Flex>

                    <Flex justifyContent="space-between" width="100%" mt="sm">
                      <Text textStyle="body-small" color="grey.700">
                        Customer fee <b>@ {data && data.customerFeeRate}</b>
                      </Text>
                      <Text
                        textStyle="body-copy"
                        textAlign="right"
                        color="grey.900"
                      >
                        {data && data.customerFeeAmount.toFormat()}
                      </Text>
                    </Flex>
                  </Flex>
                </Collapse>
              )}
            </Flex>
          )}

          {row.original.reimbursementRequiredAmount &&
            row.original.reimbursementRequiredAmount.getAmount() > 0 && (
              <Flex direction="column" p="sm" mt="sm" backgroundColor="blue.50">
                <Flex justifyContent="space-between" width="100%">
                  <Text textStyle="body-small" color="grey.700">
                    Amount you owe Marmalade
                  </Text>
                  <Text
                    textStyle="body-copy"
                    textAlign="right"
                    color="grey.900"
                    fontWeight="medium"
                  >
                    {row.original.reimbursementRequiredAmount.toFormat()}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" width="100%" mt="sm">
                  <Text textStyle="body-small" color="grey.700">
                    Amount to be settled by cash-in
                  </Text>
                  <Text
                    textStyle="body-copy"
                    textAlign="right"
                    color="grey.900"
                    fontWeight="medium"
                  >
                    -{cashinReimbursement.toFormat()}
                  </Text>
                </Flex>
              </Flex>
            )}

          <>
            <Divider my="sm" />

            <Flex justifyContent="space-between">
              <Text textStyle="body-small" fontWeight="medium" color="grey.700">
                {row.original.cashedInDate ? (
                  <>
                    <CheckCircleIcon
                      color="secondary.green"
                      mr="xxs"
                      mb={0.5}
                    />{' '}
                    Cashed-in amount
                  </>
                ) : (
                  <>One business day payout</>
                )}
              </Text>
              <Text textStyle="headline3" fontWeight="medium" color="grey.900">
                {payoutAmount.toFormat()}
              </Text>
            </Flex>
          </>

          {onNextClick && (
            <Flex justifyContent="space-between" mt="sm">
              <Button variant="secondary" onClick={onClose} width="48%">
                Cancel
              </Button>
              <Button
                variant="primary"
                colorScheme="primary"
                data-testid="next-button"
                onClick={() => {
                  onClose()
                  setTimeout(() => {
                    onNextClick()
                  }, 250)
                }}
                width="48%"
              >
                {reissuanceRequired && 'Reissue'}
                {detailsRequired && 'Provide Details'}
              </Button>
            </Flex>
          )}

          {cashinInvoice && (
            <>
              <Divider my="sm" />

              <Text textStyle="body-detail" color="grey.700">
                By cashing-in, I confirm that all goods and/or services being
                invoiced have been delivered.
              </Text>
              <Flex justifyContent="space-between" mt="sm">
                <Button
                  variant="secondary"
                  onClick={onClose}
                  isDisabled={cashinProcessing || cashinProcessingState}
                  width="48%"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  colorScheme="primary"
                  onClick={() => {
                    if (skipReimbursementConfirmationChecked) {
                      setSkipReimbursementConfirmation()
                    }
                    onClose()
                    cashinInvoice(row.original.id)
                  }}
                  width="48%"
                  isDisabled={
                    cashinDisabled || cashinProcessing || cashinProcessingState
                  }
                  isLoading={cashinProcessing}
                  loadingText="Cashing-in"
                >
                  {cashinProcessingState === 'success' && 'Cashed-in'}
                  {cashinProcessingState === 'failed' && 'Cash-in failed'}
                  {cashinProcessingState === null && 'Confirm Cash-in'}
                </Button>
              </Flex>

              {!skipReimbursementConfirmation &&
                drawerOpenedExternally &&
                row.original.reimbursementRequiredAmount.getAmount() > 0 && (
                  <Checkbox
                    mt="sm"
                    isChecked={skipReimbursementConfirmationChecked}
                    onChange={toggleSkipReimbursementConfirmation}
                  >
                    <Text fontSize="base">
                      Don&apos;t ask this again for the next hour
                    </Text>
                  </Checkbox>
                )}
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

ViewDetailsDrawer.defaultProps = {
  cashinInvoice: null,
  cashinProcessing: null,
  cashinProcessingState: null,
  onNextClick: null,
  reissuanceRequired: false,
  detailsRequired: false,
  drawerOpenedExternally: false,
}

ViewDetailsDrawer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactName: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
      xeroDate: PropTypes.number.isRequired,
      cashedInDate: PropTypes.number,
      customerRating: CustomerRatingsPropType.isRequired,
      dueAmount: PropTypes.shape(DineroPropShape).isRequired,
      reimbursementRequiredAmount: PropTypes.shape(DineroPropShape),
      feeRate: PropTypes.string.isRequired,
      feeAmount: PropTypes.shape(DineroPropShape).isRequired,
      supplierFeeRate: PropTypes.string,
      supplierFeeAmount: PropTypes.shape(DineroPropShape),
      customerFeeRate: PropTypes.string,
      customerFeeAmount: PropTypes.shape(DineroPropShape),
      payoutAmount: PropTypes.shape(DineroPropShape).isRequired,
      cashinDisabled: PropTypes.bool.isRequired,
      priceWithinMaximumLimit: PropTypes.bool.isRequired,
      showBreakdown: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  drawerOpenedExternally: PropTypes.bool,
  onNextClick: PropTypes.func,
  reissuanceRequired: PropTypes.bool,
  detailsRequired: PropTypes.bool,
  cashinInvoice: PropTypes.func,
  cashinProcessing: PropTypes.bool,
  cashinProcessingState: PropTypes.string,
  btnRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]).isRequired,
}

const ViewDetailsButton = ({
  row,
  drawerOpenedExternally,
  setDrawerOpenedExternally,
  onNextClick,
  reissuanceRequired,
  detailsRequired,
  cashinInvoice,
  cashinProcessing,
  cashinProcessingState,
}) => {
  const {isOpen, onOpen, onClose: closeModal} = useDisclosure()
  const btnRef = React.useRef()

  if (drawerOpenedExternally && !isOpen) {
    onOpen()
  }

  const onClose = () => {
    closeModal()
    setDrawerOpenedExternally(false)
  }

  return (
    <>
      <ChevronRightIcon
        data-testid="view-details-icon"
        display={{base: 'none', md: 'block'}}
        boxSize={8}
        cursor="pointer"
        ml="auto"
        my="auto"
        ref={btnRef}
        onClick={onOpen}
      />
      <Button
        data-testid="view-details-button"
        variant="tertiary"
        display={{base: 'block', md: 'none'}}
        width="100%"
        mt="sm"
        ref={btnRef}
        onClick={onOpen}
      >
        View Details
      </Button>
      <ViewDetailsDrawer
        row={row}
        drawerOpenedExternally={drawerOpenedExternally}
        onNextClick={onNextClick}
        reissuanceRequired={reissuanceRequired}
        detailsRequired={detailsRequired}
        cashinInvoice={cashinInvoice}
        cashinProcessing={cashinProcessing}
        cashinProcessingState={cashinProcessingState}
        btnRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

ViewDetailsButton.defaultProps = {
  cashinInvoice: null,
  cashinProcessing: null,
  drawerOpenedExternally: false,
  setDrawerOpenedExternally: () => {},
  cashinProcessingState: null,
  onNextClick: null,
  reissuanceRequired: false,
  detailsRequired: false,
}

ViewDetailsButton.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
      cashinDisabled: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  drawerOpenedExternally: PropTypes.bool,
  setDrawerOpenedExternally: PropTypes.func,
  onNextClick: PropTypes.func,
  reissuanceRequired: PropTypes.bool,
  detailsRequired: PropTypes.bool,
  cashinInvoice: PropTypes.func,
  cashinProcessing: PropTypes.bool,
  cashinProcessingState: PropTypes.string,
}

export default ViewDetailsButton
