import React from 'react'
import PropTypes from 'prop-types'
import {Button, Flex, Text} from '@chakra-ui/react'

const SuccessView = ({onClose}) => {
  return (
    <>
      <Text textStyle="body-intro-medium">Thanks, we&apos;ll be in touch!</Text>
      <Text>
        If you want to change your bank connections for any reasons, go to
        Settings &gt; Account Settings.
      </Text>

      <Flex pt="sm" mb="sm" justifyContent="center">
        <Button
          variant="secondary"
          width="80%"
          onClick={onClose}
          data-testid="close-button"
        >
          Close
        </Button>
      </Flex>
    </>
  )
}

SuccessView.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SuccessView
