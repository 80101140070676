import React from 'react'
import useSWR from 'swr'
import {useFormik} from 'formik'
import {
  Box,
  Text,
  Flex,
  Radio,
  RadioGroup,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import Checkbox from '../../../../components/Checkbox'
import {useRails} from '../../../../contexts/rails'
import {Alert} from '../../../../components'
import {fetcher, setBankAccount} from '../../../../../api'
import {toBankDetailRepresentation} from '../../../../../utils/formatter'
import {ArrowRightIcon} from '../../../../icons'
import ServiceAgreementModal from '../../../../components/ServiceAgreementModal'

const BankAccountSelectionForm = () => {
  const {authenticityToken, organisation} = useRails()
  const {t} = useTranslation('onboarding')
  const [bankAccountError, setBankAccountError] = React.useState('')
  const [showManualAccountDetailsFor, setShowManualAccountDetailsFor] =
    React.useState(null)

  const {data, error} = useSWR(
    `/api/suppliers/${organisation.currentSupplier.id}/bank_accounts`,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  )

  if (error && error.message === 'Unauthorized') {
    window.location.href = '/supplier_logins/sign_in'
  }

  const numbersOnly = /^\d+$/
  const valueBlank = (value) => value === null || value.trim() === ''

  const validate = (values) => {
    const errors = {}
    if (showManualAccountDetailsFor) {
      if (valueBlank(values.manualBsb)) {
        errors.manualBsb = 'Required'
      }

      if (!valueBlank(values.manualBsb)) {
        if (!(values.manualBsb.length === 6)) {
          errors.manualBsb = 'Can only be 6 numbers'
        }

        if (!numbersOnly.test(values.manualBsb)) {
          errors.manualBsb = 'Please enter numbers only'
        }
      }

      if (valueBlank(values.manualAccountNumber)) {
        errors.manualAccountNumber = 'Required'
      }

      if (!valueBlank(values.manualAccountNumber)) {
        if (!numbersOnly.test(values.manualAccountNumber)) {
          errors.manualAccountNumber = 'Please enter numbers only'
        }
      }
    }
    return errors
  }

  const formik = useFormik({
    initialValues: {
      accountId: '',
      acceptDirectDebitTerm: false,
      manualBsb: '',
      manualAccountNumber: '',
    },
    validate,
    onSubmit: async (values) => {
      const params = {}
      params.accountId = values.accountId
      params.authenticityToken = authenticityToken
      params.supplierId = organisation.currentSupplier.id
      if (showManualAccountDetailsFor) {
        params.accountNumber = `${values.manualBsb}${values.manualAccountNumber}`
      }
      try {
        await setBankAccount(params)
        window.location.href = '/dashboard'
      } catch (e) {
        setBankAccountError(e.message)
      }
    },
  })

  React.useEffect(() => {
    if (data) {
      const account = data.accounts.find(
        (acc) => acc.AccountID === formik.values.accountId,
      )
      if (account) {
        const accountNumber = account.BankAccountNumber
        if (!accountNumber) {
          // If the account number is missing show the bsb and number form
          setShowManualAccountDetailsFor(formik.values.accountId)
        } else if (accountNumber.length === 6) {
          // If the account number is missing BSB or account numbers then try prefilling before showing form
          formik.values.manualBsb = accountNumber.slice(0, 6)
          formik.values.manualAccountNumber = accountNumber.slice(6, -1)
          setShowManualAccountDetailsFor(formik.values.accountId)
        } else {
          // Otherwise we have all the bank number details so dont show the form.
          setShowManualAccountDetailsFor(null)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formik.values.accountId])

  return (
    <form onSubmit={formik.handleSubmit}>
      <input type="hidden" name="commit" value="Select+Bank+Account" />

      {(error || bankAccountError) && (
        <Alert
          status="error"
          message={
            <Box display="flex" flexDirection="column">
              {bankAccountError && (
                <Text textStyle="body-small" color="red.500">
                  {bankAccountError}
                </Text>
              )}
              {error &&
                error.message !== 'Unauthorized' &&
                error.name !== 'APINetworkError' && (
                  <Text textStyle="body-small" color="red.500">
                    Unable to retrieve bank accounts
                  </Text>
                )}
            </Box>
          }
          mb={8}
        />
      )}

      {!data && <Box mb={6}>Loading...</Box>}

      {data && (
        <Box my="md">
          <RadioGroup name="accountId" defaultValue={formik.values.accountId}>
            {data.accounts &&
              Array.isArray(data.accounts) &&
              data.accounts.map((account) => {
                const {bsb, accountNumber} = toBankDetailRepresentation(
                  account.BankAccountNumber,
                )

                return (
                  <Box
                    borderWidth="2px"
                    key={account.AccountID}
                    px={4}
                    py={3}
                    mb={3}
                    borderRadius="lg"
                    borderColor={
                      formik.values.accountId === account.AccountID
                        ? 'neutral.black'
                        : 'neutral.20'
                    }
                  >
                    <Radio
                      size="lg"
                      variant="mld"
                      value={account.AccountID}
                      onChange={formik.handleChange}
                    >
                      <Text textStyle="body-copy" color="neutral.black" pl={2}>
                        {account.Name}
                      </Text>
                      {bsb && accountNumber && (
                        <>
                          <Text textStyle="body-copy" color="neutral.60" pl={2}>
                            BSB:{' '}
                            <Text as="span" color="neutral.black">
                              {bsb}
                            </Text>
                          </Text>
                          <Text textStyle="body-copy" color="neutral.60" pl={2}>
                            Account:{' '}
                            <Text as="span" color="neutral.black">
                              {accountNumber}
                            </Text>
                          </Text>
                        </>
                      )}
                    </Radio>
                    {showManualAccountDetailsFor === account.AccountID && (
                      <Box ml="35px" mt="sm">
                        <FormControl
                          isInvalid={Boolean(
                            formik.touched.manualBsb && formik.errors.manualBsb,
                          )}
                          pb="xs"
                        >
                          <FormLabel
                            textStyle="body-copy-medium"
                            htmlFor="manualBsb"
                          >
                            BSB
                          </FormLabel>
                          <Input
                            id="manualBsb"
                            name="manualBsb"
                            value={formik.values.manualBsb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <FormErrorMessage justifyContent="flex-end">
                            {formik.touched.manualBsb &&
                              formik.errors.manualBsb}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            formik.touched.manualAccountNumber &&
                              formik.errors.manualAccountNumber,
                          )}
                          pb="xs"
                        >
                          <FormLabel
                            textStyle="body-copy-medium"
                            htmlFor="manualAccountNumber"
                          >
                            Account
                          </FormLabel>
                          <Input
                            id="manualAccountNumber"
                            name="manualAccountNumber"
                            value={formik.values.manualAccountNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <FormErrorMessage justifyContent="flex-end">
                            {formik.touched.manualAccountNumber &&
                              formik.errors.manualAccountNumber}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                )
              })}
          </RadioGroup>
        </Box>
      )}
      <Flex direction="column" mb={6}>
        <Checkbox
          name="acceptDirectDebitTerm"
          value="accept-direct-debit-term"
          size="lg"
          onChange={formik.handleChange}
          variant="mld"
          mr="sm"
        >
          <Text textStyle="body-small">
            {t('onboarding:BankAccountSelection.directDebitAuthorizationLabel')}
          </Text>
          <Box ml="nmd">
            <ServiceAgreementModal />
          </Box>
        </Checkbox>
      </Flex>
      <Button
        variant="primary"
        colorScheme="primary"
        size="lg"
        isDisabled={
          formik.values.accountId === '' ||
          formik.values.acceptDirectDebitTerm === false
        }
        isLoading={formik.isSubmitting}
        data-testid="continue-button"
        type="submit"
      >
        {t('onboarding:BankAccountSelection.submitButton')}
        <ArrowRightIcon width="24px" height="24px" ml="xs" />
      </Button>
    </form>
  )
}

const BankAccountSelection = () => {
  const {accountingProvider} = useRails()
  const {t} = useTranslation('onboarding')

  return (
    <>
      <Text
        color="mld.neutral.900"
        fontSize="48px"
        letterSpacing="-0.5"
        lineHeight="1"
        fontWeight="400"
        mb="md"
      >
        {t('onboarding:BankAccountSelection.heading', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <Text mb="xs" textStyle="body-intro">
        {t('onboarding:BankAccountSelection.body', {
          accountingProvider: accountingProvider.name,
        })}
      </Text>

      <BankAccountSelectionForm />
    </>
  )
}

export default BankAccountSelection
