import {camelizeKeys} from 'humps'
import useSWR from 'swr'

import {fetcher} from '../../api'

export default function useBankAccount() {
  const {data, error} = useSWR(
    '/api/suppliers/receivable_bank_account',
    fetcher,
  )

  return {
    data: camelizeKeys(data || {}),
    loading: !data && !error,
    error,
  }
}
