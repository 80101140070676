import React from 'react'
import {useTranslation} from 'react-i18next'
import DrawerFieldItem from './DrawerFieldItem'
import DrawerSection from './DrawerSection'
import billPaymentPropType from '../../../utils/propTypes/billPayment'

const PaymentDetailsSection = ({billPayment}) => {
  const {t} = useTranslation('billPayments')

  return (
    <DrawerSection
      title={t('billPayments:billPayment.drawer.paymentSectionTitle')}
    >
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.paymentAmount')}
        value={billPayment.netPaymentAmount.toFormat()}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.feeAmount')}
        value={billPayment.grossPaymentAmount
          .subtract(billPayment.netPaymentAmount)
          .toFormat()}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.reference')}
        value={billPayment.paymentReference}
      />
    </DrawerSection>
  )
}

PaymentDetailsSection.propTypes = {
  billPayment: billPaymentPropType.isRequired,
}

export default PaymentDetailsSection
