import React from 'react'
import PropTypes from 'prop-types'
import {Button} from '@chakra-ui/react'

const PaginationButton = ({current, ...props}) => {
  return (
    <Button
      variant="tertiary"
      isActive={current}
      padding="0"
      minWidth="32px"
      width="32px"
      height="32px"
      {...props}
    />
  )
}

PaginationButton.propTypes = {
  current: PropTypes.bool,
}

PaginationButton.defaultProps = {
  current: false,
}

export default PaginationButton
