import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'formik'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'

const FormikFieldWrapper = ({label, name, type, icon, children}) => {
  return (
    <Field name={name}>
      {({field, form: {touched, errors}}) => (
        <FormControl pb="sm" isInvalid={Boolean(touched[name] && errors[name])}>
          <FormLabel>
            <Text textStyle="body-small">{label}</Text>
          </FormLabel>
          <InputGroup size="lg">
            {icon && <InputLeftElement>{icon}</InputLeftElement>}
            {children || (
              <Input
                size="lg"
                fontSize="base"
                type={type}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
              />
            )}
          </InputGroup>
          <FormErrorMessage justifyContent="end" textStyle="body-detail">
            <Text textStyle="body-detail">{errors[name]}</Text>
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

FormikFieldWrapper.defaultProps = {
  type: 'text',
  icon: null,
  children: null,
}

FormikFieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
}

export default FormikFieldWrapper
