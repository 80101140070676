import {useEffect, useState} from 'react'

const defaultOptions = {
  threshold: 0,
  root: null,
  rootMargin: '0px',
}

// This hook wraps the IntersectionObserver API to and returns an IntersectionObserverEntry object
// It accepts a ref to the node that you are wanting to observe and also accepts additional IntersectionObserver options
// Use this hook any time you are wanting to observe changes in the intersection of a target element and either the viewport or parent element
// For more information on IntersectionObserver https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
const useIntersectionObserver = (ref, {threshold, root, rootMargin}) => {
  const [entry, setEntry] = useState()

  const updateEntry = (entries) => {
    setEntry(entries[0])
  }

  useEffect(() => {
    const node = ref?.current

    // If IntersectionObserver API is not supported or there is no node
    if (!window.IntersectionObserver || !node) return

    const observer = new IntersectionObserver(updateEntry, {
      ...defaultOptions,
      threshold,
      root,
      rootMargin,
    })

    observer.observe(node)

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current, threshold, root, rootMargin])

  return entry
}

export default useIntersectionObserver
