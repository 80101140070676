import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text} from '@chakra-ui/react'

const DrawerFieldItem = ({label, value, ...boxProps}) => (
  <Flex justify="space-between" {...boxProps}>
    <Text textStyle="body-copy">{label}</Text>
    <Text as="div" textStyle="body-copy-medium">
      {value}
    </Text>
  </Flex>
)

DrawerFieldItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default DrawerFieldItem
