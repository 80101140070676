import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Trans, useTranslation} from 'react-i18next'

import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
  Text,
} from '@chakra-ui/react'
import Checkbox from '../Checkbox'
import {createPaymentMethod} from '../../../api/billPayments'
import {useRails} from '../../contexts/rails'
import Field from './Field'

const DebitAgreementForm = () => {
  const {t} = useTranslation('billPayments')
  const i18nPrefix = 'billPayments:paymentMethods.debitAgreement.form'
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
    authenticityToken,
  } = useRails()

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [submitted, setSubmitted] = React.useState(false)

  const validationSchema = Yup.object().shape({
    branchCode: Yup.string()
      .matches(/^\d{6}$/, t(`${i18nPrefix}.fields.branchCode.lengthError`))
      .required(t(`${i18nPrefix}.fields.branchCode.requiredError`)),
    accountNumber: Yup.string()
      .matches(/^\d{6,9}$/, t(`${i18nPrefix}.fields.accountNumber.lengthError`))
      .required(t(`${i18nPrefix}.fields.accountNumber.requiredError`)),
    acceptTerms: Yup.boolean()
      .oneOf([true], t(`${i18nPrefix}.fields.acceptTerms.requiredError`))
      .required(t(`${i18nPrefix}.fields.acceptTerms.requiredError`)),
  })

  const onSubmit = async ({acceptTerms, ...values}) => {
    if (submitted) return false

    setSubmitted(true)
    setErrorMessage(null)

    return createPaymentMethod({
      supplierId,
      authenticityToken,
      acceptTerms,
      type: 'debit_agreement',
      params: values,
    })
      .then(({debitAgreement: {id}}) => {
        window.location.href = `/payment_methods/${id}`
      })
      .catch(() => {
        setSubmitted(false)
        setErrorMessage(t(`${i18nPrefix}.errors.submitError`))
      })
  }

  return (
    <Card p="md" borderRadius="md" maxWidth="600px" variant="unstyled">
      <CardHeader display="flex" flexDirection="column" gap="sm" pb="lg">
        <Text as="h4" textStyle="headline4">
          {t(`${i18nPrefix}.title`)}
        </Text>

        <Text textStyle="body-small">{t(`${i18nPrefix}.description`)}</Text>
      </CardHeader>
      <Formik
        initialValues={{
          branchCode: '',
          accountNumber: '',
          acceptTerms: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          dirty,
          isValid,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Flex as={Form} direction="column">
            <Field name="branchCode" />
            <Field name="accountNumber" />
            <FormControl
              isInvalid={touched.accountNumber && errors.acceptTerms}
            >
              <Checkbox
                name="[acceptTerms]"
                id="acceptTerms"
                isChecked={values.acceptTerms}
                onChange={(e) => {
                  setFieldValue('acceptTerms', e.target.checked)
                }}
              >
                <Text textStyle="body-detail">
                  <Trans
                    t={t}
                    i18nKey={`${i18nPrefix}.fields.acceptTerms.label`}
                    components={{
                      CustomLink: (
                        <Link
                          href="https://www.withmarmalade.com.au/terms-of-use"
                          variant="primary"
                          textStyle="body-detail"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </Text>
              </Checkbox>
              {touched.acceptTerms && errors.acceptTerms && (
                <FormErrorMessage textStyle="body-detail">
                  <Text textStyle="body-detail">{errors.acceptTerms}</Text>
                </FormErrorMessage>
              )}
            </FormControl>

            {errorMessage && (
              <Text color="red.500" mt="sm">
                {errorMessage}
              </Text>
            )}

            <Box pt="md">
              <Button
                variant="primary"
                colorScheme="primary"
                type="submit"
                isLoading={isSubmitting || submitted}
                isDisabled={isSubmitting || submitted || !isValid || !dirty}
              >
                {t(`${i18nPrefix}.submitButton`)}
              </Button>
            </Box>
          </Flex>
        )}
      </Formik>
    </Card>
  )
}

export default DebitAgreementForm
