import React from 'react'
import PropTypes from 'prop-types'
import AddDetailsButtonCell from './AddDetailsButtonCell'
import ReissueButtonCell from './ReissueButtonCell'

const ConditionallyEligibleButtonCell = ({row}) => {
  const {actionFinished, actionStarted} = row.original

  if (row.original.requiresContactDetails) {
    return (
      <AddDetailsButtonCell
        row={row}
        onStart={actionStarted}
        onSuccess={actionFinished}
      />
    )
  }
  if (row.original.requiresReissuance) {
    return (
      <ReissueButtonCell
        row={row}
        onStart={actionStarted}
        onSuccess={actionFinished}
      />
    )
  }

  return null
}

ConditionallyEligibleButtonCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      requiresContactDetails: PropTypes.bool.isRequired,
      requiresReissuance: PropTypes.bool.isRequired,
      actionStarted: PropTypes.func.isRequired,
      actionFinished: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ConditionallyEligibleButtonCell
