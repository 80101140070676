import React from 'react'
import {createIcon} from '@chakra-ui/react'

export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 25 24',
  path: (
    <path
      d="M19.85 10.04C19.17 6.59 16.14 4 12.5 4C9.61 4 7.1 5.64 5.85 8.04C2.84 8.36 0.5 10.91 0.5 14C0.5 17.31 3.19 20 6.5 20H19.5C22.26 20 24.5 17.76 24.5 15C24.5 12.36 22.45 10.22 19.85 10.04ZM19.5 18H6.5C4.29 18 2.5 16.21 2.5 14C2.5 11.95 4.03 10.24 6.06 10.03L7.13 9.92L7.63 8.97C8.58 7.14 10.44 6 12.5 6C15.12 6 17.38 7.86 17.89 10.43L18.19 11.93L19.72 12.04C21.28 12.14 22.5 13.45 22.5 15C22.5 16.65 21.15 18 19.5 18ZM8.5 13H11.05V16H13.95V13H16.5L12.5 9L8.5 13Z"
      fill="currentColor"
    />
  ),
})
