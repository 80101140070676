// This file acts similarly to the previously used WebpackReact class https://github.com/renchap/webpacker-react/blob/master/javascript/webpacker_react-npm-module/src/index.js

import React from 'react'
import ReactDOM from 'react-dom/client'
import {intersection, keys, assign, omit} from 'lodash-es'
import mixpanel from 'mixpanel-browser'
import './i18n'

const CLASS_ATTRIBUTE_NAME = 'data-react-class'
const PROPS_ATTRIBUTE_NAME = 'data-react-props'

const ReactComponent = {
  registeredComponents: {},
  componentRoots: {},

  render(node, component) {
    const propsJson = node.getAttribute(PROPS_ATTRIBUTE_NAME)
    const props = propsJson && JSON.parse(propsJson)

    if (props.disableMixpanel) {
      mixpanel.disable()
    }

    if (
      props.currentSupplier &&
      props.currentSupplier.id &&
      !props.disableMixpanel
    ) {
      // Set the current supplier as the user for all future mixpanel events
      mixpanel.identify(props.currentSupplier.id)
    }

    const reactElement = React.createElement(component, props)
    const root = ReactDOM.createRoot(node)

    root.render(reactElement)

    return root
  },

  registerComponents(components) {
    const collisions = intersection(
      keys(this.registeredComponents),
      keys(components),
    )
    if (collisions.length > 0) {
      // eslint-disable-next-line no-console
      console.error(
        `Following components are already registered: ${collisions}`,
      )
    }

    assign(this.registeredComponents, omit(components, collisions))
    return true
  },

  unmountComponents() {
    const mounted = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`)
    for (let i = 0; i < mounted.length; i += 1) {
      const className = mounted[i].getAttribute(CLASS_ATTRIBUTE_NAME)
      this.componentRoots[className].unmount()
    }
  },

  mountComponents() {
    const {registeredComponents} = this
    const toMount = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`)

    for (let i = 0; i < toMount.length; i += 1) {
      const node = toMount[i]
      const className = node.getAttribute(CLASS_ATTRIBUTE_NAME)
      const component = registeredComponents[className]

      if (component) {
        if (node.innerHTML.length === 0) {
          const root = this.render(node, component)

          this.componentRoots = {...this.componentRoots, [className]: root}
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `Can not render a component that has not been registered: ${className}`,
        )
      }
    }
  },

  setup(components = {}) {
    if (typeof window.ReactComponent === 'undefined') {
      window.ReactComponent = this
    }

    window.ReactComponent.registerComponents(components)
    window.ReactComponent.mountComponents()
  },
}

export default ReactComponent
