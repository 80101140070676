import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import FormikFieldWrapper from '../FormikFieldWrapper'

const Field = ({name, children, ...props}) => {
  const {t} = useTranslation('billPayments')

  return (
    <FormikFieldWrapper
      label={t(
        `billPayments:paymentMethods.debitAgreement.form.fields.${name}.label`,
      )}
      name={name}
      {...props}
    >
      {children}
    </FormikFieldWrapper>
  )
}

Field.defaultProps = {
  type: 'text',
  icon: null,
  children: null,
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
}

export default Field
