import React from 'react'
import PropTypes from 'prop-types'
import {useFormikContext} from 'formik'
import {debounce} from 'lodash-es'

const Autosave = ({debounceMs, onSubmit}) => {
  const formik = useFormikContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = React.useMemo(
    () => debounce(onSubmit || formik.submitForm, debounceMs),
    [formik.submitForm, debounceMs, onSubmit],
  )

  React.useEffect(() => {
    debouncedSubmit(formik.values)
  }, [debouncedSubmit, formik.values])

  return null
}

Autosave.defaultProps = {
  debounceMs: 300,
  onSubmit: undefined,
}

Autosave.propTypes = {
  debounceMs: PropTypes.number,
  onSubmit: PropTypes.func,
}

export default Autosave
