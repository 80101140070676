import React from 'react'
import PropTypes from 'prop-types'
import {Box, useDisclosure} from '@chakra-ui/react'
import {toast} from 'react-toastify'
import {noop} from 'lodash-es'

import {Dialog, LinkCard, ToastMessage} from '../../../components'
import ReportDirectPaymentForm from './ReportDirectPaymentForm'
import {createOutsidePayment} from '../../../../api'
import {useRails} from '../../../contexts/rails'
import {CashIcon} from '../../../icons'

const ReportDirectPayment = ({
  cashedIn,
  invoiceNumber,
  contactName,
  invoiceId,
  dueAmount,
  canReportDirectPayment,
  updateInvoiceTimeline,
}) => {
  const {authenticityToken} = useRails()

  const reportPaymentModal = useDisclosure()
  const reimbursementModal = useDisclosure()

  const handleSubmit = async (values) => {
    try {
      await createOutsidePayment({
        ...values,
        invoiceId,
        authenticityToken,
      })

      if (cashedIn && values.type === 'Full') {
        reimbursementModal.onOpen()
      }

      toast.dark(
        <ToastMessage>
          Invoice <strong>{invoiceNumber}</strong> marked as paid direct.
        </ToastMessage>,
      )

      reportPaymentModal.onClose()
    } catch (error) {
      toast.error(
        <ToastMessage>
          Unable to mark <strong>{invoiceNumber}</strong> as paid direct.
        </ToastMessage>,
      )
    } finally {
      await updateInvoiceTimeline()
    }
  }

  return (
    <>
      {canReportDirectPayment && (
        <LinkCard as="button" onClick={reportPaymentModal.onOpen} mb="sm">
          <Box display="flex" alignItems="center">
            <CashIcon color="primary.actionblue" fontSize="lg" />

            <Box
              mx="sm"
              fontSize="sm"
              lineHeight="3"
              name="report-direct-payment"
            >
              <strong>Payment made direct to your bank account?</strong>
              <br />
              Let us know if your customer has accidentally paid you directly.
            </Box>
          </Box>
        </LinkCard>
      )}

      <Dialog
        title={`You owe Marmalade ${dueAmount} for invoice ${invoiceNumber}`}
        confirmLabel="Settle via Cash-in now"
        closeLabel="Close"
        isOpen={reimbursementModal.isOpen}
        onClose={reimbursementModal.onClose}
        onConfirm={() => window.location.assign('/cashin')}
      >
        Settle this outstanding amount by Cashing-in another invoice.
      </Dialog>

      <ReportDirectPaymentForm
        isOpen={reportPaymentModal.isOpen}
        onClose={reportPaymentModal.onClose}
        onSubmit={handleSubmit}
        invoiceNumber={invoiceNumber}
        contactName={contactName}
      />
    </>
  )
}

ReportDirectPayment.propTypes = {
  canReportDirectPayment: PropTypes.bool,
  cashedIn: PropTypes.bool,
  invoiceNumber: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  dueAmount: PropTypes.string.isRequired,
  updateInvoiceTimeline: PropTypes.func,
}

ReportDirectPayment.defaultProps = {
  updateInvoiceTimeline: noop,
  canReportDirectPayment: false,
  cashedIn: false,
}

export default ReportDirectPayment
