import React from 'react'
import {Box, Text, Link} from '@chakra-ui/react'

import {useRails} from '../../../../contexts/rails'
import {Alert} from '../../../../components'

const UnsupportedPlan = () => {
  const {accountingProvider} = useRails()

  return (
    <Box display="flex" flexDirection="column">
      <Alert
        status="error"
        message={`Your ${accountingProvider.name} plan is not supported.`}
        mb={8}
      />

      <Box ml={5} mr={5}>
        {accountingProvider.name === 'Quickbooks' && (
          <>
            <Text textStyle="body-small" mb={4}>
              Your current plan does not have the &#8216;Bill Management&#8217;
              feature which is required to use Marmalade.
            </Text>
            <Text textStyle="body-small" mb={4}>
              Please{' '}
              <Link
                color="primary.actionblue"
                href="https://quickbooks.intuit.com/pricing"
                rel="nofollow"
                target="_blank"
                textDecoration="underline"
                _hover={{textDecoration: 'none'}}
              >
                switch your plan
              </Link>{' '}
              to Essentials or Plus to proceed.
            </Text>
          </>
        )}
        <Text textStyle="body-small">
          <Text textStyle="body-small" fontWeight="medium" as="span">
            Need help?
          </Text>{' '}
          <Link
            color="primary.actionblue"
            href="mailto:support@withmarmalade.com"
            target="_blank"
            textDecoration="underline"
            _hover={{textDecoration: 'none'}}
          >
            Contact us
          </Link>
        </Text>
      </Box>
    </Box>
  )
}

export default UnsupportedPlan
