export const colorMode = (light, dark) => {
  return (props) => (props.colorMode === 'dark' ? dark : light)
}

const marmaladeColors = {
  success: {
    50: '#F6FFF0',
    100: '#ECFFE0',
    700: '#46A50B',
    800: '#368009',
    900: '#2B6507',
  },
  alert: {
    50: '#FEF8F7',
    100: '#FEF3F1',
    200: '#F9E4E5',
    700: '#DB3328',
    800: '#BA2E25',
    900: '#982A1B',
  },
  warning: {
    50: '#FFFBF4',
    100: '#FFF8EB',
    700: '#FFC349',
    800: '#916400',
    900: '#80460D',
  },
  rewards: {
    bronze: 'linear-gradient(135deg, #994E2B 0.45%, #E18253 79.86%)',
    silver: 'linear-gradient(135deg, #7A7A7A 0.45%, #B8B8B8 69.8%)',
    gold: 'linear-gradient(135deg, #C1932E 0.45%, #DDC56F 89.87%)',
    platinum: 'linear-gradient(135deg, #B8B8B8 0.45%, #FBFBFB 84.93%)',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#F1F1F1',
    200: '#F1EFED',
    500: '#D7D2CE',
    600: '#CCC6C1',
    700: '#86827E',
    800: '#363636',
    900: '#1A1A1A',
    1000: '#000000',
  },
  primary: {
    70: '#FF9F7D',
    100: '#F75217',
    200: '#F75E15',
    300: '#FF7645',
    400: '#FF740F',
  },
  gradients: {
    100: 'linear-gradient(to right, #FFFFFF, #EBE9E7)',
    200: 'linear-gradient(to right, #343434, #000000)',
  },
}

export const colors = {
  mld: {
    ...marmaladeColors,
    sideNav: {
      background: '#1A1A1A',
      color: marmaladeColors.neutral[500],
      dividerColor: marmaladeColors.neutral[100],
      btnColor: marmaladeColors.neutral[100],
      btnHoverBg: marmaladeColors.neutral[800],
      btnHoverColor: marmaladeColors.neutral[500],
      navActiveBg: marmaladeColors.neutral[800],
      navHoverBg: marmaladeColors.primary[100],
      navHoverColor: marmaladeColors.neutral[900],
    },
    progress: {
      primary: {
        500: marmaladeColors.primary[100],
      },
    },
  },
  primary: {
    purple: '#5708FF',
    navypurple: '#110036',
    actionblue: '#4946E9',
    red: '#FF6B43',
    orange: '#FF9A35',
    yellow: '#FFD174',
    errorred: '#EC4067',
    actionhover: '#6562FF',
    destructive: '#DE0220',
    destructivehover: '#F1102F',
    50: '#EDECFD',
    700: '#6039F8',
    900: '#4123A7',
    deeppurple: '#0C002C',
  },
  grey: {
    100: '#F6F6F9',
    300: '#E9E9F0',
    500: '#B7B7C1',
    700: '#74748D',
    900: '#0B0B0F',
  },
  secondary: {
    blue: '#1789FC',
    lightblue: '#6980F4',
    green: '#29DE9D',
    purple: '#6C1CD2',
    yellow: '#FFC738',
  },
  neutral: {
    black: '#1B1C22',
    white: '#FFFFFF',
    light: '#F9F9F9',
    smoke: '#F5F7F9',
    dust: '#F4F4F5',
    glitter: '#E5EAEF',
    10: '#EAEAEA',
    20: '#D4D4D6',
    40: '#A9A9AD',
    50: '#FBFCFD',
    60: '#7D7E85',
    80: '#52535C',
    100: '#F8F9FC',
    200: '#F0F0EF',
    300: '#DEDDDB',
    500: '#77746E',
    600: '#878D99',
    700: '#707580',
    800: '#4F5259',
  },
  background: {
    purple: '#F1F1FF',
    blue: '#BDD3FC',
    red: '#FFD4D7',
    orange: '#FFF2E9',
    green: '#D1F9F0',
    yellow: '#FCF1BA',
    stripedgrey:
      'repeating-linear-gradient(-45deg, #E9E9E8, #E9E9E8 2px, #F0F0EF 2px, #F0F0EF 14px)',
    bluegradient: 'linear(107.64deg, #2A63F6, #5708FF)',
  },
  skeleton: {
    light: '#F1F1F5',
    dark: '#DFDFE4',
  },
  provider: {
    xero: '#1AB4D7',
    quickbooks: '#108000',
    myob: '#623ea6',
  },
  warning: {
    100: '#FFF8EB',
    900: '#80460D',
  },
  // Ref: https://smart-swatch.netlify.com/
  // Alt resource: https://palx.jxnblk.com/
  // Base colour: #26003A
  indigo: {
    500: '#4946E9',
  },
  blue: {
    50: '#E1EAFD',
    200: '#4382F7',
    300: '#2A63F6',
    400: '#0A4AEB',
  },
  purple: {
    50: '#f8e4ff',
    100: '#e5b3ff',
    200: '#d281fd',
    300: '#c04ffc',
    400: '#af21fb',
    500: '#950ce2',
    600: '#7407b0',
    700: '#54037e',
    800: '#32004d',
    900: '#12001d',
  },
  green: {
    500: '#0D6651',
  },
  // Base colour: #FF6B43
  red: {
    50: '#ffe8df',
    100: '#ffc2b0',
    200: '#ff9b7f',
    300: '#ff724d',
    400: '#fe4b1b',
    500: '#e53202',
    600: '#b32600',
    700: '#811a00',
    800: '#4f0d00',
    900: '#200100',
  },
  // Base colour: #FF9A35
  orange: {
    50: '#fff1dc',
    100: '#ffd8af',
    200: '#ffbf7f',
    300: '#ffa64d',
    400: '#fe8c1c',
    500: '#E57303',
    600: '#b35900',
    700: '#804000',
    800: '#4e2500',
    900: '#1f0b00',
  },
  // Base colour: #FFD174
  yellow: {
    50: '#fff7dd',
    100: '#ffe6b0',
    200: '#ffd580',
    300: '#ffc44f',
    400: '#feb320',
    500: '#e59a0b',
    600: '#b37704',
    700: '#805500',
    800: '#4e3300',
    900: '#1d1000',
  },
}
