import Dinero from 'dinero.js'
import dayjs from 'dayjs'

/* Locale are all default to 'en-AU' usage */

const locale = 'en-AU'

// Monetary value conversion from cents into currency
export const toCurrency = (amountInCents = 0, format = '$0,0.00') =>
  Dinero({amount: amountInCents, currency: 'AUD'})
    .setLocale(locale)
    .toFormat(format)

// Converting epochtime to time format
export const toLocalTime = (epochtime) => {
  const date = new Date(epochtime)

  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid local date conversion')
  }

  const formatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
  })
  return formatter.format(date)
}

// Converting epochtime to date format
export const toLocalDate = (epochtime, options = {}) => {
  const date = new Date(epochtime)

  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid local date conversion')
  }

  const formatter = new Intl.DateTimeFormat(locale, options)
  return formatter.format(date)
}

// Unix timestamp conversion from seconds to milliseconds
// since JS accepts milliseconds to construct Date object
export const toEpochMillisecond = (epochtimeInSeconds) =>
  epochtimeInSeconds * 1000

// Unix timestamp conversion from milliseconds to seconds
// since Rails API is expecting a timestamp in seconds
export const toEpochSecond = (epochtimeInMilliseconds) =>
  parseInt(epochtimeInMilliseconds / 1000, 10)

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const toGraphDateMonth = (epochtime) => {
  const dt = new Date(epochtime)

  const day = dt.getDate()
  const month = months[dt.getMonth()]

  return `${day} ${month}`
}

export const formatDateString = (dateString) => {
  if (!dateString) {
    return ''
  }

  return dayjs(dateString).format('D MMMM YYYY')
}

// E.g.: 1234 -> "1,234"
export const formatNumber = (number) => {
  return Intl.NumberFormat(locale).format(number)
}

// DE systems accept numbers, letters & space only.
// 18 character limit is set for consistency across all banks.
export const toDEformat = (code) => {
  return code.replace(/[^A-Za-z0-9 ]+/g, '')
}

// E.g.: "000000" -> "000-000"
export const formatBsb = (bsb) => {
  if (bsb === '') return bsb
  return `${bsb.substring(0, 3)}-${bsb.substring(3)}`
}

// E.g.:
// "000123456" -> "0001-23456"
// "12345678" -> "1234-5678"
export const formatAccountNumber = (accountNumber) => {
  if (accountNumber === '') return accountNumber
  return `${accountNumber.substring(0, 4)}-${accountNumber.substring(4)}`
}

// E.g.: "00000000123456"
// BSB -> 000000
// Account number -> 00123456
export const toBankDetailRepresentation = (bankAccountNumber) => {
  const bsb = bankAccountNumber.slice(0, 6)
  const accountNumber = bankAccountNumber.slice(6)

  return {
    bsb: formatBsb(bsb),
    accountNumber: formatAccountNumber(accountNumber),
  }
}

// E.g: "21006819692" => 21 006 819 692
export const formatAbn = (abn) => {
  if (!abn) return abn

  const sanitized = abn.replace(/\s+/g, '')

  return `${sanitized.substring(0, 2)} ${sanitized.substring(
    2,
    5,
  )} ${sanitized.substring(5, 8)} ${sanitized.substring(8)}`
}
