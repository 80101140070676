import React from 'react'
import {Flex, Skeleton} from '@chakra-ui/react'

const TabContentLoading = () => (
  <Flex
    height="100%"
    width="100%"
    direction="column"
    justify="center"
    align="center"
    gap="md"
    data-testid="settings-tab-loading"
  >
    <Skeleton width="100%" height="20vh" />
    <Skeleton width="100%" height="20vh" />
    <Skeleton width="100%" height="20vh" />
  </Flex>
)

export default TabContentLoading
