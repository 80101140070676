import React from 'react'
import PropTypes from 'prop-types'
import {Button, Divider, Flex, Text} from '@chakra-ui/react'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'
import BillPaymentsTable from '../../components/BillPaymentsTable'
import {useRails} from '../../contexts/rails'

const BillPaymentsIndexScreen = withProviders(({authenticityToken}) => {
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
  } = useRails()
  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Pay bills">
        <Flex flexDirection="column" height="100vh" p="md">
          <Flex justify="space-between">
            <Text as="h2" textStyle="headline2">
              Bill payments
            </Text>

            <Button
              as="a"
              href="/bill_payments/new"
              variant="primary"
              colorScheme="primary"
            >
              Make a payment
            </Button>
          </Flex>

          <Divider my="md" />

          <BillPaymentsTable supplierId={supplierId} />
        </Flex>
      </SupplierLayout>
    </AuthProvider>
  )
})

BillPaymentsIndexScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default BillPaymentsIndexScreen
