import React from 'react'
import {Flex, Spinner, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

const CreatedInstructions = () => {
  const {t} = useTranslation('billPayments')

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100%"
      gap="sm"
    >
      <Spinner size="lg" />
      <Text textStyle="body-copy-medium">
        {t('billPayments:paymentMethods.debitAgreement.instructions.created')}
      </Text>
    </Flex>
  )
}

export default CreatedInstructions
