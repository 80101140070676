import PropTypes from 'prop-types'
import React from 'react'
import {Field, Form, Formik} from 'formik'
import {object, string, date} from 'yup'
import {Text, FormControl, FormLabel, HStack, Button} from '@chakra-ui/react'
import 'yup-phone-lite'

import Input from '../Input'
import DatePicker from '../DatePicker'

const detailsSchema = object().shape({
  type: string(),
  contactName: string().required('Please enter a contact name'),
  contactEmail: string()
    .email('Please enter a valid email address')
    .required('Please enter a contact email'),
  contactPhone: string()
    .phone('AU', 'Please enter a valid Australian phone number')
    .required('Please enter a contact phone number'),
  paymentDate: date().when('type', {
    is: 'customer_promised_to_pay',
    then: () =>
      date()
        .typeError('Please enter a payment date')
        .required('Please enter a payment date'),
    otherwise: () => date().strip(),
  }),
})

const PayerArrangementDetailsForm = ({type, onSubmit, onBack}) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        type,
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        paymentDate: null,
      }}
      validationSchema={detailsSchema}
    >
      {({values, errors, touched, setFieldValue, setFieldTouched}) => (
        <Form>
          {values.type === 'customer_promised_to_pay' && (
            <>
              <Text mb="sm">
                We’ll contact your customer about the promised payment date.
              </Text>

              <FormControl>
                <FormLabel
                  fontWeight="medium"
                  fontSize="base"
                  htmlFor="paymentDate"
                >
                  When will your customer pay?
                </FormLabel>

                <DatePicker
                  id="paymentDate"
                  selectedDate={values.paymentDate}
                  onChange={(value) => setFieldValue('paymentDate', value)}
                  onBlur={() => setFieldTouched('paymentDate')}
                />

                {touched.paymentDate && errors.paymentDate && (
                  <Text mt={-3} mb="sm" textStyle="body-detail" color="red.500">
                    {errors.paymentDate}
                  </Text>
                )}
              </FormControl>
            </>
          )}

          <Text fontWeight="medium" mb="xs">
            Who should we contact?
          </Text>

          <FormControl>
            <FormLabel htmlFor="contactName" hidden>
              Contact name
            </FormLabel>

            <Field
              as={Input}
              errorMessage={touched.contactName && errors.contactName}
              type="text"
              name="contactName"
              placeholder="Contact name"
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="contactPhone" hidden>
              Contact phone number
            </FormLabel>

            <Field
              as={Input}
              errorMessage={touched.contactPhone && errors.contactPhone}
              type="text"
              name="contactPhone"
              placeholder="Contact phone number"
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="contactEmail" hidden>
              Contact email
            </FormLabel>

            <Field
              as={Input}
              errorMessage={touched.contactEmail && errors.contactEmail}
              type="text"
              name="contactEmail"
              placeholder="Contact email"
            />
          </FormControl>

          <HStack justifyContent="space-between">
            <Button variant="secondary" size="lg" onClick={onBack}>
              Back
            </Button>

            <Button variant="primary" size="lg" type="submit">
              Submit
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  )
}

PayerArrangementDetailsForm.propTypes = {
  type: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default PayerArrangementDetailsForm
