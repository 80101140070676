import React, {useEffect, useState} from 'react'
import useSWRImmutable from 'swr/immutable'

import {fetcher} from '../../../api'
import ProductFeatureBar from '../ProductFeatureBar'

const KEY = 'acknowledgements'

const Announcements = () => {
  const {data, error, isLoading} = useSWRImmutable(
    '/api/announcements',
    fetcher,
  )

  const [acknowledgements, setAcknowledgements] = useState(
    JSON.parse(localStorage.getItem(KEY)) ?? [],
  )

  useEffect(() => {
    localStorage.setItem(KEY, JSON.stringify(acknowledgements))
  }, [acknowledgements])

  if (error || isLoading) {
    return null
  }

  if (!data || !data.announcements) {
    return null
  }

  const [announcement] = data.announcements

  if (!announcement) {
    return null
  }

  if (acknowledgements.includes(announcement.id)) {
    return null
  }

  return (
    <ProductFeatureBar
      text={announcement.text}
      href={announcement.href}
      id={announcement.id}
      onAcknowledge={() =>
        setAcknowledgements([...acknowledgements, announcement.id])
      }
    />
  )
}

export default Announcements
