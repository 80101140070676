import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Divider, Flex, Text} from '@chakra-ui/react'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'
import DebitAgreementForm from '../../components/DebitAgreementForm'

const CreatePaymentMethodScreen = withProviders(({authenticityToken}) => {
  const {t} = useTranslation('billPayments')
  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Pay bills">
        <Flex flexDirection="column" height="100vh" p="md">
          <Text as="h2" textStyle="headline2" px="md">
            {t('billPayments:paymentMethods.debitAgreement.title')}
          </Text>

          <Divider my="md" />

          {/* For now there is only a single payment_method type so just render the form */}
          <DebitAgreementForm />
        </Flex>
      </SupplierLayout>
    </AuthProvider>
  )
})

CreatePaymentMethodScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default CreatePaymentMethodScreen
