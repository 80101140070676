import React from 'react'
import PropTypes from 'prop-types'
import {Link, Text} from '@chakra-ui/react'
import {toEpochMillisecond, toLocalDate} from '../../../../../utils/formatter'

const InvoiceNumberCell = ({getValue, row}) => (
  <>
    <Link
      href={`/invoices?invoice_id=${row.original.id}`}
      target="_blank"
      fontSize="sm"
      mr="xs"
      textDecoration="underline"
    >
      {getValue()}
    </Link>
    <Text textStyle="body-detail" display={{base: 'block', lg: 'none'}} mt="xs">
      {toLocalDate(toEpochMillisecond(row.original.dueDate), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}
    </Text>
  </>
)

InvoiceNumberCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      dueDate: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default InvoiceNumberCell
