import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CloseButton,
  Flex,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

const ServiceAgreementModal = () => {
  const {t} = useTranslation('onboarding')
  const {isOpen, onClose, onOpen} = useDisclosure()

  const openModal = (event) => {
    event.stopPropagation()
    event.preventDefault()
    onOpen()
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link as="button" variant="primary" onClick={openModal}>
        {t('onboarding:SupplierAgreementModal.triggerLabel')}
      </Link>
      <Modal
        isOpen={isOpen}
        size="xl"
        onClose={onClose}
        preserveScrollBarGap
        scrollBehavior="inside"
      >
        <ModalOverlay zIndex="modal" />
        <ModalContent borderRadius="lg" px="md" py="sm" maxWidth="1000px">
          <ModalHeader fontSize="lg">
            <Flex alignItems="center" justifyContent="space-between">
              <Text textStyle="headline4" textTransform="uppercase">
                {t('onboarding:SupplierAgreementModal.title')}
              </Text>

              <CloseButton onClick={onClose} />
            </Flex>
          </ModalHeader>

          <ModalBody>
            <OrderedList spacing="md">
              <ListItem>
                By agreeing to the Direct Debit Request you authorise Stripe to
                arrange for funds to be debited from your nominated financial
                institution account (the “nominated account”). Stripe is acting
                as an agent for the Merchant and Stripe does not provide any
                goods or services to you.
              </ListItem>

              <ListItem>
                Stripe or the Merchant will give you at least 14 days notice in
                writing of any changes to the terms of the drawing arrangements.
              </ListItem>

              <ListItem>
                Stripe will keep information relating to your nominated account
                confidential in accordance with Stripe’s{' '}
                <Link target="_blank" href="https://stripe.com/au/privacy">
                  privacy policy
                </Link>
                , except where required for the purposes of conducting direct
                debits with your financial institution. Your personal
                information will be transferred by Stripe to the United States.
                If you do not want to provide your personal information to
                Stripe in connection with the Direct Debit Request, Stripe will
                not be able to debit your nominated account.
              </ListItem>

              <ListItem>
                Where the due date is not a business day Stripe will draw from
                your nominated financial institution account on the next
                business day.
              </ListItem>

              <ListItem>
                It is your responsibility to:
                <OrderedList ml="lg" spacing="xs" listStyleType="lower-alpha">
                  <ListItem>
                    Ensure your nominated account can accept direct debits;
                  </ListItem>

                  <ListItem>
                    Ensure that there are sufficient clear funds available in
                    the nominated account to meet each drawing on the due date;
                  </ListItem>

                  <ListItem>
                    Advise immediately if the nominated account is transferred
                    or closed or your account details change;
                  </ListItem>

                  <ListItem>
                    Arrange a suitable payment method if Stripe or the Merchant
                    cancels the drawing arrangements;
                  </ListItem>

                  <ListItem>
                    Ensure that all authorised signatories nominated on the
                    financial institution account to be debited authorise the
                    Direct Debit Request.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                Subject to the terms and conditions of your nominated financial
                institution account and your agreement with the Merchant, you
                may alter the drawing arrangements. Such advice should be
                received by the Merchant at least 7 business days prior to the
                drawing date for any of the following:
                <OrderedList ml="lg" spacing="xs" listStyleType="lower-alpha">
                  <ListItem>Changing your nominated account number</ListItem>

                  <ListItem>Deferring a drawing</ListItem>

                  <ListItem>Altering a DDR schedule</ListItem>

                  <ListItem>Cancelling the drawings completely</ListItem>
                </OrderedList>
                If you require further information, please contact the Merchant.
                Alternatively, you can also contact your financial institution.
              </ListItem>

              <ListItem>
                If you believe that there has been an error in debiting your
                account, you should notify the Merchant as soon as possible. The
                Merchant will notify you in writing of its determination and the
                amount of any adjustment that will be made to your nominated
                account (if any). Stripe will arrange for your financial
                institution to adjust your nominated account by the applicable
                amount (if any). Alternatively, you can also contact your
                financial institution.
              </ListItem>

              <ListItem>
                The details of your drawing arrangements are contained in the
                above Direct Debit Request.
              </ListItem>

              <ListItem>
                Stripe reserves the right to cancel the drawing arrangements if
                three consecutive drawings are dishonoured by your financial
                institution, and for the Merchant to arrange with you an
                alternative payment method. Please refer to the terms and
                conditions of your nominated financial institution account to
                see whether dishonour fees apply. The Merchant may charge
                additional dishonour fees in accordance with your agreement with
                the Merchant.
              </ListItem>
            </OrderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ServiceAgreementModal
