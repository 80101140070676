import React from 'react'
import {Box} from '@chakra-ui/react'
import propTypes from 'prop-types'

const ProgressBar = ({isProgressed = false}) => (
  <Box
    w="64px"
    h="2px"
    bg={isProgressed ? 'primary.actionblue' : 'neutral.20'}
    mx={[0, 2]}
    mt={[2, 0]}
  />
)

ProgressBar.propTypes = {
  isProgressed: propTypes.bool.isRequired,
}

export default ProgressBar
