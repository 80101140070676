import React from 'react'
import PropTypes from 'prop-types'
import NoWrapCell from './NoWrapCell'
import {toLocalDate} from '../../../utils/formatter'

const DateCell = ({getValue}) => {
  const value = getValue()

  const customGetValue = () =>
    toLocalDate(value, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })

  return <NoWrapCell getValue={customGetValue} />
}

DateCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export default DateCell
