import React from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Box,
  Text,
  Tooltip,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react'
import {WarningIcon} from '@chakra-ui/icons'
import {useTranslation} from 'react-i18next'
import pluralize from 'pluralize'

import {toLocalDate, toEpochMillisecond} from '../../../utils/formatter'
import {
  PaperClipIcon,
  SmallInverseTickedIcon,
  InverseTickedIcon,
  CoinCashInIcon,
} from '../../icons'

export const TruncatedCell = ({getValue}) => (
  <Text maxWidth="120px" noOfLines={1}>
    <Tooltip label={getValue()} zIndex="tooltip">
      {getValue()}
    </Tooltip>
  </Text>
)

TruncatedCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export const InvoiceDueDateCell = ({getValue}) => (
  <Text>{toLocalDate(toEpochMillisecond(getValue()))}</Text>
)

InvoiceDueDateCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export const InvoicePaymentStatusCell = ({getValue, row}) => {
  if (getValue() === 'Paid') {
    return (
      <Tag variant="status" colorScheme="success">
        <TagLeftIcon
          boxSize="12px"
          mr="1"
          color="mld.success.700"
          as={InverseTickedIcon}
        />
        <TagLabel>Paid</TagLabel>
      </Tag>
    )
  }

  if (getValue() === 'Unpaid' && row.original.overDue) {
    return (
      <Tag variant="status" colorScheme="alert">
        <TagLeftIcon
          boxSize="12px"
          mr="1"
          color="mld.alert.700"
          as={WarningIcon}
        />
        <TagLabel>overdue</TagLabel>
        <Badge variant="nestedStatus" colorScheme="alert">
          {row.original.overDueDays}{' '}
          {pluralize('day', row.original.overDueDays)}
        </Badge>
      </Tag>
    )
  }

  if (getValue() === 'Partially Paid') {
    return (
      <Tag variant="status" colorScheme="neutral">
        <TagLeftIcon boxSize="12px" mr="1" as={CoinCashInIcon} />
        <TagLabel>partially unpaid</TagLabel>
      </Tag>
    )
  }

  return (
    <Tag variant="status" colorScheme="info">
      <TagLeftIcon boxSize="12px" mr="1" as={CoinCashInIcon} />
      <TagLabel>{getValue()}</TagLabel>
    </Tag>
  )
}

InvoicePaymentStatusCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      overDue: PropTypes.bool.isRequired,
      overDueDays: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export const InvoiceCashedInCell = ({getValue}) => (
  <Box display="flex" alignItems="center">
    {getValue() ? (
      <Tag variant="primary" colorScheme="primary">
        <TagLeftIcon boxSize="12px" as={SmallInverseTickedIcon} mr="1" />
        <TagLabel>Cashed-in</TagLabel>
      </Tag>
    ) : (
      <Text>Not cashed-in</Text>
    )}
  </Box>
)

InvoiceCashedInCell.propTypes = {
  getValue: PropTypes.func.isRequired,
}

export const InvoiceDueAmountCell = ({getValue, row}) => {
  const {t} = useTranslation('invoicesPage')
  const creditNotesCount = row.original.creditNoteApplications?.length
  return creditNotesCount ? (
    <Tooltip
      label={t('invoicesPage:creditNoteAppliedTooltip', {
        count: creditNotesCount,
      })}
      zIndex="tooltip"
      boxShadow="lg"
      hasArrow
    >
      <Text>
        <PaperClipIcon mr="xs" />
        {getValue()}
      </Text>
    </Tooltip>
  ) : (
    <Text>{getValue()}</Text>
  )
}

InvoiceDueAmountCell.propTypes = {
  getValue: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      creditNoteApplications: PropTypes.arrayOf(
        PropTypes.shape({id: PropTypes.string.isRequired}),
      ),
    }),
  }).isRequired,
}
