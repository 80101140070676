import React from 'react'
import PropTypes from 'prop-types'

import {AsyncDropdownSelect} from '../DropdownSelect'

const loadOptions = async (inputValue) => {
  if (inputValue.length < 3) {
    return []
  }

  const resp = await fetch(
    `/api/customers?name=${encodeURIComponent(inputValue)}`,
  )
  const data = await resp.json()

  return data.customers.map((customer) => {
    return {
      label: customer.contact_name,
      value: customer.id,
    }
  })
}

const CustomerFilter = ({value, onChange}) => {
  return (
    <AsyncDropdownSelect
      loadOptions={loadOptions}
      name="customer"
      value={value}
      noOptionsMessage={({inputValue}) => {
        if (inputValue.length < 3) {
          return 'Start typing to search...'
        }
        return 'No customers found'
      }}
      placeholder="Customer name"
      onChange={onChange}
    />
  )
}

CustomerFilter.defaultProps = {
  value: undefined,
  onChange: () => {},
}

CustomerFilter.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

export default CustomerFilter
