import {Flex, Stack} from '@chakra-ui/react'
import {Global} from '@emotion/react'
import PropTypes from 'prop-types'
import React from 'react'

import {LogoHorizontal, ThemeProvider} from '../../components'
import CustomerPaymentBgImage from '../../../../assets/images/customer_payment_bg_screen.png'
import theme from '../../theme'

const Layout = ({children}) => {
  return (
    <ThemeProvider>
      <Global
        styles={{
          body: {
            backgroundColor: theme.colors.primary.navypurple,
          },
        }}
      />

      <Flex
        direction="column"
        align="center"
        minH="100vh"
        p={{base: 'md', md: 'xl'}}
        bgImage={`url(${CustomerPaymentBgImage})`}
        bgSize="cover"
      >
        <Stack
          spacing="md"
          maxW={550}
          p="lg"
          bg="white"
          borderRadius="lg"
          boxShadow="base"
        >
          <LogoHorizontal width={181} height={22} mb="sm" />

          {children}
        </Stack>
      </Flex>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
