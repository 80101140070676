import React from 'react'
import AccountIntegrationWelcome from './AccountIntegrationWelcome'
import AccountIntegrationSelector from './AccountIntegrationSelector'
import AccountIntegrationConnect from './AccountIntegrationConnect'

const AccountIntegration = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [accountingProvider, setAccountingProvider] = React.useState(null)

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <>
      {currentPage === 1 && (
        <AccountIntegrationWelcome goToNextPage={goToNextPage} />
      )}
      {currentPage === 2 && (
        <AccountIntegrationSelector
          goToNextPage={goToNextPage}
          accountingProvider={accountingProvider}
          setAccountingProvider={setAccountingProvider}
        />
      )}
      {currentPage === 3 && (
        <AccountIntegrationConnect accountingProvider={accountingProvider} />
      )}
    </>
  )
}

export default AccountIntegration
