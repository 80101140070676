import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {HamburgerIcon} from '@chakra-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import SideNav from './SideNav'
import {LogoHorizontalSecondary} from '../../../../components'
import backgroundImage1 from '../../../../../../assets/images/onboarding_background_1.svg'
import backgroundImage2 from '../../../../../../assets/images/onboarding_background_2.svg'
import backgroundImage3 from '../../../../../../assets/images/onboarding_background_3.svg'
import backgroundImage4 from '../../../../../../assets/images/onboarding_background_4.svg'
import backgroundImage5 from '../../../../../../assets/images/onboarding_background_5.svg'
import backgroundImage6 from '../../../../../../assets/images/onboarding_background_6.svg'

const smallScreenBreakpoint = 'lg'

const MobileNav = ({onOpen, ...rest}) => {
  return (
    <Flex
      ml={{base: 0, [smallScreenBreakpoint]: 60}}
      px={{base: 4, [smallScreenBreakpoint]: 24}}
      height="50px"
      alignItems="center"
      justifyContent="center"
      bg="mld.sideNav.background"
      {...rest}
    >
      <IconButton
        position="absolute"
        left="0px"
        ml="sm"
        colorScheme="mld.sideNav.background"
        onClick={onOpen}
        aria-label="Open navigation menu"
        icon={<HamburgerIcon width="24px" height="24px" />}
      />
      <LogoHorizontalSecondary width={150} height={20} />
    </Flex>
  )
}

MobileNav.propTypes = {
  onOpen: PropTypes.func.isRequired,
}

const SideNavLayout = ({children, dropdownSelectProps}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const mobileView = useBreakpointValue({
    base: true,
    [smallScreenBreakpoint]: false,
  })
  const backgroundImages = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
    backgroundImage6,
  ]
  const backgroundImage =
    backgroundImages[Math.floor(Math.random() * backgroundImages.length)]

  return (
    <Flex direction={{base: 'column', lg: 'row'}}>
      {!mobileView && (
        <Box width="386px">
          <SideNav
            dropdownSelectProps={
              dropdownSelectProps.value.value ? dropdownSelectProps : null
            }
          />
        </Box>
      )}
      {mobileView && (
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <SideNav
              dropdownSelectProps={
                dropdownSelectProps.value.value ? dropdownSelectProps : null
              }
              onClose={onClose}
            />
          </DrawerContent>
        </Drawer>
      )}
      <MobileNav
        display={{base: 'flex', [smallScreenBreakpoint]: 'none'}}
        onOpen={onOpen}
      />

      <Flex
        width="full"
        mx="auto"
        direction="column"
        alignItems="center"
        justifyContent={{
          base: 'start',
          [smallScreenBreakpoint]: 'space-around',
        }}
        backgroundSize="cover"
        backgroundImage={backgroundImage}
        height="100vh"
      >
        <Box
          px="sm"
          width="100%"
          maxWidth={{base: '498px', '2xl': '676px'}}
          marginTop={{base: 'lg', [smallScreenBreakpoint]: '0'}}
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  )
}

const dropdownOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

SideNavLayout.propTypes = {
  dropdownSelectProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(dropdownOptionShape).isRequired,
    value: dropdownOptionShape.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default SideNavLayout
