import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {chakra, Avatar, Box, Flex, Text, Link} from '@chakra-ui/react'
import {ChevronRightIcon} from '@chakra-ui/icons'
import {ChecklistIcon, ReceiptIcon, UpcomingIcon} from '../../icons'
import LogoSymbol from '../Logo/LogoSymbol'

const StatItem = ({
  icon,
  statValue,
  statTitle,
  statText,
  linkText,
  linkUrl,
}) => {
  return (
    <Box
      maxWidth="360px"
      flex="1 1 0"
      borderRadius="base"
      border="1px solid"
      borderColor="neutral.300"
    >
      <Flex
        mt="xs"
        mr="xs"
        ml="auto"
        width="fit-content"
        backgroundColor="neutral.100"
        p="xxs"
        borderRadius="xl"
      >
        <LogoSymbol boxSize="4" my="auto" mr="xs" />
        <Text
          textStyle="body-detail"
          fontWeight="medium"
          color="neutral.600"
          textTransform="uppercase"
        >
          Score
        </Text>
      </Flex>
      <Flex mt="xs" mb="sm" mx="md">
        {icon}
        <Box ml="md">
          <Text display="flex" gap="xxs" textStyle="body-small">
            <chakra.b fontSize="md">{statValue}%</chakra.b>{' '}
            <chakra.span>{statTitle}</chakra.span>
          </Text>
          <Text
            textStyle="body-detail"
            color="neutral.700"
            mt="xxs"
            whiteSpace="nowrap"
          >
            {statText}
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" py="xxs" backgroundColor="grey.100">
        <Link
          href={linkUrl}
          isExternal
          ml="auto"
          mr="xs"
          fontSize="xs"
          color="primary.700"
          _hover={{textDecoration: 'none'}}
        >
          {linkText}
          <ChevronRightIcon ml="xs" my="auto" />
        </Link>
      </Flex>
    </Box>
  )
}

StatItem.propTypes = {
  icon: PropTypes.node.isRequired,
  statValue: PropTypes.number.isRequired,
  statTitle: PropTypes.string.isRequired,
  statText: PropTypes.node.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
}

const MonthlyStats = ({
  currentIssuance,
  currentPayments,
  currentResolution,
}) => {
  return (
    <Box mt="sm">
      <Flex justifyContent="space-between" gap="xs" flexWrap="wrap">
        <Text textStyle="headline3" fontWeight="medium">
          {dayjs().subtract(1, 'month').format('MMMM')} in review
        </Text>
        <Box py="xxs" px="xs" backgroundColor="neutral.100" borderRadius="base">
          <Text textStyle="body-detail" my="auto">
            <b>Next monthly status review:</b>{' '}
            {dayjs().add(1, 'month').format('01 MMM YYYY')}
          </Text>
        </Box>
      </Flex>

      <Text textStyle="body-small" color="neutral.700" mt="sm" mb="sm">
        This is where we’ll show you how you’ve used Marmalade as your payment
        method for the previous month.
      </Text>
      <Flex flexWrap="wrap" gap="md">
        <StatItem
          icon={
            <Avatar
              my="auto"
              bg="background.yellow"
              aria-label="invoices-issued-icon"
              icon={<ReceiptIcon color="yellow.500" fontSize="24px" />}
            />
          }
          statValue={Math.round(currentIssuance * 100)}
          statTitle="Invoice"
          statText={
            <>
              Issue invoices with <br /> Marmalade payment details
            </>
          }
          linkText="Check invoice template"
          linkUrl="https://marmalade.ladesk.com/188377-Issuing-Invoices-with-Marmalade"
        />
        <StatItem
          icon={
            <Avatar
              my="auto"
              bg="background.blue"
              aria-label="payments-received-icon"
              icon={<UpcomingIcon color="blue.400" fontSize="24px" />}
            />
          }
          statValue={Math.round(currentPayments * 100)}
          statTitle="Payments"
          statText={
            <>
              Get paid on Marmalade <br />
              bank account
            </>
          }
          linkText="Learn more about Payment"
          linkUrl="https://marmalade.ladesk.com/248488-Receive-Payments-through-Marmalade"
        />
        <StatItem
          icon={
            <Avatar
              my="auto"
              bg="background.orange"
              aria-label="resolve-issues-icon"
              icon={<ChecklistIcon color="orange.500" fontSize="24px" />}
            />
          }
          statValue={Math.round(currentResolution * 100)}
          statTitle="Resolution"
          statText={
            <>
              Resolve invoice issues <br />
              raised within 15 days
            </>
          }
          linkText="Learn more about resolution"
          linkUrl="https://marmalade.ladesk.com/939353-Resolving-Invoice-Issues"
        />
      </Flex>
    </Box>
  )
}

MonthlyStats.propTypes = {
  currentIssuance: PropTypes.number.isRequired,
  currentPayments: PropTypes.number.isRequired,
  currentResolution: PropTypes.number.isRequired,
}

export default MonthlyStats
