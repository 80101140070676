import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import useSWR from 'swr'
import {
  Box,
  Text,
  Flex,
  Avatar,
  Divider,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react'
import {toCurrency} from '../../../utils/formatter'
import {generate as generateHistoryItems} from './history'

const statusProps = {
  success: {
    color: 'primary.actionblue',
    opacity: 0.5,
  },
  failed: {
    color: 'primary.actionblue',
    opacity: 0.5,
  },
  pending: {
    color: 'primary.actionblue',
    opacity: 0.5,
  },
  'latest-success': {
    color: 'primary.actionblue',
    opacity: 1,
  },
  'latest-failed': {
    color: 'primary.actionblue',
    opacity: 1,
  },
  'latest-pending': {
    color: 'primary.actionblue',
    opacity: 1,
  },
  'latest-completed': {
    color: 'secondary.green',
    opacity: 1,
  },
}

const ActivityHistoryItem = ({
  eventTime,
  label,
  secondaryLabel,
  amount,
  showDivider,
  status,
  icon,
  subLabel,
}) => {
  const parsedTime = dayjs.unix(eventTime)

  return (
    <Fragment key="label">
      <Flex direction="column">
        <Text textStyle="body-small">{parsedTime.format('DD/MM/YYYY')}</Text>
        <Text textStyle="body-small">{parsedTime.format('hh:mm a')}</Text>
      </Flex>

      <Flex
        direction="column"
        pt="xs"
        align="center"
        minH={showDivider ? '100px' : 'auto'}
      >
        <Avatar
          w="28px"
          h="28px"
          bg={statusProps[status].color}
          opacity={statusProps[status].opacity}
          size="md"
          color="neutral.white"
          icon={icon}
        />
        {showDivider && (
          <Divider
            variant="dark"
            orientation="vertical"
            borderLeftWidth="2px"
            mt="xs"
          />
        )}
      </Flex>

      <Flex direction="column">
        <Text textStyle="body-copy" fontWeight="medium">
          {label}
        </Text>
        <Text textStyle="body-copy">{secondaryLabel}</Text>
        <Text textStyle="body-small" color="grey.700" fontWeight="medium">
          {amount ? toCurrency(amount.cents) : '$TBC'}
          <Text
            as="span"
            textStyle="body-small"
            color="grey.700"
            ml="xs"
            fontWeight="medium"
          >
            {subLabel}
          </Text>
        </Text>
      </Flex>
    </Fragment>
  )
}

ActivityHistoryItem.defaultProps = {
  secondaryLabel: '',
  subLabel: '',
  amount: null,
}

ActivityHistoryItem.propTypes = {
  eventTime: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string,
  subLabel: PropTypes.string,
  icon: PropTypes.node.isRequired,
  amount: PropTypes.shape({
    cents: PropTypes.number,
    currency_iso: PropTypes.string,
  }),
  showDivider: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(Object.keys(statusProps)).isRequired,
}

const PaymentActivityHistory = ({paymentId}) => {
  const {
    data = {},
    error,
    isLoading,
  } = useSWR(`/api/suppliers/payments/${paymentId}`)

  const historyItems = generateHistoryItems(data.payment)

  return (
    <Box py="sm">
      <Text textStyle="body-intro-medium" pb="md">
        Activity history
      </Text>

      {isLoading && <Spinner />}
      {error && error.message && (
        <Text>Something went wrong fetching this information.</Text>
      )}

      <SimpleGrid templateColumns="auto auto 1fr" columnGap="sm">
        {historyItems.map((historyItem, index) => (
          <ActivityHistoryItem
            key={historyItem.label}
            {...historyItem}
            status={
              index === 0
                ? `latest-${historyItem.rawStatus}`
                : historyItem.rawStatus
            }
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

PaymentActivityHistory.propTypes = {
  paymentId: PropTypes.string.isRequired,
}

export default PaymentActivityHistory
